import { CoownershipModel } from './coowner-ship-model';
import { HousingUnitModel } from './housing-unit-model';
import { UserModel } from './user-model';
export class DoormanCorrespondenceModel {
    id: string;
    receiptDate: string;
    deliverDate: string;
    incomeTime: string;
    deliveryTime: string;
    receivedBy: string;
    deliveredBy: string;
    detail: string;
    correspondenceStatus: string;
    coOwnerShipId: CoownershipModel;
    housingUnitId: HousingUnitModel;

    public constructor() {
   this.id = null;
    this.receiptDate = '';
    this.deliverDate = '';
    this.incomeTime = '';
    this.deliveryTime = '';
    this.receivedBy = '';
    this.deliveredBy = '';
    this.detail = '';
    this.correspondenceStatus = '';
    this.coOwnerShipId = new CoownershipModel();
    this.housingUnitId = new HousingUnitModel();
    }

}
