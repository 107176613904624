import { UpdateProviderComponent } from './update-provider/update-provider.component';
import { DetailProviderComponent } from './detail-provider/detail-provider.component';
import { CreateProviderComponent } from './create-provider/create-provider.component';
import { RoleAuthGuard } from './../shared/auth/roleAuth-guard.service';
import { ListProviderComponent } from './list-provider/list-provider.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'listar-proveedor',
        component: ListProviderComponent,
        data: {
          title: 'listprovider',
          expectedRole: ['ROLE_COLLABORATOR', 'ROLE_ADMIN']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'crear-proveedor',
        component: CreateProviderComponent,
        data: {
          title: 'createprovider',
          expectedRole: ['ROLE_COLLABORATOR', 'ROLE_ADMIN']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'detalle-proveedor',
        component: DetailProviderComponent,
        data: {
          title: 'detailprovider',
          expectedRole: ['ROLE_COLLABORATOR', 'ROLE_ADMIN']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'actualizar-proveedor',
        component: UpdateProviderComponent,
        data: {
          title: 'updateprovider',
          expectedRole: ['ROLE_COLLABORATOR', 'ROLE_ADMIN']
        },
        canActivate: [RoleAuthGuard]
      }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProviderRoutingModule { }
