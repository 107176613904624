import { Component, OnInit, Input, ViewEncapsulation } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "environments/environment.prod";

@Component({
  selector: "app-detail-news",
  templateUrl: "./detail-news.component.html",
  styleUrls: ["./detail-news.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DetailNewsComponent implements OnInit {
  @Input() newsInfo;
  urlPeticionImg = environment.serverUrl + "api/archivos/ver-img?ruta=";
  html: string;
  constructor(private modalService: NgbModal) {}

  ngOnInit() {
    this.html = this.newsInfo.content;
    const div = document.getElementById("text");
    div.innerHTML += this.html;
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
