import { InvoiceTypeModel } from "./../../../models/entities/invoicetype-model";
import { InvoiceModel } from "./../../../models/entities/invoice-model";
import { GenericResponseModel } from "./../../../models/utilities/generic.response.model";
import { TokenStorageService } from "./../../shared/storage-services/token-storage.service";
import { InfoMessagesService } from "./../../shared/messages/info-messages.service";
import { CrudServiceService } from "./../../shared/backend/cruds/crud-service.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-update-invoice",
  templateUrl: "./update-invoice.component.html",
  styleUrls: ["./update-invoice.component.scss"],
})
export class UpdateInvoiceComponent implements OnInit {
  @Input() newInvoice: InvoiceModel = new InvoiceModel();

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  errorMessage: string;
  listTypeInvoice: Array<InvoiceTypeModel> = new Array();
  constructor(
    public activeModal: NgbActiveModal,
    public crudServices: CrudServiceService,
    public messageService: InfoMessagesService,
    public tokenStorage: TokenStorageService
  ) {}

  ngOnInit() {
    console.log("newInvoice", this.newInvoice);
    this.getInvoiceTypes();
  }

  onSubmit() {
    this.saveInvoice();
  }

  getInvoiceTypes() {
    this.crudServices
      .getModel("api/factura/obtener-tipos?newType=income")
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.listTypeInvoice = genericResponse.answerList;
          } else {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              "No se pudieron listar los tipos de facturas del sistema",
              "Problema consultando los tipos de facturas"
            );
          }
        },
        (error) => {
          this.messageService.getInfoMessageError();
          console.error(
            "Error al cargar los tipos de facturas" + JSON.stringify(error)
          );
        }
      );
  }

  saveInvoice() {
    this.crudServices
      .createModel("api/factura/actualizar", this.newInvoice)
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.messageService.getInfoMessageCreate();
            this.passEntry.emit("creado");
            this.activeModal.close();
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se creó la factura"
            );
            this.errorMessage = genericResponse.answer;
          }
        },
        (error) => {
          this.errorMessage = error.error.message;
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }
}
