export class ClientInfoRegisterModel {
  id: any;
  completeName: any;
  email: any;
  subject: any;
  description: any;
  phoneNumber: any;

  public constructor() {
    this.id = null;
    this.completeName = "";
    this.email = "";
    this.subject = "";
    this.description = "";
    this.phoneNumber = "";
  }
}
