import { SharedService } from "app/shared/shared.service";
import { TokenStorageService } from "./../../shared/storage-services/token-storage.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FirebaseStorageService } from "app/shared/firebase/firebase-storage.service";
import { FormBuilder } from "@angular/forms";
import { UserModel } from "./../../../models/entities/user-model";
import { InfoMessagesService } from "./../../shared/messages/info-messages.service";
import { CrudServiceService } from "./../../shared/backend/cruds/crud-service.service";
import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  Input,
} from "@angular/core";
import { adminConfigModel } from "models/entities/admin-config";
import swal from "sweetalert2";
import { PlansModel } from "models/entities/plans-model";
import { environment } from "environments/environment.prod";
import { GenericResponseModel } from "../../../models/utilities/generic.response.model";
import { HandleServicesService } from "app/shared/handle-service/handle-services.service";

@Component({
  selector: "app-admin-info",
  templateUrl: "./admin-info.component.html",
  styleUrls: ["./admin-info.component.scss"],
})
export class AdminInfoComponent implements OnInit {
  user: UserModel = new UserModel();
  adminConfig: adminConfigModel = new adminConfigModel();
  currentFileUpload: File;
  url: any;
  selectedExtension = "";
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  typesRestrictions = ["jpg", "PNG", "png", "JPGE"];
  attached = false;
  @ViewChild("file")
  myInputVariable: ElementRef;
  public planInfo: [];
  errorMessage = "";
  role: any;
  variableComp: PlansModel = new PlansModel();

  userAdmin: any;
  cantidadUnidades: any;
  permisoActualizar: boolean;
  planAsignado: string;

  imageStatic: any =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQPUzlmdTEXY16lvRyTf8CthtNf7E0M9O41yd0Mq4Az1WryuRJr";

  imageStatic2: any = "https://cdn-icons-png.flaticon.com/512/2704/2704398.png";
  //FILE AWS
  filePhoto: any = null;
  filePhotoRender: any = "";

  fileSignature: any = null;
  fileSignatureRender: any = "";

  urlPeticionImg = environment.serverUrl + "api/archivos/ver-img?ruta=";

  constructor(
    public crudServices: CrudServiceService,
    private messageService: InfoMessagesService,
    private firebaseStorageService: FirebaseStorageService,
    private tokenStorage: TokenStorageService,
    private sharedService: SharedService,
    private userForm: FormBuilder,
    private handleService: HandleServicesService
  ) {}

  ngOnInit() {
    this.permisoActualizar = true;
    this.myInputVariable.nativeElement.value = "";
    this.role = this.tokenStorage.getAuthorities();
    if (
      this.tokenStorage.getAuthorities() === "ROLE_SUPER" ||
      this.tokenStorage.getAuthorities() === "ROLE_ADMIN"
    ) {
      this.configSelectPlan();
    }
    this.cantidadMaxUnidades();
    this.getInfoUserAuthenticated();
  }

  getInfoUserAuthenticated() {
    this.crudServices
      .getModel("api/usuarios/obtener-info-usuario-autenticado")
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.user = genericResponse.genericObject;
            this.planAsignado = this.user.activatedPlanId.id;
            if (this.tokenStorage.getAuthorities() === "ROLE_ADMIN") {
              this.crudServices
                .getModel("api/admin-config/obtener-configuracion")
                .subscribe(
                  (genericResponse2) => {
                    if (genericResponse2.code === 200) {
                      this.adminConfig = genericResponse2.genericObject;
                      this.sharedService.setLogoUrlCompany(
                        this.adminConfig.logoUrl
                      );
                    }
                  },
                  (error) => {
                    this.messageService.getInfoMessagePersonalized(
                      "error",
                      "Error la carga de la info",
                      "Error"
                    );
                    // this.messagesServicesCtrl.getInfoMessagePersonalized('error', 'Inténtalo de nuevo', 'Credenciales incorrectas');
                  }
                );
            }
          }
        },
        (error) => {
          this.messageService.getInfoMessagePersonalized(
            "error",
            "Error la carga de la info",
            "Error"
          );
          // this.messagesServicesCtrl.getInfoMessagePersonalized('error', 'Inténtalo de nuevo', 'Credenciales incorrectas');
        }
      );
  }

  updateAdminConfig() {
    this.adminConfig.logoUrl = this.filePhotoRender;
    let adminConfigAux = this.adminConfig;
    adminConfigAux.logoUrl = null;

    let planSeleccionado = this.user.activatedPlanId.id;
    if (!this.permisoActualizar) {
      this.messageService.getInfoMessagePersonalized(
        "error",
        "No puedes cambiar a un plan de menor valor",
        "Error"
      );
      this.getInfoUserAuthenticated();
    } else {
      this.crudServices
        .createModel("api/usuarios/actualizar", this.user)
        .subscribe(
          (genericResponse) => {
            if (genericResponse.code === 200) {
              if (this.filePhoto != null && this.fileSignature != "") {
                this.uploadPhoto();
              }
              if (this.fileSignature != null && this.fileSignature != "") {
                this.uploadSignature(this.user);
              }

              this.getInfoUserAuthenticated();
            }

            if (this.planAsignado !== planSeleccionado) {
              this.messageService.getInfoMessagePersonalized(
                "success",
                "Inicia sesión nuevamente para aplicar cambios",
                "Su plan se ha actualizado correctamente"
              );
              this.planAsignado = planSeleccionado;
            } else {
              this.messageService.getInfoMessageUpdate();
            }
          },
          (error) => {
            this.messageService.getInfoMessagePersonalized(
              "error",
              "Error en la actualización.",
              "Error"
            );
            // this.messagesServicesCtrl.getInfoMessagePersonalized('error', 'Inténtalo de nuevo', 'Credenciales incorrectas');
          }
        );
    }
  }

  updateAdminConfigs() {
    if (this.attached) {
      this.upload(this.adminConfig);
    }

    this.crudServices
      .createModel("api/admin-config/actualizar", this.adminConfig)
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            if (this.attached) {
              swal({
                title: "Estamos cargando tu nuevo logo!",
                text: "Sólo tardara 2 segundos.",
                timer: 2000,
                showConfirmButton: false,
                imageUrl:
                  "https://ea1hlh.es/wp-content/uploads/2019/04/loading.gif",
              });
              setTimeout(() => {}, 2000);
            }
          }
        },
        (error) => {
          this.messageService.getInfoMessagePersonalized(
            "error",
            "Error en la actualización.",
            "Error"
          );
          // this.messagesServicesCtrl.getInfoMessagePersonalized('error', 'Inténtalo de nuevo', 'Credenciales incorrectas');
        }
      );
  }

  selectFile(event) {
    if (
      this.typesRestrictions.includes(
        event.target.files.item(0).name.split(".").pop()
      )
    ) {
      this.currentFileUpload = event.target.files.item(0);
      this.selectedExtension = event.target.files.item(0).name.split(".").pop();
    } else {
      this.currentFileUpload = undefined;
      swal({
        title: "El tipo no es correcto",
        type: "error",
        text: "El archivo debe tener formato jpg, PNG, png ó JPEG, jpeg",
      });
    }
  }

  upload(admin: adminConfigModel) {
    // tslint:disable-next-line: max-line-length
    this.firebaseStorageService
      .uploadFile(
        "images/" + "logos/" + this.tokenStorage.getIdAdmin(),
        "logo." + this.selectedExtension,
        this.currentFileUpload
      )
      .then(
        (responseFirebase) => {
          this.firebaseStorageService
            .loadFile(
              "images/" + "logos/" + this.tokenStorage.getIdAdmin(),
              "logo." + this.selectedExtension
            )
            .then((dowloadURL) => {
              document.querySelector("img").src =
                dowloadURL + new Date().getTime();
              this.sharedService.setLogoUrlCompany(
                document.querySelector("img")
              );
            })
            .catch((error) => {
              console.error(error);
              this.messageService.getInfoMessageBadInternet();
            });
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  readUrl(inputValue: any): void {
    this.attached = true;
    const img = new Image();
    img.src = window.URL.createObjectURL(inputValue.target.files.item(0));
    img.onload = () => {
      window.URL.revokeObjectURL(img.src);
      if (img.naturalWidth <= 2000 && img.naturalHeight <= 2000) {
        this.currentFileUpload = inputValue.target.files[0];
        var myReader: FileReader = new FileReader();
        myReader.onloadend = (e) => {
          this.url = myReader.result;
          // this.adminConfig.logoUrl = myReader.result;
        };
        myReader.readAsDataURL(this.currentFileUpload);
      } else {
        this.messageService.getInfoMessagePersonalized(
          "warning",
          "El tamaño de la imagen debe ser menor o igual a 200 x 200 px",
          "Imagen fuera de proporciones"
        );
      }
    };
  }

  configSelectPlan() {
    this.crudServices.getModel("api/plan/listar-todas-menos-admin").subscribe(
      (genericResponse) => {
        if (genericResponse.code === 200) {
          this.planInfo = genericResponse.answerList;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized(
            "warning",
            genericResponse.answer,
            "No se encontraron planes"
          );
        }
      },
      (error) => {
        this.errorMessage = error.error.message;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  conseguirPlanObjetivo(idPlan) {
    this.crudServices.getModel("api/plan/ver-plan?planId=" + idPlan).subscribe(
      (genericResponse) => {
        if (genericResponse.code === 200) {
          this.variableComp = genericResponse.genericObject;

          console.log(JSON.stringify(this.cantidadUnidades));
          console.log(JSON.stringify(this.variableComp.maxNumHousUnit));

          if (this.cantidadUnidades > this.variableComp.maxNumHousUnit) {
            this.permisoActualizar = false;
          } else {
            this.permisoActualizar = true;
          }
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized(
            "warning",
            genericResponse.answer,
            "No se encontraron planes"
          );
        }
      },
      (error) => {
        this.errorMessage = error.error.message;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  cantidadMaxUnidades() {
    this.crudServices
      .getModel(
        "api/unidad-residencial/contar?id=" + this.tokenStorage.getIdAdmin()
      )
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.cantidadUnidades = genericResponse.genericObject;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se pudieron contar las unidades"
            );
          }
        },
        (error) => {
          this.errorMessage = error.error.message;
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  uploadPhoto() {
    const folderPath = "administradores ";

    var data = new FormData();
    data.append("archivo", this.filePhoto);

    let extension = this.filePhoto.name.split(".").pop();

    let path =
      "api/archivos/cargar-logo-admin?folderPath=" +
      folderPath +
      "&relationalId=" +
      +this.tokenStorage.getIdAdmin() +
      "&extension=" +
      extension;
    this.crudServices
      .createFile(path, data)
      .toPromise()
      .then((genericResponse) => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageUpdate().then(() => {
            window.location.reload();
          });
        } else {
          this.messageService.getInfoMessagePersonalized(
            "warning",
            "Se actualizo la información correctamente pero verifique el logo por favor",
            "Atención!"
          );
        }
      })
      .catch((error) => {
        this.handleService.handleError(error);
      });
  }

  uploadSignature(modelo: UserModel) {
    const folderPath = "admin " + modelo.id;
    var data = new FormData();
    data.append("archivo", this.fileSignature);
    let extension = this.fileSignature.name.split(".").pop();

    let path =
      "api/archivos/cargar-firma-admin?folderPath=" +
      folderPath +
      "&relationalId=" +
      modelo.id +
      "&extension=" +
      extension;
    this.crudServices
      .createFile(path, data)
      .toPromise()
      .then((genericResponse) => {
        if (genericResponse.code != 200) {
          this.messageService.getInfoMessagePersonalized(
            "warning",
            "Verifique la foto de la firma por favor",
            "Atención!"
          );
        }
      })
      .catch((error) => {
        this.handleService.handleError(error);
      });
  }

  public loadPhoto(event, files) {
    this.filePhoto = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (_event) => {
      this.filePhotoRender = reader.result;
    };
  }

  public loadSignature(event, files) {
    this.fileSignature = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (_event) => {
      this.fileSignatureRender = reader.result;
    };
  }

  updateInfo() {
    swal({
      title: "¿Está seguro que desea actualizar su información?",
      text: "",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, actualizar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        this.updateAdminConfig();
      }
    });
  }
}
