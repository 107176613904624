export class ModuleModel {
    id: string;
    name: string;
    description: string;
    createdDate: string;
    lastUpdateDate: string;
    path: string;
    title: string;
    icon: string;
    classs: string;
    badge: string;
    badgeClass: string;


    public constructor() {
        this.id = ''
        this.name = ''
        this.description = ''
        this.createdDate = ''
        this.lastUpdateDate = ''
        this.path = ''
        this.title = ''
        this.icon = ''
        this.classs = ''
        this.badge = ''
        this.badgeClass = ''
    }

}
