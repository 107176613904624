import { InfoMessagesService } from './../messages/info-messages.service';
import { CrudServiceService } from './../backend/cruds/crud-service.service';
import { FirebaseStorageService } from './../firebase/firebase-storage.service';
import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { RouteInfo } from './sidebar.metadata';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { TokenStorageService } from '../storage-services/token-storage.service';


import * as $ from 'jquery';
import { SharedService } from '../shared.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientModel } from 'models/entities/client-model';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { adminConfigModel } from 'models/entities/admin-config';
import { environment } from 'environments/environment.prod';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {
  public menuItems: any[] = [];
  public correoUsuario: String;
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  public error;
  public load = false;
  public logo = false;
  private url;

  adminConfig: adminConfigModel = new adminConfigModel();
  urlPeticionImg = environment.serverUrl + 'api/archivos/ver-img?ruta=';
  
  clientValue = '';
  idClient = -1;
  listClients: Array<ClientModel> = new Array();
  @Output() emitterValueToSearch: EventEmitter<any> = new EventEmitter();
  @ViewChild(DatatableComponent) table: DatatableComponent;


  constructor(private router: Router,
    public translate: TranslateService,
    private crudServices: CrudServiceService,
    private firebaseServices: FirebaseStorageService,
    private token: TokenStorageService,
    private messageService: InfoMessagesService,
    private sharedService: SharedService,
    private modalService: NgbModal,
    private crudService: CrudServiceService,
  ) {

  }

  uploadLogo(event) {
    const file = event.target.files[0];
    this.firebaseServices.uploadFile(this.token.getCompanyId() + '/' + 'logos', 'logo', file).then((result) => {
      if (result) {
        this.firebaseServices.loadFile(this.token.getCompanyId() + '/' + 'logos', 'logo').then((url) => {
          document.querySelector('img').src = url + new Date().getTime()
          this.sharedService.setLogoUrlCompany(document.querySelector('img'));

        }).catch((err) => {
          console.error(err)
          document.querySelector('img').src = '../../../assets/img/logo.png';
        })
      }
    }).catch((err) => {
      console.error(err)
    })
  }

  isLogin() {
    if (this.token.getToken() === null) {
      return false
    } else {
      return true
    }
  }

  ngOnInit() {

    $.getScript('./assets/js/app-sidebar.js');
    if (this.isLogin()) {
      this.filterMenu();

      if ((this.token.getCompanyId() !== null) && (this.token.getAuthorities() === 'ROLE_ADMIN')) {
        console.log('el role' +this.token.getAuthorities());
        this.crudServices.getModel('api/admin-config/obtener-configuracion').subscribe(
          genericResponse2 => {
            if (genericResponse2.code === 200) {
              this.adminConfig = genericResponse2.genericObject; 
              if(this.adminConfig.logoUrl != null && this.adminConfig.logoUrl != ""){
                this.sharedService.setLogoUrlCompany(this.adminConfig.logoUrl);
                this.logo = this.adminConfig.logoUrl;
              }
              else{
                this.logo = false;
              }
            }
          }, error => {
            this.messageService.getInfoMessagePersonalized('error', 'Error la carga de la info', 'Error');
            // this.messagesServicesCtrl.getInfoMessagePersonalized('error', 'Inténtalo de nuevo', 'Credenciales incorrectas');
          }
        );
      }
    } else {
      const subMenuArrayWelcome = []
      // subMenuArrayWelcome.push(
      //   {
      //     path: '/welcome/welcome', title: 'Bienvenido',
      //     icon: 'ft-layout', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      //   },
      //   {
      //     path: '/welcome/pricing', title: 'Precios',
      //     icon: 'ft-layout', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      //   },
      //   {
      //     path: '/welcome/banner', title: 'Banner',
      //     icon: 'ft-layout', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      //   },
      //   {
      //     path: '/welcome/landing', title: 'Landing',
      //     icon: 'ft-layout', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      //   }
      // )
      // const moduloWelcome: RouteInfo = {
      //   path: '',
      //   title: 'Inicio',
      //   icon: 'ft-layout',
      //   class: 'has-sub',
      //   badge: '',
      //   badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
      //   isExternalLink: false,
      //   submenu: subMenuArrayWelcome
      // }

      //this.menuItems.push(moduloWelcome);;
    }
  }

  filterMenu() {
    this.menuItems = [];
    this.crudServices.getModel('api/modulos/obtener-modulos-menus-json').subscribe(
      data => {
        if (data.code === 200) {
          const variable = data.answerList;
          // tslint:disable-next-line:forin
          for (const i in variable) {
            const subMenuArray = []
            for (const subMenu of variable[i].listMenu) {
              subMenuArray.push(
                {
                  path: subMenu.path, title: subMenu.title, icon: subMenu.icon, class:
                    '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
                }
              )
            }
            const modulo: RouteInfo = {
              path: '', title: variable[i].module.name, icon: variable[i].module.icon,
              class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
              isExternalLink: false, submenu: subMenuArray
            }
            this.menuItems.push(modulo);
          }
        } else {
          this.messageService.getInfoMessagePersonalized('error', data.answer, 'No se cargaron lo modulos')
        }
      },
      error => {
        this.messageService.getInfoMessageBadInternet();
        console.error(error);
      }
    );
  }

  ngxWizardFunction(path: string) {
    if (path.indexOf('forms/ngx') !== -1) {
      this.router.navigate(['forms/ngx/wizard'], { skipLocationChange: false });
    }
  }

  getClientIdInList(value: string) {
    const pathIdClient = 'cliente/ver-por-nombre-o-documento?valueToSearch=' + value;
    this.crudService.getModel(pathIdClient).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          if (genericResponse.answerList.length > 0) {
            genericResponse.answerList.forEach((client: ClientModel) => {
              this.listClients.push(client)
            });
          } else {
            // this.messageService.getInfoMessagePersonalized('warning', 'No se pudo encontrar el cliente en la empresa',
            // 'Problema consultando el cliente');
            this.listClients = new Array();
          }
        } else {
          // this.messageService.getInfoMessagePersonalized('warning', 'No se pudo encontrar el cliente en la empresa',
          //   'Problema consultando el cliente');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar los clientes de la empresa: ' + JSON.stringify(error))
      }
    );
  }
}
