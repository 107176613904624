import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent{
    // Variables
    currentDate: Date = new Date();

    constructor (
        private route: ActivatedRoute, private router: Router,
    ) {}
}
