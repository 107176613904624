import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-detail-doorman-visitor',
  templateUrl: './detail-doorman-visitor.component.html',
  styleUrls: ['./detail-doorman-visitor.component.scss']
})
export class DetailDoormanVisitorComponent implements OnInit {

  @Input() visitorInfo

  constructor(
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
   
  }

  closeModal() {
    this.modalService.dismissAll();
  }

}
