import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WelcomeRoutingModule } from './welcome-routing.module';
import { WelcomeTitleComponent } from './welcome-title/welcome-title.component';
import { RoleAuthGuard } from 'app/shared/auth/roleAuth-guard.service';
import { PricingComponent } from './pricing-container/pricing/pricing.component';
import { BannerComponent } from './banner/banner.component';
import { LandingComponent } from './landing/landing.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PricingContainerComponent } from './pricing-container/pricing-container.component';
import { SevicesContainerComponent } from './sevices-container/sevices-container.component';
import { ServiceComponent } from './sevices-container/service/service.component';
import { ClientInfoComponent } from './client-info/client-info.component';
import { InicioComponent } from './inicio/inicio.component';
import { NavComponent } from './nav/nav.component';
import { ExperienciaComponent } from './experiencia/experiencia.component';
import { DemoComponent } from './demo/demo.component';
import { HerramientaComponent } from './herramienta/herramienta.component';
import { ClientesComponent } from './clientes/clientes.component';
import { SuscribeteComponent } from './suscribete/suscribete.component';
import { ContactoComponent } from './contacto/contacto.component';
import { FooterComponent } from './footer/footer.component';
import { DescargaComponent } from './descarga/descarga.component';
import { ServiciosComponent } from './servicios/servicios.component';

@NgModule({
  imports: [
    CommonModule,
    WelcomeRoutingModule,
    FormsModule,
    NgbModule
  ],
  declarations: [
    WelcomeTitleComponent,
    PricingComponent,
    BannerComponent,
    LandingComponent,
    PricingContainerComponent,
    SevicesContainerComponent,
    ServiceComponent,
    ClientInfoComponent,
    InicioComponent,
    NavComponent,
    ExperienciaComponent,
    DemoComponent,
    HerramientaComponent,
    ClientesComponent,
    SuscribeteComponent,
    ContactoComponent,
    FooterComponent,
    DescargaComponent,
    ServiciosComponent],
  providers: [RoleAuthGuard],
  exports: [BannerComponent, ClientInfoComponent],
})
export class WelcomeModule { }
