import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ModalInfo } from './../../../models/entities/modal-info';
import { CrudMantenimientoComponent } from './../crud-mantenimiento/crud-mantenimiento.component';
import { CrudServiceService } from './../../shared/backend/cruds/crud-service.service';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';

import swal from 'sweetalert2';
import { EquipoModel } from 'models/entities/equipo-model';
import { FileModel } from 'models/entities/file-model';
import { FirebaseStorageService } from 'app/shared/firebase/firebase-storage.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { MantenimientoModel } from 'models/entities/mantenimiento-model';

@Component({
  selector: 'app-lista-mantenimiento',
  templateUrl: './lista-mantenimiento.component.html',
  styleUrls: ['./lista-mantenimiento.component.scss']
})
export class ListaMantenimientoComponent implements OnInit {

  rows = [];
  temp: string[];
  board: string;
  errorMessage: string;
  dato: any;
  datosDetalle: any;
  button = [];

  @ViewChild(DatatableComponent) table: DatatableComponent;
  @Input() public equipoModel;
  
  modalInfo: ModalInfo;

  constructor(private modalService: NgbModal,
    private crudServices: CrudServiceService,
    private messageService: InfoMessagesService,
    private tokenStorage: TokenStorageService,
    private firebaseServices: FirebaseStorageService) { }

  ngOnInit() {
    this.obtenerMantenimientos();
  }

  crearMantenimiento() {
    this.modalInfo = new ModalInfo();
    this.modalInfo.isCreate = true;

    const modalRef = this.modalService.open(CrudMantenimientoComponent, {
      windowClass: 'my-modal-detalle', backdrop: 'static'
    });

    modalRef.componentInstance.modalInfo = this.modalInfo;
    modalRef.componentInstance.equipoInfo = this.equipoModel;
    modalRef.componentInstance.cantidadMantenimientos = this.rows.length;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
      if (receivedEntry === 'mantenimiento_creado') {
        this.obtenerMantenimientos();
      } else {
        return false;
      }
    }
    )
  }

  verDetalle(mantenimientoInformacion: EquipoModel) {
    this.modalInfo = new ModalInfo();
    this.modalInfo.isView = true;
   
    const modalRef = this.modalService.open(CrudMantenimientoComponent, {
      windowClass: 'my-modal-detalle', backdrop: 'static'
    });
    
    modalRef.componentInstance.modalInfo = this.modalInfo;
    modalRef.componentInstance.equipoInfo = this.equipoModel;
    modalRef.componentInstance.mantenimientoInfo = mantenimientoInformacion;

  }

  actualizarMantenimiento(mantenimientoInformacion) {
    this.modalInfo = new ModalInfo();
    this.modalInfo.isUpdate = true;

    let mantenimientoInformacionInfo = new MantenimientoModel();
    mantenimientoInformacionInfo = mantenimientoInformacion;
    mantenimientoInformacionInfo.creador = mantenimientoInformacion.creadorId.id;

    const modalRef = this.modalService.open(CrudMantenimientoComponent, {
      windowClass: 'my-modal-detalle', backdrop: 'static'
    });

    modalRef.componentInstance.modalInfo = this.modalInfo;
    modalRef.componentInstance.equipoInfo = this.equipoModel;
    modalRef.componentInstance.mantenimientoInfo = mantenimientoInformacionInfo;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
      if (receivedEntry === 'mantenimiento_creado') {
        this.obtenerMantenimientos();
      } else {
        return false;
      }
    }
    )
  }

  obtenerMantenimientos() {
    this.crudServices.getModel('api/mantenimiento/listar-todo?equipoId=' + this.equipoModel.id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.dato = genericResponse.answerList;
          this.temp = [...this.dato];
          this.rows = this.dato;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin mantenimientos')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  deleteFile(fileModelTemp) {

    let fileToDelete: FileModel;
    fileToDelete = fileModelTemp;

    const url = 'api/archivo-firebase/borrar-por-id?idFile=' + fileToDelete.id;
    this.crudServices.deleteModel(
      url
    ).subscribe((genericResponse: GenericResponseModel) => {
      if (genericResponse.code === 200) {
        this.firebaseServices.deleteFile(fileToDelete.pathFirebaseFile).then((data) => {
          console.log("se elimina la imagen " + fileModelTemp.fileName);
        })
      } else {
        this.messageService.getInfoMessagePersonalized('warning', 'No se pudo eliminar', 'Algo salio mal');
      }
    }, error => {
      this.messageService.getInfoMessageBadInternet();
      console.error(error);
    })
  }

  eliminarMantenimiento(mantenimineto) {

    swal({
      title: 'Esta seguro que desea eliminar el mantenimiento?',
      text: 'No puedes revertir esta acción despues!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.crudServices.deleteModel('api/mantenimiento/borrar?mantenimientoId=' + mantenimineto.id).subscribe(
          genericResponse => {
            if (genericResponse.code === 200) {
              swal(
                'Exito en la operación!',
                'El equipo ha sido eliminado.',
                'success'
              )
              this.obtenerMantenimientos();
              this.deleteFile(mantenimineto.firebaseId);
            }
            if (genericResponse.code === 400) {
              this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error al borrar el equipo')
            }
          },
          error => {
            console.error(error);
            this.messageService.getInfoMessageBadInternet();
          }
        );
      }
    })

  }

  updateFilterByName(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function (d: any) {
      if (d.nombre) {
        return d.nombre.toLowerCase().indexOf(val) !== -1 || !val;
      } else {
        return false;
      }
    });
    this.rows = temp;
    this.table.offset = 0;
  }
}