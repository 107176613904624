import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DoormanRoutingModule } from './doorman-routing.module';
import { CreateDoormanCorrespondenceComponent } from './create-doorman-correspondence/create-doorman-correspondence.component';
import { CreateDoormanVisitorComponent } from './create-doorman-visitor/create-doorman-visitor.component';
import { ListDoormanCorrespondenceComponent } from './list-doorman-correspondence/list-doorman-correspondence.component';
import { ListDoormanVisitorComponent } from './list-doorman-visitor/list-doorman-visitor.component';
import { DetailDoormanCorrespondenceComponent } from './detail-doorman-correspondence/detail-doorman-correspondence.component';
import { UpdateDoormanCorrespondenceComponent } from './update-doorman-correspondence/update-doorman-correspondence.component';
import { UpdateDoormanVisitorComponent } from './update-doorman-visitor/update-doorman-visitor.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DetailDoormanVisitorComponent } from './detail-doorman-visitor/detail-doorman-visitor.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DoormanListComponent } from './doorman-list/doorman-list.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [CreateDoormanCorrespondenceComponent, CreateDoormanVisitorComponent,
    ListDoormanCorrespondenceComponent, ListDoormanVisitorComponent,
    DetailDoormanCorrespondenceComponent, DetailDoormanVisitorComponent,
    UpdateDoormanCorrespondenceComponent, UpdateDoormanVisitorComponent, DoormanListComponent],

    imports: [
    CommonModule, DoormanRoutingModule,
    NgbModule, FormsModule,
    NgxDatatableModule,
    ReactiveFormsModule, NgSelectModule,
    SharedModule
  ]
})
export class DoormanModule { }
