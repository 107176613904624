import { TokenStorageService } from './../../shared/storage-services/token-storage.service';
import { UserModel } from './../../../models/entities/user-model';
import { GenericResponseModel } from './../../../models/utilities/generic.response.model';
import { InfoMessagesService } from './../../shared/messages/info-messages.service';
import { CrudServiceService } from './../../shared/backend/cruds/crud-service.service';
import { CoownershipModel } from 'models/entities/coowner-ship-model';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MenuModel } from 'models/entities/menu-model';
import { NgForm, FormGroup, FormBuilder } from '@angular/forms';
import { AuthService } from 'app/shared/auth/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TowerModel } from 'models/entities/tower-model';

@Component({
  selector: 'app-update-coownership',
  templateUrl: './update-coownership.component.html',
  styleUrls: ['./update-coownership.component.scss']
})
export class UpdateCoownershipComponent implements OnInit {

  form: any = {};
  listUsers: Array<UserModel> = new Array();
  listTowers: Array<TowerModel> = new Array();
  @Input() public coownerShipInfo: CoownershipModel;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();


  constructor(private authService: AuthService,
    public modalService: NgbModal,
    public crudServices: CrudServiceService,
    public messageService: InfoMessagesService,
    public tokenStorage: TokenStorageService) { }

  ngOnInit() {
    this.configSelectTowers()
    this.configSelectUsers()
  }

  closeWindow() {
    this.modalService.dismissAll();
  }

  Update() {
    this.crudServices.createModel('api/coownership/crear', this.coownerShipInfo).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageCreate().then(
            (result) => {
              this.passEntry.emit('creado');
              this.modalService.dismissAll();
            }
          )
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Problema creando copropiedad')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageError();
      }
    );
  }

  configSelectUsers() {
    // Obtendremos los usuarios a partir del servicio SUPER o ADMIN
    // this.crudServices.getModel('api/usuarios/obtener-lista-usuarios').subscribe(
    this.crudServices.getModel('api/usuarios/admin/listar-usuarios-creador-no-dto').subscribe(
      genericResponse => {

        if (genericResponse.code === 200) {
          this.listUsers = genericResponse.answerList;

        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin usuarios')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );

  }

  configSelectTowers() {
    this.crudServices.getModel('api/torre-bloque/obtener-torres?id=' + this.tokenStorage.getCompanyId()).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.listTowers = genericResponse.answerList;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin copropiedades')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

}
