import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CrudServiceService } from "app/shared/backend/cruds/crud-service.service";
import { InfoMessagesService } from "app/shared/messages/info-messages.service";
import { TokenStorageService } from "./../../shared/storage-services/token-storage.service";
import { environment } from "environments/environment.prod";
import { GenericResponseModel } from "models/utilities/generic.response.model";
import { IncidentModel } from "models/entities/incident-model";

@Component({
  selector: "app-detail-incident",
  templateUrl: "./detail-incident.component.html",
  styleUrls: ["./detail-incident.component.scss"],
})
export class DetailIncidentComponent implements OnInit {
  urlPeticionImg = environment.serverUrl + "api/archivos/ver-img?ruta=";
  @Input() incidentInfo;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  errorMessage: String;
  public incidentList: Array<IncidentModel> = new Array();
  rows = [];
  temp: string[];
  dato: any;
  infoIncident = new IncidentModel();

  constructor(
    private modalService: NgbModal,
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    public activeModal: NgbActiveModal,
    public tokenStorage: TokenStorageService
  ) {}

  ngOnInit() {}

  closeModal() {
    this.modalService.dismissAll();
  }

  configPage() {
    this.crudService
      .getModel(
        "api/incidentes/ver-mis-incidentes-propietario?id=" +
          this.tokenStorage.getCoownerShipId()
      )
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.dato = genericResponse.answerList;
            this.temp = { ...this.dato };
            this.rows = this.dato;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Sin incidentes"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  onSubmit() {
    this.incidentInfo.status = "Atendido";
    this.crudService
      .putModel("api/incidentes/actualizar-incidente", this.incidentInfo)
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.passEntry.emit("Respuesta registrada");
            this.activeModal.dismiss();
            this.configPage();
            // window.location.reload();
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se registró el comentario"
            );
            this.errorMessage = genericResponse.answer;
          }
        },
        (error) => {
          this.errorMessage = error.error.message;
          console.error(error);
          this.messageService.getInfoMessagePersonalized(
            "warning",
            error,
            "No se registró el comentario"
          );
        }
      );
  }
}
