import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { TowerModel } from 'models/entities/tower-model';
import { HousingUnitModel } from '../../../models/entities/housing-unit-model';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';

@Component({
  selector: 'app-create-tower-block',
  templateUrl: './create-tower-block.component.html',
  styleUrls: ['./create-tower-block.component.scss']
})
export class CreateTowerBlockComponent implements OnInit {

  public towerModel = new TowerModel();

  public isCreatedTower = true;

  housingUnit = [];
  housingUnitList: Array<HousingUnitModel> = new Array();


  @Output()
  passEntry: EventEmitter<any> = new EventEmitter();

  errorMessage: any;
  form: any = {};
  selectedCity: any;

  rows = [];
  board: string;
  dato: any;
  datosDetalle: any;
  button = [];

  constructor(
    private crudServices: CrudServiceService, private modalService: NgbModal,
    public token: TokenStorageService, private messageService: InfoMessagesService
  ) { }

  ngOnInit() {
    // this.listHousingUnit();
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  onSubmit() {
    this.towerModel.housingUnitId.id = this.token.getCompanyId();
    this.crudServices.createModel('api/torre-bloque/post', this.towerModel).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.isCreatedTower = true;
          this.messageService.getInfoMessageCreate().then(
            (result) => {
              this.modalService.dismissAll();
              this.passEntry.emit('creado');
            }
          )
        }
        if (genericResponse.code === 400) {
          this.isCreatedTower = false;
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Problema registrando la torre')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
        this.isCreatedTower = false;
        this.errorMessage = error.error.answer;
      }
    );
  }

  // listHousingUnit() {
  //   this.crudServices.getModel('api/unidad-residencial/listar').subscribe(
  //     (genericResponse: GenericResponseModel) => {
  //       if (genericResponse.code === 200) {
  //         this.housingUnitList = genericResponse.answerList;
  //         this.housingUnit = this.housingUnitList;
  //       }
  //       if (genericResponse.code === 400) {
  //         this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin menús')
  //       }
  //     },
  //     error => {
  //       console.error(error);
  //       this.messageService.getInfoMessageBadInternet();
  //     }
  //   );
  // }

}
