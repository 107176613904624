import { UserModel } from './user-model';

export class adminConfigModel {
    id: string;
    userId: UserModel;
    reportActivities: boolean;
    reportPorfolio: boolean;
    allowReservation: boolean;
    logoUrl: any;


    public constructor() {
        this.id = '';
        this.userId = new UserModel();
        this.reportActivities = false;
        this.reportPorfolio = false;
        this.allowReservation = false;
        this.logoUrl = '';

    }
}
