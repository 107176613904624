import { HousingUnitModel } from 'models/entities/housing-unit-model';

export class SurveyModel {

    id: string;
    createdDate: Date;
    subject: string;
    housingUnitId: HousingUnitModel;
    publicationDate: Date;
    dueDate: Date;
    target: string;
    status: boolean;
    tipo: string; 
    
    public constructor() {
        this.id = '';
        this.createdDate = new Date();
        this.publicationDate = new Date();
        this.dueDate = new Date();
        this.subject = '';
        this.housingUnitId = new HousingUnitModel();
        this.target = '';
        this.status = false;
        this.tipo = '';

    }

}






