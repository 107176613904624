import { EquipoModel } from 'models/entities/equipo-model'
import { UserModel } from './user-model';

export class MantenimientoModel {

    id: string;
    fechaProgramacion: string;
    fechaEjecucion: string;
    tipo: string;
    estado: string;
    equipoId: EquipoModel;
    estadoMantenimiento: string;
    responsableId : UserModel;
    descripcion : string;
    creador : string;

    public constructor(){
        this.id = '';
        this.fechaProgramacion = '';
        this.fechaEjecucion = '';
        this.tipo = '';
        this.estado = '';
        this.equipoId = new EquipoModel();
        this.estadoMantenimiento = '';
        this.responsableId = new UserModel();
        this.descripcion = '';
        this.creador = '';
    }

}