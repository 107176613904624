import { UpdateParkingComponent } from "./update-parking/update-parking.component";
import { UpdateStorageRoomComponent } from "./update-storage-room/update-storage-room.component";
import { UpdateVehicleComponent } from "./update-vehicle/update-vehicle.component";
import { UpdateResidentComponent } from "./update-resident/update-resident.component";
import { CreateParkingComponent } from "./create-parking/create-parking.component";
import { CreateStorageRoomComponent } from "./create-storage-room/create-storage-room.component";
import { CreateVehicleComponent } from "./create-vehicle/create-vehicle.component";
import { CreateResidentComponent } from "./create-resident/create-resident.component";
import { UpdateCoownershipComponent } from "./update-coownership/update-coownership.component";
import { ReactiveFormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ListCoownershipComponent } from "./list-coownership/list-coownership.component";
import { RoleAuthGuard } from "app/shared/auth/roleAuth-guard.service";
import { CreateCoownershipComponent } from "./create-coownership/create-coownership.component";
import { DetailCoownershipComponent } from "./detail-coownership/detail-coownership.component";
import { SelectMyCoownershipComponent } from "./select-my-coownership/select-my-coownership.component";

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "listar-copropiedad",
        component: ListCoownershipComponent,
        data: {
          expectedRole: ["ROLE_SUPER", "ROLE_ADMIN"],
        },
        canActivate: [RoleAuthGuard],
      },
      {
        path: "detalle-copropiedad",
        component: DetailCoownershipComponent,
        data: {
          expectedRole: ["ROLE_SUPER", "ROLE_ADMIN"],
        },
        canActivate: [RoleAuthGuard],
      },
      {
        path: "crear-copropiedad",
        component: CreateCoownershipComponent,
        data: {
          expectedRole: ["ROLE_SUPER", "ROLE_ADMIN"],
        },
        canActivate: [RoleAuthGuard],
      },
      {
        path: "actualizar-copropiedad",
        component: UpdateCoownershipComponent,
        data: {
          expectedRole: ["ROLE_SUPER", "ROLE_ADMIN", "ROLE_COLLABORATOR"],
        },
        canActivate: [RoleAuthGuard],
      },
      {
        path: "crear-residente",
        component: CreateResidentComponent,
        data: {
          expectedRole: ["ROLE_SUPER", "ROLE_ADMIN", "ROLE_COLLABORATOR"],
        },
        canActivate: [RoleAuthGuard],
      },
      {
        path: "crear-vehiculo",
        component: CreateVehicleComponent,
        data: {
          expectedRole: [
            "ROLE_SUPER",
            "ROLE_ADMIN",
            "ROLE_COLLABORATOR",
            "ROLE_OWNER",
            "ROLE_COOWNER",
          ],
        },
        canActivate: [RoleAuthGuard],
      },
      {
        path: "crear-cuartoutil",
        component: CreateStorageRoomComponent,
        data: {
          expectedRole: ["ROLE_SUPER", "ROLE_ADMIN", "ROLE_COLLABORATOR"],
        },
        canActivate: [RoleAuthGuard],
      },
      {
        path: "crear-parqueaderp",
        component: CreateParkingComponent,
        data: {
          expectedRole: ["ROLE_SUPER", "ROLE_ADMIN", "ROLE_COLLABORATOR"],
        },
        canActivate: [RoleAuthGuard],
      },
      {
        path: "update-resident",
        component: UpdateResidentComponent,
        data: {
          expectedRole: ["ROLE_SUPER", "ROLE_ADMIN", "ROLE_COLLABORATOR"],
        },
        canActivate: [RoleAuthGuard],
      },
      {
        path: "update-vehicle",
        component: UpdateVehicleComponent,
        data: {
          expectedRole: [
            "ROLE_SUPER",
            "ROLE_ADMIN",
            "ROLE_COLLABORATOR",
            "ROLE_OWNER",
            "ROLE_COOWNER",
          ],
        },
        canActivate: [RoleAuthGuard],
      },
      {
        path: "update-storage",
        component: UpdateStorageRoomComponent,
        data: {
          expectedRole: ["ROLE_SUPER", "ROLE_ADMIN", "ROLE_COLLABORATOR"],
        },
        canActivate: [RoleAuthGuard],
      },
      {
        path: "update-parking",
        component: UpdateParkingComponent,
        data: {
          expectedRole: ["ROLE_SUPER", "ROLE_ADMIN", "ROLE_COLLABORATOR"],
        },
        canActivate: [RoleAuthGuard],
      },
      {
        path: "seleccionar-copropiedad",
        component: SelectMyCoownershipComponent,
        data: {
          expectedRole: ["ROLE_OWNER", "ROLE_COOWNER"],
        },
        canActivate: [RoleAuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [RoleAuthGuard],
  exports: [RouterModule],
})
export class CoownershipRoutingModule {}
