import { CoownershipModel } from "./coowner-ship-model";
import { UserModel } from "./user-model";

export class CoownerInfo {
  id: string;
  propertyId: CoownershipModel;
  ownerId: UserModel;
  creatorId: UserModel;
  createdDate: string;

  constructor() {
    this.id = "";
    this.propertyId = new CoownershipModel();
    this.ownerId = new UserModel();
    this.creatorId = new UserModel();
    this.createdDate = new Date().toUTCString();
  }
}
