import { CrudServiceService } from './../../shared/backend/cruds/crud-service.service';
import { DetailProfileComponent } from './../detail-profile/detail-profile.component';
import { CreateProfileComponent } from './../create-profile/create-profile.component';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { UpdateProfileComponent } from '../update-profile/update-profile.component';
import { ToPairUserComponent } from '../to-pair-user/to-pair-user.component';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-list-profile',
  templateUrl: './list-profile.component.html',
  styleUrls: ['./list-profile.component.scss']
})
export class ListProfileComponent implements OnInit {

  rows = [];
  temp: string[];
  board: string;
  errorMessage: string;
  dato: any;
  datosDetalle: any;
  button = [];
  columns = [
    { prop: 'name' },
    { name: 'createdDate' }
  ];

  constructor(private modalService: NgbModal,
    private crudServices: CrudServiceService,
    private messageService: InfoMessagesService,
    private tokenStorage: TokenStorageService) { }

  ngOnInit() {
    this.configPage();
  }

  configPage() {
    if (this.tokenStorage.getAuthorities() === 'ROLE_SUPER') {
      this.crudServices.getModel('api/profile/ver-todos').subscribe(
        genericResponse => {
          if (genericResponse.code === 200) {
            this.dato = genericResponse.answerList;
            this.temp = {...this.dato};
            this.rows = this.dato;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin perfiles')
          }
        },
        error => {
          this.errorMessage = `${error.status}: ${error.error.message}`;
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
    } else {
      this.crudServices.getModel('api/profile/listar-perfiles-por-empresa').subscribe(
        genericResponse => {
          if (genericResponse.code === 200) {
            this.dato = genericResponse.answerList;
            this.temp = {...this.dato};
            this.rows = this.dato;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin perfiles')
          }
        }, error => {
          this.errorMessage = `${error.status}: ${error.error.message}`;
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
    }
  }

  openProfiles() {
    const modalRef = this.modalService.open(CreateProfileComponent, {
      windowClass: 'modal', size: 'lg'
    });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      if (receivedEntry === 'Perfil Creado') {
        this.configPage();
      } else {
        return false;
      }
    })
  }

  listProfileDetail(id) {
    this.crudServices.getModel('api/profile/ver-perfil?profileId=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          const modalRef = this.modalService.open(DetailProfileComponent, {
            windowClass: '', size: 'lg',  backdrop: 'static'
          });
          modalRef.componentInstance.profileInfo = genericResponse.genericObject;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se encontro el perfil')
        }
      },
      error => {
        this.errorMessage = `${error.status}: ${error.error.message}`;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  // Actualización del perfil
  onUpdateProfiles(id) {
    this.crudServices.getModel('api/profile/ver-perfil?profileId=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          const modalRef = this.modalService.open(UpdateProfileComponent, {
            windowClass: '', size: 'lg',  backdrop: 'static'
          });
          modalRef.componentInstance.profileInfoUpdate = genericResponse.genericObject;
          modalRef.componentInstance.passEntry.subscribe((planUpdated) => {
            this.crudServices.createModel('api/profile/actualizar', planUpdated).subscribe(
              genericUpdateResponse => {
                if (genericUpdateResponse.code === 200) {
                  this.messageService.getInfoMessageUpdate().then(
                    (response) => {
                      this.configPage();
                      this.modalService.dismissAll();
                    }
                  )
                }
                if (genericUpdateResponse.code === 400) {
                  this.messageService.getInfoMessagePersonalized('warning', genericUpdateResponse.answer, 'No se actualizó el perfil')
                }
              },
              error => {
                this.errorMessage = `${error.status}: ${error.error.message}`;
                console.error(error);
                this.messageService.getInfoMessageBadInternet();
              }
            );
          })
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se encontró el perfil')
        }
      },
      error => {
        this.errorMessage = `${error.status}: ${error.error.message}`;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  onDelete(id) {
    this.crudServices.deleteModel('api/profile/eliminar?profileId=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageDelete().then(
            (response) => {
              this.configPage();
            }
          )
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se eliminó el perfil')
        }
      },
      error => {
        this.errorMessage = `${error.status}: ${error.error.message}`;
        console.error(error);
        this.messageService.getInfoMessagePersonalized('warning', 'ups, tenemos un problema.', 
        'El perfil se encuentra asociado en el sistema');
      }
    );
  }
  // Eliminar un módulo
  deleteWarning(id) {
    event.preventDefault(); // prevent form submit
    swal({
      title: '¿Está seguro que desea eliminar el perfil?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.onDelete(id);
      }
    })
  }

  toPairUser(id) {
    this.crudServices.getModel('api/profile/ver-perfil?profileId=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          const modalRef = this.modalService.open(ToPairUserComponent, {
            windowClass: '', size: 'lg',  backdrop: 'static'
          });
          // Enviamos la información al modal hijo.
          modalRef.componentInstance.profileInfoUpdate = genericResponse.genericObject;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se encontró el perfil');
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  closeWindow() {
    this.modalService.dismissAll();
  }
}
