import { UserModel } from "./../../../models/entities/user-model";
import { GenericResponseModel } from "./../../../models/utilities/generic.response.model";
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { CrudServiceService } from "app/shared/backend/cruds/crud-service.service";
import {
  NgbModal,
  NgbTypeahead,
  NgbActiveModal,
  NgbTabChangeEvent,
} from "@ng-bootstrap/ng-bootstrap";
import { InfoMessagesService } from "app/shared/messages/info-messages.service";
import { NgForm } from "@angular/forms";
import { CoownershipModel } from "models/entities/coowner-ship-model";
import { Subject, Observable } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  merge,
  filter,
  map,
} from "rxjs/operators";
import { ParkingModel } from "models/entities/parking-model";
import { StorageModel } from "models/entities/storage-model";
import { TokenStorageService } from "app/shared/storage-services/token-storage.service";
import { Console } from "@angular/core/src/console";
import { CoownerShipVehicleModel } from "models/entities/coownership-vehicle";
import { CreateUserComponent } from "app/usercomponents/create-user/create-user.component";
import swal from "sweetalert2";
import { coownerShipByUserModel } from "models/entities/coownershipbyuser";
import { DocumentTypeModel } from "models/entities/document-type-model";

@Component({
  selector: "app-create-coownership",
  templateUrl: "./create-coownership.component.html",
  styleUrls: ["./create-coownership.component.scss"],
})
export class CreateCoownershipComponent implements OnInit {
  coownershipInfo = new CoownershipModel();
  parkingInfo = new ParkingModel();
  storageInfo = new StorageModel();
  vehicleInfo: CoownerShipVehicleModel = new CoownerShipVehicleModel();
  residentInfo: coownerShipByUserModel = new coownerShipByUserModel();
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  public isCreatedCoownerShip = true;
  listUsers: Array<UserModel> = new Array();
  listParking: Array<ParkingModel> = new Array();
  listStorageRoom: Array<StorageModel> = new Array();
  listVehicles: Array<CoownerShipVehicleModel> = new Array();
  listResidents: Array<coownerShipByUserModel> = new Array();
  datoUsers: any;
  rowsUsers: Array<UserModel> = new Array();
  tempUsers: Array<any> = new Array();
  errorMessage: any;
  form: any = {};
  selectedCity: any;
  towerInfo: any = {};
  rowsTower: Array<any> = new Array();
  tempTower: Array<any> = new Array();
  datoTower: any;
  focusUser$ = new Subject<string>();
  clickUser$ = new Subject<string>();
  focusUserIn$ = new Subject<string>();
  @ViewChild("s") storageRoomForm: NgForm;
  @ViewChild("p") createParkingForm: NgForm;
  @ViewChild("vehicleFrom") createVehicleForm: NgForm;
  @ViewChild("residentForm") createResidentForm: NgForm;
  @ViewChild("f") createCoownershipForm: NgForm;
  @ViewChild("instanceUser") instanceUser: NgbTypeahead;
  selectedIndex: any = 0;
  tab2Enable = true;
  createType = "OWNER";

  messageParking = "Seguir sin parqueadero";

  constructor(
    private crudServices: CrudServiceService,
    public modalService: NgbModal,
    private messageService: InfoMessagesService,
    public token: TokenStorageService
  ) {}

  ngOnInit() {
    this.configSelectUsers();
    this.configSelectTowers();
  }

  public beforeChange($event: NgbTabChangeEvent) {
    if (
      $event.nextId === "tab-selectbyid2" &&
      !this.createCoownershipForm.form.valid
    ) {
      $event.preventDefault();
    }
  }

  onSubmit() {
    this.coownershipInfo.housingUnitId.id = this.token.getCompanyId();
    this.crudServices
      .createModel("api/coownership/crear", this.coownershipInfo)
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.saveParking(genericResponse.genericObject.id);
            this.saveStorageRoom(genericResponse.genericObject.id);
            this.saveVehicle(genericResponse.genericObject.id);
            this.saveResident(genericResponse.genericObject.id);
            this.isCreatedCoownerShip = true;
            this.passEntry.emit("copropiedad_creada");
            this.messageService.getInfoMessageCreate().then((result) => {
              this.modalService.dismissAll();
            });
          }
          if (genericResponse.code === 400) {
            this.isCreatedCoownerShip = false;
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Problema creando copropiedad"
            );
          }
        },
        (error) => {
          this.messageService.getInfoMessageError();
          this.isCreatedCoownerShip = false;
          this.errorMessage = error.error.answer;
        }
      );
    this.configSelectUsers();
  }

  openRegister() {
    const modalRef = this.modalService.open(CreateUserComponent, {
      windowClass: "modal",
      size: "lg",
      backdrop: "static",
    });
    modalRef.componentInstance.createType = this.createType;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      if (receivedEntry === "Usuario Registrado") {
        swal({
          title: "Correcto",
          text: "Registro correcto",
          type: "success",
          confirmButtonText: "Cerrar",
        });
        this.configSelectUsers();
      } else {
        return false;
      }
    });
  }

  configSelectUsers() {
    this.crudServices
      .getModel("api/usuarios/listar-propietarios-unidad-residencial?id=" + this.token.getCoownerShipId())
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.listUsers = genericResponse.answerList;
            this.tempUsers = { ...this.listUsers };
            this.rowsUsers = this.listUsers;
            console.log(this.listUsers);
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Sin usuarios"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }
  configSelectTowers() {
    this.crudServices
      .getModel(
        "api/torre-bloque/obtener-torres?id=" + this.token.getCompanyId()
      )
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.datoTower = genericResponse.answerList;
            this.tempTower = { ...this.datoTower };
            this.rowsTower = this.datoTower;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Sin torres"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  saveParking(id) {
    console.log(this.listParking);
    this.listParking.forEach((element) => {
      element.coownerShipId.id = id;
    });
    console.log(this.listParking);
    this.crudServices
      .createModel("api/parqueadero/guardar-todos", this.listParking)
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageError();
          this.isCreatedCoownerShip = false;
          this.errorMessage = error.error.answer;
        }
      );
  }

  saveStorageRoom(id) {
    console.log(this.listStorageRoom);
    this.listStorageRoom.forEach((element) => {
      element.coownerShipId.id = id;
    });
    console.log(this.listStorageRoom);
    this.crudServices
      .createModel("api/cuarto-util/guardar-todos", this.listStorageRoom)
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
          }
          if (genericResponse.code === 400) {
            this.isCreatedCoownerShip = false;
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Problema creando cuarto util copropiedad"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageError();
          this.isCreatedCoownerShip = false;
          this.errorMessage = error.error.answer;
        }
      );
  }

  saveVehicle(id) {
    console.log(this.listVehicles);
    this.listVehicles.forEach((element) => {
      element.coOwnershipId.id = id;
    });
    console.log(this.listVehicles);
    this.crudServices
      .createModel(
        "api/vehiculo/guardar-multiples-vehiculos",
        this.listVehicles
      )
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
          }
          if (genericResponse.code === 400) {
            this.isCreatedCoownerShip = false;
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Problema creando cuarto util copropiedad"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageError();
          this.isCreatedCoownerShip = false;
          this.errorMessage = error.error.answer;
        }
      );
  }

  saveResident(id) {
    console.log(this.listResidents);
    this.listResidents.forEach((element) => {
      element.coOwnershipId.id = id;
      element.userId.documentTypeId = null;
    });
    console.log(this.listResidents);
    this.crudServices
      .createModel(
        "api/usuarios/guardar-multiples-residente",
        this.listResidents
      )
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageError();
          this.isCreatedCoownerShip = false;
          this.errorMessage = error.error.answer;
        }
      );
  }

  closeButton() {
    this.modalService.dismissAll();
  }

  formatterUser = (object: { completeName: string }) => object.completeName;

  searchByNameUser = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusUserIn$),
      merge(
        this.clickUser$.pipe(filter(() => !this.instanceUser.isPopupOpen()))
      ),
      map((search) =>
        (search === ""
          ? this.listUsers
          : this.listUsers.filter(
              (user) =>
                user.completeName.toLowerCase().indexOf(search.toLowerCase()) >
                -1
            )
        ).slice(0, 10)
      )
    );

  saveParkingList() {
    this.messageParking = "Siguiente";
    console.log(this.parkingInfo);
    this.listParking.push(this.parkingInfo);
    this.parkingInfo = new ParkingModel();
    this.createParkingForm.form.markAsPristine();
    this.createParkingForm.form.markAsUntouched();
    this.createParkingForm.form.updateValueAndValidity();
  }

  saveStorageRoomList() {
    this.listStorageRoom.push(this.storageInfo);
    this.storageInfo = new StorageModel();
    this.storageRoomForm.form.markAsPristine();
    this.storageRoomForm.form.markAsUntouched();
    this.storageRoomForm.form.updateValueAndValidity();
  }

  saveVehicleList() {
    console.log(this.vehicleInfo);
    this.listVehicles.push(this.vehicleInfo);
    this.vehicleInfo = new CoownerShipVehicleModel();
    this.createVehicleForm.form.markAsPristine();
    this.createVehicleForm.form.markAsUntouched();
    this.createVehicleForm.form.updateValueAndValidity();
  }

  saveResidentList() {
    console.log(this.residentInfo);
    this.listResidents.push(this.residentInfo);
    this.residentInfo = new coownerShipByUserModel();
    this.createResidentForm.form.markAsPristine();
    this.createResidentForm.form.markAsUntouched();
    this.createResidentForm.form.updateValueAndValidity();
  }

  deleteParkingList(parking) {
    this.listParking.splice(this.listParking.indexOf(parking), 1);
  }

  deleteStorageRoomList(storage) {
    this.listStorageRoom.splice(this.listStorageRoom.indexOf(storage), 1);
  }

  deleteVehicleList(vehicle) {
    this.listVehicles.splice(this.listVehicles.indexOf(vehicle), 1);
  }

  deleteResidentList(resident) {
    this.listResidents.splice(this.listResidents.indexOf(resident), 1);
  }

  activate2() {
    this.tab2Enable = false;
  }
}
