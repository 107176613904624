import { SurveyModel } from './survey-model';
export class QuestionModel {
    id: string;
    question: string;
    surveyId: SurveyModel;
    multipleRespuesta: boolean;
    maxRespuestaMultiple: number;
    
    public constructor() {
       this.id = '';
       this.question = '';
       this.surveyId = new SurveyModel();
       this.multipleRespuesta = false;
       this.maxRespuestaMultiple = 0; 
    }
}
