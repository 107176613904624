
import { PlansModel } from './../../../models/entities/plans-model';
import { FormBuilder } from '@angular/forms';
import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { NgForm, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-detail-plan',
  templateUrl: './detail-plan.component.html',
  styleUrls: ['./detail-plan.component.scss']
})

export class DetailPlanComponent implements OnInit {

  form: any = {};
  plan: FormGroup;
  @Input() public planInfo = new PlansModel();

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @ViewChild('f') updatePlanForm: NgForm;

  constructor(private modalService: NgbModal, private planForm: FormBuilder) { }

  ngOnInit() {
    this.plan = this.planForm.group({
      id: this.planInfo.id,
      name: this.planInfo.name,
      description: this.planInfo.description,
      value: this.planInfo.value,
      maxNumHousUnit : this.planInfo.maxNumHousUnit,
      maxNumCoownerShip : this.planInfo.maxNumCoownerShip,
      isFauvorite : this.planInfo.isFauvorite,
      planSiguiente : this.planInfo.planSiguiente
    });
  }

  passBack() {
    const formValue = this.plan.value;
    this.passEntry.emit(formValue);
  }

  closeWindow() {
    this.modalService.dismissAll();
  }


}
