import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientInfoComponent } from './../../client-info/client-info.component';
import { Component, OnInit, Input } from '@angular/core';
import { PricingModel } from '../model/pricing.model';
import { Router } from '@angular/router'

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
  @Input() pricing: PricingModel;
  constructor(private modalService: NgbModal, private router: Router) { }

  ngOnInit() {
  }

  openClientInfoModal() {
    if(this.pricing.fare.trim().toLowerCase() !== 'gratis'){
      const modal = this.modalService.open(ClientInfoComponent, {
        windowClass: 'modal', size: 'lg', backdrop: 'static'
      });
      modal.componentInstance.passEntry.subscribe(newBeneficiary => {
        if (newBeneficiary !== null) {
          modal.dismiss();
        } else {
          modal.dismiss();
        }
      });
    }else{
      this.router.navigateByUrl('/trial');
    }
  }

}
