import { GenericResponseModel } from './../../../models/utilities/generic.response.model';
import { TokenStorageService } from './../../shared/storage-services/token-storage.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoMessagesService } from './../../shared/messages/info-messages.service';
import { CrudServiceService } from './../../shared/backend/cruds/crud-service.service';
import { coownerShipByUserModel } from './../../../models/entities/coownershipbyuser';
import { UserModel } from './../../../models/entities/user-model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-update-resident',
  templateUrl: './update-resident.component.html',
  styleUrls: ['./update-resident.component.scss']
})
export class UpdateResidentComponent implements OnInit {


  errorMessage: any
  @Input() public infoResident: UserModel;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
   constructor(
     public crudServices: CrudServiceService,
     public messageService: InfoMessagesService,
     public activeModal: NgbActiveModal,
     public tokenStorage: TokenStorageService,
   ) { }

  ngOnInit() {
  }
  onSubmit() {
    this.saveResident()

  }

  saveResident() {
    this.crudServices.createModel('api/usuarios/crear-residente', this.infoResident).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageCreate();
          this.activeModal.dismiss();
          this.passEntry.emit('residente_creado');
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se creó el residente')
          this.errorMessage = genericResponse.answer;
        }
      },
      error => {
        this.errorMessage = error.error.message;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

}
