import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { CrudServiceService } from './../backend/cruds/crud-service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, AfterViewChecked, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TokenStorageService } from '../storage-services/token-storage.service';
import { Router } from '@angular/router';
import { ChangePasswordComponent } from 'app/usercomponents/change-password/change-password.component';
import { NotificationModel } from 'models/entities/notification-model';
import { UserModel } from 'models/entities/user-model';
import { InfoMessagesService } from '../messages/info-messages.service';
import { environment } from 'environments/environment.prod';



@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements AfterViewChecked, OnInit {
  currentLang = 'en';
  toggleClass = 'ft-maximize';
  placement = 'bottom-right'
  public isCollapsed = true;
  public activo = false;
  public correoUsuario: String;
  public unidadResidencial: String;
  public notifications: Array<NotificationModel> = new Array();

  // photo
  pictureProfile: any;
  userModel: UserModel = new UserModel();
  urlPeticionImg = environment.serverUrl + 'api/archivos/ver-img?ruta=';


  constructor(public translate: TranslateService,
    private messageService: InfoMessagesService,
    private crudServicesCtrl: CrudServiceService,
    private router: Router,
    public token: TokenStorageService,
    private modalService: NgbModal) {
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : 'en');
  }

  ngOnInit() {
    this.consultNotifications()
    this.islogin();
    this.correoUsuario = this.token.getUsername();
    this.unidadResidencial = this.token.getCompanyName();
    this.configPhoto();

  }

  configPagePicture(dowloadURL) {
    this.pictureProfile = dowloadURL;
 }
  configPhoto() {
    this.crudServicesCtrl.getModel('api/usuarios/obtener-info-usuario-autenticado').subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.pictureProfile = genericResponse.genericObject.pictureProfile;
        }
      }, error => {
        this.messageService.getInfoMessagePersonalized('error', 'Error la carga de la info', 'Error');
      }
    );
  }

  readAll() {
    this.crudServicesCtrl.putModelParams('api/notificaciones/notificar-apertura-todos', {}, {}).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.notifications = new Array();
        }
      }, (error) => {
        console.error(error);
      }
    )
  }

  async readNotification(notification: NotificationModel) {
    this.crudServicesCtrl.putModelParams('api/notificaciones/notificar-apertura', {}, {
      notificationId: notification.id
    }).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          const index = this.notifications.indexOf(notification, 0);
          
          if (index > -1) {
            this.notifications.splice(index, 1);
            
          }
          this.goTo(notification.pathWeb);
        }
      }, (error) => {
        console.error(error);
      }
    )
  }

  goTo(pathWeb) {
    this.router.navigateByUrl(pathWeb);
  }

  consultNotifications() {
    this.crudServicesCtrl.getModel('api/notificaciones/obtener-notificaciones').subscribe(
      (genericResponse: GenericResponseModel) => {
        
        if (genericResponse.code === 200) {;
          console.log('Se obtuvieron notificaciones')
          this.notifications = genericResponse.answerList;
        }
        

        
      }, (error) => {
        console.error(error);
      }
    )
    setTimeout(() => {
      this.consultNotifications();
    }, 180000);
  }

  ngAfterViewChecked() {

    // setTimeout(() => {
    //     var wrapperDiv = document.getElementsByClassName("wrapper")[0];
    //     var dir = wrapperDiv.getAttribute("dir");
    //     if (dir === 'rtl') {
    //         this.placement = 'bottom-left';
    //     }
    //     else if (dir === 'ltr') {
    //         this.placement = 'bottom-right';
    //     }
    // }, 3000);


  }
  // Método para desloguear al usuario.
  logOut() {
    this.token.signOut();
    this.router.navigate(['pages/auth']);
  }

  changePassword() {
    const modalRef = this.modalService.open(ChangePasswordComponent, {
      windowClass: 'modal', size: 'lg', backdrop: 'static'
    });
  }

  // api/usuarios/actualizar-contrasena
  // changePassword(id) {
  //   this.crudServicesCtrl.getModel('api/usuarios/buscar-por-id?userId=' + id).subscribe(
  //     genericResponse => {
  //       if (genericResponse.code === 200) {
  //         this.updatePassword = genericResponse.genericObject
  //         const modalRef = this.modalService.open(
  //           ChangePasswordComponent, {
  //             windowClass: '', size: 'lg', backdrop: 'static'
  //           }
  //         );
  //         modalRef.componentInstance.passwordUpdate = this.updatePassword;
  //         modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
  //           if (receivedEntry === 'creado') {
  //           } else {
  //             return false;
  //           }
  //         })
  //       }
  //       if (genericResponse.code === 400) {
  //         this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error actualizando el incidente')
  //       }
  //     },
  //     error => {
  //       console.error(error);
  //       this.messageService.getInfoMessageBadInternet();
  //     }
  //   );
  // }

  redirectToLogin() {
    this.router.navigate(['pages/auth']);
  }


  ChangeLanguage(language: string) {
    this.translate.use(language);
  }

  ToggleClass() {
    if (this.toggleClass === 'ft-maximize') {
      this.toggleClass = 'ft-minimize';
    } else {
      this.toggleClass = 'ft-maximize'
    }
  }

  islogin() {
    if (this.token.getToken()) {
      this.activo = false;
      if (this.token.getAuthorities() === 'ROLE_SUPER') {
       // this.consultNotifications();
      }
    } else {
      this.activo = true;
    }
  }

  closeWindow() {
    this.modalService.dismissAll();
  }
}
