import { DetailSurveyComponent } from './../detail-survey/detail-survey.component';
import { CreateSurveyComponent } from './../create-survey/create-survey.component';
import { UpdateQuestionComponent } from './../update-question/update-question.component';
import { DetailQuestionComponent } from './../detail-question/detail-question.component';
import { CreateQuestionComponent } from './../create-question/create-question.component';
import { QuestionModel } from './../../../models/entities/question-model';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';

@Component({
  selector: 'app-list-question',
  templateUrl: './list-question.component.html',
  styleUrls: ['./list-question.component.scss']
})
export class ListQuestionComponent implements OnInit {
  dato: any;
  rows: Array<QuestionModel> = new Array();
  errorMessage: string;
  questionInfo: any;
  surveyInfo: any;

  constructor(private crudServices: CrudServiceService,
    private messageService: InfoMessagesService,
    private tokenStorage: TokenStorageService,
    private modalService: NgbModal) { }

  ngOnInit() {
    this.configPage()
  }


  configPage() {
    this.crudServices.getModel('api/preguntas/ver-preguntas?id=' + this.tokenStorage.getCompanyId()).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.dato = genericResponse.answerList;
          this.rows = this.dato;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se encontraron preguntas')
        }
      }, error => {
        this.errorMessage = `${error.status}: ${error.error.message}`;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );

  }

  openCreate() {
    const modalRef = this.modalService.open(CreateSurveyComponent, {
      windowClass: '', size: 'lg', 'backdrop': 'static'
    });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
      if (receivedEntry === 'creado') {
        this.messageService.getInfoMessageCreate();
        this.configPage();
      } else {
        return false;
      }
    })
  }

  openDetail(id) {
    this.crudServices.getModel('api/preguntas/buscar-encuesta?id=' + id).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.surveyInfo = genericResponse.genericObject
          const modalRef = this.modalService.open(
            DetailSurveyComponent, {
            windowClass: '', size: 'lg', backdrop: 'static'
          }
          );
          modalRef.componentInstance.surveyInfo = this.surveyInfo;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error buscando la factura')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  openUpdate(id) {
    this.crudServices.getModel('api/preguntas/buscar-pregunta?id=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.questionInfo = genericResponse.genericObject
          const modalRef = this.modalService.open(
            UpdateQuestionComponent, {
              windowClass: '', size: 'lg', backdrop: 'static'
            }
          );
          modalRef.componentInstance.newQuestion = this.questionInfo;
          modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
            if (receivedEntry === 'creado') {
              this.configPage()
            } else {
              return false;
            }
          })
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error actualizando la reunión')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  onDelete(id) {
    this.crudServices.deleteModel('api/preguntas/borrar-encuesta?id=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageDelete()
          this.configPage()
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se eliminó la encuesta')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
        this.errorMessage = `${error.status}: ${error.error.message}`;
      }
    );

  }

  deleteWarning(id) {
    event.preventDefault();
    swal({
      title: '¿Está seguro que desea eliminar la votación, los resultados y esta no sé podrán recuperar?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.onDelete(id);
      }
    })
  }


}
