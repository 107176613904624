import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "environments/environment.prod";
import { TokenStorageService } from "app/shared/storage-services/token-storage.service";

@Injectable({
  providedIn: "root",
})
export class CrudServiceService {
  private serverURL = environment.serverUrl;
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token.getToken(),
    }),
  };

  httpOptionsWithOutAuth = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  httpOptionsFile = {
    headers: new HttpHeaders({
      Authorization: this.token.getToken(),
    }),
  };

  constructor(private http: HttpClient, private token: TokenStorageService) {}

  buildHeaders() {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: this.token.getToken(),
      }),
    };
  }

  buildHeadersFile() {
    this.httpOptionsFile = {
      headers: new HttpHeaders({
        Authorization: this.token.getToken(),
      }),
    };
  }

  public createModelSA(path, model): Observable<any> {
    return this.http.post<any>(`${this.serverURL}${path}`, model, {});
  }

  public getModelSA(path): Observable<any> {
    return this.http.get<any>(`${this.serverURL}${path}`, {});
  }

  public createModelWA(path, model): Observable<any> {
    return this.http.post<any>(`${this.serverURL}${path}`, model, {
      headers: this.httpOptionsWithOutAuth.headers,
    });
  }

  public getModelWA(path): Observable<any> {
    return this.http.get<any>(`${this.serverURL}${path}`, {
      headers: this.httpOptions.headers,
    });
  }
  public getModelNoA(path): Observable<any> {
    return this.http.get<any>(`${this.serverURL}${path}`, {
      headers: this.httpOptionsWithOutAuth.headers,
    });
  }

  public createModel(path, model): Observable<any> {
    return this.http.post<any>(
      `${this.serverURL}${path}`,
      model,
      this.httpOptions
    );
  }

  public createModelWithoutBody(path): Observable<any> {
    return this.http.post<any>(
      `${this.serverURL}${path}`,
      {},
      this.httpOptions
    );
  }

  public createActivitie(path, model): Observable<any> {
    this.buildHeaders();
    return this.http.post<any>(
      `${this.serverURL}${path}`,
      model,
      this.httpOptions
    );
  }

  public createModelParams(path, model, params): Observable<any> {
    return this.http.post<any>(`${this.serverURL}${path}`, model, {
      headers: this.httpOptions.headers,
      params: params,
    });
  }

  public putModelParams(path, model, params): Observable<any> {
    return this.http.put<any>(`${this.serverURL}${path}`, model, {
      headers: this.httpOptions.headers,
      params: params,
    });
  }

  public putModel(path, model): Observable<any> {
    return this.http.put<any>(`${this.serverURL}${path}`, model, {
      headers: this.httpOptions.headers,
    });
  }

  public getModel(path): Observable<any> {
    this.buildHeaders();
    return this.http.get<any>(`${this.serverURL}${path}`, this.httpOptions);
  }
  public getModelModel(path, model): Observable<any> {
    return this.http.post<any>(`${this.serverURL}${path}`, model, {
      headers: this.httpOptions.headers,
    });
  }

  public getModelModelParams(path, model, params): Observable<any> {
    return this.http.post<any>(`${this.serverURL}${path}`, model, {
      headers: this.httpOptions.headers,
      params: params,
    });
  }
  public deleteModel(path): Observable<any> {
    return this.http.delete<any>(`${this.serverURL}${path}`, this.httpOptions);
  }

  public deleteModelWA(path): Observable<any> {
    return this.http.delete<any>(`${this.serverURL}${path}`, {});
  }

  // INTERACCION SERVICIOS ARCHIVOS S3
  public createFile(path, model): Observable<any> {
    this.buildHeadersFile();
    return this.http.post<any>(
      `${this.serverURL}${path}`,
      model,
      this.httpOptionsFile
    );
  }

  public getModelFile(path): Observable<any> {
    const headers = new HttpHeaders().set(
      "Authorization",
      this.token.getToken()
    );
    return this.http.get(`${this.serverURL}${path}`, {
      headers,
      responseType: "blob",
    });
  }

  public deleteImage(path): Observable<any> {
    return this.http.delete<any>(
      `${this.serverURL}${path}`,
      this.httpOptionsFile
    );
  }
}
