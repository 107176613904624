import { CoownershipModel } from 'models/entities/coowner-ship-model';
import { HousingUnitModel } from 'models/entities/housing-unit-model';
import { FirebaseFile } from 'models/entities/firebaseFile';
import { providerModel } from './provider';
import { MarcaModel } from './marca';

export class EquipoModel {

    id: string;
    codigo: string;
    nombre: string;
    vidaUtil: string;
    garantia: string;
    precioCompra: string;
    copropiedadId: CoownershipModel;
    modelo: string;
    servicio: string;
    serie: string;
    fechaCompra: Date;
    fechaInstalacion: Date;
    tiempoMantenimiento: string;
    anotacion: string;
    tipoMantenimiento: string;
    porcentajeAlerta: string;
    cantidadTiempoMantenimiento: string;

    preveedorId: providerModel;
    marcaId: MarcaModel;
    unidadResidencialId: HousingUnitModel;
    imagenPrincipalFirebaseId: FirebaseFile;
    public constructor(){
        this.id = '';
        this.codigo = '';
        this.nombre = '';
        this.vidaUtil = '';
        this.garantia = '';
        this.precioCompra = '';
        this.modelo = '';
        this.serie = '';
        this.copropiedadId = new CoownershipModel();
        this.servicio = '';
        this.fechaCompra = new Date();
        this.fechaInstalacion = new Date();
        this.tiempoMantenimiento = '';
        this.anotacion = '';
        this.tipoMantenimiento = '';
        this.porcentajeAlerta = '';
        this.cantidadTiempoMantenimiento = '';

        this.preveedorId = new providerModel();
        this.marcaId = new MarcaModel();
        this.unidadResidencialId = new HousingUnitModel();
        this.imagenPrincipalFirebaseId = new FirebaseFile();
    }

}