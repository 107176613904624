import { CrudUserModule } from "./usercomponents/crud-user.module";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { ToastrModule } from "ngx-toastr";
import { AgmCoreModule } from "@agm/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { StoreModule } from "@ngrx/store";
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from "ngx-perfect-scrollbar";
import { AppComponent } from "./app.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";

import { DragulaService } from "ng2-dragula";
import { AuthService } from "./shared/auth/auth.service";
import { AuthGuard } from "./shared/auth/auth-guard.service";
import { ChatNGRXModule } from "./chat-ngrx/chat-ngrx.module";
import { AngularFireStorage } from "angularfire2/storage";
import { AngularFireMessaging } from "angularfire2/messaging";
import { HttpModule } from "@angular/http";
import { DragulaModule } from "ng2-dragula";
import { ModulesModule } from "./modules/modules.module";
import { PlansModule } from "./plans/plans.module";
import { ProfileModule } from "./profile/profile.module";
import { MenucomponentModule } from "./menucomponent/menucomponent.module";
import { WelcomeModule } from "./welcome/welcome.module";

import { ChartsModule } from "ng2-charts";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { DatePipe, registerLocaleData } from "@angular/common";
import { AngularFireModule } from "angularfire2";
import { AngularFirestoreModule } from "angularfire2/firestore";
import { AngularFireDatabaseModule } from "angularfire2/database";
import { AngularFireAuthModule } from "angularfire2/auth";
import { AngularFireMessagingModule } from "@angular/fire/messaging";

import { environmentFire } from "environments/environment.prod";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxLoadingModule } from "ngx-loading";
import { RoleAuthGuard } from "./shared/auth/roleAuth-guard.service";
import { MessagingServicesService } from "./shared/messaging/messaging-services.service";

import * as $ from "jquery";
import { CoownershipModule } from "./coownership/coownership.module";
import { TowerBlockModule } from "./tower-block/tower-block.module";
import { HousingUnitModule } from "./housing-unit/housing-unit.module";
import { DoormanModule } from "./doorman/doorman.module";
import { EmployeeModule } from "./employee/employee.module";
import { TaskModule } from "./task/task.module";
import { NewsModule } from "./news/news.module";
import { IncidentModule } from "./incident/incident.module";
import { ProviderModule } from "./provider/provider.module";
import localeEs from "@angular/common/locales/es-CO";
import { MeetingSurveyOwnerModule } from "./meeting-survey-owner/meeting-survey-owner.module";
import { MantenimientoModule } from "./mantenimiento/mantenimiento.module";
import { TimePickerComponent } from "./admin-calendar/list-calendar/time-picker.component";
import { TrialAccountModule } from "./trial-account/trial-account.module";
import { ExportAsModule } from "ngx-export-as";
import { InvoiceModule } from "./invoice/invoice.module";
import { PayMemberComponent } from "./housing-unit/pay-member/pay-member.component";
import { CreateUserCoownerComponent } from "./usercomponents/create-user-coowner/create-user-coowner.component";

registerLocaleData(localeEs, "es-CO");

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false,
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
    TimePickerComponent,
    PayMemberComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    NgxSpinnerModule,
    NgxLoadingModule.forRoot({}),
    StoreModule.forRoot({}),
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    HttpModule,
    /** Angular Firebase Modules */
    AngularFireModule,
    AngularFirestoreModule,
    AngularFireModule.initializeApp(environmentFire.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    ToastrModule.forRoot(),
    NgbModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyCERobClkCv1U4mDijGm1FShKva_nxsGJY",
    }),
    PerfectScrollbarModule,
    CrudUserModule,
    ModulesModule,
    PlansModule,
    ProfileModule,
    MenucomponentModule,
    WelcomeModule,
    TrialAccountModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    ChatNGRXModule,
    DragulaModule,
    CoownershipModule,
    TowerBlockModule,
    HousingUnitModule,
    DoormanModule,
    EmployeeModule,
    TaskModule,
    NewsModule,
    IncidentModule,
    ProviderModule,
    MeetingSurveyOwnerModule,
    MantenimientoModule,
    ExportAsModule,
    InvoiceModule,
  ],
  providers: [
    AuthService,
    AuthGuard,
    DragulaService,
    RoleAuthGuard,
    AuthService,
    AuthGuard,
    MessagingServicesService,
    AngularFireMessaging,
    AngularFireStorage,
    DatePipe,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
  bootstrap: [AppComponent],
  exports: [],
  entryComponents: [PayMemberComponent, CreateUserCoownerComponent],
})
export class AppModule {}
