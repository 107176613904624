import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { PairModuleMenuModel } from './../../../models/utilities/pair-module-menu-model';
import { CrudServiceService } from './../../shared/backend/cruds/crud-service.service';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModuleModel } from 'models/entities/module-model';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MenuModel } from 'models/entities/menu-model';
import swal from 'sweetalert2';

@Component({
  selector: 'app-to-pair-menu',
  templateUrl: './to-pair-menu.component.html',
  styleUrls: ['./to-pair-menu.component.scss']
})
export class ToPairMenuComponent implements OnInit {

  myList = new MenuModel()
  confirmList: MenuModel[] = []
  rows = [];
  newrows = [];
  temp: string[];
  public menuInfo: any;
  selectedModule: any = '';
  moduleMenu: PairModuleMenuModel = new PairModuleMenuModel()
  public moduleInfo = new ModuleModel()
  title = 'Seleccione'
  errorMessage = '';
  isPairedMenuFailed = false;
  dato: any;
  dato1: any;
  dato2: any;
  form: any = {};

  columns = [
    { prop: 'menu' },
    { name: 'createdDate' }
  ];
  @Input() public moduleInfoUpdate;
  @ViewChild('f') ModuleForm: NgForm;

  constructor(
    private modalService: NgbModal,
    private tokenStorage: TokenStorageService,
    private crudServices: CrudServiceService,
    private messageService: InfoMessagesService
  ) { }

  ngOnInit() {
    this.form.moduleMenu = new PairModuleMenuModel()
    this.listMenuModule(this.moduleInfoUpdate.id);
  }


  drop(event: CdkDragDrop<MenuModel[]>, idModule: any) {
    moveItemInArray(this.rows, event.previousIndex, event.currentIndex);
     this.newrows = this.rows
  }

  guardarPos(idModule: any) {

    for (const row of this.rows) {
      row.index = this.rows.indexOf(row)
      this.moduleMenu.menuId = {
        id: row.id
      };
      this.moduleMenu.moduleId = {
        id: idModule
      }
      this.moduleMenu.index = row.index

      this.crudServices.createModel('api/modulos/guardar-pos', this.moduleMenu).subscribe(
        genericResponse => {
          if (genericResponse.code === 200) {
            this.isPairedMenuFailed = false;
          //  this.messageService.getInfoMessageCreate();
            this.moduleMenu.menuId = '-1';
            this.form.moduleMenu.idMenu = '-1';
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error en el ordenamiento')
          }
        },
        error => {
          this.isPairedMenuFailed = true;
          this.errorMessage = error.error.info;
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
    }

    }



  configSelectMenu() {
    if (this.tokenStorage.getAuthorities() === 'ROLE_SUPER') {
    this.crudServices.getModel('api/menu/ver-todos').subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.menuInfo = genericResponse.answerList;
          const menuToSelect = Array();
          for (const menu of this.menuInfo) {
            let itsIn = false;
            for (const menuPorModulo of this.dato) {
              if (menu.id === menuPorModulo.id) {
                itsIn = true;
              }
            }
            if (!itsIn) {
              menuToSelect.push(menu);
            }
          }
          this.menuInfo = menuToSelect;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se encontraron menús')
        }
      },
      error => {
        this.errorMessage = error.error.message;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  } else{
    this.crudServices.getModel('api/menu/admin/ver-todos').subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.menuInfo = genericResponse.answerList;
          const menuToSelect = Array();
          for (const menu of this.menuInfo) {
            let itsIn = false;
            for (const menuPorModulo of this.dato) {
              if (menu.id === menuPorModulo.id) {
                itsIn = true;
              }
            }
            if (!itsIn) {
              menuToSelect.push(menu);
            }
          }
          this.menuInfo = menuToSelect;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se encontraron menús')
        }
      },
      error => {
        this.errorMessage = error.error.message;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }
  }






  listMenuModule(id) {
    this.crudServices.getModel('api/modulos/listar-menu-por-modulo?moduleId=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.dato = genericResponse.answerList;
          this.temp = {...this.dato};
          this.rows = this.dato;
          this.confirmList = this.dato;

          for (let i = 0; i < this.dato.lenght; i++) {
            this.dato1 = this.dato[i].name
          }
          this.configSelectMenu()
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No hay menús emparejados')
        }
      },
      error => {
        this.errorMessage = error.error.message;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  toPair(idMenu, idModule) {
    this.moduleMenu.menuId = {
      id: idMenu
    };
    this.moduleMenu.moduleId = {
      id: idModule
    }
    this.crudServices.createModel('api/modulos/enlazar-modulo-menu', this.moduleMenu).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.isPairedMenuFailed = false;
          this.listMenuModule(idModule);
          this.messageService.getInfoMessageCreate();
          this.moduleMenu.menuId = '-1';
          this.form.moduleMenu.idMenu = '-1';
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error en el emparejamiento')
        }
      },
      error => {
        this.isPairedMenuFailed = true;
        this.errorMessage = error.error.info;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  unPair(idMenu, idModule) {
    this.moduleMenu.menuId = {
      id: idMenu
    };
    this.moduleMenu.moduleId = {
      id: idModule
    }
    this.crudServices.createModel('api/modulos/desenlazar-modulo-menu', this.moduleMenu).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.listMenuModule(idModule);
          this.messageService.getInfoMessageDelete();
          this.moduleMenu.menuId = '-1';
          this.form.moduleMenu.idMenu = '-1';
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error en el desemparejamiento')
        }
      },
      error => {
        this.errorMessage = error.error.info;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  closeWindow() {
    this.modalService.dismissAll();
  }

  reloadPage() {
    event.preventDefault();
    swal({
      title: 'Para ver los cambios es necesario recargar la vista,¿desea hacerlo?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, recargar!',
      cancelButtonText: 'No, mejor luego.'
    }).then((result) => {
      if (result.value) {
        window.location.reload();
      }
    })
  }
}
