import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { TowerModel } from 'models/entities/tower-model';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { HousingUnitModel } from 'models/entities/housing-unit-model';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-update-tower-block',
  templateUrl: './update-tower-block.component.html',
  styleUrls: ['./update-tower-block.component.scss']
})
export class UpdateTowerBlockComponent implements OnInit {

  @Input() towerBlockU: TowerModel;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  public towerModel = new TowerModel();
  towerBlockM: TowerModel;
  isCreatedTowerBlock = true;
  id: any;
  errorMessage: any;

  housingUnit = [];
  housingUnitList: Array<HousingUnitModel> = new Array();

  constructor(
      private crudServices: CrudServiceService, private messageService: InfoMessagesService,
      public token: TokenStorageService, private modalService: NgbModal
    ) { }

  ngOnInit() {
    this.listHousingUnit();
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  onSubmit(id: any) {
    this.crudServices.putModel('api/torre-bloque/actualizar?id=' + id, this.towerBlockU).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageCreate();
          this.passEntry.emit('creado');
          this.messageService.getInfoMessageCreate().then(
            (result) => {
              this.modalService.dismissAll();
            }
          )

        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Problema actualizando la Torre')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  listHousingUnit() {
    this.towerModel.housingUnitId.id = this.token.getCompanyId();
    this.crudServices.getModel('api/unidad-residencial/listar').subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.housingUnitList = genericResponse.answerList;
          this.housingUnit = this.housingUnitList;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin menús')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

}
