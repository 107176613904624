import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ViewChild,
} from "@angular/core";
import { CrudServiceService } from "app/shared/backend/cruds/crud-service.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { InfoMessagesService } from "app/shared/messages/info-messages.service";
import { HousingUnitModel } from "models/entities/housing-unit-model";
import { HousingUnitTypeModel } from "models/entities/housing-unit-type-model";
import { DocumentTypeModel } from "models/entities/document-type-model";
import { DepartmentModel } from "../../../models/entities/department-model";
import { CityModel } from "../../../models/entities/city-model";
import { TokenStorageService } from "app/shared/storage-services/token-storage.service";
import { GenericResponseModel } from "models/utilities/generic.response.model";
import { environment } from "environments/environment.prod";
import { HandleServicesService } from "app/shared/handle-service/handle-services.service";
import swal from "sweetalert2";

@Component({
  selector: "app-create-housing-unit",
  templateUrl: "./create-housing-unit.component.html",
  styleUrls: ["./create-housing-unit.component.scss"],
})
export class CreateHousingUnitComponent implements OnInit {
  public housingModel = new HousingUnitModel();
  public isCreatedHousing = true;

  typeHousing = [];
  typeHousingUnitList: Array<HousingUnitTypeModel> = new Array();

  typeDocument = [];
  typeDocumentList: Array<DocumentTypeModel> = new Array();

  Department = [];
  departmentList: Array<DepartmentModel> = new Array();

  Citys = [];
  citysList: Array<CityModel> = new Array();
  getDeparmentId = "";

  //FILE AWS
  filePhoto: any = null;
  filePhotoRender: any = "";
  urlPeticionImg = environment.serverUrl + "api/archivos/ver-img?ruta=";
  imageStatic: any = "https://cdn-icons-png.flaticon.com/512/746/746859.png";

  chargeInterest: boolean = false;

  errorMessage: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(
    private crudServices: CrudServiceService,
    private modalService: NgbModal,
    private handleService: HandleServicesService,
    public token: TokenStorageService,
    private messageService: InfoMessagesService
  ) {}

  ngOnInit() {
    this.listTypeHousing();
    this.listTypeDocument();
    this.listDepartments();
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  onSubmit() {
    this.housingModel.adminId.id = this.token.getId();
    this.crudServices
      .createModel("api/unidad-residencial/crear", this.housingModel)
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.isCreatedHousing = true;
            this.passEntry.emit("creado");
            this.messageService.getInfoMessageCreate().then((result) => {
              // this.messageService.getInfoMessageCreate()
              this.modalService.dismissAll();
            });
          } else if (genericResponse.code === 400) {
            this.isCreatedHousing = false;
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Problema creando la Unidad"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
          this.isCreatedHousing = false;
          this.errorMessage = error.error.answer;
        }
      );
  }

  listTypeHousing() {
    this.crudServices
      .getModel("api/tipos-de-unidad-residencial/ver-todos")
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.typeHousingUnitList = genericResponse.answerList;
            this.typeHousing = this.typeHousingUnitList;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No hay tipos de Unidad"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  listTypeDocument() {
    this.crudServices
      .getModel("api/tipos-de-documento/listar-documentos")
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.typeDocumentList = genericResponse.answerList;
            this.typeDocument = this.typeDocumentList;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Sin documentos"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  listDepartments() {
    this.crudServices
      .getModel("api/departamentos/listar-departamentos")
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.departmentList = genericResponse.answerList;
            this.Department = this.departmentList;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Sin departamentos"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  listCitys() {
    this.getDeparmentId = this.housingModel.departmentId.id;
    this.crudServices
      .getModel("api/ciudades/listar-ciudades?id=" + this.getDeparmentId)
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.citysList = genericResponse.answerList;
            this.Citys = this.citysList;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Sin ciudades"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  //AWS BUCKET S3
  updateProfile() {
    // this.housingModel.id = this.token.getCompanyId();
    this.housingModel.adminId.id = this.token.getId();

    let path = "api/unidad-residencial/crear";

    this.housingModel.logoUrl = this.filePhotoRender;
    let housingModelAux = this.housingModel;
    housingModelAux.logoUrl = null;

    this.crudServices.createModel(path, housingModelAux).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.isCreatedHousing = true;
          this.passEntry.emit("creado");
          this.housingModel = genericResponse.genericObject;
          console.log(this.filePhoto);
          if (this.filePhoto !== null) {
            this.uploadPhoto(this.housingModel);
          } else {
            this.messageService.getInfoMessageUpdate();
          }

          this.filePhoto = null;
        } else if (genericResponse.code === 400) {
          this.isCreatedHousing = false;
          this.messageService.getInfoMessagePersonalized(
            "warning",
            genericResponse.answer,
            "Problema creando la Unidad"
          );
        }
      },
      (error) => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
        this.isCreatedHousing = false;
        this.errorMessage = error.error.answer;
      }
    );
  }

  loadPhoto(event, files) {
    this.filePhoto = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (_event) => {
      this.filePhotoRender = reader.result;
    };
  }

  updateInfo() {
    swal({
      title: "¿Está seguro que desea registrar la unidad residencial?",
      text: "",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, crear!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        this.updateProfile();
      }
    });
  }

  uploadPhoto(modelo) {
    console.log(modelo);
    const folderPath = "unidad residencial " + modelo.id;
    console.log("444444");

    var data = new FormData();
    console.log("5555555");
    data.append("archivo", this.filePhoto);
    console.log("66666");
    let extension = this.filePhoto.name.split(".").pop();
    console.log("7777777");

    let path =
      "api/archivos/cargar-logo-unidad-residencial?folderPath=" +
      folderPath +
      "&relationalId=" +
      modelo.id +
      "&extension=" +
      extension;
    this.crudServices
      .createFile(path, data)
      .toPromise()
      .then((genericResponse) => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageCreate().then((result) => {
            this.modalService.dismissAll();
          });
        } else {
          this.messageService.getInfoMessagePersonalized(
            "warning",
            "Se creó la unidad correctamente pero verifique la foto por favor",
            "Atención!"
          );
        }
      })
      .catch((error) => {
        this.handleService.handleError(error);
      });
  }
}
