import { Component, OnInit } from '@angular/core';
import { ServiceModel } from './model/service.model';

@Component({
  selector: 'app-sevices-container',
  templateUrl: './sevices-container.component.html',
  styleUrls: ['./sevices-container.component.scss']
})
export class SevicesContainerComponent implements OnInit {

  services: Array<ServiceModel>;

  constructor() { }

  ngOnInit() {
    this.services = [
      {
        title: '',
        icon: 'ft-cloud',
        text: 'Totalmente desarrollado en nube, puedes disfrutar de tu aplicación desde cualquier lugar del mundo.'
      },
      {
        title: '',
        icon: 'ft-headphones',
        text: 'Soporte personalizado, no te descuidamos un solo segundo.'
      },
      {
        title: '',
        icon: 'fa fa-building',
        text: 'Nos acomodamos al tamaño de tu negocio para ofrecerte un precio acorde a tus necesidades'
      }
    ];
  }
}
