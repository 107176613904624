import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NewsRoutingModule } from './news-routing.module';
import { CreateNewsComponent } from './create-news/create-news.component';
import { UpdateNewsComponent } from './update-news/update-news.component';
import { ListNewsComponent } from './list-news/list-news.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { DetailNewsComponent } from './detail-news/detail-news.component';
import { AdminNewsComponent } from './admin-news/admin-news.component';
import { CreateAdminNewsComponent } from './create-admin-news/create-admin-news.component';

@NgModule({
  declarations: [CreateNewsComponent, UpdateNewsComponent, ListNewsComponent, DetailNewsComponent, AdminNewsComponent, CreateAdminNewsComponent],
  imports: [
    CommonModule,
    NewsRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    QuillModule
  ]
})
export class NewsModule { }
