import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CrudServiceService } from "app/shared/backend/cruds/crud-service.service";
import { InfoMessagesService } from "app/shared/messages/info-messages.service";
import { TokenStorageService } from "app/shared/storage-services/token-storage.service";
import { IncidentModel } from "models/entities/incident-model";
import { GenericResponseModel } from "models/utilities/generic.response.model";
import moment from "moment";
import { GenericFilterModel } from "../../../models/entities/generic-filter.model";
import { DetailIncidentComponent } from "../detail-incident/detail-incident.component";
import { UpdateIncidentComponent } from "../update-incident/update-incident.component";
import { CoownershipModel } from "./../../../models/entities/coowner-ship-model";

@Component({
  selector: "app-list-incident-housing-unit",
  templateUrl: "./list-incident-housing-unit.component.html",
  styleUrls: ["./list-incident-housing-unit.component.scss"],
})
export class ListIncidentHousingUnitComponent implements OnInit {
  incidentList: Array<IncidentModel> = new Array();
  rows = [];
  temp: string[];
  dato: any;
  infoIncident = new IncidentModel();
  errorMessage: string;
  filter: GenericFilterModel = new GenericFilterModel();
  paginator: any;
  isFilter: Boolean = false;

  rowsCoownerShip: CoownershipModel[];
  tempCoownerShip: string[];
  datoCoownerShip: any;
  time: any;
  totalElements: number;

  constructor(
    private modalService: NgbModal,
    private crudServices: CrudServiceService,
    private messageService: InfoMessagesService,
    private tokenStorage: TokenStorageService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.configPaginator();
    this.listCoownerShip();
  }

  listCoownerShip() {
    {
      this.crudServices
        .getModel(
          "api/coownership/listar-todo-admin?id=" +
            this.tokenStorage.getCompanyId()
        )
        .subscribe(
          (genericResponse: GenericResponseModel) => {
            if (genericResponse.code === 200) {
              this.datoCoownerShip = genericResponse.answerList;
              this.tempCoownerShip = { ...this.datoCoownerShip };
              this.rowsCoownerShip = this.datoCoownerShip;
            }
            if (genericResponse.code === 400) {
              this.messageService.getInfoMessagePersonalized(
                "warning",
                genericResponse.answer,
                "Sin copropiedades"
              );
            }
          },
          (error) => {
            console.error(error);
            this.messageService.getInfoMessageBadInternet();
          }
        );
    }
  }

  configPage() {
    this.crudServices
      .getModel(
        "api/incidentes/ver-mis-incidentes-unidad?id=" +
          this.tokenStorage.getCompanyId()
      )
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.dato = genericResponse.answerList;
            this.temp = { ...this.dato };
            this.rows = this.dato;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Sin incidentes"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  configPaginator() {
    let page: number;

    if (!page) {
      page = 0;
    }
    this.configPaginationPage(page);
  }

  configPaginationPage(page: number) {
    this.crudServices
      .getModel(
        "api/incidentes/ver-mis-incidentes-unidad/page/" +
          page +
          "?id=" +
          this.tokenStorage.getCompanyId()
      )
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.dato = genericResponse.answerList2;
            // this.totalElements = genericResponse.genericObject.totalElements;
            this.temp = { ...this.dato };
            this.rows = this.dato;
            // this.paginator = genericResponse.genericObject;
            this.rows.forEach((element) => {
              element.time = moment(element.time, "hh:mm:ss").format(
                "hh:mm:ss a"
              );
            });
          }
        },
        (error) => {
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  changePage(newPage) {
    if (!this.isFilter) {
      this.configPaginationPage(newPage);
    } else {
      this.onFilter(newPage);
    }
  }

  detailIncident(id) {
    this.crudServices
      .getModel("api/incidentes/buscar-incidente?id=" + id)
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.infoIncident = genericResponse.genericObject;
            const modalRef = this.modalService.open(DetailIncidentComponent, {
              windowClass: "",
              size: "lg",
              backdrop: "static",
            });
            modalRef.componentInstance.incidentInfo = this.infoIncident;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Error buscando el incidente"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  updateIncident(id) {
    this.crudServices
      .getModel("api/incidentes/buscar-incidente?id=" + id)
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.infoIncident = genericResponse.genericObject;
            const modalRef = this.modalService.open(UpdateIncidentComponent, {
              windowClass: "",
              size: "lg",
              backdrop: "static",
            });
            modalRef.componentInstance.incidentInfo = this.infoIncident;
            modalRef.componentInstance.passEntry.subscribe(
              (receivedEntry: string) => {
                if (receivedEntry === "creado") {
                  this.configPaginator();
                } else {
                  return false;
                }
              }
            );
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Error actualizando el incidente"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  openTask() {
    this.router.navigate(["/tarea/listar-tareas"], {
      relativeTo: this.route.parent,
    });
  }

  onFilter(page?: number) {
    if (!page) {
      page = 0;
    }
    if (this.filter.id === null) {
      this.messageService.getInfoMessagePersonalized(
        "info",
        "El campos no pueden estar vacíos.",
        ""
      );
    } else {
      this.isFilter = true;
      this.filter.relationshipId = this.tokenStorage.getCompanyId();
      this.crudServices
        .getModelModel(
          "api/incidentes/filtro-incidentes/page/" + page,
          this.filter
        )
        .subscribe(
          (genericResponse: GenericResponseModel) => {
            if (genericResponse.code === 200) {
              this.dato = genericResponse.genericObject.content;
              this.temp = { ...this.dato };
              this.rows = this.dato;
              this.paginator = genericResponse.genericObject;
            }
          },
          (error) => {
            console.error(error);
            this.messageService.getInfoMessageBadInternet();
          }
        );
    }
  }

  clean() {
    this.filter = new GenericFilterModel();
    this.isFilter = false;
    this.configPaginator();
  }
}
