import { CoownershipModel } from './coowner-ship-model';
import { UserModel } from './user-model';
export class coownerShipByUserModel {
    id: string;
    userId: UserModel;
    coOwnershipId: CoownershipModel;
    constructor() {
        this.id = '';
        this.userId = new UserModel();
        this.coOwnershipId = new CoownershipModel();

    }
}
