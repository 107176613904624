import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListDoormanCorrespondenceComponent } from './list-doorman-correspondence/list-doorman-correspondence.component';
import { CreateDoormanCorrespondenceComponent } from './create-doorman-correspondence/create-doorman-correspondence.component';
import { DetailDoormanCorrespondenceComponent } from './detail-doorman-correspondence/detail-doorman-correspondence.component';
import { UpdateDoormanCorrespondenceComponent } from './update-doorman-correspondence/update-doorman-correspondence.component';
import { ListDoormanVisitorComponent } from './list-doorman-visitor/list-doorman-visitor.component';
import { CreateDoormanVisitorComponent } from './create-doorman-visitor/create-doorman-visitor.component';
import { UpdateDoormanVisitorComponent } from './update-doorman-visitor/update-doorman-visitor.component';
import { DetailDoormanVisitorComponent } from './detail-doorman-visitor/detail-doorman-visitor.component';
import { DoormanListComponent } from './doorman-list/doorman-list.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'listar-correspondencias',
        component: ListDoormanCorrespondenceComponent,
        data: {
          title: 'list-correspondence',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN']
        }
      },
       {
        path: 'listar-correspondencias/page/:page',
        component: ListDoormanCorrespondenceComponent,
        data: {
          title: 'list-correspondence',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN']
        }
      },
      {
        path: 'registrar-correspondencia',
        component: CreateDoormanCorrespondenceComponent,
        data: {
          title: 'create-correspondence',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN']
        }
      },
      {
        path: 'detalle-correspondencia',
        component: DetailDoormanCorrespondenceComponent,
        data: {
          title: 'detail-correspondence',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN']
        }
      },
      {
        path: 'actualizar-correspondencia',
        component: UpdateDoormanCorrespondenceComponent,
        data: {
          title: 'update-correspondence',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN']
        }
      },
      {
        path: 'listar-visitas',
        component: ListDoormanVisitorComponent,
        data: {
          title: 'list-visitors',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN']
        }
      },
      {
        path: 'registrar-visitas',
        component: CreateDoormanVisitorComponent,
        data: {
          title: 'create-visitors',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN']
        }
      },
      {
        path: 'detalle-visitas',
        component: DetailDoormanVisitorComponent,
        data: {
          title: 'detail-visitors',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN']
        }
      },
      {
        path: 'actualizar-visitas',
        component: UpdateDoormanVisitorComponent,
        data: {
          title: 'update-visitors',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN']
        }
      },
      {
        path: 'listado-porteria',
        component: DoormanListComponent,
        data: {
          title: 'doorman-list',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN']
        }
      },
    ]
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DoormanRoutingModule { }
