import { UserOAuthModel } from './user-o-auth-model';
export class changePasswordModel {

    id: string;
    oldPassword: string;
    newPassword: string;

    public constructor() {
        this.id = '';
        this.oldPassword = '';
        this.newPassword = '';
    }
}