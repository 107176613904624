import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CoownerInfo } from "../../../models/entities/coownerinfo-model";
import { CoownershipModel } from "./../../../models/entities/coowner-ship-model";
import { InvoiceModel } from "./../../../models/entities/invoice-model";
import { InvoiceTypeModel } from "./../../../models/entities/invoicetype-model";
import { GenericResponseModel } from "./../../../models/utilities/generic.response.model";
import { CrudServiceService } from "./../../shared/backend/cruds/crud-service.service";
import { InfoMessagesService } from "./../../shared/messages/info-messages.service";
import { TokenStorageService } from "./../../shared/storage-services/token-storage.service";

@Component({
  selector: "app-create-invoice",
  templateUrl: "./create-invoice.component.html",
  styleUrls: ["./create-invoice.component.scss"],
})
export class CreateInvoiceComponent implements OnInit {
  newInvoice: InvoiceModel = new InvoiceModel();
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  listTypeInvoice: Array<InvoiceTypeModel> = new Array();
  errorMessage: any;
  rowsCoownership: Array<CoownershipModel> = new Array();
  coowship: CoownershipModel = new CoownershipModel();
  datoCoownership: any;
  tempCoownership: string[];
  rowsCoownerInfo: Array<CoownerInfo> = new Array();
  coowinfo: CoownerInfo = new CoownerInfo();
  datoCoownerInfo: any = {};
  tempCoownerInfo: string[];
  isChecked: boolean = false;
  isPersonalizedChecked: any;
  isTenantsChecked: boolean;
  toOwners: boolean = false;
  toCoOwners: boolean = false;

  constructor(
    public crudServices: CrudServiceService,
    public messageService: InfoMessagesService,
    public activeModal: NgbActiveModal,
    public tokenStorage: TokenStorageService
  ) {}

  ngOnInit() {
    this.getInvoiceTypes();
    this.getCoownerships();
    this.getCoownerInfo();
  }

  onSubmit() {
    if (this.isTenantsChecked) {
      this.rowsCoownerInfo.forEach((element) => {
        if (element.id == this.newInvoice.coownerShipId.id) {
          this.coowinfo = element;
          this.newInvoice.userId = this.coowinfo.ownerId;
          this.newInvoice.coownerShipId.id = this.coowinfo.propertyId.id;
        }
      });
    } else {
      this.rowsCoownership.forEach((element) => {
        if (element.id == this.newInvoice.coownerShipId.id) {
          this.coowship = element;
          this.newInvoice.userId = this.coowship.ownerId;
        }
      });
    }

    if (
      this.coowinfo.ownerId.role == "ROLE_COOWNER" &&
      this.newInvoice.invoiceType.id == "1"
    ) {
      this.messageService.getInfoMessagePersonalized(
        "warning",
        "No se puede crear una factura de administración a un arrendatario",
        "No se creó la factura"
      );
    } else {
      console.log(this.isPersonalizedChecked, "personalizado");
      this.isPersonalizedChecked == "personalized"
        ? this.saveInvoice()
        : this.saveMassiveInvoice();
    }
  }

  getInvoiceTypes() {
    this.crudServices
      .getModel("api/factura/obtener-tipos?newType=income")
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.listTypeInvoice = genericResponse.answerList;
          } else {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              "No se pudieron listar los tipos de facturas del sistema",
              "Problema consultando los tipos de facturas"
            );
          }
        },
        (error) => {
          this.messageService.getInfoMessageError();
          console.error(
            "Error al cargar los tipos de facturas" + JSON.stringify(error)
          );
        }
      );
  }

  getCoownerships() {
    this.crudServices
      .getModel(
        "api/coownership/listar-todo-admin?id=" +
          this.tokenStorage.getCompanyId()
      )
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.datoCoownership = genericResponse.answerList;
            this.tempCoownership = { ...this.datoCoownership };
            this.rowsCoownership = this.datoCoownership;
            //console.log(this.rowsCoownership);
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Sin copropiedades"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  getCoownerInfo() {
    this.crudServices
      .getModel(
        "api/coownership/obtener-copropietarios-por-unidad?id=" +
          this.tokenStorage.getCompanyId()
      )
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.datoCoownerInfo = genericResponse.answerList;
            this.tempCoownerInfo = { ...this.datoCoownerInfo };
            this.rowsCoownerInfo = this.datoCoownerInfo as CoownerInfo[];
            console.log(this.rowsCoownerInfo);
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Sin copropiedades"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }
  saveInvoice() {
    this.newInvoice.egressIncome = "income";
    this.newInvoice.createdDate = new Date();
    this.newInvoice.housingUnitId.id = this.tokenStorage.getCompanyId();
    console.log(this.newInvoice);
    this.crudServices
      .createModel("api/factura/guardar-individual", this.newInvoice)
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.messageService.getInfoMessageCreate();
            this.passEntry.emit("factura_creada");
            this.activeModal.close();
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se creó la factura"
            );
            this.errorMessage = genericResponse.answer;
          }
        },
        (error) => {
          this.errorMessage = error.error.message;
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }
  saveMassiveInvoice() {
    this.newInvoice.egressIncome = "income";
    this.newInvoice.housingUnitId.id = this.tokenStorage.getCompanyId();
    console.log(this.newInvoice);
    this.crudServices
      .createModel(
        "api/factura/guardar-masivo?" +
          "toOwners=" +
          this.toOwners +
          "&toCoOwners=" +
          this.toCoOwners,
        this.newInvoice
      )
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.messageService.getInfoMessageCreate();
            this.passEntry.emit("factura_creada");
            this.activeModal.close();
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se creó la factura"
            );
            this.errorMessage = genericResponse.answer;
          }
        },
        (error) => {
          this.errorMessage = error.error.message;
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }
}
