import { HousingUnitModel } from './housing-unit-model';
import { UserModel } from './user-model';
export class NewsModel {
  id: String;
  publicationDate: String;
  dueDate: String;
  name: String;
  logoUrl: any;
  content: String;
  publishedBy: UserModel;
  housingUnitId: HousingUnitModel;

  public constructor() {
    this.id = '';
    this.publicationDate = '';
    this.dueDate = '';
    this.name = '';
    this.logoUrl = '';
    this.content = '';
    this.publishedBy = new UserModel();
    this.housingUnitId = new HousingUnitModel();
  }
}
