import { ModalInformationModel } from 'models/utilities/modal-information-model';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl } from '@angular/forms';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { GenericResponseModel } from '../../../models/utilities/generic.response.model';

import { FormGroup, Validators, FormBuilder, ValidatorFn } from '@angular/forms';
import { RepuestoModel } from 'models/entities/repuesto-model';
import { RutinaModel } from 'models/entities/rutina-model';

@Component({
  selector: 'app-crud-rutina',
  templateUrl: './crud-rutina.component.html',
  styleUrls: ['./crud-rutina.component.scss']
})
export class CrudRutinaComponent implements OnInit {

  idCopropiedad = this.tokenStorage.getCompanyId()

  @Input() public idEquipo;
  @Input() public nombreEquipo;
  @Input() public modalInfo = new ModalInformationModel();
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  errorMessage: any;

  form: any = {};
  formGroup: FormGroup;
  rutinaModel = new RutinaModel();

  constructor(
    private crudServices: CrudServiceService,
    private messageService: InfoMessagesService,
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private tokenStorage: TokenStorageService) {

      this.formGroup = this.fb.group({
        'descripcion': ['',[Validators.required]]
      });

  }

  ngOnInit() {
   this.obtenerRutina();
  }

  onSubmit(){

    this.rutinaModel.equipoId.id = this.idEquipo;
    this.crudServices.createModel('api/rutina/crear', this.rutinaModel).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageCreate();
          this.obtenerRutina();
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se creó la rutina')
          this.errorMessage = genericResponse.answer;
        }
      },
      error => {
        this.errorMessage = error.error.message;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  obtenerRutina() {
    this.crudServices.getModel('api/rutina/listar-todo?equipoId=' + this.idEquipo).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          if(genericResponse.answerList.length > 0){
            this.rutinaModel = genericResponse.answerList[0];
          }
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin incidentes')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  imprimir(){
    var ficha = document.getElementById("contenido-rutina");
	  var ventimp = window.open(' ', 'popimpr');
	  ventimp.document.write( ficha.innerHTML );
	  ventimp.document.close();
	  ventimp.print( );
	  ventimp.close();
  }

}
