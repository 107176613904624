import { QuestionModel } from './question-model';
import { SurveyModel } from './survey-model';
import { QuestionResponseModel } from './questio-mode-response';
export class SurveyResponseModel {
    survey: SurveyModel;
    questionList: Array<QuestionResponseModel>;

    constructor() {
        this.survey = new SurveyModel();
        this.questionList = new Array();
    }
}
