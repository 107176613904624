import { DragDropModule } from "@angular/cdk/drag-drop";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ProviderRoutingModule } from "./provider-routing.module";
import { ListProviderComponent } from "./list-provider/list-provider.component";
import { CreateProviderComponent } from "./create-provider/create-provider.component";
import { DetailProviderComponent } from "./detail-provider/detail-provider.component";
import { UpdateProviderComponent } from "./update-provider/update-provider.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { CalendarsModule } from "app/calendar/calendar.module";

@NgModule({
  declarations: [
    ListProviderComponent,
    CreateProviderComponent,
    DetailProviderComponent,
    UpdateProviderComponent,
  ],
  imports: [
    CommonModule,
    ProviderRoutingModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    NgSelectModule,
    CalendarsModule,
  ],
})
export class ProviderModule {}
