import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { employeeModel } from 'models/entities/employee';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-detail-employee',
  templateUrl: './detail-employee.component.html',
  styleUrls: ['./detail-employee.component.scss']
})
export class DetailEmployeeComponent implements OnInit {

  employee: employeeModel = new employeeModel();
  @Input() public employeeInfo: employeeModel;
  constructor(
    public crudServices: CrudServiceService,
    public messageService: InfoMessagesService,
    public modalService: NgbModal
  ) { }

  ngOnInit() {
  }



  closeWindow() {
    this.modalService.dismissAll();
  }

}
