import { UserModel } from "./user-model";

export class MemberModel {
  id: string;
  infoUser: UserModel;
  state: string;
  createdDate: string;
  updatedDate: string;

  constructor() {
    this.id = "";
    this.infoUser = new UserModel();
    this.state = "";
    this.createdDate = new Date().toUTCString();
    this.updatedDate = new Date().toUTCString();
  }
}
