import { Injectable } from '@angular/core';
import { CompanyModel } from 'models/entities/company-model';
import { Observable, Observer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  public companyModel: CompanyModel = new CompanyModel();
  public logoUrl: any;
  public pictureProfileUrl: any;
  public policies = 0;
  objetModel: any;

  


  constructor() { }

  setCompanyModel(newCompany: CompanyModel) {
    this.companyModel = newCompany;
  }

  getCompanyModel(): CompanyModel {
    return this.companyModel;
  }

  cleanCompanyModel() {
    this.companyModel = new CompanyModel();
  }

  setLogoUrlCompany(url) {
    this.logoUrl = url;
 }

  getLogoUrlCompany() {
    return this.logoUrl;
  }

  setPictureProfile(url) {
    this.pictureProfileUrl = url;
 }

  getPictureProfile() {
    return this.pictureProfileUrl;
  }
  getData() {
    return this.objetModel;
  }

  setData(object) {
    this.objetModel = object;
  }

}
