import { UiSwitchModule } from "ngx-ui-switch";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { CrudUserRoutingModule } from "./crud-user-routing.module";
import { CreateUserComponent } from "./create-user/create-user.component";
import { RoleAuthGuard } from "app/shared/auth/roleAuth-guard.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UpdateUserComponent } from "./update-user/update-user.component";
import { ListUserComponent } from "./list-user/list-user.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { ToPairProfileComponent } from "./to-pair-profile/to-pair-profile.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { ChangePasswordByComponent } from "./change-password-by/change-password-by.component";
import { AdminInfoComponent } from "./admin-info/admin-info.component";
import { ToPairProfileHousingunitComponent } from "./to-pair-profile-housingunit/to-pair-profile-housingunit.component";
import { UpdateOwnerComponent } from "./update-owner/update-owner.component";
import { PetsModule } from "../pets/pets.module";
import { CoownershipModule } from "../coownership/coownership.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxSpinnerModule } from "ngx-spinner";
import { LogErroresComponent } from "./log-errores-carga-masiva/log-errores.component";
import { FirstLoginComponent } from "./first-login/first-login.component";
import { ModalReunionesComponent } from "./modal-reuniones/modal-reuniones.component";
import { CreateUserCoownerComponent } from "./create-user-coowner/create-user-coowner.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  imports: [
    CommonModule,
    CrudUserRoutingModule,
    FormsModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    CoownershipModule,
    PetsModule,
    NgSelectModule,
    NgxSpinnerModule,
    NgbModule,
  ],
  entryComponents: [
    LogErroresComponent,
    FirstLoginComponent,
    ModalReunionesComponent,
  ],
  declarations: [
    CreateUserComponent,
    UpdateUserComponent,
    ToPairProfileComponent,
    ListUserComponent,
    ChangePasswordComponent,
    ChangePasswordByComponent,
    AdminInfoComponent,
    ToPairProfileHousingunitComponent,
    UpdateOwnerComponent,
    LogErroresComponent,
    FirstLoginComponent,
    ModalReunionesComponent,
    CreateUserCoownerComponent,
  ],
  providers: [RoleAuthGuard],
})
export class CrudUserModule {}
