import { DetailSurveyComponent } from "./detail-survey/detail-survey.component";
import { CreateSurveyComponent } from "./create-survey/create-survey.component";
import { UpdateQuestionComponent } from "./update-question/update-question.component";
import { DetailQuestionComponent } from "./detail-question/detail-question.component";
import { CreateQuestionComponent } from "./create-question/create-question.component";
import { ListQuestionComponent } from "./list-question/list-question.component";
import { RoleAuthGuard } from "app/shared/auth/roleAuth-guard.service";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "listar-preguntas",
        component: ListQuestionComponent,
        data: {
          expectedRole: ["ROLE_ADMIN", "ROLE_OWNER", "ROLE_COOWNER"],
        },
        canActivate: [RoleAuthGuard],
      },
      {
        path: "crear-preguntas",
        component: CreateQuestionComponent,
        data: {
          expectedRole: ["ROLE_ADMIN", "ROLE_OWNER", "ROLE_COOWNER"],
        },
        canActivate: [RoleAuthGuard],
      },
      {
        path: "crear-encuesta",
        component: CreateSurveyComponent,
        data: {
          expectedRole: ["ROLE_ADMIN", "ROLE_OWNER", "ROLE_COOWNER"],
        },
        canActivate: [RoleAuthGuard],
      },
      {
        path: "detalle-preguntas",
        component: DetailQuestionComponent,
        data: {
          expectedRole: ["ROLE_ADMIN", "ROLE_OWNER", "ROLE_COOWNER"],
        },
        canActivate: [RoleAuthGuard],
      },
      {
        path: "actualizar-preguntas",
        component: UpdateQuestionComponent,
        data: {
          expectedRole: ["ROLE_ADMIN", "ROLE_OWNER", "ROLE_COOWNER"],
        },
        canActivate: [RoleAuthGuard],
      },
      {
        path: "detalle-encuesta",
        component: DetailSurveyComponent,
        data: {
          expectedRole: ["ROLE_ADMIN", "ROLE_OWNER", "ROLE_COOWNER"],
        },
        canActivate: [RoleAuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class QuestionRoutingModule {}
