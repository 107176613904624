import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TowerBlockRoutingModule } from './tower-block-routing.module';
import { ListTowerBlockComponent } from './list-tower-block/list-tower-block.component';
import { CreateTowerBlockComponent } from './create-tower-block/create-tower-block.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UpdateTowerBlockComponent } from './update-tower-block/update-tower-block.component';
import { DetailTowerBlockComponent } from './detail-tower-block/detail-tower-block.component';

@NgModule({
  declarations: [ListTowerBlockComponent, CreateTowerBlockComponent, UpdateTowerBlockComponent, DetailTowerBlockComponent],
  imports: [
    CommonModule,
    NgbModule,
    TowerBlockRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ],
  entryComponents: [ListTowerBlockComponent, CreateTowerBlockComponent],
})
export class TowerBlockModule { }
