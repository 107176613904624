import { NgSelectModule } from '@ng-select/ng-select';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoownershipRoutingModule } from './coownership-routing.module';
import { ListCoownershipComponent } from './list-coownership/list-coownership.component';
import { RoleAuthGuard } from 'app/shared/auth/roleAuth-guard.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateCoownershipComponent } from './create-coownership/create-coownership.component';
import { UpdateCoownershipComponent } from './update-coownership/update-coownership.component';
import { ArchwizardModule } from 'angular-archwizard';
import { NGXFormWizardModule } from 'app/forms/ngx-wizard/ngx-wizard.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DetailCoownershipComponent } from './detail-coownership/detail-coownership.component';
import { CreateResidentComponent } from './create-resident/create-resident.component';
import { CreateVehicleComponent } from './create-vehicle/create-vehicle.component';
import { CreateStorageRoomComponent } from './create-storage-room/create-storage-room.component';
import { CreateParkingComponent } from './create-parking/create-parking.component';
import { UpdateResidentComponent } from './update-resident/update-resident.component';
import { UpdateVehicleComponent } from './update-vehicle/update-vehicle.component';
import { UpdateStorageRoomComponent } from './update-storage-room/update-storage-room.component';
import { UpdateParkingComponent } from './update-parking/update-parking.component';
import { SelectMyCoownershipComponent } from './select-my-coownership/select-my-coownership.component';

@NgModule({
  declarations: [
    CreateCoownershipComponent, UpdateCoownershipComponent, DetailCoownershipComponent,
    ListCoownershipComponent, CreateResidentComponent, CreateVehicleComponent, CreateStorageRoomComponent,
    CreateParkingComponent, UpdateResidentComponent, UpdateVehicleComponent, UpdateStorageRoomComponent,
    UpdateParkingComponent, SelectMyCoownershipComponent
  ],
  imports: [
    NGXFormWizardModule,
    ArchwizardModule,
    CommonModule,
    CoownershipRoutingModule,
    NgxDatatableModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    NgSelectModule
  ],
  providers: [RoleAuthGuard],
  exports: [ListCoownershipComponent],
  entryComponents: [
    CreateVehicleComponent
  ]
})
export class CoownershipModule { }
