import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MenuModel } from 'models/entities/menu-model';
import { NgForm, FormGroup, FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/shared/auth/auth.service';

@Component({
  selector: 'app-updatemenu',
  templateUrl: './updatemenu.component.html',
  styleUrls: ['./updatemenu.component.scss']
})
export class UpdatemenuComponent implements OnInit {
  form: any = {};
  menu: FormGroup;
  @Input() public menuInfoUpdate = new MenuModel;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @ViewChild('f') updateMenuForm: NgForm;

  icons = [
    {id: 0, name: 'ninguno', icon: 'none'},
    {id: 1, name: 'campana', icon: 'ft-bell'},
    {id: 2, name: 'bateria', icon: 'ft-battery'},
    {id: 3, name: 'libro', icon: 'ft-book'},
    {id: 4, name: 'compass', icon: 'ft-compass'},
    {id: 5, name: 'tarjeta de credito', icon: 'ft-credit-card'}
];

  constructor(private authService: AuthService,
    private modalService: NgbModal,
    private menuForm: FormBuilder) { }

  ngOnInit() {
    this.menu = this.menuForm.group({
      id: this.menuInfoUpdate.id,
      name: this.menuInfoUpdate.name,
      title: this.menuInfoUpdate.title,
      path: this.menuInfoUpdate.path,
      icon: this.menuInfoUpdate.icon

    });
  }
// Con esta función le diremos al modal hijo que pase la información al modal padre.
  passBack() {
    const formValue = this.menu.value;
    this.passEntry.emit(formValue);
  }

  closeWindow() {
    this.modalService.dismissAll();
  }

}
