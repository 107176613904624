import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormComponent } from '../form/form.component';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit {

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    const modal = this.modalService.open(FormComponent, {
      windowClass: 'mediumModal', backdrop: 'static', size: 'lg'
    });
  }

  ngOnDestroy() {
    this.modalService.dismissAll();
  }
}
