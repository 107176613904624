import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DoormanCorrespondenceModel } from '../../../models/entities/doorman-correspondence-model';
import { CoownershipModel } from 'models/entities/coowner-ship-model';
import { GenericResponseModel } from '../../../models/utilities/generic.response.model';

@Component({
  selector: 'app-create-doorman-correspondence',
  templateUrl: './create-doorman-correspondence.component.html',
  styleUrls: ['./create-doorman-correspondence.component.scss']
})
export class CreateDoormanCorrespondenceComponent implements OnInit {

  public correspondenceModel = new DoormanCorrespondenceModel();

  public isCreatedCorrespondence = true;

  rowsOwners = [];
  tempOwners: string[];
  datoOwners: any;

  rowsCoownerShip: Array<CoownershipModel> =  [];
  tempCoownerShip: string[];
  datoCoownerShip: any;

  @Output()
  passEntry: EventEmitter<any> = new EventEmitter();

  errorMessage: any;

  constructor(
    private crudServices: CrudServiceService, private modalService: NgbModal,
    public tokenStorage: TokenStorageService, private messageService: InfoMessagesService
  ) { }

  ngOnInit() {
    //this.listOwners();
    this.listOwnersAndCoowners();
    this.listCoownerShip();
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  onSubmit() {
    this.correspondenceModel.housingUnitId.id = this.tokenStorage.getCompanyId();
    this.correspondenceModel.receivedBy = this.tokenStorage.getCompleteName();
    this.correspondenceModel.correspondenceStatus = 'Por Entregar';
    console.log(this.correspondenceModel);
    this.crudServices.createModel('api/porteria/registrar-correspondencia', this.correspondenceModel).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.isCreatedCorrespondence = true;
          this.messageService.getInfoMessageCreate().then(
            (result) => {
              this.modalService.dismissAll();
              this.passEntry.emit('creado');
            }
          )
        }
        if (genericResponse.code === 400) {
          this.isCreatedCorrespondence = false;
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Problema registrando la correspondencia')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
        this.isCreatedCorrespondence = false;
        this.errorMessage = error.error.answer;
      }
    );
  }

  listOwners() {
    {
      this.crudServices.getModel('api/usuarios/obtener-propietarios').subscribe(
        genericResponse => {
          if (genericResponse.code === 200) {
            this.datoOwners = genericResponse.answerList;
            this.tempOwners = { ...this.datoOwners };
            this.rowsOwners = this.datoOwners;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin propietarios')
          }
        },
        error => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
    }
  }

  listOwnersAndCoowners() {
    this.crudServices
      .getModel(
        "api/coownership/listar-todo-propietarios-copropietarios-por-unidad-residencial?housingId=" +
          this.tokenStorage.getCompanyId()
      )
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.datoOwners = genericResponse.answerList;
            this.tempOwners = { ...this.datoOwners };
            this.rowsOwners = this.datoOwners;
            console.log(this.rowsOwners);
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No hay propietarios ni copropietarios registrados"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  listCoownerShip() {
    {
      this.crudServices.getModel('api/coownership/listar-todo-admin?id=' + this.tokenStorage.getCompanyId()).subscribe(
        genericResponse => {

          if (genericResponse.code === 200) {
            this.datoCoownerShip = genericResponse.answerList;
            this.tempCoownerShip = { ...this.datoCoownerShip };
            this.rowsCoownerShip = this.datoCoownerShip;
            console.log(this.rowsCoownerShip);
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin copropiedades')
          }
        },
        error => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
    }
  }
}
