import { GenericResponseModel } from "./../../../models/utilities/generic.response.model";
import { CreateUserComponent } from "./../../usercomponents/create-user/create-user.component";
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { CrudServiceService } from "../../shared/backend/cruds/crud-service.service";
import { InfoMessagesService } from "app/shared/messages/info-messages.service";
import { HousingUnitModel } from "../../../models/entities/housing-unit-model";
import swal from "sweetalert2";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CreateHousingUnitComponent } from "../create-housing-unit/create-housing-unit.component";
import { UserModel } from "../../../models/entities/user-model";
import { TokenStorageService } from "app/shared/storage-services/token-storage.service";
import { UpdateUserComponent } from "app/usercomponents/update-user/update-user.component";
import { PlansModel } from "models/entities/plans-model";
import { ToPairProfileComponent } from "app/usercomponents/to-pair-profile/to-pair-profile.component";
import { CreateProfileComponent } from "app/profile/create-profile/create-profile.component";
import { DetailProfileComponent } from "app/profile/detail-profile/detail-profile.component";
import { UpdateProfileComponent } from "app/profile/update-profile/update-profile.component";
import { ToPairUserComponent } from "app/profile/to-pair-user/to-pair-user.component";
import { UpdateHousingUnitComponent } from "../update-housing-unit/update-housing-unit.component";
import { environment } from "environments/environment.prod";

@Component({
  selector: "app-list-housing-unit",
  templateUrl: "./list-housing-unit.component.html",
  styleUrls: ["./list-housing-unit.component.scss"],
})
export class ListHousingUnitComponent implements OnInit {
  hosingUnitList: Array<HousingUnitModel> = new Array();
  housingUnit: HousingUnitModel = new HousingUnitModel();
  rowsHousing = [];
  errorMessage: string;
  dato: any;

  collaboratorsList: Array<UserModel> = new Array();
  rowsCollaborators = [];
  datoCollaborators: any;
  tempCollaborators: String[];
  idUnity: string = "";
  titlePage: any;
  buttonCreate: any;
  isAdmin: boolean;
  roleTitle = this.tokenStorage.getAuthorities();
  createType = "ROLE_COLLABORATOR";

  rowsProfiles = [];
  datoProfiles: any;
  tempProfiles: String[];

  urlPeticionImg = environment.serverUrl + "api/archivos/ver-img?ruta=";

  public adminIdStorage: string;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private crudServices: CrudServiceService,
    private messageService: InfoMessagesService,
    private tokenStorage: TokenStorageService
  ) {}

  ngOnInit() {
    this.listCollaborators();
    this.configPage();
    this.listProfile();
    this.adminIdStorage = this.tokenStorage.getId();
  }

  openCreateHousingUnit() {
    
    var plan : any;
    this.crudServices.getModel("api/plan/buscar-por-nombre?name="+this.tokenStorage.getPlanId()).subscribe(
      (genericResponse) => {
        if (genericResponse.code === 200) {
          plan=genericResponse.genericObject;
          this.createHousingUnit(plan);
          
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized(
            "warning",
            genericResponse.answer,
            "No se puede crear copropiedad"
          );
        }
      },
      (error) => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
     
   
  }
  createHousingUnit(plan) {
 
    if (
      this.hosingUnitList.length>= plan.maxNumHousUnit 
    ) {
      if(plan.planSiguiente==null){
        this.messageService.getInfoMessagePersonalized(
          "warning",
          "Contacta con tu administrador llamando al numero +57 300 7393720",
          "Has excedido la capacidad máxima de unidades permitidas en tu plan actual"
        );}else{
          this.crudServices.getModel("api/plan/buscar-por-nombre?name="+plan.planSiguiente).subscribe(
            (genericResponse) => {
              if (genericResponse.code === 200) {
                
                var nextPlan=genericResponse.genericObject;
                this.messageService.getInfoUpgradePlan(nextPlan.name,nextPlan.maxNumHousUnit,nextPlan.maxNumCoownerShip,nextPlan.value);
              }
              if (genericResponse.code === 400) {
                this.messageService.getInfoMessagePersonalized(
                  "warning",
                  "Contacta con tu administrador llamando al numero +57 300 7393720",
                  "Has excedido la capacidad máxima de unidades permitidas en tu plan actual"
                );
              }
            },
            (error) => {
              console.error(error);
              this.messageService.getInfoMessagePersonalized(
                "warning",
                "Contacta con tu administrador llamando al numero +57 300 7393720",
                "Has excedido la capacidad máxima de unidades permitidas en tu plan actual"
              );
            }
          );
                
         
        }
    } else {
    const modalRef = this.modalService.open(CreateHousingUnitComponent, {
      windowClass: "",
      size: "lg",
      backdrop: "static",
    });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
      if (receivedEntry === "creado") {
        this.configPage();
      } else {
        return false;
      }
    });
  }
  }

  configPage() {
    if (this.tokenStorage.getAuthorities() === "ROLE_SUPER") {
      this.crudServices.getModel("api/unidad-residencial/listar").subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.hosingUnitList = genericResponse.answerList;
            this.rowsHousing = this.hosingUnitList;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Sin unidades"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
    } else {
      this.crudServices
        .getModel("api/unidad-residencial/admin/ver-todos")
        .subscribe(
          (genericResponse) => {
            if (genericResponse.code === 200) {
              this.hosingUnitList = genericResponse.answerList;
              this.rowsHousing = this.hosingUnitList;
            }
            if (genericResponse.code === 400) {
              this.messageService.getInfoMessagePersonalized(
                "warning",
                genericResponse.answer,
                "Sin unidades"
              );
            }
          },
          (error) => {
            console.error(error);
            this.messageService.getInfoMessageBadInternet();
          }
        );
    }
  }

  onDeleteHousing(id: any) {
    this.idUnity = this.tokenStorage.getCompanyId();
    this.crudServices
      .deleteModel("api/unidad-residencial/eliminar?id=" + id)
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.messageService.getInfoMessageDelete().then(() => {
              this.configPage();
              if (id == this.idUnity) {
                this.router.navigate(["/seleccionar-unidades-residenciales"]);
              }
            });
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se eliminó la Unidad"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
          this.errorMessage = `${error.status}: ${error.error.message}`;
        }
      );
  }

  deleteHousing(id: any) {
    event.preventDefault(); // prevent form submit
    swal({
      title: "¿Está seguro que desea eliminar la Unidad?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "¡Sí, eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        this.onDeleteHousing(id);
      }
    });
  }

  onUpdateHousing(id) {
    this.crudServices
      .getModel("api/unidad-residencial/buscar?id=" + id)
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.housingUnit = genericResponse.genericObject;
            const modalRef = this.modalService.open(
              UpdateHousingUnitComponent,
              {
                windowClass: "",
                size: "lg",
                backdrop: "static",
              }
            );
            modalRef.componentInstance.housingUnitU = this.housingUnit;
            modalRef.componentInstance.passEntry.subscribe(
              (receivedEntry: string) => {
                if (receivedEntry === "creado") {
                  this.configPage();
                } else {
                  return false;
                }
              }
            );
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Error actualizando la unidad residencial"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  /* Open Details of Housing */

  openDetailHousing(id: any, name: any) {
    this.tokenStorage.saveIdCompany(id);
    this.tokenStorage.saveCompanyName(name);
    this.router.navigate(
      ["/unidades-residenciales/detalle-unidad-residencial"],
      { relativeTo: this.route.parent }
    );
  }

  //  --------------------- Collaborators --------------------------
  // api/usuarios/super/listar-todos api/usuarios/obtener-lista-usuarios
  listCollaborators() {
    this.buttonCreate = "Agregar colaborador  ";
    this.crudServices.getModel("api/usuarios/obtener-colaborador").subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.datoCollaborators = genericResponse.answerList;
          this.tempCollaborators = { ...this.datoCollaborators };
          this.rowsCollaborators = this.datoCollaborators;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized(
            "warning",
            genericResponse.answer,
            "Sin menús"
          );
        }
      },
      (error) => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  openRegister() {
    const modalRef = this.modalService.open(CreateUserComponent, {
      windowClass: "modal",
      size: "lg",
      backdrop: "static",
    });
    modalRef.componentInstance.createType = this.createType;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      if (receivedEntry === "Usuario Registrado") {
        this.listCollaborators();
      } else {
        return false;
      }
    });
  }

  onDetailUser(id) {
    this.crudServices
      .getModel("api/usuarios/buscar-por-id?userId=" + id)
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            const modalRef = this.modalService.open(UpdateUserComponent, {
              windowClass: "",
              size: "lg",
            });
            modalRef.componentInstance.userInfoUpdate =
              genericResponse.genericObject;
            modalRef.componentInstance.isView = true;
          } else if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se encontro el usuario"
            );
          }
        },
        (error) => {
          this.errorMessage = `${error.status}: ${error.error.message}`;
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  deleteUser(name?) {
    event.preventDefault(); // prevent form submit
    swal({
      title: "¿Está seguro que desea eliminar el colaborador?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "¡Sí, eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        this.onDeleteUser(name);
      }
    });
  }

  onDeleteUser(name) {
    this.crudServices
      .deleteModel("api/usuarios/borrar?username=" + name)
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.messageService.getInfoMessageDelete().then((response) => {
              this.listCollaborators();
            });
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se eliminó el perfil"
            );
          }
        },
        (error) => {
          this.errorMessage = `${error.status}: ${error.error.message}`;
          console.error(error);
          this.messageService.getInfoMessagePersonalized(
            "warning",
            "ups, tenemos un problema.",
            "El Colaborador tiene asignaciones en el sistema"
          );
        }
      );
  }

  onUpdateUser(id) {
    this.crudServices
      .getModel("api/usuarios/buscar-por-id?userId=" + id)
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            const modalRef = this.modalService.open(UpdateUserComponent, {
              windowClass: "",
              size: "lg",
              backdrop: "static",
            });
            modalRef.componentInstance.userInfoUpdate =
              genericResponse.genericObject;
            modalRef.componentInstance.passEntry.subscribe(
              (userUpdated: UserModel) => {
                const selectedPlan: any = userUpdated.activatedPlanId;
                userUpdated.activatedPlanId = new PlansModel();
                userUpdated.activatedPlanId.id = selectedPlan;
                swal({
                  title: "¿Está seguro que desea realizar la actualización?",
                  showCancelButton: true,
                  confirmButtonText: "Aceptar",
                  type: "warning",
                }).then((result) => {
                  if (result.value) {
                    this.crudServices
                      .createModel("api/usuarios/actualizar", userUpdated)
                      .subscribe(
                        (genericUpdatedResponse) => {
                          if (genericUpdatedResponse.code === 200) {
                            this.messageService
                              .getInfoMessageUpdate()
                              .then((response) => {
                                this.modalService.dismissAll();
                              });
                          }
                          if (genericUpdatedResponse.code === 400) {
                            this.messageService.getInfoMessagePersonalized(
                              "warning",
                              genericResponse.answer,
                              "No se realizó la actualización"
                            );
                          }
                        },
                        (error) => {
                          this.errorMessage = `${error.status}: ${error.error.message}`;
                          console.error(error);
                          this.messageService.getInfoMessageBadInternet();
                        }
                      );
                  }
                });
              }
            );
          } else if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se encontró el usuario"
            );
          }
        },
        (error) => {
          this.errorMessage = `${error.status}: ${error.error.message}`;
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  toPairProfile(id) {
    this.crudServices
      .getModel("api/usuarios/buscar-por-id?userId=" + id)
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            const modalRef = this.modalService.open(ToPairProfileComponent, {
              windowClass: "",
              size: "lg",
            });
            // Enviamos la información al modal hijo.
            modalRef.componentInstance.userInfoUpdate =
              genericResponse.genericObject;
          } else if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se encontró el usuario"
            );
          }
        },
        (error) => {
          this.errorMessage = error.error.message;
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  //  --------------------- Profiles --------------------------

  listProfile() {
    if (this.tokenStorage.getAuthorities() === "ROLE_SUPER") {
      this.crudServices.getModel("api/profile/ver-todos").subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.datoProfiles = genericResponse.answerList;
            this.tempProfiles = { ...this.datoProfiles };
            this.rowsProfiles = this.datoProfiles;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Sin perfiles"
            );
          }
        },
        (error) => {
          this.errorMessage = `${error.status}: ${error.error.message}`;
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
    } else {
      this.crudServices
        .getModel("api/profile/listar-perfiles-por-empresa")
        .subscribe(
          (genericResponse) => {
            if (genericResponse.code === 200) {
              this.datoProfiles = genericResponse.answerList;
              this.tempProfiles = { ...this.datoProfiles };
              this.rowsProfiles = this.datoProfiles;
            }
            if (genericResponse.code === 400) {
              this.messageService.getInfoMessagePersonalized(
                "warning",
                genericResponse.answer,
                "Sin perfiles"
              );
            }
          },
          (error) => {
            this.errorMessage = `${error.status}: ${error.error.message}`;
            console.error(error);
            this.messageService.getInfoMessageBadInternet();
          }
        );
    }
  }

  openProfiles() {
    const modalRef = this.modalService.open(CreateProfileComponent, {
      windowClass: "modal",
      size: "lg",
    });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      if (receivedEntry === "Perfil Creado") {
        this.listProfile();
      } else {
        return false;
      }
    });
  }

  listProfileDetail(id) {
    this.crudServices
      .getModel("api/profile/ver-perfil?profileId=" + id)
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            const modalRef = this.modalService.open(DetailProfileComponent, {
              windowClass: "",
              size: "lg",
            });
            modalRef.componentInstance.profileInfo =
              genericResponse.genericObject;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se encontro el perfil"
            );
          }
        },
        (error) => {
          this.errorMessage = `${error.status}: ${error.error.message}`;
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  onUpdateProfiles(id) {
    this.crudServices
      .getModel("api/profile/ver-perfil?profileId=" + id)
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            const modalRef = this.modalService.open(UpdateProfileComponent, {
              windowClass: "",
              size: "lg",
            });
            modalRef.componentInstance.profileInfoUpdate =
              genericResponse.genericObject;
            modalRef.componentInstance.passEntry.subscribe((planUpdated) => {
              this.crudServices
                .createModel("api/profile/actualizar", planUpdated)
                .subscribe(
                  (genericUpdateResponse) => {
                    if (genericUpdateResponse.code === 200) {
                      this.messageService
                        .getInfoMessageUpdate()
                        .then((response) => {
                          this.modalService.dismissAll();
                        });
                    }
                    if (genericUpdateResponse.code === 400) {
                      this.messageService.getInfoMessagePersonalized(
                        "warning",
                        genericUpdateResponse.answer,
                        "No se actualizó el perfil"
                      );
                    }
                  },
                  (error) => {
                    this.errorMessage = `${error.status}: ${error.error.message}`;
                    console.error(error);
                    this.messageService.getInfoMessageBadInternet();
                  }
                );
            });
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se encontró el perfil"
            );
          }
        },
        (error) => {
          this.errorMessage = `${error.status}: ${error.error.message}`;
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  deleteProfile(id) {
    event.preventDefault(); // prevent form submit
    swal({
      title: "¿Está seguro que desea eliminar el perfil?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "¡Sí, eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        this.onDeleteProfile(id);
      }
    });
  }

  onDeleteProfile(id) {
    this.crudServices
      .deleteModel("api/profile/eliminar?profileId=" + id)
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.messageService.getInfoMessageDelete().then((response) => {
              this.listProfile();
            });
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se eliminó el perfil"
            );
          }
        },
        (error) => {
          this.errorMessage = `${error.status}: ${error.error.message}`;
          console.error(error);
          this.messageService.getInfoMessagePersonalized(
            "warning",
            "ups, tenemos un problema.",
            "El perfil se encuentra asociado en el sistema"
          );
        }
      );
  }

  toPairUser(id) {
    this.crudServices
      .getModel("api/profile/ver-perfil?profileId=" + id)
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            const modalRef = this.modalService.open(ToPairUserComponent, {
              windowClass: "",
              size: "lg",
            });
            // Enviamos la información al modal hijo.
            modalRef.componentInstance.profileInfoUpdate =
              genericResponse.genericObject;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se encontró el perfil"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }
}
