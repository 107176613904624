import { MantenimientoModel } from 'models/entities/mantenimiento-model'
import { UserModel } from 'models/entities/user-model';

export class DetalleMantenimientoModel {

    id: string;
    estadomantenimiento: string;
    descripcion: string;
    costoManoObra: string;
    observacionManoObra: string;
    costoRepuesto: string;
    observacionRepuesto: string;
    estadoMantenimiento: string;

    mantenimientoId: MantenimientoModel;
    responsableId : UserModel;

    public constructor(){
        this.id = '';
        this.estadomantenimiento = '';
        this.descripcion = '';
        this.costoManoObra = '';
        this.observacionManoObra = '';
        this.costoRepuesto = '';
        this.observacionRepuesto = '';
        this.estadoMantenimiento = '';

        this.mantenimientoId = new MantenimientoModel();
        this.responsableId = new UserModel();
    }

}