import { UpdateTaskComponent } from './update-task/update-task.component';
import { DetailTaskComponent } from './detail-task/detail-task.component';
import { CreateTaskComponent } from './create-task/create-task.component';
import { ListTaskComponent } from './list-task/list-task.component';
import { RoleAuthGuard } from 'app/shared/auth/roleAuth-guard.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [

  {
    path: '',
    children: [
      {
        path: 'listar-tareas',
        component: ListTaskComponent,
        data: {
          expectedRole: ['ROLE_ADMIN', 'ROLE_COLLABORATOR']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'crear-tareas',
        component: CreateTaskComponent,
        data: {
          expectedRole: ['ROLE_ADMIN', 'ROLE_COLLABORATOR']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'detalle-tareas',
        component: DetailTaskComponent,
        data: {
          expectedRole: ['ROLE_ADMIN', 'ROLE_COLLABORATOR']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'actualizar-tareas',
        component: UpdateTaskComponent,
        data: {
          expectedRole: ['ROLE_ADMIN', 'ROLE_COLLABORATOR']
        },
        canActivate: [RoleAuthGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TaskRoutingModule { }
