import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IncidentRoutingModule } from './incident-routing.module';
import { CreateIncidentComponent } from './create-incident/create-incident.component';
import { UpdateIncidentComponent } from './update-incident/update-incident.component';
import { DetailIncidentComponent } from './detail-incident/detail-incident.component';
import { ListIncidentOwnerComponent } from './list-incident-owner/list-incident-owner.component';
import { ListIncidentHousingUnitComponent } from './list-incident-housing-unit/list-incident-housing-unit.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TaskModule } from '../task/task.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from 'app/shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [CreateIncidentComponent, UpdateIncidentComponent, DetailIncidentComponent,
    ListIncidentOwnerComponent, ListIncidentHousingUnitComponent
  ],
  imports: [
    CommonModule,
    IncidentRoutingModule,
    TaskModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    SharedModule,
    NgSelectModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class IncidentModule { }
