import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HousingUnitRoutingModule } from './housing-unit-routing.module';
import { ListHousingUnitComponent } from './list-housing-unit/list-housing-unit.component';
import { CreateHousingUnitComponent } from './create-housing-unit/create-housing-unit.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UpdateHousingUnitComponent } from './update-housing-unit/update-housing-unit.component';
import { SelectHousingUnitComponent } from './select-housing-unit/select-housing-unit.component';
import { DetailHousingUnitComponent } from './detail-housing-unit/detail-housing-unit.component';
import { CoownershipModule } from '../coownership/coownership.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ArchwizardModule } from 'angular-archwizard';
import { NGXFormWizardModule } from 'app/forms/ngx-wizard/ngx-wizard.module';
import { AssignedHousingUnitComponent } from './assigned-housing-unit/assigned-housing-unit.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [ListHousingUnitComponent, CreateHousingUnitComponent, UpdateHousingUnitComponent,
     SelectHousingUnitComponent, DetailHousingUnitComponent, AssignedHousingUnitComponent],
  imports: [
    NGXFormWizardModule,
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HousingUnitRoutingModule,
    CoownershipModule,
    ArchwizardModule,
    NgxDatatableModule,
    NgSelectModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class HousingUnitModule { }
