import { UserModel } from './user-model';
import { HousingUnitModel } from './housing-unit-model';
import { IncidentTypetModel } from './incident-type-model';
import { CoownershipModel } from './coowner-ship-model';

export class IncidentModel {
    id: string;
    title: string;
    description: string;
    status: string;
    answerAdmin: string;
    reportNumber: string;
    pictureIncident: any;
    typeIncident: IncidentTypetModel;
    coOwnerShipId: CoownershipModel;
    housingUnitId: HousingUnitModel;
    createdDate: string;

    public constructor() {
        this.id = '';
        this.title = '';
        this.description = '';
        this.status = 'Pendiente';
        this.answerAdmin = '';
        this.reportNumber = '';
        this.pictureIncident = '';
        this.typeIncident = new IncidentTypetModel;
        this.coOwnerShipId = new CoownershipModel();
        this.housingUnitId = new HousingUnitModel();
        this.createdDate = new Date().toUTCString();
    }
}
