import { UserModel } from "./user-model";
import { UserCreationModel } from "./user-creation-model";
import { InvoiceTypeModel } from "./invoicetype-model";
import { coownerShipByUserModel } from "./coownershipbyuser";
import { CoownershipModel } from "./coowner-ship-model";
import { HousingUnitModel } from "./housing-unit-model";
export class InvoiceModel {
  id: string;
  housingUnitId: HousingUnitModel;
  coownerShipId: CoownershipModel;
  invoiceType: InvoiceTypeModel;
  createdDate: Date;
  expirationDate: Date;
  description: string;
  paymentDate: Date;
  evidencePhoto: any;
  amount: string;
  status: string;
  discuountPercentage: string;
  discountValue: string;
  userId: UserModel;
  otherName: string;
  egressIncome: string;
  receiptNumber: number;
  updatedAt: Date;

  public constructor() {
    this.id = "";
    this.housingUnitId = new HousingUnitModel();
    this.coownerShipId = new CoownershipModel();
    this.invoiceType = new InvoiceTypeModel();
    this.createdDate = new Date();
    this.expirationDate = new Date();
    this.paymentDate = new Date();
    this.description = "";
    this.evidencePhoto = "";
    this.amount = "0";
    this.status = "";
    this.discountValue = "0";
    this.discuountPercentage = "0";
    this.userId = new UserModel();
    this.otherName = "";
    this.egressIncome = "";
    this.receiptNumber = 0;
    this.updatedAt = new Date();
  }
}
