import { HousingUnitModel } from 'models/entities/housing-unit-model';
import { ProfileModel } from 'models/entities/profile-model';
import { UserModel } from 'models/entities/user-model';
import { NgForm } from '@angular/forms';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { InfoMessagesService } from './../../shared/messages/info-messages.service';
import { TokenStorageService } from './../../shared/storage-services/token-storage.service';
import { CrudServiceService } from './../../shared/backend/cruds/crud-service.service';
import { UserProfileHousingUnitModel } from './../../../models/entities/user-profile-housingunit';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-to-pair-profile-housingunit',
  templateUrl: './to-pair-profile-housingunit.component.html',
  styleUrls: ['./to-pair-profile-housingunit.component.scss']
})
export class ToPairProfileHousingunitComponent implements OnInit {

  userProfileHousingUnit: UserProfileHousingUnitModel = new UserProfileHousingUnitModel()
  collaborator: UserModel = new UserModel();
  profiles: ProfileModel = new ProfileModel();
  housingUnits: HousingUnitModel = new HousingUnitModel();
  @ViewChild('f') form: NgForm;

  datoProfile = [];
  tempProfile: string[];
  rowsProfile = [];

  datoCollaborator = [];
  tempCollaborator: string[];
  rowsCollaborator = [];

  datoHousing = [];
  tempHousing: string[];
  rowsHousing = [];

  constructor(private crudServices: CrudServiceService,
    private token: TokenStorageService,
    private message: InfoMessagesService) { }

  ngOnInit() {
    this.configCollaborator()
    this.configProfile()
    this.configHousingUnits()
  }
  configCollaborator() {
    this.crudServices.getModel('api/usuarios/obtener-colaborador').subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.datoCollaborator = genericResponse.answerList;
          this.tempCollaborator = { ...this.datoCollaborator };
          this.rowsCollaborator = this.datoCollaborator;
        }
        if (genericResponse.code === 400) {
          this.message.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin menús')
        }
      },
      error => {
        console.error(error);
        this.message.getInfoMessageBadInternet();
      }
    );

  }

  configProfile() {
    this.crudServices.getModel('api/profile/listar-perfiles-por-empresa').subscribe(
      (genericResponse: GenericResponseModel ) => {
        if (genericResponse.code === 200) {
          this.datoProfile = genericResponse.answerList;
          this.tempProfile = { ...this.datoProfile };
          this.rowsProfile = this.datoProfile;
        }
        if (genericResponse.code === 400) {
          this.message.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin perfiles')
        }
      }, error => {

        this.message.getInfoMessageBadInternet();
      }
    );
  }

  configHousingUnits() {
    this.crudServices.getModel('api/unidad-residencial/admin/ver-todos').subscribe(
      (genericResponse: GenericResponseModel ) => {
        if (genericResponse.code === 200) {
          this.datoHousing = genericResponse.answerList;
          this.tempHousing = { ...this.datoHousing };
          this.rowsHousing = this.datoHousing;
        }
        if (genericResponse.code === 400) {
          this.message.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin perfiles')
        }
      }, error => {

        this.message.getInfoMessageBadInternet();
      }
    );
  }

  onSubmit() {
    this.crudServices.createModel('api/perfil-uresidencial/guardar', this.userProfileHousingUnit).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this .message.getInfoMessageCreate();
          this.clean()
        } else if (genericResponse.code === 400) {
          this.message.getInfoMessagePersonalized('info', genericResponse.answer, 'Ups! tu colaborador ' +
          'ya esta asignado a estos paremetros')
        }
      },
      error => {
        this.message.getInfoMessageBadInternet()
      }
    );
  }

  clean() {
    this.userProfileHousingUnit = new UserProfileHousingUnitModel();
    this.form.form.markAsPristine();
          this.form.form.markAsUntouched();
          this.form.form.updateValueAndValidity();
  }
}
