import { NgForm } from '@angular/forms';
import { GenericResponseModel } from './../../../models/utilities/generic.response.model';
import { TokenStorageService } from './../../shared/storage-services/token-storage.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoMessagesService } from './../../shared/messages/info-messages.service';
import { CrudServiceService } from './../../shared/backend/cruds/crud-service.service';
import { QuestionOptionModel } from './../../../models/entities/question-option-model';
import { QuestionModel } from './../../../models/entities/question-model';
import { Component, OnInit, Output, Input, EventEmitter, ViewChild } from '@angular/core';

@Component({
  selector: 'app-update-question',
  templateUrl: './update-question.component.html',
  styleUrls: ['./update-question.component.scss']
})
export class UpdateQuestionComponent implements OnInit {

  @Input() public newQuestion: QuestionModel;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  questionOptionList: Array<QuestionOptionModel> = new Array();
  questionOptionInfo: QuestionOptionModel = new QuestionOptionModel();
  @ViewChild('q') questionOptionForm: NgForm;
  constructor(
    public crudServices: CrudServiceService,
    public messageService: InfoMessagesService,
    public activeModal: NgbActiveModal,
    public tokenStorage: TokenStorageService,
  ) { }


  ngOnInit() {
    this.getQuestionOption(this.newQuestion.id);
  }

  onSubmit() {
    //this.newQuestion.housingUnitId.id = this.tokenStorage.getCompanyId();
    this.crudServices.createModel('api/preguntas/guardar', this.newQuestion).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.saveOptions(genericResponse.genericObject.id)
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Problema creando pregunta')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageError();
      }
    );
  }

  saveQuestionList() {
    this.questionOptionList.push(this.questionOptionInfo)
    this.questionOptionInfo = new QuestionOptionModel();
    this.questionOptionForm.form.markAsPristine();
    this.questionOptionForm.form.markAsUntouched();
    this.questionOptionForm.form.updateValueAndValidity();
  }


  deleteQuestionOptionList(storage: QuestionOptionModel) {
    if (storage.id !== '') {
      this.DeleteQuestionOption(storage.id)
      this.questionOptionList.splice(this.questionOptionList.indexOf(storage), 1)
    } else {
      this.questionOptionList.splice(this.questionOptionList.indexOf(storage), 1)
    }

  }

  saveOptions(id) {
    this.questionOptionList.forEach(options => {
      options.question.id = id;
    });
    this.crudServices.createModel('api/preguntas/guardar-opciones', this.questionOptionList).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageCreate().then()
          this.passEntry.emit('creado')
          this.activeModal.close()
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Problema creando opción de pregunta')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageError();
      }
    );
  }


  getQuestionOption(id) {
    this.crudServices.getModel('api/preguntas/buscar-opcion-pregunta?id=' + id).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.questionOptionList = genericResponse.answerList;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se cargaron las opciones de las preguntas')

        }
      },
      error => {
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  DeleteQuestionOption(id) {
    this.crudServices.deleteModel('api/preguntas/borrar-opcion-pregunta?id=' + id).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageDelete()
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se cargaron las opciones de las preguntas')

        }
      },
      error => {
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }
}
