import { ListmenuComponent } from './listmenu/listmenu.component';
import { CreatemenuComponent } from './createmenu/createmenu.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleAuthGuard } from 'app/shared/auth/roleAuth-guard.service';
import { DetailmenuComponent } from './detailmenu/detailmenu.component';
import { UpdatemenuComponent } from './updatemenu/updatemenu.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'listar-menus',
        component: ListmenuComponent,
        data: {
          title: 'listmenu',
          expectedRole: ['ROLE_SUPER']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'crear-menu',
        component: CreatemenuComponent,
        data: {
          title: 'createmenu',
          expectedRole: ['ROLE_SUPER']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'detail-menu',
        component: DetailmenuComponent,
        data: {
          title: 'detailmenu',
          expectedRole: ['ROLE_SUPER']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'update-menu',
        component: UpdatemenuComponent,
        data: {
          title: 'updatemenu',
          expectedRole: ['ROLE_SUPER']
        },
        canActivate: [RoleAuthGuard]
      }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [RoleAuthGuard],
  exports: [RouterModule]
})
export class MenucomponentRoutingModule { }
