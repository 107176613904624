import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { IncidentModel } from 'models/entities/incident-model';
import { IncidentTypetModel } from 'models/entities/incident-type-model';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { FirebaseStorageService } from 'app/shared/firebase/firebase-storage.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-update-incident',
  templateUrl: './update-incident.component.html',
  styleUrls: ['./update-incident.component.scss']
})
export class UpdateIncidentComponent implements OnInit {

  @Input() incidentInfo;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  rowsTypeIncident = [];
  tempTypeIncident: string[];
  datoTypeIncident: any;
  incidentTypeId: any;
  public incidentModel = new IncidentModel();
  public incidentTypeModel = new IncidentTypetModel();
  infoIncidentType = new IncidentTypetModel();

  rolAdmin = this.tokenStorage.getAuthorities();
  errorMessage: any;

  // file
  downloadURL: any;
  currentFileUpload: File;
  url: any;
  selectedExtension = '';
  typesRestrictions = ['jpg', 'PNG', 'png', 'JPGE'];
  atached = false;

  constructor(
    private crudServices: CrudServiceService, private messageService: InfoMessagesService,
    private modalService: NgbModal, private tokenStorage: TokenStorageService,
    private firebaseStorageService: FirebaseStorageService,
  ) { }

  ngOnInit() {
    this.listTypeIncident();
    this.stimatedTime();
  }

  closeModal() {
    this.modalService.dismissAll();
  }
  
  

  onSubmit() {
    this.crudServices.putModel('api/incidentes/actualizar-incidente?id=' + this.incidentInfo.id, this.incidentInfo).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          if (this.atached) {
            swal({
              title: 'Estamos actualizando la foto!',
              text: 'Sólo tardara unos segundos.',
              timer: 2000,
              showConfirmButton: false,
              imageUrl: 'https://ea1hlh.es/wp-content/uploads/2019/04/loading.gif'
            });
            setTimeout(() => {
            }, 2000);
            this.upload(this.incidentInfo)
          }

          if ( !this.atached) {
            this.incidentInfo.logoUrl = this.incidentInfo;
            this.messageService.getInfoMessageCreate().then(
              (result) => {
                this.passEntry.emit('creado');
                this.modalService.dismissAll();
              }
            )
          }

        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Problema actualizando el incidente')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  listTypeIncident() {
    {
      this.crudServices.getModel('api/incidentes/tipo-incidente').subscribe(
        genericResponse => {
          if (genericResponse.code === 200) {
            this.datoTypeIncident = genericResponse.answerList;
            this.tempTypeIncident = { ...this.datoTypeIncident };
            this.rowsTypeIncident = this.datoTypeIncident;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin incidentes')
          }
        },
        error => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
    }
  }

  stimatedTime() {
    this.incidentTypeId = this.incidentInfo.typeIncident.id;
    this.crudServices.getModel('api/incidentes/buscar-tiempo-estimado?id=' + this.incidentTypeId).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.incidentTypeModel = genericResponse.genericObject;
          this.infoIncidentType = this.incidentTypeModel;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error buscando el incidente')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

// Upload Image in Firebase

readUrl(inputValue: any): void {
  const img = new Image();
  this.atached = true;
  img.src = window.URL.createObjectURL(inputValue.target.files.item(0));
  img.onload = () => {
    window.URL.revokeObjectURL(img.src);
    if (img.naturalWidth <= 2000 && img.naturalHeight <= 2000) {
      this.currentFileUpload = inputValue.target.files[0];
      var myReader: FileReader = new FileReader();
      myReader.onloadend = (e) => {
        this.url = myReader.result;
      }
      myReader.readAsDataURL(this.currentFileUpload);
    } else {
      this.messageService.getInfoMessagePersonalized
        ('warning', 'El tamaño de la imagen debe ser menor o igual a 200 x 200 px', 'Imagen fuera de proporciones');

    }
  }
}

selectFile(event) {
  if (this.typesRestrictions.includes(event.target.files.item(0).name.split('.').pop())) {
    this.currentFileUpload = event.target.files.item(0);
    this.selectedExtension = event.target.files.item(0).name.split('.').pop()
  } else {
    this.currentFileUpload = undefined;
    swal({
      title: 'El tipo no es correcto', type: 'error',
      text: 'El archivo debe tener formato jpg, PNG, png ó JPEG, jpeg'
    })
  }
}


upload(incident: IncidentModel) {
  this.firebaseStorageService
    .uploadFile('images/' + 'incidentes/' + incident.id, 'incident.' + this.selectedExtension, this.currentFileUpload).then(
      (responseFirebase) => {
        this.firebaseStorageService
          .loadFile('images/' + 'incidentes/' + incident.id, 'incident.' + this.selectedExtension).then(
            (dowloadURL) => {
              this.downloadURL = dowloadURL
              this.passEntry.emit('creado');
              this.modalService.dismissAll();
              this.addPhoto(this.downloadURL)
            }
          ).catch(error => {
            console.error(error);
            this.messageService.getInfoMessageBadInternet();
          })
      }, (error) => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    )
}

addPhoto(downloadURL: any) {
  this.incidentInfo.pictureIncident = downloadURL;
  this.crudServices.putModel('api/incidentes/actualizar-incidente?id=' + this.incidentInfo.id, this.incidentInfo).subscribe(
    (genericResponse: GenericResponseModel) => {
      if (genericResponse.code === 200) {
        this.incidentInfo = new IncidentModel();
      }
    },
    error => {
      console.error(error);
      this.messageService.getInfoMessageBadInternet();
      this.errorMessage = error.error.answer;
    }
  );
}
 
}
