import { HousingUnitModel } from './housing-unit-model';
import { UserModel } from 'models/entities/user-model';
import { TowerModel } from './tower-model';

export class CoownershipModel {
    id: string;
    number: string;
    percentage: string;
    area: string;
    ownerId: UserModel;
    towerBlockId: TowerModel;
    housingUnitId: HousingUnitModel;

    public constructor() {
        this.id = '';
        this.number = '';
        this.percentage = '';
        this.area = '';
        this.ownerId = new UserModel();
        this.towerBlockId = new TowerModel();
        this.housingUnitId = new HousingUnitModel();
    }
}
