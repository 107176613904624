import { DocumentTypeModel } from "./document-type-model";
import { HousingUnitTypeModel } from "./housing-unit-type-model";
import { UserModel } from "./user-model";
import { CityModel } from "./city-model";
import { DepartmentModel } from "./department-model";

export class HousingUnitModel {
  id: string;
  name: string;
  documentTypeId: DocumentTypeModel;
  documentNumber: string;
  housingUnitTypeId: HousingUnitTypeModel;
  departmentId: DepartmentModel;
  cityId: CityModel;
  address: string;
  email: string;
  phone: string;
  cellphone: string;
  adminId: UserModel;
  createdDate: string;
  lastUpdateDate: string;
  adminCost: number;
  adminCostCalculated: boolean;
  expirationDays: number;
  defaulters: boolean;
  logoUrl: any;
  chargeInterest: boolean;

  constructor() {
    this.id = null;
    this.name = "";
    this.documentTypeId = new DocumentTypeModel();
    this.documentNumber = "";
    this.housingUnitTypeId = new HousingUnitTypeModel();
    this.departmentId = new DepartmentModel();
    this.cityId = new CityModel();
    this.email = "";
    this.address = "";
    this.phone = "";
    this.cellphone = "";
    this.adminId = new UserModel();
    this.createdDate = new Date().toUTCString();
    this.lastUpdateDate = new Date().toUTCString();
    this.adminCost = 0;
    this.adminCostCalculated = true;
    this.expirationDays = 0;
    this.defaulters = false;
    this.logoUrl = "";
    this.chargeInterest = false;
  }
}
