import { UserModel } from './user-model';
import {UserOAuthModel} from './user-o-auth-model';

export class UserCreationModel {

    user: UserOAuthModel;
    userInfo: UserModel;

    constructor(){
        this.user = new UserOAuthModel();
        this.userInfo = new UserModel();
    }

}