import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListTowerBlockComponent } from './list-tower-block/list-tower-block.component';
import { UpdateTowerBlockComponent } from './update-tower-block/update-tower-block.component';
import { CreateTowerBlockComponent } from './create-tower-block/create-tower-block.component';
import { DetailTowerBlockComponent } from './detail-tower-block/detail-tower-block.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'listar-torres',
        component: ListTowerBlockComponent,
        data: {
          title: 'list-tower',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN']
        }
      },
      {
        path: 'crear-torre',
        component: CreateTowerBlockComponent,
        data: {
          title: 'create-Tower',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN']
        }
      },
      {
      path: 'actualizar-torre',
      component: UpdateTowerBlockComponent,
      data: {
        title: 'update-tower',
        expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN']
      }
    },
    {
      path: 'detalle-torre',
      component: DetailTowerBlockComponent,
      data: {
        title: 'detail-tower',
        expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN']
      }
    },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TowerBlockRoutingModule { }
