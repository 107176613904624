import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PetsRoutingModule } from './pets-routing.module';
import { CreatePetComponent } from './create-pet/create-pet.component';
import { UpdatePetComponent } from './update-pet/update-pet.component';
import { DetailPetComponent } from './detail-pet/detail-pet.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ListPetsHousingUnitComponent } from './list-pets-housing-unit/list-pets-housing-unit.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
  declarations: [CreatePetComponent, UpdatePetComponent, DetailPetComponent, ListPetsHousingUnitComponent],
  imports: [
    CommonModule,
    PetsRoutingModule,
    NgbModule,
    FormsModule,
    NgSelectModule,
    NgxDatatableModule
  ],
  entryComponents: [
    CreatePetComponent, UpdatePetComponent, DetailPetComponent
  ]
})
export class PetsModule { }
