import { HousingUnitModel } from "./housing-unit-model";
import { SocialAreaModel } from "./social-area";

export class MeetingModel {
  id: string;
  name: string;
  openDate: Date;
  openDateTime: string;
  closeDate: Date;
  closeDateTime: string;
  socialAreaId: SocialAreaModel;
  housingUnitId: HousingUnitModel;
  subject: string;
  qrCode: string;
  typePermit: string;

  public constructor() {
    this.id = "";
    this.name = "";
    this.openDate = new Date();
    this.openDateTime = "";
    this.closeDate = new Date();
    this.closeDateTime = "";
    this.socialAreaId = new SocialAreaModel();
    this.housingUnitId = new HousingUnitModel();
    this.subject = "";
    this.qrCode = "";
    this.typePermit = "";
  }
}
