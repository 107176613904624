import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ListIncidentOwnerComponent } from "./list-incident-owner/list-incident-owner.component";
import { ListIncidentHousingUnitComponent } from "./list-incident-housing-unit/list-incident-housing-unit.component";
import { CreateIncidentComponent } from "./create-incident/create-incident.component";
import { DetailIncidentComponent } from "./detail-incident/detail-incident.component";
import { UpdateIncidentComponent } from "./update-incident/update-incident.component";

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "listar-incidentes-propietario",
        component: ListIncidentOwnerComponent,
        data: {
          title: "list-incidents-owner",
          expectedRole: ["ROLE_OWNER", "ROLE_COOWNER"],
        },
      },
      {
        path: "listar-incidentes-unidad",
        component: ListIncidentHousingUnitComponent,
        data: {
          title: "list-incident-housgin",
          expectedRole: ["ROLE_ADMIN"],
        },
      },
      {
        path: "registrar-incidente",
        component: CreateIncidentComponent,
        data: {
          title: "create-incident",
          expectedRole: [
            "ROLE_SUPER",
            "ROLE_ADMIN",
            "ROLE_OWNER",
            "ROLE_COOWNER",
          ],
        },
      },
      {
        path: "detalle-incidente",
        component: DetailIncidentComponent,
        data: {
          title: "detail-incident",
          // expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN']
        },
      },
      {
        path: "actualizar-incidente",
        component: UpdateIncidentComponent,
        data: {
          title: "update-incident",
          // expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN']
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class IncidentRoutingModule {}
