import { TipoEquipoModel } from './tipo-equipo-model';

export class MarcaModel {

    id: string;
    nombre: string;
    tipoEquipoId : TipoEquipoModel;

    public constructor(){
        this.id = '';
        this.nombre = '';
        this.tipoEquipoId = new TipoEquipoModel();
    }

}