import { coownerShipByUserModel } from './../../../models/entities/coownershipbyuser';
import { GenericResponseModel } from './../../../models/utilities/generic.response.model';
import { UserModel } from './../../../models/entities/user-model';
import { TokenStorageService } from './../../shared/storage-services/token-storage.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoMessagesService } from './../../shared/messages/info-messages.service';
import { CrudServiceService } from './../../shared/backend/cruds/crud-service.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-create-resident',
  templateUrl: './create-resident.component.html',
  styleUrls: ['./create-resident.component.scss']
})
export class CreateResidentComponent implements OnInit {
  
 newResident: UserModel  = new UserModel()
 residentCoownerShip: coownerShipByUserModel = new coownerShipByUserModel();
 errorMessage: any
 @Input() public idCoownerShip;
 @Output() passEntry: EventEmitter<any> = new EventEmitter();
  constructor(
    public crudServices: CrudServiceService,
    public messageService: InfoMessagesService,
    public activeModal: NgbActiveModal,
    public tokenStorage: TokenStorageService,
  ) { }

  ngOnInit() {
  }

  onSubmit() {
    this.saveResident()

  }

  saveResident() {
    this.newResident.documentTypeId = null
    this.newResident.activatedPlanId = null
    this.crudServices.createModel('api/usuarios/crear-residente', this.newResident).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.residentCoownerShip.userId = genericResponse.genericObject
          this.residentCoownerShip.coOwnershipId.id = this.idCoownerShip
          this.crudServices.createModel('api/usuarios/guardar-residente-copropiedad', this.residentCoownerShip).subscribe(
            (genericResponse2: GenericResponseModel) => {
              if (genericResponse.code === 200) {
                this.messageService.getInfoMessageCreate();
                this.activeModal.dismiss();
                this.passEntry.emit('Usuario_Registrado');
              }
              if (genericResponse.code === 400) {
                this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se creó el residente')
                this.errorMessage = genericResponse.answer;
              }
            },
            error => {
              this.errorMessage = error.error.message;
              console.error(error);
              this.messageService.getInfoMessageBadInternet();
            }
          );

        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se creó el residente')
          this.errorMessage = genericResponse.answer;
        }
      },
      error => {
        this.errorMessage = error.error.message;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

}
