import { UserModel } from 'models/entities/user-model';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import swal from 'sweetalert2';
import { FirebaseStorageService } from 'app/shared/firebase/firebase-storage.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { FileModel } from 'models/entities/file-model';
import { RelationalFileModel } from 'models/utilities/relational-type-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewFileComponent } from '../new-file/new-file.component';
import { CrudRepuestoComponent } from '../crud-repuesto/crud-repuesto.component';
import { ComposeModel } from 'models/utilities/compose-model';
import { EquipoModel } from 'models/entities/equipo-model';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { ModalInfo } from 'models/entities/modal-info';
import { RepuestoModel } from 'models/entities/repuesto-model';

@Component({
  selector: 'app-lista-repuestos',
  templateUrl: './lista-repuestos.component.html',
  styleUrls: ['./lista-repuestos.component.scss']
})
export class ListaRepuestosComponent implements OnInit {

  @ViewChild(DatatableComponent) table: DatatableComponent;

  modalInfo: ModalInfo;

  constructor(private messageService: InfoMessagesService,
              private crudServices: CrudServiceService,
              private tokenStorage: TokenStorageService,
              private modalService: NgbModal
              ) { }

       
  messages:any;
  rows = [];
  temp: string[];
  dato: any;

  @Input() idEquipo = '';

  ngOnInit() {
    this.messages = {
      'emptyMessage': 'No se encontraron registros',
      'totalMessage': 'registros totales'
    };

    this.obtenerRepuesto();
  }

  obtenerRepuesto() {
    this.crudServices.getModel('api/repuesto/listar-todo?equipoId=' + this.idEquipo).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.dato = genericResponse.answerList;
          this.temp = [...this.dato];
          this.rows = this.dato;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin incidentes')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  eliminarRepuesto(repuestoId) {

    swal({
      title: 'Esta seguro que desea eliminar el repuesto?',
      text: 'No puedes revertir esta acción despues!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.crudServices.deleteModel('api/repuesto/borrar?repuestoId=' + repuestoId).subscribe(
          genericResponse => {
            if (genericResponse.code === 200) {
              swal(
                'Exito en la operación!',
                'El repuesto ha sido eliminado.',
                'success'
              )
              this.obtenerRepuesto();
            }
            if (genericResponse.code === 400) {
              this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error al borrar el equipo')
            }
          },
          error => {
            console.error(error);
            this.messageService.getInfoMessageBadInternet();
          }
        );
      }
    })

  }

  crearRepuesto() {
    this.modalInfo = new ModalInfo();
    this.modalInfo.isCreate = true;

    const modalRef = this.modalService.open(CrudRepuestoComponent, {
      windowClass: '', size: 'lg',  backdrop: 'static'
    });

    modalRef.componentInstance.modalInfo = this.modalInfo;
    modalRef.componentInstance.idEquipo = this.idEquipo;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
      if (receivedEntry === 'repuesto_creado') {
        this.obtenerRepuesto();
      } else {
        return false;
      }
    }
    )
  }

  detalleRepuesto(repuestoModel) {
    this.modalInfo = new ModalInfo();
    this.modalInfo.isView = true;

    const modalRef = this.modalService.open(CrudRepuestoComponent, {
      windowClass: '', size: 'lg',  backdrop: 'static'
    });

    modalRef.componentInstance.modalInfo = this.modalInfo;
    modalRef.componentInstance.repuestoInfo = repuestoModel;
  }

  editarRepuesto(repuestoModel) {
    this.modalInfo = new ModalInfo();
    this.modalInfo.isUpdate = true;

    const modalRef = this.modalService.open(CrudRepuestoComponent, {
      windowClass: '', size: 'lg',  backdrop: 'static'
    });

    modalRef.componentInstance.modalInfo = this.modalInfo;
    modalRef.componentInstance.repuestoInfo = repuestoModel;
    modalRef.componentInstance.idEquipo = this.idEquipo;
  }

}
