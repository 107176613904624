import { ChartistComponent } from './../charts/chartist/chartist.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CalendarsModule } from './../calendar/calendar.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { QuestionRoutingModule } from './question-routing.module';
import { ListQuestionComponent } from './list-question/list-question.component';
import { CreateQuestionComponent } from './create-question/create-question.component';
import { DetailQuestionComponent } from './detail-question/detail-question.component';
import { UpdateQuestionComponent } from './update-question/update-question.component';
import { CreateSurveyComponent } from './create-survey/create-survey.component';
import { DetailSurveyComponent } from './detail-survey/detail-survey.component';

@NgModule({
  declarations: [ListQuestionComponent, CreateQuestionComponent, DetailQuestionComponent, UpdateQuestionComponent, CreateSurveyComponent, DetailSurveyComponent],
  imports: [
    CommonModule,
    QuestionRoutingModule,
    FormsModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    CalendarsModule,
    NgxChartsModule
  ]
})
export class QuestionModule { }
