import { HousingUnitModel } from "./../../../models/entities/housing-unit-model";
import { SharedService } from "./../../shared/shared.service";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TokenStorageService } from "./../../shared/storage-services/token-storage.service";
import { InfoMessagesService } from "./../../shared/messages/info-messages.service";
import { InvoiceModel } from "./../../../models/entities/invoice-model";
import { GenericResponseModel } from "./../../../models/utilities/generic.response.model";
import { CrudServiceService } from "./../../shared/backend/cruds/crud-service.service";
import { Component, OnInit, Input } from "@angular/core";
import * as html2pdf from "html2pdf.js";
import swal from "sweetalert2";
import { FirebaseStorageService } from "app/shared/firebase/firebase-storage.service";
import { ExportAsService, ExportAsConfig } from "ngx-export-as";
import { environment } from "environments/environment.prod";
import html2canvas from "html2canvas";
import { UserModel } from "../../../models/entities/user-model";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-detail-invoice",
  templateUrl: "./detail-invoice.component.html",
  styleUrls: ["./detail-invoice.component.scss"],
})
export class DetailInvoiceComponent implements OnInit {
  logo: any;
  @Input() public invoiceInfo: InvoiceModel;
  housingU: HousingUnitModel = new HousingUnitModel();
  @Input() public infoAdmin: UserModel = new UserModel();

  rolAdmin = this.tokenStorage.getAuthorities();
  errorMessage: any;
  urlPeticionImg = environment.serverUrl + "api/archivos/ver-img?ruta=";
  imageUrl: string;

  // file
  currentFileUpload: File;
  url: any;
  selectedExtension = "";
  typesRestrictions = ["jpg", "PNG", "png", "JPGE"];
  atached = false;
  downloadURL: any;

  constructor(
    private crudServices: CrudServiceService,
    public messageService: InfoMessagesService,
    private firebaseStorageService: FirebaseStorageService,
    public tokenStorage: TokenStorageService,
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private sharedService: SharedService,
    private exportAsService: ExportAsService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.configHU();
    this.getImg();
  }

  configHU() {
    this.crudServices
      .getModel(
        "api/unidad-residencial/buscar?id=" + this.tokenStorage.getCompanyId()
      )
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.housingU = genericResponse.genericObject;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              " Error al buscar la Unidad"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  public getImg() {
    let url = this.urlPeticionImg + this.infoAdmin.signatureUrl;
    console.log(url);
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const image = document.getElementById("my-image") as HTMLImageElement;
        image.src = url;
        this.imageUrl = URL.createObjectURL(blob);
      });
  }

  exportImageAsPDF() {
    const exportConfig: ExportAsConfig = {
      type: "pdf", // Set the export type to PDF
      fileName: "my-image.pdf", // Set the file name
      elementIdOrContent: "my-image",
    };

    // Get the image element
    const image = document.getElementById("my-image") as HTMLImageElement;

    // Convert the image to a canvas using html2canvas
    html2canvas(image).then((canvas) => {
      // Get the canvas as a data URL
      const dataUrl = canvas.toDataURL("image/png");

      // Set the data URL as the content to export

      // Export the image as a PDF
      this.exportAsService.save(exportConfig, "my-image").subscribe(() => {});
    });
  }

  onExportPdf(apto) {
    this.exportAsService
      .save(this.exportAsConfig, "factura" + apto)
      .subscribe(() => {
        // save started
      });
    // const options = {
    //   margin: 10,
    //   filename: 'factura' + apto + '.pdf',
    //   image: { type: 'png' },
    //   html2canvas: {},
    //   jsPDF: { orientation: 'portrait' }
    // };
    // const content: Element = document.getElementById('my-body');
    // html2pdf().from(content).set(options).save();
  }

  exportAsConfig: ExportAsConfig = {
    type: "pdf", // the type you want to download
    elementIdOrContent: "my-body", // the id of html/table element
  };

  saveEvidencePhoto() {
    this.crudServices
      .createModel("api/factura/actualizar", this.invoiceInfo)
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            if (this.atached) {
              swal({
                title: "Estamos cargando tu comprobante de pago!",
                text: "Sólo tardara unos segundos.",
                timer: 2000,
                showConfirmButton: false,
                imageUrl:
                  "https://ea1hlh.es/wp-content/uploads/2019/04/loading.gif",
              });
              setTimeout(() => {}, 2000);
              this.upload(this.invoiceInfo);
            }
            // this.messageService.getInfoMessageCreate();
            this.activeModal.close();
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se creó la factura"
            );
            this.errorMessage = genericResponse.answer;
          }
        },
        (error) => {
          this.errorMessage = error.error.message;
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  // Upload Image in Firebase

  readUrl(inputValue: any): void {
    const img = new Image();
    this.atached = true;
    img.src = window.URL.createObjectURL(inputValue.target.files.item(0));
    img.onload = () => {
      window.URL.revokeObjectURL(img.src);
      if (img.naturalWidth <= 2000 && img.naturalHeight <= 2000) {
        this.currentFileUpload = inputValue.target.files[0];
        var myReader: FileReader = new FileReader();
        myReader.onloadend = (e) => {
          this.url = myReader.result;
        };
        myReader.readAsDataURL(this.currentFileUpload);
      } else {
        this.messageService.getInfoMessagePersonalized(
          "warning",
          "El tamaño de la imagen debe ser menor o igual a 200 x 200 px",
          "Imagen fuera de proporciones"
        );
      }
    };
  }

  selectFile(event) {
    if (
      this.typesRestrictions.includes(
        event.target.files.item(0).name.split(".").pop()
      )
    ) {
      this.currentFileUpload = event.target.files.item(0);
      this.selectedExtension = event.target.files.item(0).name.split(".").pop();
    } else {
      this.currentFileUpload = undefined;
      swal({
        title: "El tipo no es correcto",
        type: "error",
        text: "El archivo debe tener formato jpg, PNG, png ó JPEG, jpeg",
      });
    }
  }

  upload(invoice: InvoiceModel) {
    this.firebaseStorageService
      .uploadFile(
        "images/" + "Facturas/" + invoice.id,
        "invoice." + this.selectedExtension,
        this.currentFileUpload
      )
      .then(
        (responseFirebase) => {
          this.firebaseStorageService
            .loadFile(
              "images/" + "Facturas/" + invoice.id,
              "invoice." + this.selectedExtension
            )
            .then((dowloadURL) => {
              this.downloadURL = dowloadURL;
              this.addPhoto(this.downloadURL);
            })
            .catch((error) => {
              console.error(error);
              this.messageService.getInfoMessageBadInternet();
            });
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  addPhoto(downloadURL: any) {
    this.invoiceInfo.evidencePhoto = downloadURL;
    this.crudServices
      .createModel("api/factura/actualizar", this.invoiceInfo)
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.invoiceInfo = new InvoiceModel();
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
          this.errorMessage = error.error.answer;
        }
      );
  }
}
