import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TowerModel } from 'models/entities/tower-model';

@Component({
  selector: 'app-detail-tower-block',
  templateUrl: './detail-tower-block.component.html',
  styleUrls: ['./detail-tower-block.component.scss']
})
export class DetailTowerBlockComponent implements OnInit {

  @Input() public towerBlockInfo

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  closeWindow() {
    this.modalService.dismissAll();
  }
}
