import { QuestionModel } from './question-model';


export class QuestionOptionModel {
    id: string;
    createdDate: Date;
    questionOption: string;
    question: QuestionModel;
    count: number;
    quorum: number;
    resultados: number;
    respuestaSeleccionada: boolean;

    public constructor() {
        this.id = '';
        this.createdDate = new Date();
        this.questionOption = '';
        this.question = new QuestionModel();
        this.count = 0;
        this.quorum = 0; 
        this.resultados = 0;
        this.respuestaSeleccionada = false;

    }

}
