import { GenericResponseModel } from "./../../../models/utilities/generic.response.model";
import { TokenStorageService } from "./../../shared/storage-services/token-storage.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { InfoMessagesService } from "./../../shared/messages/info-messages.service";
import { CrudServiceService } from "./../../shared/backend/cruds/crud-service.service";
import { providerModel } from "./../../../models/entities/provider";
import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { DepartmentModel } from "models/entities/department-model";
import { CityModel } from "models/entities/city-model";
import { MatDatepickerModule } from "@angular/material/datepicker";
@Component({
  selector: "app-create-provider",
  templateUrl: "./create-provider.component.html",
  styleUrls: ["./create-provider.component.scss"],
})
export class CreateProviderComponent implements OnInit {
  newProvider: providerModel = new providerModel();
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  errorMessage: String;

  Department = [];
  departmentList: Array<DepartmentModel> = new Array();

  Citys = [];
  citysList: Array<CityModel> = new Array();

  departmentId: any;

  constructor(
    public crudServices: CrudServiceService,
    public messageService: InfoMessagesService,
    public activeModal: NgbActiveModal,
    public tokenStorage: TokenStorageService
  ) {}

  ngOnInit() {
    this.listDepartments();
  }

  onSubmit() {
    this.save();
  }

  save() {
    //console.log(this.newProvider);
    console.log(this.newProvider.finishDate);
    this.newProvider.housingUnitId.id = this.tokenStorage.getCompanyId();
    this.crudServices
      .createModel("api/proveedor/guardar", this.newProvider)
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.messageService.getInfoMessageCreate();
            this.passEntry.emit("tarea_creada");
            this.activeModal.close();
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se creó el usuario"
            );
            this.errorMessage = genericResponse.answer;
          }
        },
        (error) => {
          this.errorMessage = error.error.message;
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  listDepartments() {
    this.crudServices
      .getModel("api/departamentos/listar-departamentos")
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.departmentList = genericResponse.answerList;
            this.Department = this.departmentList;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Sin departamentos"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  listCitys() {
    this.crudServices
      .getModel(
        "api/ciudades/listar-ciudades?id=" + this.newProvider.departmentId.id
      )
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.citysList = genericResponse.answerList;
            this.Citys = this.citysList;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Sin ciudades"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }
}
