import { Component, OnInit } from '@angular/core';
import { PricingModel } from './model/pricing.model';

@Component({
  selector: 'app-pricing-container',
  templateUrl: './pricing-container.component.html',
  styleUrls: ['./pricing-container.component.scss']
})
export class PricingContainerComponent implements OnInit {
  pricings: Array<PricingModel>;
  constructor() { }

  ngOnInit() {
    this.pricings = [
      {
        fare: 'Gratis',
        price: '',
        period: '',
        actionText: 'Obten Información',
        items: [
          {
            icon: 'fa fa-check',
            title: '¿Preguntame Cómo?'
          }
        ]
      },
      {
        fare: 'Básico',
        price: '',
        period: '',
        actionText: 'Obten Información',
        items: [
          {
            icon: 'fa fa-check',
            title: 'Hasta 100 Copropiedades'
          },
          {
            icon: 'fa fa-check',
            title: '5GB'
          },
          {
            icon: 'fa fa-check',
            title: '1 Propiedad Horizontal'
          },
          {
            icon: 'fa fa-check',
            title: 'App Movil'
          },
          {
            icon: 'fa fa-check',
            title: 'Asamblea Virtual',
            hasMeted: false
          },
          {
            icon: 'fa fa-check',
            title: 'Reportes',
            hasMeted: false
          },
          {
            icon: 'fa fa-check',
            title: '120.000/mes',
            hasMeted: false
          },
          {
            icon: 'fa fa-check',
            title: '1.100.000/año',
            hasMeted: false
          }
        ]
      },
      {
        fare: 'Pro',
        price: '',
        period: '',
        actionText: 'Obten Información',
        items: [
          {
            icon: 'fa fa-check',
            title: 'Hasta 300 copropiedades'
          },
          {
            icon: 'fa fa-check',
            title: '10GB'
          },
          {
            icon: 'fa fa-check',
            title: '1 Propiedad Horizontal'
          },
          {
            icon: 'fa fa-check',
            title: 'App Movil'
          },
          {
            icon: 'fa fa-check',
            title: 'Asamblea Virtual',
            hasMeted: false
          },
          {
            icon: 'fa fa-check',
            title: 'Reportes',
            hasMeted: false
          },
          {
            icon: 'fa fa-check',
            title: '220.000/mes',
            hasMeted: false
          },
          {
            icon: 'fa fa-check',
            title: '2.200.000/año',
            hasMeted: false
          }
        ]
      }
     
    ]
  }
}
