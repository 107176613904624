import { Component, OnInit, ViewChild } from '@angular/core';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { InvoiceModel } from 'models/entities/invoice-model';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { DetailInvoiceComponent } from '../detail-invoice/detail-invoice.component';
import { GenericFilterModel } from 'models/entities/generic-filter.model';
import { InvoiceTypeModel } from 'models/entities/invoicetype-model';

@Component({
  selector: 'app-list-invoice-owner',
  templateUrl: './list-invoice-owner.component.html',
  styleUrls: ['./list-invoice-owner.component.scss']
})
export class ListInvoiceOwnerComponent implements OnInit {

  filter: GenericFilterModel = new GenericFilterModel();
  invoiceInfo: InvoiceModel = new InvoiceModel()
  listTypeInvoice: Array<InvoiceTypeModel> = new Array();
  rows: Array<InvoiceModel> = new Array();
  dato: any;
  temp = [];

  datoInvoiceType: any;
  tempInvoiceType: string[];
  rowsInvoiceType = [];

  idHousingUnit: any = this.tokenStorage.getCompanyId();

  errorMessage: any;

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(
    public crudServices: CrudServiceService, public messageService: InfoMessagesService,
    public tokenStorage: TokenStorageService, public modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.configPage();
    this.getInvoiceTypes();
  }

  configPage() {
    this.crudServices.getModel('api/factura/facturas-copropiedad?id=' + this.tokenStorage.getCoownerShipId()).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.dato = genericResponse.answerList;
          this.temp = {...this.dato};
          this.rows = this.dato;
        }
      }, error => {
        this.errorMessage = `${error.status}: ${error.error.message}`;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  getInvoiceTypes() {
    console.log('listado las facturas tupsoas')
    this.crudServices.getModel('api/factura/obtener-tipos?newType=income').subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.datoInvoiceType = genericResponse.answerList;
          this.tempInvoiceType = {...this.datoInvoiceType};
          this.rowsInvoiceType = this.datoInvoiceType;
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los tipos de facturas del sistema',
            'Problema consultando los tipos de facturas');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar los tipos de facturas' + JSON.stringify(error))
      }
    );
  }

  detail(id) {
    this.crudServices.getModel('api/factura/detalle?id=' + id).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.invoiceInfo = genericResponse.genericObject
          const modalRef = this.modalService.open(
            DetailInvoiceComponent, {
            windowClass: '', size: 'lg', backdrop: 'static'
          }
          );
          modalRef.componentInstance.invoiceInfo = this.invoiceInfo;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error buscando la factura')
        }
      }, 
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }


  updateFilterByNumber(event) {
    const val = event.target.value;
    const temp = this.temp.filter(function (d: any) {
      if (d.expirationDate) {
        return d.expirationDate.toLowerCase().indexOf(val) !== -1 || !val;
      } else {
        return false;
      }
    });
    this.rows = temp;
    this.table.offset = 0;
    // const val = event.target.value.toLowerCase();
    // const temp = this.temp.filter(function (d: any) {
    //   if (d.coownerShipId.number) {
    //     return d.coownerShipId.number.toLowerCase().indexOf(val) !== -1 || !val;
    //   } else {
    //     return false;
    //   }
    // });
    // this.rows = temp;
    // this.table.offset = 0;
  }

  updateFilterByType(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function (d: any) {
      if (d.invoiceType.name) {
        return d.invoiceType.name.toLowerCase().indexOf(val) !== -1 || !val;
      } else {
        return false;
      }
    });
    this.rows = temp;
    this.table.offset = 0;
  }

  onFilter() {
    if (this.filter.firstDate === '' && this.filter.endDate === '' && this.filter.id === '') {
      this.messageService.getInfoMessagePersonalized('info', 'Los campos no pueden estar vacíos.', ' Error en la búsqueda')
    } else if ( this.filter.firstDate !== '' && this.filter.endDate === '' && this.filter.name === '') {
      this.messageService.getInfoMessagePersonalized('info', 'Combinación de búsqueda no permitida', ' Error en la búsqueda')
     }else if ( this.filter.firstDate === '' && this.filter.endDate !== '' && this.filter.name === '') {
      this.messageService.getInfoMessagePersonalized('info', 'Combinación de búsqueda no permitida', ' Error en la búsqueda')
     } else if ( this.filter.firstDate === '' && this.filter.endDate !== '' && this.filter.name !== '') {
      this.messageService.getInfoMessagePersonalized('info', 'Combinación de búsqueda no permitida', ' Error en la búsqueda')
     }else if ( this.filter.firstDate !== '' && this.filter.endDate === '' && this.filter.name !== '') {
      this.messageService.getInfoMessagePersonalized('info', 'Combinación de búsqueda no permitida', ' Error en la búsqueda')
     } else {
      this.crudServices.getModelModel('api/factura/filtro-facturas?id=' + this.tokenStorage.getCoownerShipId(), this.filter).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.dato = genericResponse.answerList;
            this.temp = { ...this.dato };
            this.rows = this.dato;
          }
        },
        error => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );

    }
  }

  clean() {
    this.filter = new GenericFilterModel();
    this.configPage();
  }

}
