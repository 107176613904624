import { GenericResponseModel } from "./../../../models/utilities/generic.response.model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { InfoMessagesService } from "./../../shared/messages/info-messages.service";
import { CrudServiceService } from "./../../shared/backend/cruds/crud-service.service";
import { providerModel } from "./../../../models/entities/provider";
import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { DepartmentModel } from "models/entities/department-model";
import { CityModel } from "models/entities/city-model";

@Component({
  selector: "app-update-provider",
  templateUrl: "./update-provider.component.html",
  styleUrls: ["./update-provider.component.scss"],
})
export class UpdateProviderComponent implements OnInit {
  @Input() public providerInfo: providerModel;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  errorMessage: String;

  Department = [];
  departmentList: Array<DepartmentModel> = new Array();

  Citys = [];
  citysList: Array<CityModel> = new Array();

  constructor(
    public crudServices: CrudServiceService,
    public messageService: InfoMessagesService,
    public modalService: NgbModal
  ) {}

  ngOnInit() {
    console.log(this.providerInfo);
    this.listDepartments();
    this.listCitys();
  }

  closeWindow() {
    this.modalService.dismissAll();
  }

  upateProvider() {
    this.crudServices
      .createModel("api/proveedor/guardar", this.providerInfo)
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.messageService.getInfoMessageCreate();
            this.passEntry.emit("creado");
            this.closeWindow();
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Problema actualizando el proveedor"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageError();
          this.errorMessage = error.error.answer;
        }
      );
  }

  listDepartments() {
    this.crudServices
      .getModel("api/departamentos/listar-departamentos")
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.departmentList = genericResponse.answerList;
            this.Department = this.departmentList;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Sin departamentos"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  listCitys() {
    this.crudServices
      .getModel(
        "api/ciudades/listar-ciudades?id=" + this.providerInfo.departmentId.id
      )
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.citysList = genericResponse.answerList;
            this.Citys = this.citysList;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Sin ciudades"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }
}
