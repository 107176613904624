import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';

@Component({
  selector: 'app-update-doorman-correspondence',
  templateUrl: './update-doorman-correspondence.component.html',
  styleUrls: ['./update-doorman-correspondence.component.scss']
})
export class UpdateDoormanCorrespondenceComponent implements OnInit {

  @Input() correspondenceInfo;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  rowsOwners = [];
  tempOwners: string[];
  datoOwners: any;

  rowsCoownerShip = [];
  tempCoownerShip: string[];
  datoCoownerShip: any;

  constructor(
    private crudServices: CrudServiceService, private modalService: NgbModal,
    public tokenStorage: TokenStorageService, private messageService: InfoMessagesService
  ) { }

  ngOnInit() {
    //this.listOwners();
    this.listOwnersAndCoowners();
    this.listCoownerShip();
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  onSubmit(id: any) {
    console.log(this.correspondenceInfo.incomeTime);
    console.log(this.correspondenceInfo.correspondenceStatus);
    if ( this.correspondenceInfo.correspondenceStatus !== 'Por Entregar' ) {
      this.correspondenceInfo.deliveredBy = this.tokenStorage.getCompleteName();
    }
    this.crudServices.putModel('api/porteria/actualizar-correspondencia?id=' + id, this.correspondenceInfo).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageCreate();
          this.passEntry.emit('creado');
          this.messageService.getInfoMessageCreate().then(
            (result) => {
              this.modalService.dismissAll();
            }
          )

        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Problema registrando la torre')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  listOwners() {
    {
      this.crudServices.getModel('api/usuarios/obtener-propietarios').subscribe(
        genericResponse => {
          if (genericResponse.code === 200) {
            this.datoOwners = genericResponse.answerList;
            this.tempOwners = { ...this.datoOwners };
            this.rowsOwners = this.datoOwners;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin copropiedades')
          }
        },
        error => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
    }
  }

  listOwnersAndCoowners() {
    this.crudServices
      .getModel(
        "api/coownership/listar-todo-propietarios-copropietarios-por-unidad-residencial?housingId=" +
          this.tokenStorage.getCompanyId()
      )
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.datoOwners = genericResponse.answerList;
            this.tempOwners = { ...this.datoOwners };
            this.rowsOwners = this.datoOwners;
            console.log(this.rowsOwners);
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No hay propietarios ni copropietarios registrados"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  listCoownerShip() {
      this.crudServices.getModel('api/coownership/listar-todo-admin?id=' + this.tokenStorage.getCompanyId()).subscribe(
        genericResponse => {
          if (genericResponse.code === 200) {
            this.datoCoownerShip = genericResponse.answerList;
            this.tempCoownerShip = { ...this.datoCoownerShip };
            this.rowsCoownerShip = this.datoCoownerShip;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin copropiedades')
          }
        },
        error => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

}
