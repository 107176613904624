export class FilterDoormanVisitorModel {
    coOwnerShipId: string;
    housingId: number;
    arrivalDate: string;
    departureDate: string;

    public constructor() {
        this.coOwnerShipId = null;
        this.housingId = null;
        this.arrivalDate = '';
        this.departureDate = '';
    }
}
