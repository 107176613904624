import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-paginator-nav',
  templateUrl: './paginator-nav.component.html',
  styleUrls: ['./paginator-nav.component.scss']
})
export class PaginatorNavComponent implements OnInit, OnChanges {

  @Input() paginator: any;

  myPages: number[];

  from: number;
  to: number;

  @Output()
  passEntry: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.initPaginator();
   }

  ngOnChanges(changes: SimpleChanges) {
    if ( changes.paginator.previousValue ) {
      this.initPaginator();
    }
  }

  private initPaginator(): void {
    this.from =Math.min( Math.max(1, this.paginator.number - 4) , this.paginator.totalPages - 5);
    this.to = Math.max( Math.min(this.paginator.totalPages, this.paginator.number + 4) , 6);

    if ( this.paginator.totalPages > 5 ) {
      this.myPages = new Array(this.to - this.from + 1).fill(0).map((_valor, indice) => indice + this.from);
    } else {
      this.myPages = new Array(this.paginator.totalPages).fill(0).map((_valor, indice) => indice + 1);
    }
  }

  numberPage(pageId: number) {
    this.passEntry.emit(pageId);
  }

}

