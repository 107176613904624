export class MenuModel {
    id: string;
    name: string;
    path: string;
    title: string;
    icon: string;
    classs: string;
    badge: string;
    badgeClass: string;
    createdDate: string;


    public constructor() {
        this.id = '';
        this.name = '';
        this.path = '';
        this.title = '';
        this.icon = '';
        this.classs = '';
        this.badge = '';
        this.badgeClass = '';
        this.createdDate = '';
    }
}
