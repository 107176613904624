import { InfoMessagesService } from './../../shared/messages/info-messages.service';
import { TokenStorageService } from './../../shared/storage-services/token-storage.service';
import { GenericResponseModel } from './../../../models/utilities/generic.response.model';
import { CrudServiceService } from './../../shared/backend/cruds/crud-service.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-doorman-list',
  templateUrl: './doorman-list.component.html',
  styleUrls: ['./doorman-list.component.scss']
})
export class DoormanListComponent implements OnInit {
  employeeInfo: any;

  constructor(
    private crudServices: CrudServiceService,
    private messageService: InfoMessagesService,
    private tokenStorage: TokenStorageService
  ) { }

  ngOnInit() {
  }

  }

