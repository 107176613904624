import { PlansModel } from './../../../models/entities/plans-model';
import { FormBuilder } from '@angular/forms';
import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { NgForm, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';

@Component({
  selector: 'app-update-plan',
  templateUrl: './update-plan.component.html',
  styleUrls: ['./update-plan.component.scss']
})
export class UpdatePlanComponent implements OnInit {
  form: any = {};
  plan: FormGroup;
  @Input() public planInfoUpdate = new PlansModel();
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @ViewChild('f') updatePlanForm: NgForm;
  rowsPlanes = [];
  tempPlanes: string[];
  datoPlanes: any;
  isCreatedPlan: boolean;
  errorMessage: any;

  constructor(private modalService: NgbModal, private planForm: FormBuilder, private messageServices: InfoMessagesService,
    private crudServices: CrudServiceService) { }

  ngOnInit() {
    this.plan = this.planForm.group({
      id: this.planInfoUpdate.id,
      name: this.planInfoUpdate.name,
      description: this.planInfoUpdate.description,
      value: this.planInfoUpdate.value,
      maxNumHousUnit : this.planInfoUpdate.maxNumHousUnit,
      maxNumCoownerShip : this.planInfoUpdate.maxNumCoownerShip,
      isFauvorite : this.planInfoUpdate.isFauvorite,
      planSiguiente : this.planInfoUpdate.planSiguiente,
      createdDate: this.planInfoUpdate.createdDate,
    });

    this.listarPlanes();
  }

  listarPlanes() {
    this.crudServices.getModel('api/plan/listar-todas-menos-admin').subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.datoPlanes = genericResponse.answerList;
          this.tempPlanes = { ...this.datoPlanes };
          this.rowsPlanes = this.datoPlanes;

          console.log(this.rowsPlanes);
        }
        if (genericResponse.code === 400) {
          this.messageServices.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se listan los planes')
        }
      },
      error => {
        this.isCreatedPlan = false;
        this.errorMessage = error.error.info;
        console.error(error);
        this.messageServices.getInfoMessageBadInternet();
      }
    );
  }

  passBack() {
    const formValue = this.plan.value;
    this.passEntry.emit(formValue);
  }

  closeWindow() {
    this.modalService.dismissAll();
  }


}
