import { RelationalFileModel } from './../../../models/utilities/relational-type-model';
import { ModalInformationModel } from 'models/utilities/modal-information-model';
import { ModalInfo } from 'models/entities/modal-info';
import { EquipoModel } from '../../../models/entities/equipo-model';
import { MarcaModel } from './../../../models/entities/marca';
import { providerModel } from './../../../models/entities/provider';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm, AbstractControl } from '@angular/forms';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { GenericResponseModel } from './../../../models/utilities/generic.response.model';
import { TowerModel } from 'models/entities/tower-model';

import { FormGroup, FormControl, Validators, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import { TipoEquipoModel } from 'models/entities/tipo-equipo-model';

@Component({
  selector: 'app-crud-equipo',
  templateUrl: './crud-equipo.component.html',
  styleUrls: ['./crud-equipo.component.scss']
})
export class CrudEquipoComponent implements OnInit {

  idCopropiedad = this.tokenStorage.getCompanyId()

  @Input() public equipoInformacion;
  @Input() public aMantenimientoInfo;
  @Input() public modalInfo = new ModalInformationModel();
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @ViewChild('t') tabset;
  
  public relationalModel: RelationalFileModel = new RelationalFileModel();
  public isCreatedProfile = true;
  errorMessage: any;

  form: any = {};
  formGroup: FormGroup;
  @ViewChild('f') createModuleForm: NgForm;

  rows: any;
  dato: any;
  temp = [];

  pages: string[] = ["tab-imagenes", "tab-archivos"];
  listaTiposMantenimiento = [{id:1,nombre:'Días'},
                             {id:2,nombre:'Horas'},
                             {id:3,nombre:'Kilometros'}
                            ];

  listaProveedores = new providerModel();
  listaMarcas = new MarcaModel();
  listaTipoEquipo = new TipoEquipoModel();
  listaCopropiedades = new TowerModel();
  equipoModel = new EquipoModel();
  t: any;
  tipoEquipoId:any;

  llamadoModuloImagenes = 'mantenimiento_imagen';
  llamadoModuloAdjunto = 'mantenimiento_adjunto';

  constructor(
    private crudServices: CrudServiceService,
    private messageService: InfoMessagesService,
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    private fb: FormBuilder,
    private tokenStorage: TokenStorageService) {

      this.formGroup = this.fb.group({
        'codigo': ['', [Validators.required]],
        'marcaId': ['', [Validators.required]],
        'nombre': ['', [Validators.required]],
        'modelo': [''],
        'copropiedad': ['', [Validators.required]],
        'serie': [''],
        'servicio': [''],
        'vidaUtil': ['', [this.checkLimit(1, 99)]],
        'proveedor': ['', [Validators.required]],
        'fechaCompra': ['', [Validators.required]],
        'fechaInstalacion': ['', [Validators.required]],
        'garantia': ['', [this.checkLimit(1, 99)]],
        'tiempoMantenimiento': ['', [Validators.required]],
        'precioCompra': [''],
        'anotacion': [''],
        'tipoEquipoId': [''],
        'tipoMantenimiento': ['', [Validators.required]],
        //'porcentajeAlerta': ['', [Validators.required,this.checkLimit(1, 99)]]
      });

  }

  ngOnInit() {
    this.obtenerProveedores();
    this.obtenerCopropiedades();
    this.obtenerTipoEquipo();

   // se valida el modelo del equipo para la operacion del modal crear, detalle, aditar
   
   if (!this.modalInfo.isCreate){

    // se posiciona el tipo de equipo
    this.tipoEquipoId = this.equipoInformacion.marcaId.tipoEquipo.id;
    this.obtenerMarcas();
    this.equipoModel = this.equipoInformacion;


   } else {
    this.equipoModel = new EquipoModel();
   }

    // se inicializa el modelo relationalModel
    this.relationalModel.relationalId = this.equipoModel.id;
    this.relationalModel.relationalRestrictions = new Array();

    // se valida si la carga viene desde un reporte mantenimiento

    if(this.aMantenimientoInfo == 1){
      setTimeout(() => {
        $("#btn-mantenimiento").click();
        this.aMantenimientoInfo = 0;
      }, 100);
    }
  }

  checkLimit(min: number, max: number): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
        if (c.value && (isNaN(c.value) || c.value < min || c.value > max)) {
            return { 'range': true };
        }
        return null;
    };
  }

  crearEquipo(t){

    this.equipoModel.unidadResidencialId.id = this.tokenStorage.getCompanyId();

    // se valida que la insercion sea una primera vez
    if(this.equipoModel.id == ''){
      this.equipoModel.imagenPrincipalFirebaseId = null;
    }

    this.crudServices.createModel('api/equipo/crear', this.equipoModel).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          //this.messageService.getInfoMessageCreate();

          if(this.aMantenimientoInfo == 1){
            this.passEntry.emit('equipo_creado_mantenimiento');
          }else{
            this.passEntry.emit('equipo_creado');
          }

          this.equipoModel = genericResponse.genericObject;

          // inicializacion de la info del equipo registrado
          this.relationalModel.relationalId = this.equipoModel.id;
          this.relationalModel.relationalRestrictions = new Array();

          // envio a tab archivos o adjuntos
          setTimeout(() => {
            var that = this;
            t.select(that.pages[0]);
          }, 100);


          //this.activeModal.close()
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se creó el equipo')
          this.errorMessage = genericResponse.answer;
        }
      },
      error => {
        this.errorMessage = error.error.message;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  imagenPrincipal(equipoModel){

    this.equipoModel = equipoModel;

    this.equipoModel.unidadResidencialId.id = this.tokenStorage.getCompanyId();
    this.crudServices.createModel('api/equipo/crear', this.equipoModel).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          //this.messageService.getInfoMessageCreate();
          this.passEntry.emit('equipo_creado');
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se creó el equipo')
          this.errorMessage = genericResponse.answer;
        }
      },
      error => {
        this.errorMessage = error.error.message;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  obtenerProveedores() {
    this.crudServices.getModel('api/proveedor/ver-mis-proveedores?id=' + this.idCopropiedad).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.listaProveedores = genericResponse.answerList;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se encontraron proveedores')
        }
      }, error => {
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  obtenerTipoEquipo() {
    this.crudServices.getModel('api/equipo/tipo-equipo').subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.listaTipoEquipo = genericResponse.answerList;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se encontraron tips de equipo')
        }
      }, error => {
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  obtenerMarcas() {

    this.crudServices.getModel('api/marcas/listar-todo?tipoEquipoId=' + this.tipoEquipoId).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.listaMarcas = genericResponse.answerList;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se encontraron marcas')
        }
      }, error => {
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  obtenerCopropiedades() {
    this.crudServices.getModel('api/coownership/listar-todo-admin?id=' + this.tokenStorage.getCompanyId()).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.listaCopropiedades = genericResponse.answerList;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin copropiedades')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

}
