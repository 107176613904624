import { providerModel } from './../../../models/entities/provider';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoMessagesService } from './../../shared/messages/info-messages.service';
import { CrudServiceService } from './../../shared/backend/cruds/crud-service.service';
import { TaskModel } from './../../../models/entities/task';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-detail-provider',
  templateUrl: './detail-provider.component.html',
  styleUrls: ['./detail-provider.component.scss']
})
export class DetailProviderComponent implements OnInit {

  @Input() public providerInfo: providerModel;
  constructor(
    public crudServices: CrudServiceService,
    public messageService: InfoMessagesService,
    public modalService: NgbModal
  ) { }
  ngOnInit() {
  }

  closeWindow() {
    this.modalService.dismissAll();
  }

}
