import { GenericResponseModel } from "./../../../models/utilities/generic.response.model";
import { QuestionOptionModel } from "./../../../models/entities/question-option-model";
import { QuestionModel } from "./../../../models/entities/question-model";
import { TokenStorageService } from "./../../shared/storage-services/token-storage.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { InfoMessagesService } from "./../../shared/messages/info-messages.service";
import { CrudServiceService } from "./../../shared/backend/cruds/crud-service.service";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { pieChartSingle, pieChartmulti } from "../../shared/data/ngxChart";
import * as chartsData from "../../shared/configs/ngx-charts.config";

@Component({
  selector: "app-detail-question",
  templateUrl: "./detail-question.component.html",
  styleUrls: ["./detail-question.component.scss"],
})
export class DetailQuestionComponent implements OnInit {
  // config chart
  newChartList = [];
  //Pie Charts
  pieChartSingle: any;
  pieChartView: any[] = chartsData.pieChartView;
  pieChartShowLegend = chartsData.pieChartShowLegend;
  pieChartColorScheme = chartsData.pieChartColorScheme;

  pieChartShowLabels = chartsData.pieChartShowLabels;
  pieChartExplodeSlices = chartsData.pieChartExplodeSlices;
  pieChartDoughnut = chartsData.pieChartDoughnut;
  pieChartGradient = chartsData.pieChartGradient;

  pieChart1ExplodeSlices = chartsData.pieChart1ExplodeSlices;
  pieChart1Doughnut = chartsData.pieChart1Doughnut;
  sumaPorcentaje: any = 0;
  sumaPorcentaje2: any = 0;

  // edn chart

  @Input() public questionInfo: QuestionModel;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  questionOptionList: Array<QuestionOptionModel> = new Array();
  newQuestion: QuestionModel = new QuestionModel();
  contadorCeros = 0;
  contador = 0;
  constructor(
    public crudServices: CrudServiceService,
    public messageService: InfoMessagesService,
    public activeModal: NgbActiveModal,
    public tokenStorage: TokenStorageService
  ) {}

  ngOnInit() {
    this.getQuestionOption(this.questionInfo.id);
  }

  getQuestionOption(id) {
    this.crudServices
      .getModel("api/preguntas/buscar-opcion-pregunta?id=" + id)
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.questionOptionList = genericResponse.answerList;
            this.questionOptionList.forEach((optionList) => {
              if (
                optionList.question.surveyId.tipo.toLocaleLowerCase() ===
                "Coeficiente".toLocaleLowerCase()
              ) {
                console.log(optionList);

                if (optionList.quorum.toFixed(2).match("0.00")) {
                  this.contadorCeros++;
                }
                this.contador++;

                const employee = {
                  name: optionList.questionOption,
                  value: optionList.quorum.toFixed(4),
                };

                this.newChartList.push(employee);
              } else if (
                optionList.question.surveyId.tipo.toLocaleLowerCase() ===
                "Asistencia".toLocaleLowerCase()
              ) {
                if (optionList.count.toFixed(2).match("0.00")) {
                  this.contadorCeros++;
                }
                this.contador++;
                
                const employee = {
                  name: optionList.questionOption,
                  value: optionList.resultados,
                };

                this.newChartList.push(employee);
              }
            });
            if (this.contador == this.contadorCeros) {
              this.newChartList = [];
              const employee = {
                name: "no hay votos",
                value: 100,
              };

              this.newChartList.push(employee);
            }
            this.pieChartSingle = this.newChartList;
          }

          this.questionOptionList.forEach((optionList2) => {
            this.sumaPorcentaje += optionList2.quorum;
          });

          this.questionOptionList.forEach((optionList3) => {
            this.sumaPorcentaje2 += optionList3.resultados;
          });

          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se cargaron las opciones de las preguntas"
            );
          }
        },
        (error) => {
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }
}
