import { HousingUnitModel } from './housing-unit-model';
import { UserOAuthModel } from './user-o-auth-model';
import { UserModel } from 'models/entities/user-model';
export class employeeModel {
    id: string;
    idUser: UserModel;
    salary: string;
    position: string;
    payBenefits: boolean;
    admissionDate: string;
    creatorId: UserOAuthModel;
    observations: string;
    housingUnitId: HousingUnitModel;

    constructor() {
        this.id = '';
        this.idUser = new UserModel();
        this.salary = '';
        this.position = '';
        this.payBenefits = false;
        this.admissionDate = '';
        this.observations = '';
        this.creatorId = new UserOAuthModel();
        this.housingUnitId = new HousingUnitModel();
    }
}
