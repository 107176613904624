import { Component, OnInit } from "@angular/core";
import { CrudServiceService } from "app/shared/backend/cruds/crud-service.service";
import { Router, ActivatedRoute } from "@angular/router";
import { TokenStorageService } from "app/shared/storage-services/token-storage.service";
import { InfoMessagesService } from "app/shared/messages/info-messages.service";
import { GenericResponseModel } from "models/utilities/generic.response.model";
import { CoownershipModel } from "../../../models/entities/coowner-ship-model";

@Component({
  selector: "app-select-my-coownership",
  templateUrl: "./select-my-coownership.component.html",
  styleUrls: ["./select-my-coownership.component.scss"],
})
export class SelectMyCoownershipComponent implements OnInit {
  coOwnerShipList: Array<CoownershipModel> = new Array();

  rows = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private crudServices: CrudServiceService,
    private messageService: InfoMessagesService,
    private tokenStorage: TokenStorageService
  ) {}

  ngOnInit() {
    this.configPage();
  }

  configPage() {
    if (this.tokenStorage.getAuthorities() == "ROLE_COOWNER") {
      this.crudServices
        .getModel(
          "api/coownership/listar-mis-copropiedades-copropietario?id=" +
            this.tokenStorage.getId()
        )
        .subscribe(
          (genericResponse: GenericResponseModel) => {
            if (genericResponse.code === 200) {
              this.coOwnerShipList = genericResponse.answerList;
              this.rows = this.coOwnerShipList;
            }
            if (genericResponse.code === 400) {
              this.messageService.getInfoMessagePersonalized(
                "warning",
                genericResponse.answer,
                "Sin copropiedades"
              );
            }
          },
          (error) => {
            console.error(error);
            this.messageService.getInfoMessageBadInternet();
          }
        );
    } else {
      this.crudServices
        .getModel(
          "api/coownership/listar-mis-copropiedades?id=" +
            this.tokenStorage.getId()
        )
        .subscribe(
          (genericResponse: GenericResponseModel) => {
            if (genericResponse.code === 200) {
              this.coOwnerShipList = genericResponse.answerList;
              this.rows = this.coOwnerShipList;
            }
            if (genericResponse.code === 400) {
              this.messageService.getInfoMessagePersonalized(
                "warning",
                genericResponse.answer,
                "Sin copropiedades"
              );
            }
          },
          (error) => {
            console.error(error);
            this.messageService.getInfoMessageBadInternet();
          }
        );
    }
  }

  openDetailHousing(coownership: CoownershipModel) {
    this.tokenStorage.saveIdCoownerShip(coownership.id);
    this.tokenStorage.saveCompanyName(coownership.housingUnitId.name);
    this.tokenStorage.saveIdCompany(coownership.housingUnitId.id);
    // this.tokenStorage.saveIdAdmin(housing.adminId.id);
    this.router.navigate(["/usuarios/mi-perfil"], {
      relativeTo: this.route.parent,
    });
  }

  logOut() {
    this.tokenStorage.signOut();
    this.router.navigate(["pages/auth"]);
  }
}
