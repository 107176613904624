import { GenericResponseModel } from './../../../models/utilities/generic.response.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from './../../shared/storage-services/token-storage.service';
import { InfoMessagesService } from './../../shared/messages/info-messages.service';
import { CrudServiceService } from './../../shared/backend/cruds/crud-service.service';
import { CoownershipModel } from './../../../models/entities/coowner-ship-model';
import { InvoiceTypeModel } from './../../../models/entities/invoicetype-model';
import { InvoiceModel } from './../../../models/entities/invoice-model';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-create-massive-invoice',
  templateUrl: './create-massive-invoice.component.html',
  styleUrls: ['./create-massive-invoice.component.scss']
})
export class CreateMassiveInvoiceComponent implements OnInit {

  newInvoice: InvoiceModel = new InvoiceModel()
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  listTypeInvoice: Array<InvoiceTypeModel> = new Array();
  errorMessage: any;

  constructor(
    public crudServices: CrudServiceService,
    public messageService: InfoMessagesService,
    public activeModal: NgbActiveModal,
    public tokenStorage: TokenStorageService,

  ) { }
  ngOnInit() {
    this.getInvoiceTypes()
  }

  getInvoiceTypes() {
    this.crudServices.getModel('api/factura/obtener-tipos?newType=income').subscribe(
      (genericResponse: GenericResponseModel) => {

        if (genericResponse.code === 200) {
          this.listTypeInvoice = genericResponse.answerList;
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los tipos de facturas del sistema',
            'Problema consultando los tipos de facturas');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar los tipos de facturas' + JSON.stringify(error))
      }
    );
  }

  onSubmit() {
    this.saveInvoice()

  }

  saveInvoice() {
    this.newInvoice.egressIncome = 'income'
    this.newInvoice.housingUnitId.id = this.tokenStorage.getCompanyId();
    console.log(this.newInvoice)
    this.crudServices.createModel('api/factura/guardar-masivo', this.newInvoice).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageCreate();
          this.passEntry.emit('factura_creada');
          this.activeModal.close()
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se creó la factura')
          this.errorMessage = genericResponse.answer;
        }
      },
      error => {
        this.errorMessage = error.error.message;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }
}
