import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { HousingUnitModel } from "models/entities/housing-unit-model";
import { CrudServiceService } from "app/shared/backend/cruds/crud-service.service";
import { InfoMessagesService } from "app/shared/messages/info-messages.service";
import { HousingUnitTypeModel } from "models/entities/housing-unit-type-model";
import { DocumentTypeModel } from "models/entities/document-type-model";
import { DepartmentModel } from "models/entities/department-model";
import { CityModel } from "models/entities/city-model";
import { TokenStorageService } from "app/shared/storage-services/token-storage.service";
import { GenericResponseModel } from "models/utilities/generic.response.model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "environments/environment.prod";
import swal from "sweetalert2";
import { HandleServicesService } from "app/shared/handle-service/handle-services.service";

@Component({
  selector: "app-update-housing-unit",
  templateUrl: "./update-housing-unit.component.html",
  styleUrls: ["./update-housing-unit.component.scss"],
})
export class UpdateHousingUnitComponent implements OnInit {
  @Input() housingUnitU: HousingUnitModel;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  public housingModel = new HousingUnitModel();

  typeHousing = [];
  typeHousingUnitList: Array<HousingUnitTypeModel> = new Array();

  typeDocument = [];
  typeDocumentList: Array<DocumentTypeModel> = new Array();

  Department = [];
  departmentList: Array<DepartmentModel> = new Array();
  getDeparmentId = "";

  Citys = [];
  citysList: Array<CityModel> = new Array();

  //FILE AWS
  filePhoto: any = null;
  filePhotoRender: any = "";
  urlPeticionImg = environment.serverUrl + "api/archivos/ver-img?ruta=";
  imageStatic: any = "https://cdn-icons-png.flaticon.com/512/746/746859.png";

  constructor(
    private crudServices: CrudServiceService,
    public token: TokenStorageService,
    private handleService: HandleServicesService,
    private messageService: InfoMessagesService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.listTypeHousing();
    this.listTypeDocument();
    this.listDepartments();
    this.listCitys();
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  onSubmit(id: any) {
    this.housingModel.adminId.id = this.token.getId();
    this.crudServices
      .putModel("api/unidad-residencial/actualizar?id=" + id, this.housingUnitU)
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.passEntry.emit("creado");
            this.messageService.getInfoMessageCreate().then((result) => {
              this.modalService.dismissAll();
            });
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Problema actualizando la Unidad"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  listTypeHousing() {
    this.crudServices
      .getModel("api/tipos-de-unidad-residencial/ver-todos")
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.typeHousingUnitList = genericResponse.answerList;
            this.typeHousing = this.typeHousingUnitList;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No hay tipos de unidad residencial"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  listTypeDocument() {
    this.crudServices
      .getModel("api/tipos-de-documento/listar-documentos")
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.typeDocumentList = genericResponse.answerList;
            this.typeDocument = this.typeDocumentList;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Sin documentos"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  listDepartments() {
    this.crudServices
      .getModel("api/departamentos/listar-departamentos")
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.departmentList = genericResponse.answerList;
            this.Department = this.departmentList;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Sin departamentos"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  listCitys() {
    this.getDeparmentId = this.housingUnitU.departmentId.id;
    this.crudServices
      .getModel("api/ciudades/listar-ciudades?id=" + this.getDeparmentId)
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.citysList = genericResponse.answerList;
            this.Citys = this.citysList;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Sin ciudades"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  //AWS BUCKET S3
  updateProfile(id: any) {
    this.housingUnitU.adminId.id = this.token.getId();

    this.housingUnitU.logoUrl = this.filePhotoRender;
    let housingModelAux = this.housingUnitU;
    housingModelAux.logoUrl = null;

    this.crudServices
      .putModel("api/unidad-residencial/actualizar?id=" + id, housingModelAux)
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            //this.messageService.getInfoMessageCreate();
            this.passEntry.emit("creado");
            console.log("sadfgd");
            console.log(genericResponse);
            if (this.filePhoto !== null) {
              this.uploadPhoto(this.housingUnitU);
            } else {
              this.messageService.getInfoMessageUpdate();
            }

            this.filePhoto = null;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Problema actualizando la Unidad"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  loadPhoto(event, files) {
    this.filePhoto = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (_event) => {
      this.filePhotoRender = reader.result;
    };
  }

  updateInfo(id: any) {
    swal({
      title: "¿Está seguro que desea actualizar la unidad residencial?",
      text: "",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, crear!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        this.updateProfile(id);
      }
    });
  }

  uploadPhoto(modelo) {
    const folderPath = "unidad residencial " + modelo.id;

    var data = new FormData();
    data.append("archivo", this.filePhoto);
    let extension = this.filePhoto.name.split(".").pop();

    let path =
      "api/archivos/cargar-logo-unidad-residencial?folderPath=" +
      folderPath +
      "&relationalId=" +
      modelo.id +
      "&extension=" +
      extension;
    this.crudServices
      .createFile(path, data)
      .toPromise()
      .then((genericResponse) => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageCreate().then((result) => {
            this.modalService.dismissAll();
          });
        } else {
          this.messageService.getInfoMessagePersonalized(
            "warning",
            "Se actualizó la unidad correctamente pero verifique la foto por favor",
            "Atención!"
          );
        }
      })
      .catch((error) => {
        this.handleService.handleError(error);
      });
  }
}
