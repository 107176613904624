import { UpdateParkingComponent } from './../update-parking/update-parking.component';
import { ParkingModel } from './../../../models/entities/parking-model';
import { UpdateStorageRoomComponent } from './../update-storage-room/update-storage-room.component';
import { StorageModel } from './../../../models/entities/storage-model';
import { VehicleModel } from './../../../models/entities/vehicle';
import { UpdateVehicleComponent } from './../update-vehicle/update-vehicle.component';
import { coownerShipByUserModel } from './../../../models/entities/coownershipbyuser';
import { CoownerShipVehicleModel } from './../../../models/entities/coownership-vehicle';
import { Console } from '@angular/core/src/console';
import { UpdateResidentComponent } from './../update-resident/update-resident.component';
import { CreateParkingComponent } from './../create-parking/create-parking.component';
import { CreateStorageRoomComponent } from './../create-storage-room/create-storage-room.component';
import { CoownershipModel } from './../../../models/entities/coowner-ship-model';
import { CreateVehicleComponent } from './../create-vehicle/create-vehicle.component';
import { CreateResidentComponent } from './../create-resident/create-resident.component';
import { UserModel } from './../../../models/entities/user-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UpdateUserComponent } from './../../usercomponents/update-user/update-user.component';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-detail-coownership',
  templateUrl: './detail-coownership.component.html',
  styleUrls: ['./detail-coownership.component.scss']
})
export class DetailCoownershipComponent implements OnInit {
  rows = [];
  temp: string[];
  rowsStorage = [];
  tempStorage: string[];
  rowsParking = [];
  tempParking: string[];
  rowsResident = [];
  tempResident: string[];
  infoResident: UserModel = new UserModel()
  id: any;
  dato: any;
  coownerShipInfo: CoownershipModel = new CoownershipModel();
  datosVehiculos: any = {};
  datosStorageRoom: any = {};
  datosParking: any = {};
  datosResident: any = {};
  errorMessage: string;
  infoVehicle: VehicleModel = new VehicleModel();
  infoStorage: StorageModel = new StorageModel();
  infoParking: ParkingModel = new ParkingModel();

  constructor(private routeParams: ActivatedRoute,
    private messageService: InfoMessagesService,
    private crudServices: CrudServiceService,
    private modalService: NgbModal) { }

  ngOnInit() {
    
    this.id = Number(this.routeParams.snapshot.params['id']);

    this.configPage(this.id)
    this.configVehicles(this.id)
    this.configStorageRoom(this.id)
    this.configParking(this.id)
    this.configResident(this.id)
  }

  configPage(id) {
    this.crudServices.getModel('api/coownership/detalle?coOwnershipId=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.dato = genericResponse.genericObject;
          this.coownerShipInfo = this.dato;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, ' Error en la búsqueda')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  configVehicles(id) {
    // Obtendremos los usuarios a partir del servicio SUPER o ADMIN
    this.crudServices.getModel('api/vehiculo/obtener-vehiculos?id=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.datosVehiculos = genericResponse.answerList;
          this.temp = { ...this.datosVehiculos };
          this.rows = this.datosVehiculos;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin vehiculos')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  configStorageRoom(id) {
    // Obtendremos los usuarios a partir del servicio SUPER o ADMIN
    this.crudServices.getModel('api/cuarto-util/obtener-cuarto-util?id=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.datosStorageRoom = genericResponse.answerList;
          this.tempStorage = { ...this.datosStorageRoom };
          this.rowsStorage = this.datosStorageRoom;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin cuartos utiles.')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }
  configParking(id) {
    // Obtendremos los usuarios a partir del servicio SUPER o ADMIN
    this.crudServices.getModel('api/parqueadero/obtener-parqueadero?id=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.datosParking = genericResponse.answerList;
          this.tempParking = { ...this.datosParking };
          this.rowsParking = this.datosParking;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin parkeaderos')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  configResident(id) {
    // Obtendremos los residentes a partir del servicio SUPER o ADMIN
    this.crudServices.getModel('api/usuarios/obtener-residentes?id=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.datosResident = genericResponse.answerList;
          this.tempResident = { ...this.datosResident };
          this.rowsResident = this.datosResident;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin parkeaderos')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  onDeleteVehicle(id) {
    this.crudServices.deleteModel('api/vehiculo/borrar-vehiculo?id=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageDelete().then(
            () => {
              this.configVehicles(this.id);
            }
          )
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se eliminó el menú')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
        this.errorMessage = `${error.status}: ${error.error.message}`;
      }
    );
  }

  onDeleteParking(id) {
    this.crudServices.deleteModel('api/parqueadero/borrar-parqueadero?id=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageDelete().then(
            () => {
              this.configParking(this.id);
            }
          )
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se eliminó el parqueadero')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
        this.errorMessage = `${error.status}: ${error.error.message}`;
      }
    );
  }

  onDeleteStorageRoom(id) {
    this.crudServices.deleteModel('api/cuarto-util/borrar-cuarto-util?id=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageDelete().then(
            () => {
              this.configStorageRoom(this.id);
            }
          )
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se eliminó el cuarto útil')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
        this.errorMessage = `${error.status}: ${error.error.message}`;
      }
    );
  }


  onDeleteResident(id) {
    this.crudServices.deleteModel('api/usuarios/borrar-residentes?id=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageDelete().then(
            () => {
              this.configResident(this.id);
            }
          )
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se eliminó el residente')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
        this.errorMessage = `${error.status}: ${error.error.message}`;
      }
    );
  }


  deleteWarningVehicle(id) {
    event.preventDefault(); // prevent form submit
    swal({
      title: '¿Está seguro que desea eliminar el parqueadero?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.onDeleteVehicle(id);
      }
    })
  }

  deleteWarningParking(id) {
    event.preventDefault(); // prevent form submit
    swal({
      title: '¿Está seguro que desea eliminar el parqueadero?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.onDeleteParking(id);
      }
    })
  }

  deleteWarningStorageRoom(id) {
    event.preventDefault(); // prevent form submit
    swal({
      title: '¿Está seguro que desea eliminar el cuarto útil?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.onDeleteStorageRoom(id);
      }
    })
  }

  deleteWarningResident(id) {
    event.preventDefault(); // prevent form submit
    swal({
      title: '¿Está seguro que desea eliminar el residente?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.onDeleteResident(id);
      }
    })
  }

  openCreateResident() {
    const modalRef = this.modalService.open(CreateResidentComponent, {
      windowClass: '', size: 'lg', 'backdrop': 'static'
    });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
      if (receivedEntry === 'Usuario_Registrado') {
        this.ngOnInit();
      } else {
        this.ngOnInit();
      }
    });
    modalRef.componentInstance.idCoownerShip = this.id;
  }


  openCreateVehicle() {
    const modalRef = this.modalService.open(CreateVehicleComponent, {
      windowClass: '', size: 'lg', 'backdrop': 'static'
    });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
      if (receivedEntry === 'Vehiculo_Registrado') {
        this.ngOnInit();
      } else {
        this.ngOnInit();
      }
    });
    modalRef.componentInstance.coownerShipInfo = this.coownerShipInfo;
  }

  openCreateStorageRoom() {
    const modalRef = this.modalService.open(CreateStorageRoomComponent, {
      windowClass: '', size: 'lg', 'backdrop': 'static'
    });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
      if (receivedEntry === 'Cuarto_Registrado') {
        this.ngOnInit();
      } else {
        this.ngOnInit();
      }
    });
    modalRef.componentInstance.coownerShipInfo = this.coownerShipInfo;
  }


  openCreateParking() {
    const modalRef = this.modalService.open(CreateParkingComponent, {
      windowClass: '', size: 'lg', 'backdrop': 'static'
    });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
      if (receivedEntry === 'Parquedero_Registrado') {
        this.ngOnInit();
      } else {
        this.ngOnInit();
      }
    });
    modalRef.componentInstance.coownerShipInfo = this.coownerShipInfo;
  }

  openResidentUpdate(id) {
    this.crudServices.getModel('api/usuarios/buscar-residente?userId=' + id).subscribe(
      genericResponse => {
        console.log(genericResponse);
        if (genericResponse.code === 200) {
          this.infoResident = genericResponse.genericObject
          const modalRef = this.modalService.open(
            UpdateResidentComponent, {
            windowClass: '', size: 'lg', backdrop: 'static'
          }
          );
          modalRef.componentInstance.infoResident = this.infoResident;
          modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
            if (receivedEntry === 'residente_creado') {
              this.ngOnInit()
            } else {
              return false;
            }
          })
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error buscando el área social')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  openVehicleUpdate(id) {
    this.crudServices.getModel('api/vehiculo/obtener-vehiculo?id=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.infoVehicle = genericResponse.genericObject
          const modalRef = this.modalService.open(
            UpdateVehicleComponent, {
            windowClass: '', size: 'lg', backdrop: 'static'
          }
          );
          modalRef.componentInstance.infoVehicle = this.infoVehicle;
          modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
            if (receivedEntry === 'vehiculo_creado') {
              this.ngOnInit()
            } else {
              return false;
            }
          })
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error buscando el área social')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  openStorageUpdate(id) {
    this.crudServices.getModel('api/cuarto-util/buscar?id=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.infoStorage = genericResponse.genericObject
          const modalRef = this.modalService.open(
            UpdateStorageRoomComponent, {
            windowClass: '', size: 'lg', backdrop: 'static'
          }
          );
          modalRef.componentInstance.infoStorage = this.infoStorage;
          modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
            if (receivedEntry === 'cuarto_creado') {
              this.ngOnInit()
            } else {
              return false;
            }
          })
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error buscando el área social')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  openParkingUpdate(id) {
    this.crudServices.getModel('api/parqueadero/buscar?id=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.infoParking = genericResponse.genericObject
          const modalRef = this.modalService.open(
            UpdateParkingComponent, {
            windowClass: '', size: 'lg', backdrop: 'static'
          }
          );
          modalRef.componentInstance.infoParking = this.infoParking;
          modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
            if (receivedEntry === 'parqueadero_creado') {
              this.ngOnInit()
            } else {
              return false;
            }
          })
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error buscando el área social')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }
}
