import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router, ActivatedRoute } from "@angular/router";
import { CrudServiceService } from "app/shared/backend/cruds/crud-service.service";
import { InfoMessagesService } from "app/shared/messages/info-messages.service";
import { HousingUnitModel } from "models/entities/housing-unit-model";
import { CreateHousingUnitComponent } from "../create-housing-unit/create-housing-unit.component";
import { TokenStorageService } from "../../shared/storage-services/token-storage.service";
import { GenericResponseModel } from "models/utilities/generic.response.model";
import { environment } from "environments/environment.prod";
import { PayMembershipComponent } from "app/membership/pay-membership/pay-membership.component";
import { PayMemberComponent } from "../pay-member/pay-member.component";
import { MemberModel } from "models/entities/member-model";

@Component({
  selector: "app-select-housing-unit",
  templateUrl: "./select-housing-unit.component.html",
  styleUrls: ["./select-housing-unit.component.scss"],
})
export class SelectHousingUnitComponent implements OnInit {
  hosingUnitList: Array<HousingUnitModel> = new Array();
  rolAdmin = this.tokenStorage.getAuthorities();

  rows = [];
  errorMessage: string;

  estadoRespuestaMaxUnid: any;
  respuestaMaxUnidades: any;
  membership: MemberModel = new MemberModel();

  urlPeticionImg = environment.serverUrl + "api/archivos/ver-img?ruta=";

  public userLoggedIn: string;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private crudServices: CrudServiceService,
    private messageService: InfoMessagesService,
    private tokenStorage: TokenStorageService
  ) {}

  ngOnInit() {
    this.configPage();
    this.getMember();
    // this.userLoggedIn = this.tokenStorage.getId();
  }

  configPage() {
    this.crudServices
      .getModel("api/unidad-residencial/admin/ver-todos")
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            //console.log(genericResponse);
            this.hosingUnitList = genericResponse.answerList;
            this.rows = this.hosingUnitList;
            this.respuestaMaxUnidades = this.hosingUnitList.length;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Sin unidades"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  openCreateHousingUnit() {
    var plan : any;
    this.crudServices.getModel("api/plan/buscar-por-nombre?name="+this.tokenStorage.getPlanId()).subscribe(
      (genericResponse) => {
        if (genericResponse.code === 200) {
          plan=genericResponse.genericObject;
          this.createHousingUnit(plan);
          
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized(
            "warning",
            genericResponse.answer,
            "No se puede crear copropiedad"
          );
        }
      },
      (error) => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
    
  }
  createHousingUnit(plan) {
    

    if (
      this.hosingUnitList.length>= plan.maxNumHousUnit
    ) {
      if(plan.planSiguiente==null){
      this.messageService.getInfoMessagePersonalized(
        "warning",
        "Contacta con tu administrador llamando al numero +57 300 7393720",
        "Has excedido la capacidad máxima de unidades permitidas en tu plan actual"
      );}else{
        this.crudServices.getModel("api/plan/buscar-por-nombre?name="+plan.planSiguiente).subscribe(
          (genericResponse) => {
            if (genericResponse.code === 200) {
              
              var nextPlan=genericResponse.genericObject;
              this.messageService.getInfoUpgradePlan(nextPlan.name,nextPlan.maxNumHousUnit,nextPlan.maxNumCoownerShip,nextPlan.value);
            }
            if (genericResponse.code === 400) {
              this.messageService.getInfoMessagePersonalized(
                "warning",
                "Contacta con tu administrador llamando al numero +57 300 7393720",
                "Has excedido la capacidad máxima de unidades permitidas en tu plan actual"
              );
            }
          },
          (error) => {
            console.error(error);
            this.messageService.getInfoMessagePersonalized(
              "warning",
              "Contacta con tu administrador llamando al numero +57 300 7393720",
              "Has excedido la capacidad máxima de unidades permitidas en tu plan actual"
            );
          }
        );
              
       
      }
    } else {
      const modalRef = this.modalService.open(CreateHousingUnitComponent, {
        windowClass: "",
        size: "lg",
        backdrop: "static",
      });

      modalRef.componentInstance.passEntry.subscribe(
        (receivedEntry: string) => {
          if (receivedEntry === "creado") {
            this.configPage();
          } else {
            return false;
          }
        }
      );
    }
  }

  openDetailHousing(housing: HousingUnitModel) {
    console.log(housing);
    this.tokenStorage.saveIdCompany(housing.id);
    this.tokenStorage.saveIdCoownerShip(housing.id);
    this.tokenStorage.saveCompanyName(housing.name);
    this.tokenStorage.saveIdAdmin(housing.adminId.id);
    if (this.tokenStorage.getAuthorities() === "ROLE_EMPLOYEE") {
      this.router.navigate(["porteria/listado-porteria"], {
        relativeTo: this.route.parent,
      });
    } else {
      this.router.navigate(
        ["/unidades-residenciales/detalle-unidad-residencial"],
        { relativeTo: this.route.parent }
      );
    }
  }

  logOut() {
    this.tokenStorage.signOut();
    this.router.navigate(["pages/auth"]);
  }

  payMembership() {
    const modalRef = this.modalService.open(PayMemberComponent, {
      windowClass: "",
      size: "lg",
      backdrop: "static",
    });
  }

  getMember() {
    if (
      this.tokenStorage.getAuthorities() !== "ROLE_COOWNER" &&
      this.tokenStorage.getAuthorities() !== "ROLE_OWNER"
    ) {
      this.crudServices
        .getModel(
          "api/membresia/obtener-membresia-por-correo?email=" +
            this.tokenStorage.getUsername()
        )
        .subscribe(
          (genericResponse: GenericResponseModel) => {
            if (genericResponse.code === 200) {
              console.log(genericResponse.genericObject);
              this.membership = genericResponse.genericObject;
              //alert(this.membership.state);
            }
          },
          (error) => {
            this.errorMessage = `${error.status}: ${error.error.message}`;
            console.error(error);
            this.messageService.getInfoMessageBadInternet();
          }
        );
    }
  }

  message() {
    this.messageService.getInfoMessagePersonalized(
      "info",
      "",
      "Debe pagar la membresía para acceder a todos los beneficios"
    );
  }

  message2() {
    this.messageService.getInfoMessagePersonalized(
      "info",
      "Contacte a un administrador",
      "No cuenta con una membresía"
    );
  }
}
