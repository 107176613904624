import { TaskModel } from './../../../models/entities/task';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoMessagesService } from './../../shared/messages/info-messages.service';
import { CrudServiceService } from './../../shared/backend/cruds/crud-service.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-detail-task',
  templateUrl: './detail-task.component.html',
  styleUrls: ['./detail-task.component.scss']
})
export class DetailTaskComponent implements OnInit {


  @Input() public taskInfo: TaskModel;
  constructor(
    public crudServices: CrudServiceService,
    public messageService: InfoMessagesService,
    public modalService: NgbModal
  ) { }

  ngOnInit() {
  }
  closeWindow() {
    this.modalService.dismissAll();
  }

}
