import { RelationalFileModel } from '../../../models/utilities/relational-type-model';
import { ModalInformationModel } from 'models/utilities/modal-information-model';
import { ModalInfo } from 'models/entities/modal-info';
import { EquipoModel } from '../../../models/entities/equipo-model';
import { MarcaModel } from '../../../models/entities/marca';
import { providerModel } from '../../../models/entities/provider';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm, AbstractControl } from '@angular/forms';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { GenericResponseModel } from '../../../models/utilities/generic.response.model';
import { TowerModel } from 'models/entities/tower-model';

import { FormGroup, FormControl, Validators, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import { RepuestoModel } from 'models/entities/repuesto-model';
import swal from 'sweetalert2';
import { FileModel } from 'models/entities/file-model';
import { FirebaseStorageService } from 'app/shared/firebase/firebase-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-crud-repuesto',
  templateUrl: './crud-repuesto.component.html',
  styleUrls: ['./crud-repuesto.component.scss']
})
export class CrudRepuestoComponent implements OnInit {

  idCopropiedad = this.tokenStorage.getCompanyId()

  @Input() public repuestoInfo;
  @Input() public idEquipo;
  @Input() public modalInfo = new ModalInformationModel();
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  errorMessage: any;

  form: any = {};
  formGroup: FormGroup;
  repuestoModel = new RepuestoModel();
  equipoModel = new EquipoModel();
  selectedFiles: any = undefined;
  selectedExtension = '';
  restrictions = new Array('JPEG', 'PNG', 'png', 'jpeg', 'jpg');
  fileModel: FileModel = new FileModel();
  fileModelTemp: FileModel = new FileModel();

  constructor(
    private crudServices: CrudServiceService,
    private messageService: InfoMessagesService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private tokenStorage: TokenStorageService,
    private firebaseStorageService: FirebaseStorageService,
    private storageInfoCtrl: TokenStorageService,
    private spinner: NgxSpinnerService,
    private firebaseServices: FirebaseStorageService ) {

      this.formGroup = this.fb.group({
        'nombre': ['', [Validators.required]],
        'cantidad': ['', [Validators.required,this.checkLimit(1, 999)]],
        'valor': [''],
        'descripcion': ['']
      });

  }

  ngOnInit() {
   if (!this.modalInfo.isCreate){
    this.repuestoModel = this.repuestoInfo;
    this.fileModelTemp = this.repuestoModel.firebaseId;
    console.log(this.repuestoModel);
   } else {
    this.repuestoModel = new RepuestoModel();
   }
  }

  checkLimit(min: number, max: number): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
        if (c.value && (isNaN(c.value) || c.value < min || c.value > max)) {
            return { 'range': true };
        }
        return null;
    };
  }

  crearImagen(){
    this.uploadFile().then((urlResult) => {
      this.fileModel.downloadUrl = urlResult;
      this.crudServices.createModelWA('api/archivo-firebase/crear', this.fileModel).subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.messageService.getInfoMessageCreate().then(
              (response) => {
                this.selectFile = undefined;
                this.crearRepuestoImagen(genericResponse.genericObject);
              }
            )
          } else if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se pudo crear el archivo')
            this.spinner.hide();
          }
        }
      )
    }).catch((error) => {
      console.error(error);
      this.messageService.getInfoMessageBadInternet();
    })
  }

  crearRepuesto(){

    this.repuestoModel.equipoId.id = this.idEquipo;
    this.repuestoModel.firebaseId = null;
    this.crudServices.createModel('api/repuesto/crear', this.repuestoModel).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          //configuracion archivo - imagen
          this.fileModel.relationalId = genericResponse.genericObject.id;
          this.repuestoModel.id = genericResponse.genericObject.id;
          this.fileModel.relationalType = 'REPUESTOS';
          this.fileModel.fileName = this.repuestoModel.nombre;
          this.fileModel.idCreator = this.storageInfoCtrl.getId();

            this.crearImagen();
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se creó el repuesto')
          this.errorMessage = genericResponse.answer;
        }
      },
      error => {
        this.errorMessage = error.error.message;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  actualizarRepuesto(){

    this.repuestoModel.equipoId.id = this.idEquipo;
    this.repuestoModel.firebaseId = null;
    this.crudServices.createModel('api/repuesto/crear', this.repuestoModel).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          //configuracion archivo - imagen
          this.fileModel.relationalId = genericResponse.genericObject.id;
          this.repuestoModel.id = genericResponse.genericObject.id;
          this.fileModel.relationalType = 'REPUESTOS';
          this.fileModel.fileName = this.repuestoModel.nombre;
          this.fileModel.idCreator = this.storageInfoCtrl.getId();

          if(this.fileModelTemp != null){
            this.deleteFile();
            this.crearImagen();
          }
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se creó el repuesto')
          this.errorMessage = genericResponse.answer;
        }
      },
      error => {
        this.errorMessage = error.error.message;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  crearRepuestoImagen(firebaseModel){

    this.repuestoModel.equipoId.id = this.idEquipo;
    this.repuestoModel.firebaseId = firebaseModel;
    this.crudServices.createModel('api/repuesto/crear', this.repuestoModel).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          
          //this.messageService.getInfoMessageCreate();
          this.passEntry.emit('repuesto_creado');
          this.spinner.hide();
          this.activeModal.close();
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se creó el repuesto')
          this.errorMessage = genericResponse.answer;
        }
      },
      error => {
        this.errorMessage = error.error.message;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  selectFile(event) {
    console.log(event.target.files.item(0));
    console.log((Number(event.target.files.item(0).size) / 1000 / 1000));
    if ((Number(event.target.files.item(0).size) / 1000 / 1000) <= 500) {
      if (this.restrictions.length === 0) {
        this.selectedFiles = event.target.files.item(0);
        this.selectedExtension = this.selectedFiles.name.split('.').pop()
      } else {
        this.selectedFiles = event.target.files.item(0);
        if (this.restrictions.includes(this.selectedFiles.name.split('.').pop())) {
          this.selectedExtension = this.selectedFiles.name.split('.').pop()
        } else {
          this.selectedFiles = undefined;
          swal({
            title: 'El tipo de archivo seleccionado no es correcto', type: 'error',
            text: 'El archivo debe tener formato ' + this.restrictions.toString()
          })
        }
      }
    } else {
      this.selectedFiles = undefined;
      swal({
        title: 'El archivo es demasiado pesado', type: 'error',
        text: 'El archivo no puede pesar mas de ' + 500 + 'mb'
      })
    }
  }

  uploadFile(): Promise<any> {
    return new Promise((resolve, reject) => {
      const realTimeUpload = new Date().getTime()
      if (this.fileModel.relationalType === '') {
        this.fileModel.relationalType = 'FILE'
      }
      if (this.fileModel.fileType === '') {
        this.fileModel.fileType = 'FILE'
      }
      const folderPath = this.storageInfoCtrl.getCompanyId() + '/' + this.fileModel.idCreator + '/'
        + this.fileModel.relationalType + '/' + this.fileModel.relationalId + '/';
      //const fileName = this.fileModel.fileName.trim() + '-' + realTimeUpload + '.' + this.selectedExtension;
      const fileName = this.fileModel.fileName.trim() + '.' + this.selectedExtension;
      this.fileModel.pathFirebaseFile = folderPath + fileName;
      this.fileModel.fileName = fileName
      this.firebaseStorageService.uploadFile(folderPath, fileName, this.selectedFiles).then(
        (responseFirebase) => {
          this.firebaseStorageService.loadFile(folderPath, fileName).then(
            (dowloadURL) => {
              resolve(dowloadURL);
            }
          ).catch(error => {
            console.error(error);
            this.messageService.getInfoMessageBadInternet();
            reject(error)
          })
        }, (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
          reject(error)
        })
    })
  }

  

  deleteFile() {

    let fileToDelete: FileModel;
    fileToDelete = this.fileModelTemp;

    const url = 'api/archivo-firebase/borrar-por-id?idFile=' + fileToDelete.id;
    this.crudServices.deleteModel(
      url
    ).subscribe((genericResponse: GenericResponseModel) => {
      if (genericResponse.code === 200) {
        this.firebaseServices.deleteFile(fileToDelete.pathFirebaseFile).then((data) => {
          console.log("se elimina la imagen " + this.fileModelTemp.fileName);
        })
      } else {
        this.messageService.getInfoMessagePersonalized('warning', 'No se pudo eliminar', 'Algo salio mal');
      }
    }, error => {
      this.messageService.getInfoMessageBadInternet();
      console.error(error);
    })
  }

}
