import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ModalInfo } from './../../../models/entities/modal-info';
import { CrudEquipoComponent } from './../crud-equipo/crud-equipo.component';
import { CrudServiceService } from './../../shared/backend/cruds/crud-service.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';

import swal from 'sweetalert2';
import { EquipoModel } from 'models/entities/equipo-model';
import { RegistroCantidadesComponent } from '../registro-cantidades/registro-cantidades.component';
import { ReporteMantenimientoComponent } from '../reporte-mantenimientos/reporte-mantenimientos.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-lista-equipo',
  templateUrl: './lista-equipo.component.html',
  styleUrls: ['./lista-equipo.component.scss']
})
export class ListaEquipoComponent implements OnInit {

  rows = [];
  temp: string[];
  board: string;
  errorMessage: string;
  dato: any;
  datosDetalle: any;
  button = [];
  id: any;

  @ViewChild(DatatableComponent) table: DatatableComponent;

  columns = [
    { prop: 'name' },
    { name: 'createdDate' }
  ];
  
  modalInfo: ModalInfo;

  constructor(private modalService: NgbModal,
    private crudServices: CrudServiceService,
    private messageService: InfoMessagesService,
    private tokenStorage: TokenStorageService,
    private routeParams: ActivatedRoute) { }

  ngOnInit() {
    this.obtenerEquipos();
    
    this.id = Number(this.routeParams.snapshot.params['id']);
  }

  crearEquipo() {
    this.modalInfo = new ModalInfo();
    this.modalInfo.isCreate = true;

    const modalRef = this.modalService.open(CrudEquipoComponent, {
      windowClass: 'my-class', backdrop: 'static'
    });

    modalRef.componentInstance.modalInfo = this.modalInfo;
    modalRef.componentInstance.aMantenimientoInfo = 0;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
      if (receivedEntry === 'equipo_creado') {
        this.obtenerEquipos();
      } else {
        return false;
      }
    }
    )
    
   
  }

  verDetalle(equipoInformacion: EquipoModel) {
    this.modalInfo = new ModalInfo();
    this.modalInfo.isView = true;

    const modalRef = this.modalService.open(CrudEquipoComponent, {
      windowClass: 'my-class', backdrop: 'static'
    });

    modalRef.componentInstance.modalInfo = this.modalInfo;
    modalRef.componentInstance.aMantenimientoInfo = 0;
    modalRef.componentInstance.equipoInformacion = equipoInformacion;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
      if (receivedEntry === 'equipo_creado') {
        this.obtenerEquipos();
      } else {
        return false;
      }
    }
    )
  }

  registrarCantidad(equipoInformacion: EquipoModel) {
    this.modalInfo = new ModalInfo();
    this.modalInfo.isCreate = true;

    const modalRef = this.modalService.open(RegistroCantidadesComponent, {
      windowClass: '',size:'sm', backdrop: 'static'
    });

    modalRef.componentInstance.modalInfo = this.modalInfo;
    modalRef.componentInstance.aMantenimientoInfo = 0;
    modalRef.componentInstance.equipoInformacion = equipoInformacion;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
      if (receivedEntry === 'registro_cantidad_creado') {
        this.obtenerEquipos();
      } else {
        return false;
      }
    }
    )
  }

  actualizarEquipo(equipoInformacion: EquipoModel) {// variable a aMantenimiento, 1: ir a pestana mantenimiento
    this.modalInfo = new ModalInfo();
    this.modalInfo.isUpdate = true;

    const modalRef = this.modalService.open(CrudEquipoComponent, {
      windowClass: 'my-class', backdrop: 'static'
    });
    modalRef.componentInstance.modalInfo = this.modalInfo;
    modalRef.componentInstance.aMantenimientoInfo = 0;
    modalRef.componentInstance.equipoInformacion = equipoInformacion;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
      if (receivedEntry === 'equipo_creado') {
        this.obtenerEquipos();
      } else {
        return false;
      }
    }
    )
  }

  actualizarEquipoMantenimientos(equipoInformacion: EquipoModel) {// variable a aMantenimiento, 1: ir a pestana mantenimiento
    this.modalInfo = new ModalInfo();
    this.modalInfo.isUpdate = true;

    const modalRef = this.modalService.open(CrudEquipoComponent, {
      windowClass: 'my-class', backdrop: 'static'
    });

    modalRef.componentInstance.modalInfo = this.modalInfo;
    modalRef.componentInstance.aMantenimientoInfo = 1;
    modalRef.componentInstance.equipoInformacion = equipoInformacion;
    this.id = 0;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
      if (receivedEntry === 'equipo_creado_mantenimiento') {
        this.obtenerEquipos();
      } else {
        return false;
      }
    }
    )
  }

  obtenerEquipos() {
    this.crudServices.getModel('api/equipo/listar-todo?unidadResidencialId=' + this.tokenStorage.getCompanyId()).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {

          this.dato = genericResponse.answerList;
          this.temp = [...this.dato];
          this.rows = this.dato;

          // se valida si la pagina viene con id de equipo para mostrar el  mantenimiento
          if(this.id > 0){
            for(var i = 0; i < this.dato.length; i++){
                if(this.id == this.dato[i].id){
                  this.actualizarEquipoMantenimientos(this.dato[i]);
                  break;
                }
            }
          }

        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin equipos')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  eliminarEquipo(equipoId) {

    swal({
      title: 'Esta seguro que desea eliminar el equipo?',
      text: 'No puedes revertir esta acción despues!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.crudServices.deleteModel('api/equipo/borrar?equipoId=' + equipoId.id).subscribe(
          genericResponse => {
            if (genericResponse.code === 200) {
              swal(
                'Exito en la operación!',
                'El equipo ha sido eliminado.',
                'success'
              )
              this.obtenerEquipos();
            }
            if (genericResponse.code === 400) {
              this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error al borrar el equipo')
            }
          },
          error => {
            console.error(error);
            this.messageService.getInfoMessageBadInternet();
          }
        );
      }
    })

  }

  updateFilterByName(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function (d: any) {
      if (d.nombre) {
        return d.nombre.toLowerCase().indexOf(val) !== -1 || !val;
      } else {
        return false;
      }
    });
    this.rows = temp;
    this.table.offset = 0;
  }

  
}