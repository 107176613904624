import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  images = [1076, 1078, 180].map((i) => { return `https://picsum.photos/id/${i}/1900/600`});
  constructor(config: NgbCarouselConfig,
    private router: Router) {
    // customize default values of carousels used by this component tree
    config.interval = 0;
    // config.showNavigationArrows = false;
    // config.showNavigationIndicators = false;
  }

  ngOnInit() {
  }

  openLogin(){
    this.router.navigate(['pages/auth']);
  }

}
