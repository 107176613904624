import { DetailQuestionComponent } from "./../detail-question/detail-question.component";
import { GenericResponseModel } from "./../../../models/utilities/generic.response.model";
import { SurveyModel } from "./../../../models/entities/survey-model";
import { QuestionResponseModel } from "./../../../models/entities/questio-mode-response";

import { TokenStorageService } from "app/shared/storage-services/token-storage.service";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { InfoMessagesService } from "app/shared/messages/info-messages.service";
import { CrudServiceService } from "app/shared/backend/cruds/crud-service.service";
import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import * as xls from "xlsx";

@Component({
  selector: "app-detail-survey",
  templateUrl: "./detail-survey.component.html",
  styleUrls: ["./detail-survey.component.scss"],
})
export class DetailSurveyComponent implements OnInit {
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  questionOptionList: Array<QuestionResponseModel> = new Array();
  @Input() public surveyInfo: SurveyModel;
  questions: any;
  errorMessage: string;
  questionInfo: any;
  surveyAnswers: any;
  rolAdmin = this.tokenStorage.getAuthorities();
  constructor(
    public crudServices: CrudServiceService,
    public messageService: InfoMessagesService,
    public activeModal: NgbActiveModal,
    public tokenStorage: TokenStorageService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.configPage();
    if (this.rolAdmin === "ROLE_ADMIN") {
      this.surveyAnsweredAdmin();
    }
  }

  configPage() {
    this.crudServices
      .getModel("api/preguntas/ver-preguntas-encuesta?id=" + this.surveyInfo.id)
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.questions = genericResponse.answerList;
            console.log(this.questions);
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se encontraron preguntas"
            );
          }
        },
        (error) => {
          this.errorMessage = `${error.status}: ${error.error.message}`;
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  closeSurvey() {
    this.crudServices
      .createModel(
        "api/preguntas/close-survey?surveyId=" + this.surveyInfo.id,
        this.surveyInfo.id
      )
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.questionInfo = genericResponse.genericObject;
            this.messageService.getInfoMessagePersonalized(
              "success",
              "",
              "Encuesta cerrada"
            );
            this.activeModal.close();
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Error cerrando la encuesta"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessagePersonalized(
            "warning",
            "",
            "Error cerrando la encuesta"
          );
        }
      );
  }

  openDetail(id) {
    this.crudServices
      .getModel("api/preguntas/buscar-pregunta?id=" + id)
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.questionInfo = genericResponse.genericObject;
            const modalRef = this.modalService.open(DetailQuestionComponent, {
              windowClass: "",
              size: "lg",
              backdrop: "static",
            });
            modalRef.componentInstance.questionInfo = this.questionInfo;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Error buscando la factura"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  surveyAnsweredAdmin() {
    this.crudServices
      .getModel(
        "api/preguntas/respuestas-encuesta?surveyId=" + this.surveyInfo.id
      )
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 201) {
            this.surveyAnswers = null;
          } else if (genericResponse.code === 200) {
            this.surveyAnswers = genericResponse.answerList;
            this.creaerTablaExcel(this.surveyAnswers);
            this.creaerVotantes(this.surveyAnswers);
          }
        },
        (error) => {
          this.errorMessage = `${error.status}: ${error.error.message}`;
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  creaerVotantes(datos) {
    let tabla = "";
    let tablaRespuesta = "";
    let tempPregunta = "";
    let pregunta = "";

    console.log(JSON.stringify(datos[0].questionOption));
    tabla += '<table style="display:block;width:100%">';

    // tslint:disable-next-line: forin
    for (const i in datos) {
      pregunta = datos[i].question.question;

      if (tempPregunta !== pregunta) {
        tablaRespuesta = "";
        tablaRespuesta +=
          '<table style="width:330px;border: 1px solid #BBB8B7;">';

        tablaRespuesta +=
          '<tr>\
        <td colspan="2" align="center" style="border: 1px solid #BBB8B7;padding:0px">\
            <label>Mostrar tabla</label>\
          </td>\
        </tr>\
        <tr style="background:#d9d9d9">\
        <td align="center" style="border: 1px solid #BBB8B7;padding:5px">\
            Apto\
          </td>\
          <td align="center" style="border: 1px solid #BBB8B7;padding:5px">\
            Copropetario\
          </td>\
        </tr>';

        for (let j in datos) {
          if (datos[i].question.id == datos[j].question.id) {
            tablaRespuesta +=
              '<tr>\
              <td align="center" style="border: 1px solid #BBB8B7;padding:5px">\
                ' +
              datos[j].coOwnerShipId.number +
              '\
              </td>\
              <td style="border: 1px solid #BBB8B7;padding:5px">\
                ' +
              datos[j].coOwnerShipId.ownerId.completeName +
              "\
              </td>\
            </tr>";
          }
        }

        tablaRespuesta += "</table>";

        tabla +=
          '<tr>\
          <td align="center">\
            ' +
          tablaRespuesta +
          '\
          </td>\
          <td align="center">\
            ' +
          tablaRespuesta +
          "\
          </td>\
        </tr>";
      }

      tempPregunta = pregunta;
    }

    tabla += "</table>";

    $("#contentVotantes").append(tabla);
  }

  creaerTablaExcel(datos) {
    let tabla = "";
    let tablaRespuesta = "";
    let tempPregunta = "";
    let pregunta = "";

    console.log(JSON.stringify(datos[0].questionOption));
    tabla += '<table style="display:none" id="tablaExcel" border>';

    // tslint:disable-next-line: forin
    for (const i in datos) {
      pregunta = datos[i].question.question;

      if (tempPregunta !== pregunta) {
        tablaRespuesta = "";
        tablaRespuesta += '<table border="1">';

        tablaRespuesta +=
          '<tr style="background:#d9d9d9">\
          <td>\
            Apto\
          </td>\
          <td>\
            Copropetario\
          </td>\
          <td>\
            Respuesta\
          </td>\
          <td>\
            Fecha\
          </td>\
        </tr>';

        for (let j in datos) {
          if (datos[i].question.id == datos[j].question.id) {
            tablaRespuesta +=
              "<tr>\
              <td>\
                " +
              datos[j].coOwnerShipId.number +
              "\
              </td>\
              <td>\
                " +
              datos[j].coOwnerShipId.ownerId.completeName +
              "\
              </td>\
              <td>\
                " +
              datos[j].questionOption.questionOption +
              "\
              </td>\
              <td>\
                " +
              datos[j].questionOption.createdDate.toString().substring(0, 10) +
              "\
              </td>\
            </tr>";
          }
        }

        tablaRespuesta += "</table>";

        tabla +=
          '<tr>\
          <td style="background:#bfbfbf" align="center">\
            ' +
          datos[i].question.question +
          "\
          </td>\
        </tr>\
        <tr>\
          <td>\
            " +
          tablaRespuesta +
          "\
          </td>\
        </tr>";
      }

      tempPregunta = pregunta;
    }

    tabla += "</table>";

    $("#contentExcel").append(tabla);
  }

  ExportToExcel() {
    if (this.surveyAnswers == null) {
      this.messageService.getInfoMessagePersonalized(
        "warning",
        "Ningún residente ha respondido aun la encuesta",
        "No se pudo generar el Excel"
      );
    } else {
      let nombreArchivo = this.surveyAnswers[0].question.surveyId.subject;
      const htmlExport = $("#tablaExcel").prop("outerHTML");
      const ua = window.navigator.userAgent;
      const msie = ua.indexOf("MSIE ");
      let iframeExport: any;

      //other browser not tested on IE 11
      // If Internet Explorer
      if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        jQuery("body").append(
          ' <iframe id="iframeExport" style="display:none"></iframe>'
        );
        iframeExport.document.open("txt/html", "replace");
        iframeExport.document.write(htmlExport);
        iframeExport.document.close();
        iframeExport.focus();
        let sa = iframeExport.document.execCommand(
          "SaveAs",
          true,
          nombreArchivo + ".xls"
        );
      } else {
        var link = document.createElement("a");

        document.body.appendChild(link); // Firefox requires the link to be in the body
        link.download = nombreArchivo + ".xls";
        link.href = "data:application/vnd.ms-excel," + escape(htmlExport);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}
