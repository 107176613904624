import { Component, OnInit } from '@angular/core';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { DetailPetComponent } from '../detail-pet/detail-pet.component';
import { PetModel } from 'models/entities/pet-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Console } from '@angular/core/src/console';
import { GenericFilterModel } from 'models/entities/generic-filter.model';

@Component({
  selector: 'app-list-pets-housing-unit',
  templateUrl: './list-pets-housing-unit.component.html',
  styleUrls: ['./list-pets-housing-unit.component.scss']
})
export class ListPetsHousingUnitComponent implements OnInit {

  petModel = new PetModel();
  rowsPets = [];
  tempPets: string[];
  datoPets: any;

  filter: any;

  rowsCoownerShip = [];
  tempCoownerShip: string[];
  datoCoownerShip: any;

  constructor(
    private crudServices: CrudServiceService, public tokenStorage: TokenStorageService,
    private messageService: InfoMessagesService, private modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.configPage();
    this.listCoownerShip();
  }

  configPage() {
    this.crudServices.getModel('api/mascotas/consultar-mascotas?id=' + this.tokenStorage.getCompanyId()).subscribe(
      (genericResponse: GenericResponseModel) => {
        console.log(genericResponse)
        if (genericResponse.code === 200) {
          this.datoPets = genericResponse.answerList;
          this.tempPets = { ...this.datoPets };
          this.rowsPets = this.datoPets;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin correspondencia')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  detailPet(id) {
    this.crudServices.getModel('api/mascotas/buscar-mascota?id=' + id).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.petModel = genericResponse.genericObject
          const modalRef = this.modalService.open(
            DetailPetComponent, {
            windowClass: '', size: 'lg', backdrop: 'static',
          }
          );
          modalRef.componentInstance.petsInfo = this.petModel;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error buscando el modulo')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  listCoownerShip() {
    {
      this.crudServices.getModel('api/coownership/listar-todo-admin?id=' + this.tokenStorage.getCompanyId()).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.datoCoownerShip = genericResponse.answerList;
            this.tempCoownerShip = { ...this.datoCoownerShip };
            this.rowsCoownerShip = this.datoCoownerShip;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin copropiedades')
          }
        },
        error => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
    }
  }

  onFilter() {
    
    this.crudServices.getModel('api/mascotas/ver-mis-mascotas?id=' + this.petModel.coOwnerShip.id).subscribe(
      
      (genericResponse: GenericResponseModel) => {

          if (genericResponse.code === 200) {
          this.datoPets = genericResponse.answerList;
          this.tempPets = { ...this.datoPets };
          this.rowsPets = this.datoPets;
        }
        
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin correspondencia')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  clean() {
    this.filter = new GenericFilterModel();
    this.petModel.coOwnerShip.id = null;
    this.configPage();

  }

}
