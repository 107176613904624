import { Injectable } from "@angular/core";
import swal from "sweetalert2";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class InfoMessagesService {
  public message: string;
  public type: string;

  constructor(private router: Router) {}

  getInfoMessageError() {
    swal({
      title: "Error en el servidor",
      text: "Ocurrio un problema durante la operación",
      type: "error",
      confirmButtonText: "Cerrar",
    });
  }

  getInfoMessageCreate(): Promise<any> {
    return swal({
      title: "Correcto",
      text: "Registro correcto",
      type: "success",
      confirmButtonText: "Cerrar",
    });
  }

  getInfoMessageDelete(): Promise<any> {
    return swal({
      title: "Correcto",
      text: "Registro eliminado",
      type: "success",
      confirmButtonText: "Cerrar",
    });
  }

  getInfoMessageUpdate(): Promise<any> {
    return swal({
      title: "Correcto",
      text: "Registro actualizado",
      type: "success",
      confirmButtonText: "Cerrar",
    });
  }

  getInfoMessageBadInternet() {
    swal({
      title: "Problemas de conexion",
      text: "Ha ocurrido un problema con su conexión, por favor revisa tu conexión a internet",
      type: "warning",
      confirmButtonText: "Cerrar",
    });
  }

  getInfoMessagePersonalized(typeMessage, message, titleMessage): Promise<any> {
    return swal({
      title: titleMessage,
      text: message,
      type: typeMessage,
      confirmButtonText: "Cerrar",
    });
  }

  getInfoMessagePersonalizedHtml(
    typeMessage,
    messagesArray,
    titleMessage
  ): Promise<any> {
    const listItems = messagesArray
      .map(
        (message) =>
          `<li style="font-size: 18px; line-height: 1.5; color: black">${message}</li>`
      )
      .join("");
    const htmlContent = `
        <div class="info" style="width: 100%;">
          <ul style="padding: 0 0 0 25px;">
            ${listItems}
          </ul>
        </div>`;

    return swal({
      title: titleMessage,
      html: htmlContent,
      type: typeMessage,
      confirmButtonText: "Cerrar",
    });
  }

  getInfoMessagePersonalizedProxy(
    typeMessage,
    messagesArray,
    titleMessage
  ): Promise<any> {
    const listItems = messagesArray
      .map(
        (message) =>
          `<li style="font-size: 18px; line-height: 1.5; color: black">${message}</li>`
      )
      .join("");
    const htmlContent = `
        <div class="info" style="width: 100%;">
          <ul style="padding: 0 0 0 25px;">
            ${listItems}
          </ul>
        </div>`;

    return swal({
      title: titleMessage,
      html: htmlContent,
      type: typeMessage,
      confirmButtonText: "Cerrar",
    });
  }

  getInfoMessagePersonalizedLoadMassive(typeMessage, message, titleMessage) {
    return swal({
      title: titleMessage,
      text: message,
      type: typeMessage,
      confirmButtonText: "Continuar",
    });
  }

  getInfoUpgradePlan(nextPlan, maxNumHousingUnit, maxNumCoownerShip, value) {
    return swal({
      title: "¡WOW!",
      // text: 'Superaste el límite de tu plan! \n Sube de nivel o contáctanos para mejorar tu experiencia.',
      html:
        "<p>Superaste el límite de tu plan! \n Sube de nivel o contáctanos para mejorar tu experiencia.</p>" +
        '<div class="container" style="display: flex; ' +
        "flex-direction: row; " +
        "justify-content: space-between; " +
        "border: 1px solid #fd660a; " +
        "padding: 10px; " +
        "width: 500px; " +
        'margin: 0 auto;"> ' +
        '<div class="info" style="width: 70%;"> ' +
        '<ul style="list-style: none; padding: 0;"> ' +
        '<li style="font-size: 18px; line-height: 1.5;"><strong style="color: black">Plan Siguiente: </strong><p style="color: black">' +
        nextPlan +
        "</p></li> " +
        '<li style="font-size: 18px; line-height: 1.5;"><strong style="color: black">Máximo unidades residenciales: </strong><p style="color: black">' +
        maxNumHousingUnit +
        "</p></li> " +
        '<li style="font-size: 18px; line-height: 1.5;"><strong style="color: black">Máximo de copropiedades: </strong><p style="color: black">' +
        maxNumCoownerShip +
        "</p></li> " +
        '<li style="font-size: 18px; line-height: 1.5;"><strong style="color: black">Valor:$ </strong><p style="color: black">' +
        value +
        "</p></li> " +
        "</ul> " +
        "</div> " +
        '<div class="actions" style="display: flex; flex-direction: column; justify-content: space-between;"> ' +
        '<button style="display: block; width: 100%; margin-bottom: 10px; background-color: #fd660a; color: white" class="btn">Plan siguiente</button> ' +
        '<a style="display: block; width: 100%; margin-bottom: 10px; background-color: #fd660a; color: white" class="btn"  href="/welcome#contacto" >Contacto</a> ' +
        "</div> " +
        "</div>",
      heightAuto: true,
      width: 600,
      type: "warning",
      confirmButtonText: "Cerrar",
    });
  }
}
