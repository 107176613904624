import { Component, OnInit } from '@angular/core';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { ListQuestionOwnerComponent } from '../list-question-owner/list-question-owner.component';
import { DetailQuestionOwnerComponent } from '../detail-question-owner/detail-question-owner.component';
import { DetailSurveyComponent } from 'app/question/detail-survey/detail-survey.component';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { DetailQuestionComponent } from 'app/question/detail-question/detail-question.component';

@Component({
  selector: 'app-list-survey-owner',
  templateUrl: './list-survey-owner.component.html',
  styleUrls: ['./list-survey-owner.component.scss']
})
export class ListSurveyOwnerComponent implements OnInit {

  rows = [];
  temp: string[];
  dato: any;

  datoSinResponder: any;
  datoRespuesta: any;

  surveyInfo: any;
  estadoMorosos : any;
  estadoMorosoEncuesta : any;
  questionInfo: any;

  constructor(
    private crudServices: CrudServiceService, 
    private tokenStorage: TokenStorageService,
    private modalService: NgbModal, 
    private messageService: InfoMessagesService
  ) { }

  ngOnInit() {
    this.configPage();
    this.validarVotacionMorosos();
    this.consultarEstadoMoroso();
  }
  
  openDetail(id) {
    this.crudServices.getModel('api/preguntas/buscar-pregunta?id=' + id).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.questionInfo = genericResponse.genericObject
          const modalRef = this.modalService.open(
            DetailQuestionComponent, {
            windowClass: '', size: 'lg', backdrop: 'static'
          }
          );
          modalRef.componentInstance.questionInfo = this.questionInfo;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error buscando la factura')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  configPage() {

    let path = 'api/preguntas/consultar-encuesta-respondida?housingId=' + 
          `${this.tokenStorage.getCompanyId()}&coownerId=${this.tokenStorage.getCoownerShipId()}`;

    this.crudServices.getModel(path).toPromise().then((genericResponse: GenericResponseModel) => {
      if (genericResponse.code === 200) {
        this.dato = genericResponse.answerList;
        this.temp = { ...this.dato };
        this.rows = this.dato;

        this.obtenerResultados(genericResponse.answerList);

      }
      else {
        this.messageService.getInfoMessagePersonalized('error', 'no fue posible listar las encuestas', 'Error!');
      }
    })
    .catch(error => {
      console.error(error);
    });
  }

  obtenerResultados(encuestas){

    let lstSinRespuesta = [];
    let lstConRespuesta = [];

    encuestas.forEach(element => {

      if(element.status == 0){
        lstSinRespuesta.push(element);
      }else{
        lstConRespuesta.push(element);
      }
      
    });
//alert(lstSinRespuesta);
    this.datoSinResponder = lstSinRespuesta;
    this.datoRespuesta = lstConRespuesta;

  }

  consultarEstadoMoroso() {
    this.crudServices.getModel('api/unidad-residencial/buscar?id=' + this.tokenStorage.getCompanyId()).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.estadoMorosos = genericResponse.genericObject.defaulters;
        }   
      },
    );
  }

  validarVotacionMorosos() {

    let path = 'api/factura/datos-morosos-factura?idPropietario='+ this.tokenStorage.getId() + 
            '&idUnidadResidencial='+this.tokenStorage.getCompanyId();

    this.crudServices.getModel(path).toPromise().then((genericResponse: GenericResponseModel) => {
      if (genericResponse.code === 200) {
        let respuestMoroso =genericResponse.answerList;
        let casaActual = this.tokenStorage.getCoownerShipId();

        for(var i in respuestMoroso){
          if(casaActual == respuestMoroso[i].idCopropietario) {
            if(respuestMoroso[i].respuesta == true) {
              this.estadoMorosoEncuesta = true;
            }
            else {
              this.estadoMorosoEncuesta =false;
            }
          }
        } 
      }
    })
    .catch(error => {
      console.error(error);
    });
  }

  openListQuestions(mySurveyId) {

    if (this.estadoMorosos === false && this.estadoMorosoEncuesta == true ){
      this.messageService.getInfoMessagePersonalized(
        'error', 
        'No estas al día en tus pagos', 
        'No puedes participar en esta encuesta'
      );
    }
    else {
      const modalRef = this.modalService.open(ListQuestionOwnerComponent, {
        windowClass: '', size: 'lg', backdrop: 'static',
      });
      modalRef.componentInstance.surveyId = mySurveyId;
      modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
        if (receivedEntry === 'creado') {
          this.configPage();
        } 
        else {
          return false;
        }
      })
    }
  }

  idPregunta(surveyId) {
    this.crudServices.getModel(
      `api/preguntas/detalle-encuesta-respondida?coownerId=${this.tokenStorage.getCoownerShipId()}&surveyId=${surveyId}`
    ).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          console.log(genericResponse.answerList);
          this.openDetail(genericResponse.answerList[0].question.id);
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  openDetailQuestions(mySurveyId) {
    const modalRef = this.modalService.open(DetailQuestionOwnerComponent, {
      windowClass: '', size: 'lg', backdrop: 'static',
    });
    modalRef.componentInstance.surveyId = mySurveyId;
  }

  openResultSurvey(id) {
    this.crudServices.getModel('api/preguntas/buscar-encuesta?id=' + id).subscribe((genericResponse: GenericResponseModel) => {
      if (genericResponse.code === 200) {
        this.surveyInfo = genericResponse.genericObject
        const modalRef = this.modalService.open(DetailSurveyComponent, {
          windowClass: '', size: 'lg', backdrop: 'static'
        });
        modalRef.componentInstance.surveyInfo = this.surveyInfo;
      }
    },
    error => {
      console.error(error);
    });
  }

}
