import { Routes, RouterModule } from "@angular/router";

// Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: "dashboard",
    loadChildren: "./dashboard/dashboard.module#DashboardModule",
  },
  {
    path: "calendar",
    loadChildren: "./calendar/calendar.module#CalendarsModule",
  },
  {
    path: "charts",
    loadChildren: "./charts/charts.module#ChartsNg2Module",
  },
  {
    path: "forms",
    loadChildren: "./forms/forms.module#FormModule",
  },
  {
    path: "maps",
    loadChildren: "./maps/maps.module#MapsModule",
  },
  {
    path: "tables",
    loadChildren: "./tables/tables.module#TablesModule",
  },
  {
    path: "datatables",
    loadChildren: "./data-tables/data-tables.module#DataTablesModule",
  },
  {
    path: "uikit",
    loadChildren: "./ui-kit/ui-kit.module#UIKitModule",
  },
  {
    path: "components",
    loadChildren: "./components/ui-components.module#UIComponentsModule",
  },
  {
    path: "pages",
    loadChildren: "./pages/full-pages/full-pages.module#FullPagesModule",
  },
  {
    path: "cards",
    loadChildren: "./cards/cards.module#CardsModule",
  },
  {
    path: "colorpalettes",
    loadChildren: "./color-palette/color-palette.module#ColorPaletteModule",
  },
  {
    path: "chat",
    loadChildren: "./chat/chat.module#ChatModule",
  },
  {
    path: "chat-ngrx",
    loadChildren: "./chat-ngrx/chat-ngrx.module#ChatNGRXModule",
  },
  {
    path: "inbox",
    loadChildren: "./inbox/inbox.module#InboxModule",
  },
  {
    path: "taskboard",
    loadChildren: "./taskboard/taskboard.module#TaskboardModule",
  },
  {
    path: "taskboard-ngrx",
    loadChildren: "./taskboard-ngrx/taskboard-ngrx.module#TaskboardNGRXModule",
  },
  {
    path: "player",
    loadChildren: "./player/player.module#PlayerModule",
  },
  // Módulos
  {
    path: "modulos",
    loadChildren: "./modules/modules.module#ModulesModule",
  },
  // Planes
  {
    path: "planes",
    loadChildren: "./plans/plans.module#PlansModule",
  },
  // Profile
  {
    path: "perfiles",
    loadChildren: "./profile/profile.module#ProfileModule",
  },
  // Menu
  {
    path: "menus",
    loadChildren: "./menucomponent/menucomponent.module#MenucomponentModule",
  },
  // Users
  {
    path: "usuarios",
    loadChildren: "./usercomponents/crud-user.module#CrudUserModule",
  },
  // Maestros
  {
    path: "maestros",
    loadChildren: "./master/master.module#MasterModule",
  },
  // Unidades Residenciales
  {
    path: "unidades-residenciales",
    loadChildren: "./housing-unit/housing-unit.module#HousingUnitModule",
  },
  // Copropiedad
  {
    path: "copropiedad",
    loadChildren: "./coownership/coownership.module#CoownershipModule",
  },
  // Torres
  {
    path: "torres",
    loadChildren: "./tower-block/tower-block.module#TowerBlockModule",
  },
  // Area-social
  {
    path: "area-social",
    loadChildren: "./social-area/social-area.module#SocialAreaModule",
  },
  // Porteria
  {
    path: "porteria",
    loadChildren: "./doorman/doorman.module#DoormanModule",
  },
  // Empleados
  {
    path: "empleado",
    loadChildren: "./employee/employee.module#EmployeeModule",
  },
  // Tareas
  {
    path: "tarea",
    loadChildren: "./task/task.module#TaskModule",
    // Incidentes
  },
  {
    path: "incidentes",
    loadChildren: "./incident/incident.module#IncidentModule",
  },
  {
    path: "proveedor",
    loadChildren: "./provider/provider.module#ProviderModule",
  },
  // Noticias
  {
    path: "noticia",
    loadChildren: "./news/news.module#NewsModule",
  },
  // libreria documentos
  {
    path: "libreria-documentos",
    loadChildren:
      "./document-library/document-library.module#DocumentLibraryModule",
  },
  // actividades
  {
    path: "actividades2",
    loadChildren: "./activity/activity.module#ActivityModule",
  },
  // actividades
  {
    path: "actividades",
    loadChildren: "./activities/activities.module#ActivitiesModule",
  },
  // admin-calendar
  {
    path: "admin-calendar",
    loadChildren: "./admin-calendar/admin-calendar.module#AdminCalendarModule",
  },
  // facturas
  {
    path: "facturas",
    loadChildren: "./invoice/invoice.module#InvoiceModule",
  },
  // reuniones
  {
    path: "reuniones",
    loadChildren: "./meeting/meeting.module#MeetingModule",
  },
  // miembros del consejo
  {
    path: "miembros-consejo",
    loadChildren:
      "./committee-users/committee-users.module#CommitteeUsersModule",
  },
  // miembros del consejo
  {
    path: "preguntas",
    loadChildren: "./question/question.module#QuestionModule",
  },
  // Mascotas
  {
    path: "mascotas",
    loadChildren: "./pets/pets.module#PetsModule",
  },
  // actas
  {
    path: "actas",
    loadChildren: "./proceedings/proceedings.module#ProceedingsModule",
  },
  // adjuntos
  {
    path: "adjuntos",
    loadChildren: "./attached/attached.module#AttachedModule",
  },
  // actas
  {
    path: "estado-cuenta",
    loadChildren: "./account-status/account-status.module#AccountStatusModule",
  },
  // reuniones y encuestas propietario
  {
    path: "reuniones-encuestas",
    loadChildren:
      "./meeting-survey-owner/meeting-survey-owner.module#MeetingSurveyOwnerModule",
  },
  // mantenimientos equipos - maquinaria
  {
    path: "mantenimiento",
    loadChildren: "./mantenimiento/mantenimiento.module#MantenimientoModule",
  },
  // membresía
  {
    path: "membresia",
    loadChildren: "./membership/membership.module#MembershipModule",
  },

  //intereses
  {
    path: "intereses",
    loadChildren: "./interest/interest.module#InterestModule",
  },

  //Reports
  {
    path: "reportes",
    loadChildren: "./reports/reports.module#ReportsModule",
  },

  // policy
  {
    path: "politicas",
    loadChildren: "./policy/policy.module#PolicyModule",
  },
];
