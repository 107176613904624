import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-herramienta',
  templateUrl: './herramienta.component.html',
  styleUrls: ['./herramienta.component.scss']
})
export class HerramientaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
