import { EquipoModel } from 'models/entities/equipo-model';
import { FileModel } from 'models/entities/file-model';

export class RepuestoModel {

    id: string;
    nombre: string;
    cantidad: string;
    valor: string;
    modelo: string;
    descripcion: string;
    firebaseId: FileModel;
    equipoId: EquipoModel;

    public constructor(){
        this.id = '';
        this.nombre = '';
        this.valor = '';
        this.descripcion =
        this.valor = ''; new FileModel();
        this.equipoId = new EquipoModel();
    }

}