export class HousingUnitTypeModel {

    id: String;
    name: String;
    createdDate: String;
    lastUpdateDate: String;

    constructor() {
        this.id = null;
        this.name = '';
        this.createdDate = new Date().toUTCString();
        this.lastUpdateDate = new Date().toUTCString();
    }

}
