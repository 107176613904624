import { UserOAuthModel } from 'models/entities/user-o-auth-model';
import { UserModel } from 'models/entities/user-model';
import { HousingUnitModel } from 'models/entities/housing-unit-model';

export class AccountRequest {

	user: UserOAuthModel;
	userInfo: UserModel;
	housingUnit: HousingUnitModel;

	constructor() {
		this.user = new UserOAuthModel();
		this.userInfo = new UserModel();
		this.housingUnit = new HousingUnitModel();
	}
}
