import { GenericFilterModel } from "./../../../models/entities/generic-filter.model";
import { UpdateInvoiceComponent } from "./../update-invoice/update-invoice.component";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { DetailInvoiceComponent } from "./../detail-invoice/detail-invoice.component";
import { HousingUnitModel } from "models/entities/housing-unit-model";
import { CreateMassiveInvoiceComponent } from "./../create-massive-invoice/create-massive-invoice.component";
import { CreateInvoiceComponent } from "./../create-invoice/create-invoice.component";
import { InvoiceModel } from "./../../../models/entities/invoice-model";
import { GenericResponseModel } from "./../../../models/utilities/generic.response.model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TokenStorageService } from "./../../shared/storage-services/token-storage.service";
import { InfoMessagesService } from "./../../shared/messages/info-messages.service";
import { CrudServiceService } from "./../../shared/backend/cruds/crud-service.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import swal from "sweetalert2";
import { UserModel } from "../../../models/entities/user-model";
import { CoownershipModel } from "../../../models/entities/coowner-ship-model";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { NgxSpinnerService } from "ngx-spinner";
import { LogErroresComponent } from "../../usercomponents/log-errores-carga-masiva/log-errores.component";
import { ChangeDetectorRef } from "@angular/core";

enum Field {
  TOWER = "BLOQUE/TORRE",
  NAME_OWNER = "NOMBRE_PROP",
  LAST_NAME_OWNER = "APELLIDO_PROP",
  EXPIRED_DATE = "FECHA_VENCIMIENTO",
  CELLPHONE = "CELULAR",
  CO_OWNERSHIP = "APTO",
  RECEIPT_NUMBER = "#_RECIBO",
  CONCEPT = "CONCEPTO",
  STATUS = "ESTADO",
  AMOUNT = "VALOR",
  INTEREST_MORA = "INTERES_CARGADO",
  DESCRIPTION = "DESCRIPCION"
}

interface FieldValue {
  value: string;
  required: boolean;
  field: Field;
}

interface StructureTemplate {
  housingUnitId: string;
  invoicesHistoryRequest: InvoiceHistory[];
}

interface InvoiceHistory {
  receiptNumber: number;
  concept: string;
  status: string;
  expiration_date: string;
  amount: number;
  interestMora: number;
  towerBlock: string;
  nameCoOwnership: string;
  nameOwner: string;
  lastNameOwner: string;
  username: string;
  description: string;
}

@Component({
  selector: "app-list-invoice",
  templateUrl: "./list-invoice.component.html",
  styleUrls: ["./list-invoice.component.scss"],
})
export class ListInvoiceComponent implements OnInit {
  file: File;
  fields: {
    tower?: FieldValue;
    nameOwner?: FieldValue;
    lastNameOwner?: FieldValue;
    expiredDate?: FieldValue;
    cellphone?: FieldValue;
    coOwnership?: FieldValue;
    receiptNumber?: FieldValue;
    concept?: FieldValue;
    status?: FieldValue;
    amount?: FieldValue;
    interestMora?: FieldValue;
    description?: FieldValue;
  };
  user: UserModel = new UserModel();
  userAux: UserModel = new UserModel();
  idUser: string;
  invoiceSearch: InvoiceModel = new InvoiceModel();
  invoiceSearchType: InvoiceModel = new InvoiceModel();
  coownership: CoownershipModel = new CoownershipModel();
  filter: GenericFilterModel = new GenericFilterModel();
  rows: Array<InvoiceModel> = [];
  rowsAux: Array<InvoiceModel> = [];
  dato: any;
  arrayBuffer;
  errorMessage: any;
  idHousingUnit: any = this.tokenStorage.getCompanyId();
  housingU: HousingUnitModel = new HousingUnitModel();
  mensaje = "Valor total de la  Administración a calcular por porcentajes";
  invoiceInfo: InvoiceModel = new InvoiceModel();
  temp = [];
  datoInvoiceType: any;
  tempInvoiceType: string[];
  rowsInvoiceType = [];
  datoInvoiceState: any;
  tempInvoiceState: string[];
  rowsCoownership = [];
  datoCoownership: any;
  tempCoownership: string[];
  rowsInvoiceState = [];
  datoUserInfo: any = {};
  tempUserInfo: string[];
  rowsUserInfo: Array<UserModel> = [];
  mostrarDatos = false;
  isType: boolean = false;
  isUser: boolean = false;
  isCoownership: boolean = false;
  isState: boolean = false;
  amount: number = 0;
  textAmount: string = "0";
  textCollected: string = "0";
  data: any = [];
  totalAmount: number = 0;
  collected: number = 0;
  totalUniqueAmount: number = 0;
  userAdmin: UserModel = new UserModel();
  metodosPago: any;
  invoiceFilter: any;
  towersInfo: any = [];
  coownershipInfo: any = [];
  dataTower: any = [];
  idTower: number = 0;
  status: string = "0";
  indexMonth: number = 0;
  idCoownership: number;
  excel;
  months: any = [];
  firstDate: any;

  @ViewChild(DatatableComponent) table: DatatableComponent;

  public canShowLoading = false;

  constructor(
    public crudServices: CrudServiceService,
    public messageService: InfoMessagesService,
    public tokenStorage: TokenStorageService,
    public modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.getInfoAdmin();
    this.configPage();
    this.configHousingUnit();
    console.clear();
  }

  private getInfoAdmin(): void {
    this.crudServices
      .getModel(
        "api/usuarios/buscar-por-id?userId=" + this.tokenStorage.getIdSesion()
      )
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.userAdmin = genericResponse.genericObject[
              "userAux"
            ] as UserModel;
            this.months = [
              {
                id: 1,
                name: "Enero",
              },
              {
                id: 2,
                name: "Febrero",
              },
              {
                id: 3,
                name: "Marzo",
              },
              {
                id: 4,
                name: "Abril",
              },
              {
                id: 5,
                name: "Mayo",
              },
              {
                id: 6,
                name: "Junio",
              },
              {
                id: 7,
                name: "Julio",
              },
              {
                id: 8,
                name: "Agosto",
              },
              {
                id: 9,
                name: "Septiembre",
              },
              {
                id: 10,
                name: "Octubre",
              },
              {
                id: 11,
                name: "Noviembre",
              },
              {
                id: 12,
                name: "Diciembre",
              },
            ];
          }
        },
        (error) => {
          this.errorMessage = `${error.status}: ${error.error.message}`;
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  sumAmountByState(rowsP: InvoiceModel[]): void {
    this.amount = 0;
    this.totalAmount = 0;
    this.collected = 0;

    rowsP.forEach((invoice: InvoiceModel) => {
      this.amount += Number(invoice.amount);
      if (invoice.status == "pagada") {
        this.collected += Number(invoice.amount);
      }
    });
    this.textAmount = this.formatCurrency(this.amount);
    this.textCollected = this.formatCurrency(this.collected);
  }

  formatCurrency(amount: number): string {
    return amount.toLocaleString("es-CO", {
      style: "currency",
      currency: "COP",
    });
  }

  messageSelected() {
    if (this.housingU.adminCostCalculated === true) {
      this.mensaje =
        "Valor total de la  Administración a calcular por porcentajes";
    } else {
      this.mensaje = "Valor definido para cuota de administración";
    }
  }

  updateFilterByNumber(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function (d: any) {
      if (d.coownerShipId.number) {
        return d.coownerShipId.number.toLowerCase().indexOf(val) !== -1 || !val;
      } else {
        return false;
      }
    });
    this.rows = temp;
    this.table.offset = 0;
  }

  rest(): void {
    this.restart();
  }

  showFilter(numFilter: number): void {
    this.restart();
    if (numFilter == 1) {
      this.isType = true;
      this.isUser = false;
      this.isCoownership = false;
      this.isState = false;
    } else if (numFilter == 2) {
      this.isType = false;
      this.isUser = true;
      this.isCoownership = false;
      this.isState = false;
    } else if (numFilter == 3) {
      this.isType = false;
      this.isUser = false;
      this.isCoownership = true;
      this.isState = false;
    } else if (numFilter == 4) {
      this.isType = false;
      this.isUser = false;
      this.isCoownership = false;
      this.isState = true;
    }
  }

  updateFilterByType(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function (d: any) {
      if (d.invoiceType.name) {
        return d.invoiceType.name.toLowerCase().indexOf(val) !== -1 || !val;
      } else {
        return false;
      }
    });
    this.rows = temp;
    this.table.offset = 0;
  }

  clean() {
    this.filter = new GenericFilterModel();

    this.mostrarDatos = false;
    this.configPage();
  }

  restart() {
    this.mostrarDatos = false;
    this.getAllValuetoExcel();
    this.invoiceSearch = new InvoiceModel();
    this.coownership = new CoownershipModel();
    this.user = new UserModel();
    this.filter = new GenericFilterModel();
    this.filter.id = "";
    this.invoiceSearchType = new InvoiceModel();
  }

  validarFechaEnRango(inicio, fin, fecha) {
    return (
      inicio.valueOf() <= fecha.valueOf() && fecha.valueOf() <= fin.valueOf()
    );
  }

  filterInvoiceByUser(): void {
    this.totalAmount = 0;
    if (this.user.id == "") {
      this.messageService.getInfoMessagePersonalized(
        "warning",
        "Seleccione que desea buscar",
        "No es posible realizar la búsqueda"
      );
      return;
    }
    this.amount = 0;
    this.mostrarDatos = true;
    const result = this.rows.filter((obj: InvoiceModel) => {
      if (obj.userId.id == this.user.id) {
        if (this.filter.firstDate != "" && this.filter.endDate != "") {
          const fecha = new Date(obj.expirationDate);
          if (
            this.validarFechaEnRango(
              new Date(this.filter.firstDate),
              new Date(this.filter.endDate),
              fecha
            )
          ) {
            this.amount = this.amount + Number(obj.amount);
            this.totalAmount = this.totalAmount + Number(obj.amount);

            return true;
          }
        } else {
          this.amount = this.amount + Number(obj.amount);
          this.totalAmount = this.totalAmount + Number(obj.amount);
          return true;
        }
      }
    });
    this.textAmount = this.amount.toFixed(2);
    this.rowsAux = result;
  }

  filterInvoiceByCoownership(): void {
    this.totalAmount = 0;
    if (this.coownership.number == "") {
      this.messageService.getInfoMessagePersonalized(
        "warning",
        "Seleccione que desea buscar",
        "No es posible realizar la búsqueda"
      );
      return;
    }
    this.amount = 0;
    this.mostrarDatos = true;
    const result = this.rows.filter((obj: InvoiceModel) => {
      if (obj.coownerShipId.number == this.coownership.number) {
        if (this.filter.firstDate != "" && this.filter.endDate != "") {
          const fecha = new Date(obj.expirationDate);
          if (
            this.validarFechaEnRango(
              new Date(this.filter.firstDate),
              new Date(this.filter.endDate),
              fecha
            )
          ) {
            this.amount = this.amount + Number(obj.amount);
            // this.totalAmount = this.totalAmount + Number(obj.amount);
            return true;
          }
        } else {
          this.amount = this.amount + Number(obj.amount);
          // this.totalAmount = this.totalAmount + Number(obj.amount);
          return true;
        }
      }
    });
    this.textAmount = this.amount.toFixed(2);
    this.rowsAux = result;
  }

  filterInvoiceByState(): void {
    this.totalAmount = 0;
    if (this.invoiceSearch.status == "") {
      this.messageService.getInfoMessagePersonalized(
        "warning",
        "Seleccione que desea buscar",
        "No es posible realizar la búsqueda"
      );
      return;
      return;
    }
    this.amount = 0;
    this.mostrarDatos = true;
    const result = this.rows.filter((obj: InvoiceModel) => {
      if (obj.status == this.invoiceSearch.status) {
        if (this.filter.firstDate != "" && this.filter.endDate != "") {
          const fecha = new Date(obj.expirationDate);
          if (
            this.validarFechaEnRango(
              new Date(this.filter.firstDate),
              new Date(this.filter.endDate),
              fecha
            )
          ) {
            this.amount = this.amount + Number(obj.amount);
            // this.totalAmount = this.totalAmount + Number(obj.amount);
            return true;
          }
        } else {
          this.amount = this.amount + Number(obj.amount);
          // this.totalAmount = this.totalAmount + Number(obj.amount);
          return true;
        }
      }
    });
    this.textAmount = this.amount.toFixed(2);
    this.rowsAux = result;
  }

  getUsers(): void {
    this.crudServices
      .getModel(
        "api/factura/ver-usuarios-facturas?idUnidad=" +
          this.tokenStorage.getCompanyId()
      )
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.datoUserInfo = genericResponse.answerList;
            this.tempUserInfo = { ...this.datoUserInfo };
            this.rowsUserInfo = this.datoUserInfo as UserModel[];
            //console.log(this.rowsUserInfo);
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Sin usuarios con facturas"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  onFilter() {
    this.amount = 0;
    this.mostrarDatos = true;
    const result = this.rows.filter((obj: InvoiceModel) => {
      if (obj.invoiceType.id == this.invoiceSearchType.id) {
        if (this.filter.firstDate != "" && this.filter.endDate != "") {
          const fecha = new Date(obj.expirationDate);
          if (
            this.validarFechaEnRango(
              new Date(this.filter.firstDate),
              new Date(this.filter.endDate),
              fecha
            )
          ) {
            this.amount = this.amount + Number(obj.amount);
            return true;
          }
        } else {
          this.amount = this.amount + Number(obj.amount);
          return true;
        }
      }
    });
    this.textAmount = this.amount.toFixed(2);
    this.rowsAux = result;
  }

  getFirstDateOfMonth(month) {
    const year = new Date().getFullYear();
    this.firstDate = new Date(year, month - 1, 1);
    return this.firstDate.toLocaleDateString("sv-SE");
  }

  configPage() {
    this.crudServices
      .getModel(
        this.invoiceFilter == "1"
          ? "api/factura/ver-facturas-copropiedad?id=" +
              this.idHousingUnit +
              "&date=" +
              this.firstDate.toLocaleDateString("sv-SE")
          : this.invoiceFilter == "2"
          ? "api/factura/ver-facturas-copropiedad?id=" +
            this.idHousingUnit +
            "&coOwnershipId=" +
            this.idCoownership
          : this.invoiceFilter == "3"
          ? "api/factura/ver-facturas-copropiedad?id=" +
            this.idHousingUnit +
            "&status=" +
            this.status
          : "api/factura/ver-facturas-copropiedad?id=" + this.idHousingUnit
      )
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.dato = genericResponse.answerList2;
            this.rows = this.dato as InvoiceModel[];
            this.sumAmountByState(this.rows);
            this.getState();
            this.getInvoiceTypes();
            this.getUsers();
            this.getCoownerShips();
            this.idTower = 0;
            this.coownershipInfo = [];
            this.indexMonth = 0;
            this.cdr.detectChanges();
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se encontraron empleados"
            );
          }
        },
        (error) => {
          this.errorMessage = `${error.status}: ${error.error.message}`;
          this.messageService.getInfoMessageBadInternet();
        }
      );
    // this.invoiceFilter = {};
  }

  getCoownerShips(): void {
    this.crudServices
      .getModel(
        "api/factura/ver-propiedades-facturas?idUnidad=" + this.idHousingUnit
      )
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.datoCoownership = genericResponse.answerList;
            this.rowsCoownership = this.datoCoownership as CoownershipModel[];
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se encontraron empleados"
            );
          }
        },
        (error) => {
          this.errorMessage = `${error.status}: ${error.error.message}`;
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  getState(): void {
    this.crudServices
      .getModel(
        "api/factura/ver-estado-facturas?idUnidad=" + this.idHousingUnit
      )
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.datoInvoiceState = genericResponse.answerList;
            this.rowsInvoiceState = this.datoInvoiceState as InvoiceModel[];
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se encontraron empleados"
            );
          }
        },
        (error) => {
          this.errorMessage = `${error.status}: ${error.error.message}`;
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  obtenerMetodoPago() {
    this.crudServices.getModel("api/pagar/obtener-todas").subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.metodosPago = genericResponse.genericObject;
        }
      },
      (error) => {
        this.errorMessage = `${error.status}: ${error.error.message}`;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  getAllValuetoExcel(): void {
    this.data = [];
    this.obtenerMetodoPago();
    this.mostrarDatos == false
      ? this.rows.forEach((element: InvoiceModel) => {
          //console.log(element.userId);
          this.userAux = element.userId;
          //this.userAux == null ? console.log(true) : console.log(false);
          // console.log(this.metodosPago.find((x) => x.idProduct == element.id));
          this.data.push({
            id: element.id,
            tipo: element.invoiceType.name,
            estado: element.status,
            valor: element.amount,
            fecha: element.expirationDate,
            unidad: element.housingUnitId.name,
            apartamento: element.coownerShipId.number,
            torre: element.coownerShipId.towerBlockId.name,
            responsable: this.userAux == null ? "" : this.userAux.completeName,
            correo: this.userAux == null ? "" : this.userAux.email,
            telefono: this.userAux == null ? "" : this.userAux.phone,

            metodo: this.metodosPago.find((x) => x.idProduct == element.id)
              ? this.metodosPago.find((x) => x.idProduct == element.id)
                  .methodPay
              : "No pago",
          });
        })
      : this.rowsAux.forEach((element: InvoiceModel) => {
          this.userAux = element.userId;
          //this.userAux == null ? console.log(true) : console.log(false);
          this.data.push({
            id: element.id,
            tipo: element.invoiceType.name,
            estado: element.status,
            valor: element.amount,
            fecha: element.expirationDate,
            unidad: element.housingUnitId.name,
            responsable: this.userAux == null ? "" : this.userAux.completeName,
            correo: this.userAux == null ? "" : element.userId.email,
            telefono: this.userAux == null ? "" : element.userId.phone,
          });
        });
    this.data.push({
      id: "",
      tipo: "",
      estado: "",
      valor: "",
      fecha: "",
      unidad: "",
      responsable: "",
      correo: "",
      telefono: "",
    });

    this.data.push({
      id: "Total",
      tipo: "facturas",
      valor: this.formatter.format(this.amount),
      fecha: "",
      unidad: "",
      responsable: "",
      correo: "",
      telefono: "",
    });
    // this.data.push({
    //   id: "",
    //   tipo: "Total",
    //   estado: "facturas",
    //   valor: this.formatter.format(this.totalAmount),
    //   fecha: "",
    //   unidad: "",
    //   responsable: "",
    //   correo: "",
    //   telefono: "",
    // });
  }

  formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  downloadExcel() {
    this.getAllValuetoExcel();
    // Crear la hoja de cálculo
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.data);
    console.log(this.data);
    // Personalizar los nombres de las columnas
    worksheet.A1.v = "N° Factura";
    worksheet.B1.v = "Tipo";
    worksheet.C1.v = "Estado";
    worksheet.D1.v = "Valor (COP)";
    worksheet.E1.v = "Fecha Expiración";
    worksheet.F1.v = "Unidad Residencial";
    worksheet.G1.v = "Apartamento";
    worksheet.H1.v = "Torre";
    worksheet.I1.v = "Responsable";
    worksheet.J1.v = "Correo";
    worksheet.K1.v = "Teléfono";
    worksheet.L1.v = "Método de Pago";

    // Dibujar la tabla
    const range: XLSX.Range = XLSX.utils.decode_range(worksheet["!ref"]);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cell: XLSX.CellObject =
        worksheet[XLSX.utils.encode_cell({ r: 0, c: C })];
      cell.s = {
        border: {
          top: { style: "thin", color: { rgb: "FF0000FF" } },
          bottom: { style: "thin", color: { rgb: "FF0000FF" } },
          left: { style: "thin", color: { rgb: "FF0000FF" } },
          right: { style: "thin", color: { rgb: "FF0000FF" } },
        },
      };
    }
    for (let R = range.s.r; R <= range.e.r; ++R) {
      const cell: XLSX.CellObject =
        worksheet[XLSX.utils.encode_cell({ r: R, c: 0 })];
      cell.s = {
        border: {
          top: { style: "thin", color: { rgb: "FF0000FF" } },
          bottom: { style: "thin", color: { rgb: "FF0000FF" } },
          left: { style: "thin", color: { rgb: "FF0000FF" } },
          right: { style: "thin", color: { rgb: "FF0000FF" } },
        },
      };
    }

    // Crear el libro y agregar la hoja de cálculo
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Datos");
    const date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    // Descargar el archivo
    XLSX.writeFile(workbook, `reporte-${year}-${month}-${day}.xlsx`);
  }

  openCreate() {
    const modalRef = this.modalService.open(CreateInvoiceComponent, {
      windowClass: "",
      size: "lg",
      backdrop: "static",
    });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
      if (receivedEntry === "factura_creada") {
        this.configPage();
      } else {
        return false;
      }
    });
  }

  openCreateMassive() {
    const modalRef = this.modalService.open(CreateMassiveInvoiceComponent, {
      windowClass: "",
      size: "lg",
      backdrop: "static",
    });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
      if (receivedEntry === "factura_creada") {
        this.configPage();
      } else {
        return false;
      }
    });
  }

  configHousingUnit() {
    this.crudServices
      .getModel(
        "api/unidad-residencial/buscar?id=" + this.tokenStorage.getCompanyId()
      )
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.housingU = genericResponse.genericObject;
            if (this.housingU.adminCostCalculated === false) {
              this.mensaje = "Valor definido para cuota de administración";
            }
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              " Error al buscar la Unidad"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  detail(id) {
    this.crudServices.getModel("api/factura/detalle?id=" + id).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.invoiceInfo = genericResponse.genericObject;
          const modalRef = this.modalService.open(DetailInvoiceComponent, {
            windowClass: "",
            size: "lg",
            backdrop: "static",
          });
          modalRef.componentInstance.invoiceInfo = this.invoiceInfo;
          modalRef.componentInstance.infoAdmin = this.userAdmin;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized(
            "warning",
            genericResponse.answer,
            "Error buscando la factura"
          );
        }
      },
      (error) => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  onDelete(id) {
    this.crudServices.deleteModel("api/factura/borrar?id=" + id).subscribe(
      (genericResponse) => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageDelete();
          this.configPage();
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized(
            "warning",
            genericResponse.answer,
            "No se eliminó la factura"
          );
        }
      },
      (error) => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
        this.errorMessage = `${error.status}: ${error.error.message}`;
      }
    );
  }

  deleteWarning(id) {
    event.preventDefault();
    swal({
      title:
        "¿Está seguro que desea eliminar la factura, esta no sé podrá recuperar?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "¡Sí, eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        this.onDelete(id);
      }
    });
  }

  saveConfigHousingUnit() {
    this.crudServices
      .createModel("api/unidad-residencial/crear", this.housingU)
      .subscribe((genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageCreate().then();
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized(
            "warning",
            genericResponse.answer,
            "Problema guardando configuración de la Unidad"
          );
        }
      });
  }

  updateInvoice(id) {
    this.crudServices.getModel("api/factura/detalle?id=" + id).subscribe(
      (genericResponse) => {
        if (genericResponse.code === 200) {
          this.invoiceInfo = genericResponse.genericObject;
          const modalRef = this.modalService.open(UpdateInvoiceComponent, {
            windowClass: "",
            size: "lg",
            backdrop: "static",
          });
          modalRef.componentInstance.newInvoice = this.invoiceInfo;
          modalRef.componentInstance.passEntry.subscribe(
            (receivedEntry: string) => {
              if (receivedEntry === "creado") {
                this.configPage();
              } else {
                return false;
              }
            }
          );
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized(
            "warning",
            genericResponse.answer,
            "Error actualizando la factura"
          );
        }
      },
      (error) => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  getInvoiceTypes() {
    //console.log("listado las facturas tupsoas");
    this.crudServices
      .getModel("api/factura/obtener-tipos?newType=income")
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.datoInvoiceType = genericResponse.answerList;
            this.tempInvoiceType = { ...this.datoInvoiceType };
            this.rowsInvoiceType = this.datoInvoiceType;
          } else {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              "No se pudieron listar los tipos de facturas del sistema",
              "Problema consultando los tipos de facturas"
            );
          }
        },
        (error) => {
          this.messageService.getInfoMessageError();
          console.error(
            "Error al cargar los tipos de facturas" + JSON.stringify(error)
          );
        }
      );
  }

  exportExcel() {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(this.rows);
      const workbook = {
        Sheets: { data: worksheet },
        SheetNames: ["data"],
      };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      this.saveAsExcelFile(excelBuffer, "tutorials");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  searchTowers(): void {
    this.crudServices
      .getModel(
        "api/torre-bloque/get-towers-by-housing?id=" +
          this.tokenStorage.getCompanyId()
      )
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.towersInfo = genericResponse.answerList;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Sin torres asociadas"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  searchHouse(): void {
    this.dataTower.push({
      towerId: this.idTower,
      housingUnitId: this.tokenStorage.getCompanyId(),
    });
    this.crudServices
      .createModel(
        "api/coownership/list",
        this.dataTower[this.dataTower.length - 1]
      )
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.coownershipInfo = genericResponse.answerList;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Sin torres asociadas"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  cleanData() {
    this.invoiceFilter = {};
    this.idTower = 0;
    this.idCoownership = 0;
    this.configPage();
  }

  async incomingFile(event) {
    this.file = event.target.files[0];
    const value = await swal({
      title: "Confirmar carga?",
      text: "Este archivo creará nuevos registros!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si , confirmar!",
    }).then((result) => {
      if (result.value) {
        this.canShowLoading = true;
        this.spinner.show(); // SE MUESTRA LA VENTANA DE CARGANDO
        this.createMassive();
      } else {
        $("#uploadFile").val("");
      }
    });
  }

  async validates(value, rowNumber) {
    this.fields = {};
    this.fields = {
      tower: { value: value[Field.TOWER], required: true, field: Field.TOWER },
      nameOwner: {
        value: value[Field.NAME_OWNER],
        required: false,
        field: Field.NAME_OWNER,
      },
      lastNameOwner: {
        value: value[Field.LAST_NAME_OWNER],
        required: false,
        field: Field.LAST_NAME_OWNER,
      },
      expiredDate: {
        value: value[Field.EXPIRED_DATE],
        required: true,
        field: Field.EXPIRED_DATE,
      },
      cellphone: {
        value: value[Field.CELLPHONE],
        required: true,
        field: Field.CELLPHONE,
      },
      coOwnership: {
        value: value[Field.CO_OWNERSHIP],
        required: true,
        field: Field.CO_OWNERSHIP,
      },
      receiptNumber: {
        value: value[Field.RECEIPT_NUMBER],
        required: true,
        field: Field.RECEIPT_NUMBER,
      },
      concept: {
        value: value[Field.CONCEPT],
        required: true,
        field: Field.CONCEPT,
      },
      amount: {
        value: value[Field.AMOUNT],
        required: true,
        field: Field.AMOUNT,
      },
      interestMora: {
        value: `${value[Field.INTEREST_MORA]}`,
        required: true,
        field: Field.INTEREST_MORA,
      },
      status: {
        value: value[Field.STATUS].toLowerCase(),
        required: true,
        field: Field.STATUS,
      },
      description: {
        value: value[Field.DESCRIPTION],
        required: true,
        field: Field.DESCRIPTION,
      },
    };

    for (const key in this.fields) {
      if (this.fields[key].required && !this.fields[key].value) {
        await this.spinner.hide();
        this.canShowLoading = false;
        $("#uploadFile").val("");
        return this.messageService.getInfoMessagePersonalized(
          "warning",
          `El campo ${this.fields[key].field} en la fila ${rowNumber} no puede estar vacio`,
          "Atención!"
        );
      }
    }
  }

  async createMassive() {
    const fileReader = new FileReader();
    fileReader.onload = async (e) => {
      this.arrayBuffer = fileReader.result;
      const data = new Uint8Array(this.arrayBuffer);
      const arr = [];

      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }

      let numeroFila = 1;

      const bstr = arr.join("");
      const workbook = XLSX.read(bstr, { type: "binary" });
      const first_sheet_name = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[first_sheet_name];
      const excel = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      this.excel = [];
      const registers: StructureTemplate = {
        housingUnitId: this.tokenStorage.getCompanyId(),
        invoicesHistoryRequest: [],
      };

      for (const valor of excel) {
        const clave = excel.indexOf(valor);
        numeroFila++;
        const resultValidate = await this.validates(valor, numeroFila);
        if (resultValidate) {
          return resultValidate;
        }
        const invoiceHistory: InvoiceHistory = {
          lastNameOwner: this.fields.lastNameOwner.value,
          amount: Number(this.fields.amount.value),
          concept: this.fields.concept.value,
          expiration_date: this.fields.expiredDate.value,
          interestMora: Number(this.fields.interestMora.value),
          nameOwner: this.fields.nameOwner.value,
          nameCoOwnership: this.fields.coOwnership.value,
          receiptNumber: +this.fields.receiptNumber.value,
          status: this.fields.status.value,
          towerBlock: this.fields.tower.value,
          username: this.fields.cellphone.value,
          description: this.fields.description.value,
        };

        registers.invoicesHistoryRequest.push(invoiceHistory);
      }
      console.log(registers);
      return this.loadMassive(registers);
    };

    fileReader.readAsArrayBuffer(this.file);
  }

  loadMassive(list) {
    const path = "api/factura/create-invoice-history";
    this.crudServices
      .createModel(path, list)
      .toPromise()
      .then((result) => {
        if (result.code === 200) {
          if (result.answer === "Exitoso") {
            this.messageService.getInfoMessageCreate();
            this.configPage();
          } else if (result.answer === "ErrorLE") {
            this.messageService
              .getInfoMessagePersonalizedLoadMassive(
                "error",
                "No se pudo realizar la carga masiva, a continuación podra ver los problemas presentados",
                "Error!"
              )
              .then(() => {
                $("#uploadFile").val("");
                this.canShowLoading = false;
                this.spinner.hide();
                this.lanzarModalErroresCargaMasiva(result.answerList);
              });
          } else {
            $("#uploadFile").val("");
            this.canShowLoading = false;
            this.spinner.hide();
            this.messageService.getInfoMessagePersonalized(
              "error",
              result.answer,
              "Error!"
            );
          }
        } else {
          $("#uploadFile").val("");
          this.canShowLoading = false;
          this.spinner.hide();
          this.messageService.getInfoMessageError();
        }
      })
      .catch((error) => {
        $("#uploadFile").val("");
        this.canShowLoading = false;
        this.spinner.hide();
        this.messageService.getInfoMessagePersonalized(
          "error",
          error.error.answer,
          "Error!"
        );
      });
  }

  lanzarModalErroresCargaMasiva(logErrores) {
    const modalRef = this.modalService.open(LogErroresComponent, {
      windowClass: "my-class",
      backdrop: "static",
    });
    modalRef.componentInstance.propietario = false;
    modalRef.componentInstance.log = logErrores;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      modalRef.dismiss();
    });
  }
}
