import { ModalInformationModel } from 'models/utilities/modal-information-model';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';

import { FormGroup, Validators, FormBuilder, ValidatorFn } from '@angular/forms';
import { RutinaModel } from 'models/entities/rutina-model';

@Component({
  selector: 'app-log-equipo',
  templateUrl: './log-equipo.component.html',
  styleUrls: ['./log-equipo.component.scss']
})
export class LogEquipoComponent implements OnInit {

  idCopropiedad = this.tokenStorage.getCompanyId()

  @Input() public idEquipo;
  @Input() public modalInfo = new ModalInformationModel();
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  errorMessage: any;

  form: any = {};
  formGroup: FormGroup;
  listaLog:any;

  constructor(
    private crudServices: CrudServiceService,
    private messageService: InfoMessagesService,
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private tokenStorage: TokenStorageService) {

      this.formGroup = this.fb.group({
        'descripcion': ['',[Validators.required]]
      });

  }

  ngOnInit() {
   this.obtenerLog();
  }

  obtenerLog() {
    this.crudServices.getModel('api/log/listar-todo?id=' + this.idEquipo + "&tipoRelacion=GESTION_EQUIPO").subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          if(genericResponse.answerList.length > 0){
            this.listaLog = genericResponse.answerList;
          }
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin incidentes')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

}
