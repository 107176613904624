import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalInfo } from 'models/entities/modal-info';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { ListSurveyOwnerComponent } from '../list-survey-owner/list-survey-owner.component'
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { HandleServicesService } from 'app/shared/handle-service/handle-services.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalReunionesComponent } from 'app/usercomponents/modal-reuniones/modal-reuniones.component';
import { MeetingAssistantModel } from 'models/entities/meeting-assistant-model';

@Component({
  selector: 'app-meeting-survey-owner',
  templateUrl: './meeting-survey-owner.component.html',
  styleUrls: ['./meeting-survey-owner.component.scss']
})
export class MeetingSurveyOwnerComponent implements OnInit {

  visibleMeeting = true;
  visibleVotations = false;
  visibleRefresco = false;
  modalInfo: ModalInfo;
  nombreReunion: String;
  nombreReunionForm: String;
  cantidadAsistencias: number;
  errorMessage: string;
  quorumInfo: any;

  temp: string[];
  dato: any;
  rows: string[];
  
  lstPorResponder: string[];
  lstNoRespuestas: string[];
  reunion = new MeetingAssistantModel();

  textButtonMeeting = 'Ocultar reuniones';
  textButtonVotation = 'Ocultar votaciones';

  @ViewChild(ListSurveyOwnerComponent) votaciones: ListSurveyOwnerComponent;

  constructor(public crudServices: CrudServiceService, private handle: HandleServicesService,
              private tokenStorage: TokenStorageService, private messageService: InfoMessagesService,
              private modalService: NgbModal
              ) { }

  ngOnInit() {

    this.ultimaReunion();

    this.infoReuniones();
  }

  getTotalQuorum(idReunion){
    //alert('este es......');
    this.crudServices.getModel('api/reuniones/obtener-quorum?id=' + idReunion).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.quorumInfo = genericResponse.genericObject;
          this.quorumInfo = this.quorumInfo.toFixed(2);
          console.log('DATOS DE QUORUM: '+this.quorumInfo);
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error consultando el quorum')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
    
  }

  infoReuniones() {
    this.crudServices.getModel('api/reuniones/reuniones-propietario?id=' + this.tokenStorage.getCoownerShipId()).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.dato = genericResponse.answerList;
          this.temp = { ...this.dato };
          this.rows = this.dato;

          this.cargarReuniones();

          //console.log(this.rows);
        }
      },
      error => {
        this.errorMessage = `${error.status}: ${error.error.message}`;
        console.error(error);
        this.handle.handleError(error);
      }
    );
  }

  ultimaReunion() {
    this.crudServices.getModel('api/reuniones/reuniones-propietario?id=' + this.tokenStorage.getCoownerShipId()).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.dato = genericResponse.answerList;
          this.temp = { ...this.dato };
          this.rows = this.dato;

          this.buscarUltimaReunion(genericResponse.answerList);

          //console.log(this.rows);
        }
      },
      error => {
        this.errorMessage = `${error.status}: ${error.error.message}`;
        console.error(error);
        this.handle.handleError(error);
      }
    );
  }

  buscarUltimaReunion(lstReuniones){

    let nombreReunion = '';
    let idReunion = 0;
    let reunion = new MeetingAssistantModel();

    for(let lst in this.dato) {
      
      if(this.dato[lst].status == 'present'){

        nombreReunion = this.dato[lst].meetingId.name;
        
        idReunion = this.dato[lst].meetingId.id;
        reunion = this.dato[lst].meetingId;
        
      }

    }

    this.nombreReunion = nombreReunion;
    //this.reunion = reunion;

    this.reunion.meetingId.id = idReunion.toString();
    this.reunion.coownerShipId.id = this.tokenStorage.getCoownerShipId();
    this.reunion.status = 'present';

    this.getTotalQuorum(idReunion);
    this.infoAsistencia(idReunion);

  }

  confirmarAsistencia() {
    this.crudServices.putModel('api/reuniones/confirmar-asistencia', this.reunion).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.ultimaReunion();
          //this.infoReuniones();
          this.messageService.getInfoMessagePersonalized('success', 'Se ha confirmado la asistencia para la reunion', 'Correcto');
        }
      },
      error => {
        this.errorMessage = `${error.status}: ${error.error.message}`;
        console.error(error);
        this.handle.handleError(error);
      }
    );
  }

  infoAsistencia(idReunion) {
    this.crudServices.getModel('api/reuniones/obtener-asistencia?id=' + idReunion).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {

          this.cantidadAsistencias = genericResponse.genericObject;

          //console.log(this.rows);
        }
      },
      error => {
        this.errorMessage = `${error.status}: ${error.error.message}`;
        console.error(error);
        this.handle.handleError(error);
      }
    );
  }

  cargarReuniones(){
    
    for(let lst in this.dato) {
      
      if(this.dato[lst].status == 'nopresent'){

        this.nombreReunion = this.dato[lst].meetingId.name + '-' + this.dato[lst].meetingId.id;
        //alert(this.nombreReunion);
        this.abrirModalReuniones(this.dato[lst]);
        break;
        
      }

    }

  }

  abrirModalReuniones(reunion) {
    this.modalInfo = new ModalInfo();
    this.modalInfo.isCreate = true;
    this.modalInfo.param = reunion;

    const modalRef = this.modalService.open(ModalReunionesComponent, {
      windowClass: 'my-class', backdrop: 'static'
    });

    modalRef.componentInstance.modalInfo = this.modalInfo;

    modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
      //alert(receivedEntry);
      if (receivedEntry === 'creado') {
        this.ultimaReunion();
      } else {
        return false;
      }
    })

  }

  changeOptionMeeting() {
    this.visibleMeeting = !this.visibleMeeting;
    this.visibleVotations = !this.visibleVotations;

    if(this.visibleMeeting) {
      this.textButtonMeeting = 'Ocultar reuniones';
    }
    else {
      this.textButtonMeeting = 'Mostrar reuniones';
    }
  }

  changeOptionVotation() {
    this.visibleVotations = !this.visibleVotations;

    if(this.visibleVotations) {
      this.textButtonVotation = 'Ocultar votaciones';
    }
    else {
      this.textButtonVotation = 'Mostrar votaciones';
    }
  }

  updateInfo() {
    this.votaciones.ngOnInit();
  }

}
