import { DatatableComponent } from "@swimlane/ngx-datatable";
import { UpdateProviderComponent } from "./../update-provider/update-provider.component";
import { DetailProviderComponent } from "./../detail-provider/detail-provider.component";
import { CreateProviderComponent } from "./../create-provider/create-provider.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { providerModel } from "./../../../models/entities/provider";
import { TokenStorageService } from "./../../shared/storage-services/token-storage.service";
import { GenericResponseModel } from "./../../../models/utilities/generic.response.model";
import { InfoMessagesService } from "./../../shared/messages/info-messages.service";
import { CrudServiceService } from "./../../shared/backend/cruds/crud-service.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import swal from "sweetalert2";

@Component({
  selector: "app-list-provider",
  templateUrl: "./list-provider.component.html",
  styleUrls: ["./list-provider.component.scss"],
})
export class ListProviderComponent implements OnInit {
  idHousingUnit = this.tokenStorage.getCompanyId();
  listProvider: Array<providerModel> = new Array();
  errorMessage: String;
  providerInfo: providerModel = new providerModel();
  temp = [];

  @ViewChild(DatatableComponent) table: DatatableComponent;
  rows: Array<providerModel> = new Array();
  dato: any;

  constructor(
    public crudServices: CrudServiceService,
    public messageService: InfoMessagesService,
    public tokenStorage: TokenStorageService,
    public modalService: NgbModal
  ) {}

  ngOnInit() {
    this.configPage();
  }

  configPage() {
    this.crudServices
      .getModel("api/proveedor/ver-mis-proveedores?id=" + this.idHousingUnit)
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.dato = genericResponse.answerList;
            this.temp = { ...this.dato };
            this.rows = this.dato as Array<providerModel>;
            console.log(this.dato);
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se encontraron empleados"
            );
          }
        },
        (error) => {
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  openCreate() {
    const modalRef = this.modalService.open(CreateProviderComponent, {
      windowClass: "",
      size: "lg",
      backdrop: "static",
    });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
      if (receivedEntry === "tarea_creada") {
        this.configPage();
      } else {
        return false;
      }
    });
  }

  OpenDetail(id) {
    this.crudServices
      .getModel("api/proveedor/buscar-proveedor?id=" + id)
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.providerInfo = genericResponse.genericObject;
            const modalRef = this.modalService.open(DetailProviderComponent, {
              windowClass: "",
              size: "lg",
              backdrop: "static",
            });
            modalRef.componentInstance.providerInfo = this.providerInfo;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Error buscando el proveedor"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  OpenUpdate(provider: providerModel) {
    const modalRef = this.modalService.open(UpdateProviderComponent, {
      windowClass: "",
      size: "lg",
      backdrop: "static",
    });
    modalRef.componentInstance.providerInfo = provider;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
      if (receivedEntry === "creado") {
        this.configPage();
      } else {
        return false;
      }
    });
  }

  onDelete(id) {
    this.crudServices.deleteModel("api/proveedor/borrar?id=" + id).subscribe(
      (genericResponse) => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageDelete();
          this.configPage();
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized(
            "warning",
            genericResponse.answer,
            "No se eliminó el proveedor"
          );
        }
      },
      (error) => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
        this.errorMessage = `${error.status}: ${error.error.message}`;
      }
    );
  }

  deleteWarning(id) {
    event.preventDefault();
    swal({
      title: "¿Está seguro que desea eliminar el proveedor?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "¡Sí, eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        this.onDelete(id);
      }
    });
  }

  updateFilterByName(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function (d: any) {
      if (d.service) {
        return d.service.toLowerCase().indexOf(val) !== -1 || !val;
      } else {
        return false;
      }
    });
    this.rows = temp;
    this.table.offset = 0;
  }

  updateFilterByName2(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function (d: any) {
      if (d.name) {
        return d.name.toLowerCase().indexOf(val) !== -1 || !val;
      } else {
        return false;
      }
    });
    this.rows = temp;
    this.table.offset = 0;
  }
}
