import { Component, OnInit } from "@angular/core";
import { UserModel } from "models/entities/user-model";
import { adminConfigModel } from "models/entities/admin-config";
import { CrudServiceService } from "app/shared/backend/cruds/crud-service.service";
import { InfoMessagesService } from "app/shared/messages/info-messages.service";
import { FirebaseStorageService } from "app/shared/firebase/firebase-storage.service";
import { TokenStorageService } from "app/shared/storage-services/token-storage.service";
import { SharedService } from "app/shared/shared.service";
import { GenericResponseModel } from "../../../models/utilities/generic.response.model";
import { VehicleModel } from "models/entities/vehicle";
import swal from "sweetalert2";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UpdateVehicleComponent } from "app/coownership/update-vehicle/update-vehicle.component";
import { CreateVehicleComponent } from "app/coownership/create-vehicle/create-vehicle.component";
import { CoownershipModel } from "models/entities/coowner-ship-model";
import { CreatePetComponent } from "../../pets/create-pet/create-pet.component";
import { UpdatePetComponent } from "../../pets/update-pet/update-pet.component";
import { PetModel } from "../../../models/entities/pet-model";
import { DetailPetComponent } from "../../pets/detail-pet/detail-pet.component";
import { ModalInfo } from "models/entities/modal-info";
import { EquipoAuxModel } from "models/utilities/equipo-aux-model";
import { ModalReunionesComponent } from "../modal-reuniones/modal-reuniones.component";
import { environment } from "environments/environment.prod";
import { HandleServicesService } from "app/shared/handle-service/handle-services.service";
import { CreateUserCoownerComponent } from "../create-user-coowner/create-user-coowner.component";
import { CreateUserComponent } from "../create-user/create-user.component";
import { UpdateUserComponent } from "../update-user/update-user.component";
import { PlansModel } from "models/entities/plans-model";

@Component({
  selector: "app-update-owner",
  templateUrl: "./update-owner.component.html",
  styleUrls: ["./update-owner.component.scss"],
})
export class UpdateOwnerComponent implements OnInit {
  userModel: UserModel = new UserModel();
  errorMessage: string;
  createType = "OWNER";
  // Resindetes de la copropiedad
  datosResident: any = {};
  rowsResident = [];
  tempResident: string[];

  // Copropietarios de la copropiedad
  datosCoowner: any = {};
  rowsCoowner: Array<UserModel> = [];
  tempCoowner: string[];

  // Mascotas de la copropiedad
  petModel = new PetModel();
  rowsPets = [];
  tempPets: string[];
  datoPets: any;
  nombreReunion: String;

  // Vehiculos de la copropiedad
  coownerShipInfo: CoownershipModel = new CoownershipModel();
  infoVehicle: VehicleModel = new VehicleModel();
  datosVehiculos: any = {};
  tempVehiculos = [];
  rows: string[];
  modalInfo: ModalInfo;

  temp: string[];
  dato: any;

  // file
  currentFileUpload: File;
  url: any;
  selectedExtension = "";
  typesRestrictions = ["jpg", "PNG", "png", "JPGE"];
  atached = false;
  downloadURLProfil: any;
  pictureProfile: any;
  listaEquipoAux: Array<EquipoAuxModel> = new Array();
  equipoAux = new EquipoAuxModel();
  rolAdmin = this.tokenStorage.getAuthorities();
  imageStatic: any =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQPUzlmdTEXY16lvRyTf8CthtNf7E0M9O41yd0Mq4Az1WryuRJr";

  //FILE AWS
  filePhoto: any = null;
  filePhotoRender: any = "";

  urlPeticionImg = environment.serverUrl + "api/archivos/ver-img?ruta=";

  constructor(
    public crudServices: CrudServiceService,
    private messageService: InfoMessagesService,
    private handleService: HandleServicesService,
    private firebaseStorageService: FirebaseStorageService,
    private tokenStorage: TokenStorageService,
    private sharedService: SharedService,
    private modalService: NgbModal,
    private handle: HandleServicesService
  ) {}

  ngOnInit() {
    this.configCoowner();
    this.configPageCoownerShip();
    this.configPage();
    this.configResident();
    this.configVehicles();
    this.listPets();
    this.getCoownerSHipInfo();
    this.infoReuniones();
  }

  infoReuniones() {
    this.crudServices
      .getModel(
        "api/reuniones/reuniones-propietario?id=" +
          this.tokenStorage.getCoownerShipId()
      )
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.dato = genericResponse.answerList;
            this.temp = { ...this.dato };
            this.rows = this.dato;

            this.cargarReuniones();

            //console.log(this.rows);
          }
        },
        (error) => {
          this.errorMessage = `${error.status}: ${error.error.message}`;
          console.error(error);
          this.handle.handleError(error);
        }
      );
  }

  cargarReuniones() {
    for (let lst in this.dato) {
      if (this.dato[lst].status == "nopresent") {
        this.nombreReunion =
          this.dato[lst].meetingId.name + "-" + this.dato[lst].meetingId.id;
        //alert(this.nombreReunion);
        this.abrirModalReuniones(this.dato[lst]);
        break;
      }
    }
  }

  abrirModalReuniones(reunion) {
    this.modalInfo = new ModalInfo();
    this.modalInfo.isCreate = true;
    this.modalInfo.param = reunion;

    const modalRef = this.modalService.open(ModalReunionesComponent, {
      windowClass: "my-class",
      backdrop: "static",
    });

    modalRef.componentInstance.modalInfo = this.modalInfo;
  }

  // ---------------------------------- Mi Perfil -------------------------------

  configPageCoownerShip() {
    this.crudServices
      .getModel(
        "api/coownership/detalle?coOwnershipId=" +
          this.tokenStorage.getCoownerShipId()
      )
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.coownerShipInfo = genericResponse.genericObject;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              " Error en la búsqueda"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  addCoowner() {
    const modalRef = this.modalService.open(CreateUserCoownerComponent, {
      windowClass: "",
      size: "lg",
      backdrop: "static",
    });
    modalRef.componentInstance.createType = this.createType;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      if (receivedEntry === "Usuario Registrado") {
        this.configCoowner();
      } else {
        return false;
      }
    });
  }

  configPage() {
    this.crudServices
      .getModel("api/usuarios/obtener-info-usuario-autenticado")
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.userModel = genericResponse.genericObject;
            this.pictureProfile = genericResponse.genericObject.pictureProfile;
          }
        },
        (error) => {
          this.messageService.getInfoMessagePersonalized(
            "error",
            "Error la carga de la info",
            "Error"
          );
        }
      );
  }

  updateProfile() {
    let path = "api/usuarios/actualizar";

    this.userModel.pictureProfile = this.filePhotoRender;
    let userModelAux = this.userModel;
    userModelAux.pictureProfile = null;

    this.crudServices.createModel(path, userModelAux).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          if (this.filePhoto !== null) {
            this.uploadPhoto(this.userModel);
          } else {
            this.messageService.getInfoMessageUpdate();
          }

          this.filePhoto = null;
        } else {
          this.messageService.getInfoMessagePersonalized(
            "error",
            genericResponse.answer,
            "Atención!"
          );
        }
      },
      (error) => {
        this.messageService.getInfoMessagePersonalized(
          "error",
          "Error en la actualización.",
          "Error"
        );
      }
    );
  }

  // Upload Image in Firebase

  readUrl(inputValue: any): void {
    const img = new Image();
    this.atached = true;
    img.src = window.URL.createObjectURL(inputValue.target.files.item(0));
    img.onload = () => {
      window.URL.revokeObjectURL(img.src);
      if (img.naturalWidth <= 2000 && img.naturalHeight <= 2000) {
        this.currentFileUpload = inputValue.target.files[0];
        var myReader: FileReader = new FileReader();
        myReader.onloadend = (e) => {
          this.url = myReader.result;
        };
        myReader.readAsDataURL(this.currentFileUpload);
      } else {
        this.messageService.getInfoMessagePersonalized(
          "warning",
          "El tamaño de la imagen debe ser menor o igual a 200 x 200 px",
          "Imagen fuera de proporciones"
        );
      }
    };
  }

  selectFile(event) {
    if (
      this.typesRestrictions.includes(
        event.target.files.item(0).name.split(".").pop()
      )
    ) {
      this.currentFileUpload = event.target.files.item(0);
      this.selectedExtension = event.target.files.item(0).name.split(".").pop();
    } else {
      this.currentFileUpload = undefined;
      swal({
        title: "El tipo no es correcto",
        type: "error",
        text: "El archivo debe tener formato jpg, PNG, png ó JPEG, jpeg",
      });
    }
  }

  upload(user: UserModel) {
    this.firebaseStorageService
      .uploadFile(
        "images/" + "mi-perfil/" + this.tokenStorage.getId(),
        "perfil." + this.selectedExtension,
        this.currentFileUpload
      )
      .then(
        (responseFirebase) => {
          this.firebaseStorageService
            .loadFile(
              "images/" + "mi-perfil/" + this.tokenStorage.getId(),
              "perfil." + this.selectedExtension
            )
            .then((dowloadURL) => {
              this.downloadURLProfil = dowloadURL;
              this.addPhoto(this.downloadURLProfil);
              this.pictureProfile = dowloadURL;
            })
            .catch((error) => {
              console.error(error);
              this.messageService.getInfoMessageBadInternet();
            });
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  addPhoto(downloadURLProfil: any) {
    this.userModel.pictureProfile = downloadURLProfil;
    this.crudServices
      .createModel("api/usuarios/actualizar", this.userModel)
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.userModel = new UserModel();
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
          this.errorMessage = error.error.answer;
        }
      );
  }

  // -------------------------------------------- RESIDENTES ---------------------------------------------------

  configResident() {
    // Obtendremos los residentes a partir del servicio SUPER o ADMIN
    this.crudServices
      .getModel(
        "api/usuarios/obtener-residentes?id=" +
          this.tokenStorage.getCoownerShipId()
      )
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.datosResident = genericResponse.answerList;
            this.tempResident = { ...this.datosResident };
            this.rowsResident = this.datosResident;
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  configCoowner() {
    // Obtendremos los residentes a partir del servicio SUPER o ADMIN
    this.crudServices
      .getModel(
        "api/usuarios/obtener-por-id-rol?id=" +
          this.tokenStorage.getIdSesion() +
          "&role=ROLE_COOWNER"
      )
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.datosCoowner = genericResponse.answerList2;
            this.tempCoowner = { ...this.datosResident };
            this.rowsCoowner = this.datosCoowner as UserModel[];
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
          //alert("ERROR");
        }
      );
  }

  onUpdateUser(id: string): void {
    this.crudServices
      .getModel("api/usuarios/buscar-por-id-coowner?userId=" + id)
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            const modalRef = this.modalService.open(UpdateUserComponent, {
              windowClass: "",
              size: "lg",
              backdrop: "static",
            });

            modalRef.componentInstance.userInfoUpdate =
              genericResponse.genericObject;
            modalRef.componentInstance.passEntry.subscribe(
              (userUpdated: UserModel) => {
                const selectedPlan: any = userUpdated.activatedPlanId;
                userUpdated.activatedPlanId = new PlansModel();
                userUpdated.activatedPlanId.id = selectedPlan;
                swal({
                  title: "¿Está seguro que desea realizar la actualización?",
                  showCancelButton: true,
                  confirmButtonText: "Aceptar",
                  type: "warning",
                }).then((result) => {
                  if (result.value) {
                    this.crudServices
                      .createModel(
                        "api/usuarios/actualizar-copropietario",
                        userUpdated
                      )
                      .subscribe(
                        (genericUpdatedResponse) => {
                          if (genericUpdatedResponse.code === 200) {
                            this.messageService
                              .getInfoMessageUpdate()
                              .then((response) => {
                                this.configPage();
                                this.modalService.dismissAll();
                              });
                          }
                          if (genericUpdatedResponse.code === 400) {
                            this.messageService.getInfoMessagePersonalized(
                              "warning",
                              genericResponse.answer,
                              "No se realizó la actualización"
                            );
                          }
                        },
                        (error) => {
                          this.errorMessage = `${error.status}: ${error.error.message}`;
                          console.error(error);
                          this.messageService.getInfoMessageBadInternet();
                        }
                      );
                  }
                });
              }
            );
          } else if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se encontró el usuario"
            );
          }
        },
        (error) => {
          this.errorMessage = `${error.status}: ${error.error.message}`;
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  // -------------------------------------------- MASCOTAS ---------------------------------------------------

  listPets() {
    this.crudServices
      .getModel(
        "api/mascotas/ver-mis-mascotas?id=" +
          this.tokenStorage.getCoownerShipId()
      )
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.datoPets = genericResponse.answerList;
            this.tempPets = { ...this.datoPets };
            this.rowsPets = this.datoPets;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Sin correspondencia"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  openPets() {
    const modalRef = this.modalService.open(CreatePetComponent, {
      windowClass: "",
      size: "lg",
      backdrop: "static",
    });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry.creado === "creado") {
        this.modalService.dismissAll();
        this.rowsPets.push(receivedEntry.petsModel);
      } else {
        return false;
      }
    });
  }

  deleteCoowner(coowner: UserModel) {
    this.crudServices
      .deleteModel(`api/usuarios/eliminar?username=${coowner.email}`)
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.messageService.getInfoMessageDelete().then(() => {
              this.configCoowner();
            });
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se eliminó el arrendatario"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
          this.errorMessage = `${error.status}: ${error.error.message}`;
        }
      );
  }
  detailPet(id) {
    this.crudServices
      .getModel("api/mascotas/buscar-mascota?id=" + id)
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.petModel = genericResponse.genericObject;
            const modalRef = this.modalService.open(DetailPetComponent, {
              windowClass: "",
              size: "lg",
              backdrop: "static",
            });
            modalRef.componentInstance.petsInfo = this.petModel;
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  updatePet(id: any) {
    this.crudServices
      .getModel("api/mascotas/buscar-mascota?id=" + id)
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.petModel = genericResponse.genericObject;
            const modalRef = this.modalService.open(UpdatePetComponent, {
              windowClass: "",
              size: "lg",
              backdrop: "static",
            });
            modalRef.componentInstance.petsInfo = this.petModel;
            modalRef.componentInstance.passEntry.subscribe(
              (receivedEntry: any) => {
                if (receivedEntry.estado === "actualizado") {
                  let id = receivedEntry.petsModel.id;
                  let name = receivedEntry.petsModel.name;
                  let petPicture = receivedEntry.petsModel.petPicture;
                  this.modalService.dismissAll();
                  this.listPetsUpdate(id, name, petPicture);
                  // console.log(this.petModel.name)
                  // console.log(this.petModel.petPicture)
                } else {
                  return false;
                }
              }
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  listPetsUpdate(id, name, petPicture) {
    this.crudServices
      .getModel(
        "api/mascotas/ver-mis-mascotas?id=" +
          this.tokenStorage.getCoownerShipId()
      )
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.datoPets = genericResponse.answerList;
            this.tempPets = { ...this.datoPets };
            this.rowsPets = this.datoPets;

            console.log(
              "Foreach de las macotas supuestamente pobladas ---------------------"
            );
            this.rowsPets.forEach((element) => {
              if (id == element.id) {
                element.name = name;
                element.petPicture = petPicture;
                console.log("condicion cumplicad ----------------");
                // this.petModel.name = element.name;
                // this.datoPets.name =  name;
                // this.datoPets.petPicture =  petPicture;
              }
            });
            console.log(this.rowsPets);
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Sin correspondencia"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
    console.log("llamando al foreach");
    let number = [2, 5, 12, 4];
    // this.rowsPets.forEach(arrayFunction);
    // console.log()
    console.log("el rowPet: " + this.rowsPets);
    console.log("EL LISTADO EN MI ARRAY----------------");
    number.forEach((element) => {
      console.log(element);
    });
    // function arrayFunction(element, index, array){
    //   console.log('arra['+index+']= '+element+' array '+array);
    // }
  }

  onDeletePet(pet: PetModel) {
    this.crudServices
      .deleteModel("api/mascotas/remover-mascota?id=" + pet.id)
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.borrarImagenS3(pet.petPicture);
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se eliminó la mascota"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
          this.errorMessage = `${error.status}: ${error.error.message}`;
        }
      );
  }

  deleteWarningPet(pet: PetModel) {
    event.preventDefault(); // prevent form submit
    swal({
      title: "¿Está seguro que desea eliminar la mascota?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "¡Sí, eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        this.onDeletePet(pet);
      }
    });
  }

  // -------------------------------------------- VEHICULOS ---------------------------------------------------

  configVehicles() {
    this.crudServices
      .getModel(
        "api/vehiculo/obtener-vehiculos?id=" +
          this.tokenStorage.getCoownerShipId()
      )
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.datosVehiculos = genericResponse.answerList;
            this.tempVehiculos = { ...this.datosVehiculos };
            this.rows = this.datosVehiculos;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Sin Vehiculos"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  getCoownerSHipInfo() {
    this.crudServices
      .getModel(
        "api/coownership/detalle?coOwnershipId=" +
          this.tokenStorage.getCoownerShipId()
      )
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.coownerShipInfo = genericResponse.genericObject;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              " Error en la búsqueda"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  openCreateVehicle() {
    const modalRef = this.modalService.open(CreateVehicleComponent, {
      windowClass: "",
      size: "lg",
      backdrop: "static",
    });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
      if (receivedEntry === "Vehiculo_Registrado") {
        this.ngOnInit();
      } else {
        this.ngOnInit();
      }
    });
    modalRef.componentInstance.coownerShipInfo = this.coownerShipInfo;
  }

  openVehicleUpdate(id) {
    this.crudServices
      .getModel("api/vehiculo/obtener-vehiculo?id=" + id)
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.infoVehicle = genericResponse.genericObject;
            const modalRef = this.modalService.open(UpdateVehicleComponent, {
              windowClass: "",
              size: "lg",
              backdrop: "static",
            });
            modalRef.componentInstance.infoVehicle = this.infoVehicle;
            modalRef.componentInstance.passEntry.subscribe(
              (receivedEntry: string) => {
                if (receivedEntry === "vehiculo_creado") {
                  this.ngOnInit();
                } else {
                  return false;
                }
              }
            );
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Error buscando el área social"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  deleteWarningVehicle(id) {
    event.preventDefault(); // prevent form submit
    swal({
      title: "¿Está seguro que desea eliminar el parqueadero?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "¡Sí, eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        this.onDeleteVehicle(id);
      }
    });
  }

  onDeleteVehicle(id) {
    this.crudServices
      .deleteModel("api/vehiculo/borrar-vehiculo?id=" + id)
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.messageService.getInfoMessageDelete().then(() => {
              this.configVehicles();
            });
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se eliminó el menú"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
          this.errorMessage = `${error.status}: ${error.error.message}`;
        }
      );
  }

  loadPhoto(event, files) {
    this.filePhoto = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (_event) => {
      this.filePhotoRender = reader.result;
    };
  }

  updateInfo() {
    swal({
      title: "¿Está seguro que desea actualizar su información?",
      text: "",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, actualizar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        this.updateProfile();
      }
    });
  }

  uploadPhoto(userModel) {
    const folderPath = "unidad residencial " + this.tokenStorage.getCompanyId();

    var data = new FormData();
    data.append("archivo", this.filePhoto);

    let extension = this.filePhoto.name.split(".").pop();

    let path =
      "api/archivos/cargar-foto-propietario?folderPath=" +
      folderPath +
      "&relationalId=" +
      userModel.id +
      "&extension=" +
      extension;
    this.crudServices
      .createFile(path, data)
      .toPromise()
      .then((genericResponse) => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageUpdate().then(() => {
            window.location.reload();
          });
        } else {
          this.messageService.getInfoMessagePersonalized(
            "warning",
            "Se actualizo la información correctamente pero verifique la foto por favor",
            "Atención!"
          );
        }
      })
      .catch((error) => {
        this.handleService.handleError(error);
      });
  }

  borrarImagenS3(ruta) {
    let path = "api/archivos/borrar-imagen?ruta=" + ruta;
    this.crudServices
      .deleteImage(path)
      .toPromise()
      .then((genericResponse) => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageDelete().then(() => {
            window.location.reload();
          });
        } else {
          this.messageService.getInfoMessagePersonalized(
            "warning",
            genericResponse.answer,
            "No se eliminó la mascota"
          );
        }
      })
      .catch((error) => {
        this.handleService.handleError(error);
      });
  }
}
