import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert2';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { PairModulePlanModel } from 'models/utilities/pair-module-plan-model';

@Component({
  selector: 'app-to-pair-module',
  templateUrl: './to-pair-module.component.html',
  styleUrls: ['./to-pair-module.component.scss']
})
export class ToPairModuleComponent implements OnInit {
  rows = [];
  temp: string[];
  public moduleInfo: any;
  public planModules = new PairModulePlanModel()
  errorMessage = '';
  isPairedModuleFailed = false;
  dato: any;
  form: any = {
  };
  columns = [
    { prop: 'nameModule' },
    { name: 'creatingDate' }
  ];
  @Input() public planInfoUpdate;
  @ViewChild('f') planForm: NgForm;

  constructor(
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    private modalSerice: NgbModal
  ) { }

  ngOnInit() {
    this.form.planModule = new PairModulePlanModel()
    this.listModulePlan(this.planInfoUpdate.id);
  }

  configSelectModules() {
    this.crudService.getModel('api/modulos/super/ver-todos').subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.moduleInfo = genericResponse.answerList;
          const modulesToSelect = Array();
          for (const module of this.moduleInfo) {
            let itsIn = false;
            for (const moduloPorPlan of this.dato) {
              if (module.id === moduloPorPlan.id) {
                itsIn = true;
              }
            }
            if (!itsIn) {
              modulesToSelect.push(module);
            }
          }
          this.moduleInfo = modulesToSelect;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se encontraron menús')
        }
      },
      error => {
        this.errorMessage = error.error.message;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  listModulePlan(id) {
    this.crudService.getModel('api/plan/listar-modulos-por-plan?planId=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.dato = genericResponse.answerList;
          this.rows = this.dato;
          this.configSelectModules();
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se encontrron modulos')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  toPairModule(idModule, idPlan) {
    this.planModules.moduleId = {
      id: idModule
    }
    this.planModules.planId = {
      id: idPlan
    }
    this.crudService.createModel('api/plan/enlazar-plan-modulo', this.planModules).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageCreate().then(
            (response) => {
              this.isPairedModuleFailed = false;
              this.listModulePlan(idPlan);
            }
          )
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se creó la relación')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  unPair(idModule, idPlan) {
    this.planModules.moduleId = {
      id: idModule
    }
    this.planModules.planId = {
      id: idPlan
    }
    this.crudService.createModel('api/plan/desenlazar-plan-modulo', this.planModules).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageDelete().then(
            (response) => {
              this.listModulePlan(idPlan);
            }
          )
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se elimino la relación')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  closeWindow() {
    this.modalSerice.dismissAll();
  }

}
