import { CalendarsModule } from 'app/calendar/calendar.module';
import { DateTimePickerComponent } from './../calendar/date-time-picker.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TaskRoutingModule } from './task-routing.module';
import { ListTaskComponent } from './list-task/list-task.component';
import { CreateTaskComponent } from './create-task/create-task.component';
import { DetailTaskComponent } from './detail-task/detail-task.component';
import { UpdateTaskComponent } from './update-task/update-task.component';

@NgModule({
  declarations: [ListTaskComponent, CreateTaskComponent, DetailTaskComponent, UpdateTaskComponent],
  imports: [
    CommonModule,
    TaskRoutingModule,
    FormsModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    CalendarsModule
  ],
  exports: [
    ListTaskComponent
  ]
})
export class TaskModule { }
