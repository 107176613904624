import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { CreateDoormanCorrespondenceComponent } from '../create-doorman-correspondence/create-doorman-correspondence.component';
import { UpdateDoormanCorrespondenceComponent } from '../update-doorman-correspondence/update-doorman-correspondence.component';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { DoormanCorrespondenceModel } from '../../../models/entities/doorman-correspondence-model';
import { DetailDoormanCorrespondenceComponent } from '../detail-doorman-correspondence/detail-doorman-correspondence.component';
import { FilterDoormanCorrespondenceModel } from '../../../models/entities/filter-doorman-correspondence-model';
import { GenericResponseModel } from '../../../models/utilities/generic.response.model';
import { ActivatedRoute } from '@angular/router'


@Component({
  selector: 'app-list-doorman-correspondence',
  templateUrl: './list-doorman-correspondence.component.html',
  styleUrls: ['./list-doorman-correspondence.component.scss']
})
export class ListDoormanCorrespondenceComponent implements OnInit {

  correspondenceList: Array<DoormanCorrespondenceModel> = new Array();
  rows = [];
  temp: string[];
  dato: any;
  infoCorrespondence = new DoormanCorrespondenceModel();

  isFilter: Boolean = false;

  // @Input() myNumberPage;

  correspondenceInfo: DoormanCorrespondenceModel = new DoormanCorrespondenceModel();

  filter: FilterDoormanCorrespondenceModel = new FilterDoormanCorrespondenceModel();

  rowsCoownerShip = [];
  tempCoownerShip: string[];
  datoCoownerShip: any;

  paginator: any;

  constructor(
    private modalService: NgbModal, private crudServices: CrudServiceService,
    private messageService: InfoMessagesService, private tokenStorage: TokenStorageService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.configPage();
    //this.configPaginator()
    //this.listCoownerShip();
    this.listOwnersAndCoowners();
  }

  configPage() {
    this.crudServices.getModel('api/porteria/ver-mis-correspondencias?id=' + this.tokenStorage.getCompanyId()).subscribe(
      (genericResponse: GenericResponseModel) => {
        console.log(genericResponse.answerList)
        if (genericResponse.code === 200) {
          this.dato = genericResponse.answerList;
          this.temp = { ...this.dato };
          this.rows = this.dato;
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  configPaginator(){
      let page: number;

      if ( !page ) {
        page = 0;
      }
      this.configPage();
      //this.configPaginationPage(page);
  }

  configPaginationPage(page: number) {
    this.crudServices.getModel('api/porteria/ver-mis-correspondencias/page/'+page+'?id=' + this.tokenStorage.getCompanyId())
    .subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.dato = genericResponse.genericObject.content;
          this.temp = { ...this.dato };
          this.rows = this.dato;
          this.paginator =  genericResponse.genericObject;
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  openCreateCorrespondence() {
    const modalRef = this.modalService.open(CreateDoormanCorrespondenceComponent, {
      windowClass: '', size: 'lg', backdrop: 'static',
    });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
      if (receivedEntry === 'creado') {
        //this.configPaginator();
        this.configPage();
      } else {
        return false;
      }
    })
  }

  changePage(newPage){
    if ( !this.isFilter ) {
      this.configPage();
      //this.configPaginationPage(newPage);
    } else {
      this.onFilter(newPage);
    }
  }

  detailCorrespondence(id) {
    this.crudServices.getModel('api/porteria/buscar-correspondencia?id=' + id).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.infoCorrespondence = genericResponse.genericObject
          const modalRef = this.modalService.open(
            DetailDoormanCorrespondenceComponent, {
              windowClass: '', size: 'lg', backdrop: 'static',
            }
          );
          modalRef.componentInstance.correspondeceInfo = this.infoCorrespondence;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error buscando el modulo')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  updateCorrespondence(id) {
    this.crudServices.getModel('api/porteria/buscar-correspondencia?id=' + id).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.infoCorrespondence = genericResponse.genericObject
          const modalRef = this.modalService.open(
            UpdateDoormanCorrespondenceComponent, {
              windowClass: '', size: 'lg', backdrop: 'static'
            }
          );
          modalRef.componentInstance.correspondenceInfo = this.infoCorrespondence;
          modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
            if (receivedEntry === 'creado') {
              //this.configPaginator();
              this.configPage();
            } else {
              return false;
            }
          })
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error actualizando la correspondencia')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  listCoownerShip() {
    {
      this.crudServices.getModel('api/coownership/listar-todo-admin?id=' + this.tokenStorage.getCompanyId()).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.datoCoownerShip = genericResponse.answerList;
            this.tempCoownerShip = { ...this.datoCoownerShip };
            this.rowsCoownerShip = this.datoCoownerShip;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin copropiedades')
          }
        },
        error => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
    }
  }

  listOwnersAndCoowners() {
    this.crudServices
      .getModel(
        "api/coownership/listar-todo-propietarios-copropietarios-por-unidad-residencial?housingId=" +
          this.tokenStorage.getCompanyId()
      )
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.datoCoownerShip = genericResponse.answerList;
            this.tempCoownerShip = { ...this.datoCoownerShip };
            this.rowsCoownerShip = this.datoCoownerShip;
            console.log(this.rowsCoownerShip);
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No hay propietarios ni copropietarios registrados"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  onFilter(page?: number) {
    if ( !page ) {
        page = 0;
      }
    if (this.filter.coOwnerShipId === null && this.filter.receiptDate === '' && this.filter.deliverDate === '') {
      this.messageService.getInfoMessagePersonalized('warning', 'Todos los campos no pueden estar vacíos.', ' Error en la búsqueda')
    } else {
      this.isFilter = true;
      let myHousingId = this.tokenStorage.getCompanyId();
      let housingid = Number(myHousingId);
      this.filter.housingId =  housingid;
      this.crudServices.getModelModel('api/porteria/correspondencias-co-propiedad/page/'+page, this.filter).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.dato = genericResponse.genericObject.content;
            this.temp = { ...this.dato };
            this.rows = this.dato;
            this.paginator =  genericResponse.genericObject;
          }
        },
        error => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );

    }
  }

  clean() {
    this.filter = new FilterDoormanCorrespondenceModel();
    this.isFilter = false;
    //this.configPaginator();
    this.configPage();
  }

}
