export class GenericFilterModel {
    id: string;
    relationshipId: string;
    name: string;
    firstDate: string;
    endDate: string;

    public constructor() {
		this.id = '';
		this.relationshipId = '';
		this.name = '';
		this.firstDate = '';
		this.endDate = '';
	}
}