import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { IncidentModel } from '../../../models/entities/incident-model';
import { IncidentTypetModel } from '../../../models/entities/incident-type-model';
import { GenericResponseModel } from '../../../models/utilities/generic.response.model';
import { FirebaseStorageService } from 'app/shared/firebase/firebase-storage.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-create-incident',
  templateUrl: './create-incident.component.html',
  styleUrls: ['./create-incident.component.scss']
})
export class CreateIncidentComponent implements OnInit {

  public incidentModel: IncidentModel = new IncidentModel();
  public incidentTypeModel = new IncidentTypetModel();

  @Output()
  passEntry: EventEmitter<any> = new EventEmitter();

  public isCreatedIncident = true;
  errorMessage: any;

  rowsTypeIncident = [];
  tempTypeIncident: string[];
  datoTypeIncident: any;
  data: any;
  incidentTypeId: any;
  infoIncident = new IncidentModel();
  infoIncidentType = new IncidentTypetModel();
  
  // file
  downloadURL: any;
  currentFileUpload: File;
  url: any;
  selectedExtension = '';
  typesRestrictions = ['jpg', 'PNG', 'png', 'JPGE'];
  atached = false;

  constructor(
    private crudServices: CrudServiceService, private modalService: NgbModal,
    public tokenStorage: TokenStorageService, private messageService: InfoMessagesService,
    private firebaseStorageService: FirebaseStorageService,
  ) { }

  ngOnInit() {
    this.listTypeIncident();
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  onSubmit() {
    this.incidentModel.housingUnitId.id = this.tokenStorage.getCompanyId();
    this.incidentModel.coOwnerShipId.id = this.tokenStorage.getCoownerShipId();
    this.incidentModel.pictureIncident = ' ';
    this.crudServices.createModel('api/incidentes/crear-incidente', this.incidentModel).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.isCreatedIncident = true;
          if (this.atached) {
            this.incidentModel = genericResponse.genericObject;
            this.upload(this.incidentModel)
          }
          this.messageService.getInfoMessageCreate().then(
            (result) => {
              this.modalService.dismissAll();
              this.passEntry.emit('creado');
            }
          )
        }
        if (genericResponse.code === 400) {
          this.isCreatedIncident = false;
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Problema registrando los incidentes')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
        this.isCreatedIncident = false;
        this.errorMessage = error.error.answer;
      }
    );
  }

  addPhoto() {
    this.incidentModel.pictureIncident = this.downloadURL;
    this.crudServices.createModel('api/incidentes/crear-incidente', this.incidentModel).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.isCreatedIncident = true;
        }
        if (genericResponse.code === 400) {
          this.isCreatedIncident = false;
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Problema registrando los incidentes')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
        this.isCreatedIncident = false;
        this.errorMessage = error.error.answer;
      }
    );
  }

  listTypeIncident() {
    {
      this.crudServices.getModel('api/incidentes/tipo-incidente').subscribe(
        genericResponse => {
          if (genericResponse.code === 200) {
            this.datoTypeIncident = genericResponse.answerList;
            this.tempTypeIncident = { ...this.datoTypeIncident };
            this.rowsTypeIncident = this.datoTypeIncident;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin incidentes')
          }
        },
        error => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
    }
  }

  stimatedTime() {
    this.incidentTypeId = this.incidentModel.typeIncident.id;
    this.crudServices.getModel('api/incidentes/buscar-tiempo-estimado?id=' + this.incidentTypeId).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.incidentTypeModel = genericResponse.genericObject;
          this.infoIncidentType = this.incidentTypeModel;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error buscando el incidente')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  selectFile(event) {
    if (this.typesRestrictions.includes(event.target.files.item(0).name.split('.').pop())) {
      this.currentFileUpload = event.target.files.item(0);
      this.selectedExtension = event.target.files.item(0).name.split('.').pop()
    } else {
      this.currentFileUpload = undefined;
      swal({
        title: 'El tipo no es correcto', type: 'error',
        text: 'El archivo debe tener formato jpg, PNG, png ó JPEG, jpeg'
      })
    }
  }


  upload(incident: IncidentModel) {
    this.firebaseStorageService
      .uploadFile('images/' + 'incidentes/' + incident.id, 'incident.' + this.selectedExtension, this.currentFileUpload).then(
        (responseFirebase) => {
          this.firebaseStorageService
            .loadFile('images/' + 'incidentes/' + incident.id, 'incident.' + this.selectedExtension).then(
              (dowloadURL) => {
                this.downloadURL = dowloadURL
                this.addPhoto()
              }
            ).catch(error => {
              console.error(error);
              this.messageService.getInfoMessageBadInternet();
            })
        }, (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      )
  }

  readUrl(inputValue: any): void {
    const img = new Image();
    this.atached = true;
    img.src = window.URL.createObjectURL(inputValue.target.files.item(0));
    img.onload = () => {
      window.URL.revokeObjectURL(img.src);
      if (img.naturalWidth <= 2000 && img.naturalHeight <= 2000) {
        this.currentFileUpload = inputValue.target.files[0];
        var myReader: FileReader = new FileReader();
        myReader.onloadend = (e) => {
          this.url = myReader.result;
        }
        myReader.readAsDataURL(this.currentFileUpload);
      } else {
        this.messageService.getInfoMessagePersonalized
          ('warning', 'El tamaño de la imagen debe ser menor o igual a 200 x 200 px', 'Imagen fuera de proporciones');

      }
    }
  }

}
