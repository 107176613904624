import { SurveyResponseModel } from "./../../../models/entities/survey-model-response";
import { GenericResponseModel } from "models/utilities/generic.response.model";
import { QuestionResponseModel } from "./../../../models/entities/questio-mode-response";
import { CreateQuestionComponent } from "./../create-question/create-question.component";
import { TokenStorageService } from "app/shared/storage-services/token-storage.service";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { InfoMessagesService } from "app/shared/messages/info-messages.service";
import { CrudServiceService } from "app/shared/backend/cruds/crud-service.service";
import { SurveyModel } from "./../../../models/entities/survey-model";
import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { DatePipe } from "@angular/common";
import { MeetingModel } from "models/entities/meeting-module";

@Component({
  selector: "app-create-survey",
  templateUrl: "./create-survey.component.html",
  styleUrls: ["./create-survey.component.scss"],
})
export class CreateSurveyComponent implements OnInit {
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  surveyInfo = new SurveyResponseModel();
  meetingInfo: Array<MeetingModel> = new Array();
  questionOptionList: Array<QuestionResponseModel> = new Array();
  EmptyQuestion = false;

  minDate = new Date();
  startDate = new DatePipe("es-CO");
  minDate2 = this.startDate.transform(this.minDate, "yyyy-MM-dd");

  constructor(
    public crudServices: CrudServiceService,
    public messageService: InfoMessagesService,
    public activeModal: NgbActiveModal,
    public tokenStorage: TokenStorageService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.getMeetingNames();
  }

  openCreateQuestion() {
    const modalRef = this.modalService.open(CreateQuestionComponent, {
      windowClass: "",
      size: "lg",
      backdrop: "static",
    });
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: QuestionResponseModel) => {
        console.log(receivedEntry);
        this.surveyInfo.questionList.push(receivedEntry);
        this.questionOptionList.push(receivedEntry);
        this.EmptyQuestion = true;
      }
    );
  }

  deleteQuestionOptionList(storage) {
    this.questionOptionList.splice(this.questionOptionList.indexOf(storage), 1);
  }

  getMeetingNames(){
    this.crudServices
    .getModel("api/reuniones/obtener-reuniones-activas?id="+this.tokenStorage.getCompanyId())
    .subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.meetingInfo = genericResponse.answerList;
          
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized(
            "warning",
            genericResponse.answer,
            "Problema obteniendo las reuniones"
          );
        }
      },
      (error) => {
        console.error(error);
        this.messageService.getInfoMessageError();
      }
    );
  }

  onSubmit() {
    if (this.questionOptionList.length == 0) {
      this.messageService.getInfoMessagePersonalized(
        "warning",
        "Debe agregar al menos una pregunta",
        "Atención!"
      );
      return;
    }
    this.surveyInfo.survey.status = true;
    this.surveyInfo.survey.housingUnitId.id = this.tokenStorage.getCompanyId();
    this.crudServices
      .createModel("api/preguntas/guardar", this.surveyInfo)
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.passEntry.emit("creado");
            this.activeModal.close();
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Problema creando pregunta"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageError();
        }
      );
  }
}
