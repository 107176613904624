import { CoownershipModel } from './../../../models/entities/coowner-ship-model';
import { TokenStorageService } from './../../shared/storage-services/token-storage.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoMessagesService } from './../../shared/messages/info-messages.service';
import { CrudServiceService } from './../../shared/backend/cruds/crud-service.service';
import { ParkingModel } from './../../../models/entities/parking-model';
import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-create-parking',
  templateUrl: './create-parking.component.html',
  styleUrls: ['./create-parking.component.scss']
})
export class CreateParkingComponent implements OnInit {

  parkingInfo: ParkingModel = new ParkingModel();
  rowsTower = [];
  tempTower: string[];
  datoTower: any;
  @Input() public coownerShipInfo: CoownershipModel;
  @Output() passEntry: EventEmitter<any> = new EventEmitter()
  constructor(
    public crudServices: CrudServiceService,
    public messageService: InfoMessagesService,
    public activeModal: NgbActiveModal,
    public tokenStorage: TokenStorageService,
    public token: TokenStorageService
  ) { }

  ngOnInit() {
    this.configSelectTowers()
  }


  saveParkingList() {
    this.parkingInfo.coownerShipId = this.coownerShipInfo;
    this.crudServices.createModel('api/parqueadero/guardar', this.parkingInfo).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageCreate();
          this.passEntry.emit('Parqueadero_Registrado');
          this.activeModal.dismiss();
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Problema creando parqueaderos copropiedad')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageError();
      }
    );

  }

  configSelectTowers() {
    this.crudServices.getModel('api/torre-bloque/obtener-torres?id=' + this.token.getCompanyId()).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.datoTower = genericResponse.answerList;
          this.tempTower = { ...this.datoTower };
          this.rowsTower = this.datoTower;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin torres')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }
}
