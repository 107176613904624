import { ClientInfoComponent } from './client-info/client-info.component';
import { LandingComponent } from './landing/landing.component';
import { BannerComponent } from './banner/banner.component';
import { WelcomeTitleComponent } from './welcome-title/welcome-title.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleAuthGuard } from 'app/shared/auth/roleAuth-guard.service';
import { PricingContainerComponent } from './pricing-container/pricing-container.component';
import { SevicesContainerComponent } from './sevices-container/sevices-container.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'welcome',
        component: WelcomeTitleComponent,
        data: {
          title: 'welcome',
          expectedRole: []
        },
        // canActivate: [RoleAuthGuard]
      },
      {
        path: 'pricing',
        component: PricingContainerComponent,
        data: {
          title: 'pricing',
          expectedRole: []
        },
        // canActivate: [RoleAuthGuard]
      },
      {
        path: 'banner',
        component: BannerComponent,
        data: {
          title: 'banner',
          expectedRole: []
        },
        // canActivate: [RoleAuthGuard]
      },
      {
        path: '',
        component: LandingComponent,
        data: {
          title: 'landing',
          expectedRole: []
        },
      },
      {
        path: 'services',
        component: SevicesContainerComponent,
        data: {
          title: 'services',
          expectedRole: []
        },
      },
      {
        path: 'info-client',
        component: ClientInfoComponent,
        data: {
          title: 'Client Info',
          expectedRole: []
        },
      }
    ],
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [RoleAuthGuard],
  exports: [RouterModule]
})
export class WelcomeRoutingModule { }
