import { UpdateTaskComponent } from './../update-task/update-task.component';
import { TaskModel } from './../../../models/entities/task';
import { DetailTaskComponent } from './../detail-task/detail-task.component';
import { CreateTaskComponent } from './../create-task/create-task.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common'
import swal from 'sweetalert2';

@Component({
  selector: 'app-list-task',
  templateUrl: './list-task.component.html',
  styleUrls: ['./list-task.component.scss']
})
export class ListTaskComponent implements OnInit {

  rows: any;
  dato: any;
  errorMessage: any;
  idHousingUnit: any = this.tokenStorage.getCompanyId();
  taskInfo: TaskModel = new TaskModel();


  constructor(
    public crudServices: CrudServiceService,
    public messageService: InfoMessagesService,
    public tokenStorage: TokenStorageService,
    public modalService: NgbModal,
    public datepipe: DatePipe
  ) { }

  ngOnInit() {
    this.configPage()
  }

  configPage() {
    this.crudServices.getModel('api/tarea/obtener-tareas?idHousingUnit=' + this.idHousingUnit).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.dato = genericResponse.answerList;
          this.rows = this.dato;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se encontraron empleados')
        }
      }, error => {
        this.errorMessage = `${error.status}: ${error.error.message}`;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  openCreateTask() {
    const modalRef = this.modalService.open(CreateTaskComponent, {
      windowClass: '', size: 'lg', 'backdrop': 'static'
    });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
      if (receivedEntry === 'tarea_creada') {
        this.configPage();
      } else {
        return false;
      }
    })
  }


  detailTask(id) {
    this.crudServices.getModel('api/tarea/detalle?id=' + id).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.taskInfo = genericResponse.genericObject
          const modalRef = this.modalService.open(
            DetailTaskComponent, {
            windowClass: '', size: 'lg', backdrop: 'static'
          }
          );
          modalRef.componentInstance.taskInfo = this.taskInfo;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error buscando el modulo')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  updateTask(id) {
    this.crudServices.getModel('api/tarea/detalle?id=' + id).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.taskInfo = genericResponse.genericObject
          const modalRef = this.modalService.open(
            UpdateTaskComponent, {
            windowClass: '', size: 'lg', backdrop: 'static'
          }
          );
          modalRef.componentInstance.taskInfo = this.taskInfo;
          modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
            if (receivedEntry === 'creado') {
              this.configPage();
            } else {
              return false;
            }
          })
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error buscando el modulo')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  onDelete(id) {
    this.crudServices.deleteModel('api/tarea/borrar?id=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageDelete()
          this.configPage()
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se eliminó la tarea')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
        this.errorMessage = `${error.status}: ${error.error.message}`;
      }
    );

  }

  deleteWarning(id) {
    event.preventDefault();
    swal({
      title: '¿Está seguro que desea eliminar la tarea?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.onDelete(id);
      }
    })
  }


}
