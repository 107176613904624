import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateTowerBlockComponent } from '../create-tower-block/create-tower-block.component';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { TowerBlockModule } from '../tower-block.module';
import swal from 'sweetalert2';
import { UpdateTowerBlockComponent } from '../update-tower-block/update-tower-block.component';
import { Router, ActivatedRoute } from '@angular/router';
import { TowerModel } from '../../../models/entities/tower-model';
import { MenuModel } from 'models/entities/menu-model';
import { DetailTowerBlockComponent } from '../detail-tower-block/detail-tower-block.component';
import { DetailmenuComponent } from 'app/menucomponent/detailmenu/detailmenu.component';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { GenericResponseModel } from '../../../models/utilities/generic.response.model';

@Component({
  selector: 'app-list-tower-block',
  templateUrl: './list-tower-block.component.html',
  styleUrls: ['./list-tower-block.component.scss']
})
export class ListTowerBlockComponent implements OnInit {

  toweBlockList: Array<TowerBlockModule> = new Array();
  towerBlockSelected: TowerModel;
  infoTowerBlock = new MenuModel();
  rows = [];
  errorMessage: string;
  infoMenu = new MenuModel();

  public housingUnitStorage: string;

  constructor(private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute, private crudServices: CrudServiceService,
    private messageService: InfoMessagesService, private tokenStorage: TokenStorageService
    ) { }


  ngOnInit() {
    this.configPage();
    this.housingUnitStorage = this.tokenStorage.getCompanyId();
  }


    openCreateTower() {
    const modalRef = this.modalService.open(CreateTowerBlockComponent, {
      windowClass: '', size: 'lg', backdrop: 'static',
    });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
      if (receivedEntry === 'creado') {
        this.configPage();
      } else {
        return false;
      }
    })
  }

  //  api/torre-bloque/listar (para super admin)
  // api/torre-bloque/ver-mis-torres?id= (para admin)
  configPage() {
    this.crudServices.getModel('api/torre-bloque/ver-mis-torres?id=' + this.tokenStorage.getCompanyId()).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.toweBlockList = genericResponse.answerList;
          this.rows = this.toweBlockList;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin torres')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  onDelete(id: any) {
    this.crudServices.deleteModel('api/torre-bloque/eliminar?id=' + id).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageDelete().then(
            () => {
              this.configPage();
            }
          )
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se eliminó la torre' )
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
        this.errorMessage = `${error.status}: ${error.error.message}`;
      }
    );
  }

  deleteWarning(id: any) {
    event.preventDefault(); // prevent form submit
    swal({
      title: '¿Está seguro que desea eliminar la torre?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.onDelete(id);
      }
    })
  }

  onDetail(id) {
    this.router.navigate(['/actualizar-torre', { id }], { relativeTo: this.route.parent });
  }

  openModalUpdate(id: any) {
    this.crudServices.getModel('api/torre-bloque/buscar?id=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.infoTowerBlock = genericResponse.genericObject
          const modalRef = this.modalService.open(
            UpdateTowerBlockComponent, {
              windowClass: '', size: 'lg', backdrop: 'static'
            }
          );
          modalRef.componentInstance.towerBlockU = this.infoTowerBlock;
          modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
            if (receivedEntry === 'creado') {
              this.configPage();
            } else {
              return false;
            }
          })
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error actualizando la torre')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }


  detailTowerBlock(id) {
    this.crudServices.getModel('api/torre-bloque/buscar?id=' + id).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.infoMenu = genericResponse.genericObject
          const modalRef = this.modalService.open(
            DetailTowerBlockComponent, {
              windowClass: '', size: 'lg',  backdrop: 'static'
            }
          );
          modalRef.componentInstance.towerBlockInfo = this.infoMenu;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error buscando el modulo')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }
}
