import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';

@Component({
  selector: 'app-detail-doorman-correspondence',
  templateUrl: './detail-doorman-correspondence.component.html',
  styleUrls: ['./detail-doorman-correspondence.component.scss']
})
export class DetailDoormanCorrespondenceComponent implements OnInit {

  @Input() correspondeceInfo

  rowsCoownerShip = [];
  tempCoownerShip: string[];
  datoCoownerShip: any;

  constructor(
    private modalService: NgbModal, private crudServices: CrudServiceService,
    public tokenStorage: TokenStorageService, private messageService: InfoMessagesService
  ) { }

  ngOnInit() {
    this.listCoownerShip();
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  listCoownerShip() {
      this.crudServices.getModel('api/coownership/listar-todo-admin?id=' + this.tokenStorage.getCompanyId()).subscribe(
        genericResponse => {

          if (genericResponse.code === 200) {
            this.datoCoownerShip = genericResponse.answerList;
            this.tempCoownerShip = { ...this.datoCoownerShip };
            this.rowsCoownerShip = this.datoCoownerShip;
            console.log(this.rowsCoownerShip);
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin copropiedades')
          }
        },
        error => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }
}
