import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';

@Component({
  selector: 'app-detail-question-owner',
  templateUrl: './detail-question-owner.component.html',
  styleUrls: ['./detail-question-owner.component.scss']
})
export class DetailQuestionOwnerComponent implements OnInit {

  @Input() surveyId: number;

  rows = [];
  temp: string[];
  dato: any;

  constructor(
    private modalService: NgbModal, private crudServices: CrudServiceService,
    private tokenStorage: TokenStorageService
  ) { }

  ngOnInit() {
    this.configPage();
  }

  configPage() {
    this.crudServices.getModel(
      `api/preguntas/detalle-encuesta-respondida?coownerId=${this.tokenStorage.getCoownerShipId()}&surveyId=${this.surveyId}`
    ).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.dato = genericResponse.answerList;
          this.temp = { ...this.dato };
          this.rows = this.dato;
          console.log(this.rows);
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  closeModal() {
    this.modalService.dismissAll();
  }

}
