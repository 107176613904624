import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListaEquipoComponent } from './lista-equipo/lista-equipo.component';
import { RoleAuthGuard } from 'app/shared/auth/roleAuth-guard.service';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'listar-equipos',
        component: ListaEquipoComponent,
        data: {
          title: 'Listar Equipos',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN']
        },
        canActivate: [RoleAuthGuard]
      }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [RoleAuthGuard],
  exports: [RouterModule]
})
export class MantenientoRoutingModule { }
