import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { ChangePasswordFirstLogin } from 'models/request/change-pass-first-login';
import { GenericResponseModel } from 'models/utilities/generic.response.model';

@Component({
  selector: 'app-first-login',
  templateUrl: './first-login.component.html',
  styleUrls: ['./first-login.component.scss']
})
export class FirstLoginComponent implements OnInit {

  @Input() idUsuario;
  
  errorMessage = '';
  request = new ChangePasswordFirstLogin();
  passwordValidationError = '';
  isErrorPassword = false;
  passwordConfirmation: any;

  constructor(
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.request.idUsuario = this.idUsuario;
  }

  changePassword() {
    this.passwordValidation();
    this.crudService.createModel('api/usuarios/actualizar-contrasena-user-primer-login', this.request).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageUpdate().then(() => {
            this.closeWindow();
          })
        } 
        else if (genericResponse.code === 201) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Actualizacion fallida')
        } 
        else if (genericResponse.code === 202) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Actualizacion fallida')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
        this.errorMessage = JSON.stringify(error.error.mensaje);
      }
    );
  }


  passwordValidation() {
    if (this.request.nuevaContrasena !== this.passwordConfirmation) {
      this.passwordValidationError = '¡Las contraseñas no coinciden!';
      this.isErrorPassword = true;
      return;
    } else if (this.request.nuevaContrasena === this.passwordConfirmation) {
      this.passwordValidationError = '¡Las contraseñas no coinciden!';
      this.isErrorPassword = false;
      return;
    }
  }

  closeWindow() {
    this.modalService.dismissAll();
  }
}
