import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-detailmenu',
  templateUrl: './detailmenu.component.html',
  styleUrls: ['./detailmenu.component.scss']
})
export class DetailmenuComponent implements OnInit {

  @Input() public menuInfo

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  closeWindow() {
    this.modalService.dismissAll();
  }

}
