import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MenucomponentRoutingModule } from './menucomponent-routing.module';
import { CreatemenuComponent } from './createmenu/createmenu.component';
import { RoleAuthGuard } from 'app/shared/auth/roleAuth-guard.service';
import { ListmenuComponent } from './listmenu/listmenu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DragAndDropModule } from 'angular-draggable-droppable';
import { DetailmenuComponent } from './detailmenu/detailmenu.component';
import { UpdatemenuComponent } from './updatemenu/updatemenu.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    MenucomponentRoutingModule,
    NgxDatatableModule,
    FormsModule,
    DragAndDropModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbModule
  ],
  declarations: [CreatemenuComponent, ListmenuComponent, DetailmenuComponent, UpdatemenuComponent],
  providers: [RoleAuthGuard]
})
export class MenucomponentModule { }
