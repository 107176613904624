import { FirebaseStorageService } from './../../shared/firebase/firebase-storage.service';
import { UserModel } from './../../../models/entities/user-model';
import { TokenStorageService } from './../../shared/storage-services/token-storage.service';
import { employeeModel } from './../../../models/entities/employee';
import { EmployeeModule } from './../../employee/employee.module';
import { TaskModel } from './../../../models/entities/task';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { Task } from './../../taskboard/taskboard.model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TaskTypeModel } from 'models/entities/task-type';
import swal from 'sweetalert2';

@Component({
  selector: 'app-create-task',
  templateUrl: './create-task.component.html',
  styleUrls: ['./create-task.component.scss']
})
export class CreateTaskComponent implements OnInit {

  newTask: TaskModel = new TaskModel();
  errorMessage: String;
  listEmployees: Array<UserModel> = new Array();
  listTaskType: Array<TaskTypeModel> = new Array();
  currentFileUpload: File;
  url: any;
  downloadUrl = ' ';
  selectedExtension = '';
  idTask = '';
  attached = false;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  typesRestrictions = ['jpg', 'PNG', 'png', 'JPGE','doc', 'docx', 'pdf'];
  constructor(
    public crudServices: CrudServiceService,
    public messageService: InfoMessagesService,
    public activeModal: NgbActiveModal,
    public tokenStorage: TokenStorageService,
    private firebaseStorageService: FirebaseStorageService,
  ) { }

  ngOnInit() {
    this.getEmployees();
    this.getTaskTypes();

  }
  onSubmit() {
    this.saveTask()

  }

  saveTask() {
    this.newTask.housingUnitId.id = this.tokenStorage.getCompanyId();
    this.crudServices.createModel('api/tarea/guardar', this.newTask).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.newTask = genericResponse.genericObject;
          if (this.attached) {
            swal({
              title: 'Estamos cargando la foto!',
              text: 'Sólo tardara unos segundos.',
              timer: 2000,
              showConfirmButton: false,
              imageUrl: 'https://ea1hlh.es/wp-content/uploads/2019/04/loading.gif'
            });
            setTimeout(() => {
            }, 2000);
            this.upload(this.newTask)
          } else {
            this.messageService.getInfoMessageCreate();
            this.passEntry.emit('tarea_creada');
            this.activeModal.close()
          }
        }
      },
      error => {
        this.errorMessage = error.error.message;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  saveTaskLogo(dowloadURL: any) {
    this.newTask.firebaseFile = dowloadURL;
    this.crudServices.createModel('api/tarea/guardar', this.newTask).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageCreate();
          this.passEntry.emit('tarea_creada');
          this.activeModal.close()
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se creó el usuario')
          this.errorMessage = genericResponse.answer;
        }
      },
      error => {
        this.errorMessage = error.error.message;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  getEmployees() {
    this.crudServices.getModel('api/usuarios/obtener-colaborador-empleado?idHousingUnit=' + this.tokenStorage.getCompanyId()).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.listEmployees = genericResponse.answerList;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se encontraron empleados')
        }
      }, error => {
        this.errorMessage = `${error.status}: ${error.error.message}`;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  getTaskTypes() {
    const pathListDOcumentTypesUrl = 'api/tarea/obtener-tipos';
    this.listTaskType = new Array();
    this.crudServices.getModel(pathListDOcumentTypesUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.listTaskType = genericResponse.answerList;
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los tipos de taras del sistema',
            'Problema consultando los tipos de tareas');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar los tipos de tareas' + JSON.stringify(error))
      }
    );
  }

 
      // Upload Image in Firebase

   readUrl(inputValue: any): void {
    const img = new Image();
    this.attached = true;
    img.src = window.URL.createObjectURL(inputValue.target.files.item(0));
    img.onload = () => {
      window.URL.revokeObjectURL(img.src);
      if (img.naturalWidth <= 2000 && img.naturalHeight <= 2000) {
        this.currentFileUpload = inputValue.target.files[0];
        var myReader: FileReader = new FileReader();
        myReader.onloadend = (e) => {
          this.url = myReader.result;
        }
        myReader.readAsDataURL(this.currentFileUpload);
      } else {
        this.messageService.getInfoMessagePersonalized
          ('warning', 'El tamaño de la imagen debe ser menor o igual a 200 x 200 px', 'Imagen fuera de proporciones');

      }
    }
  }

  selectFile(event) {
    if (this.typesRestrictions.includes(event.target.files.item(0).name.split('.').pop())) {
      this.currentFileUpload = event.target.files.item(0);
      this.selectedExtension = event.target.files.item(0).name.split('.').pop()
    } else {
      this.currentFileUpload = undefined;
      swal({
        title: 'El tipo no es correcto', type: 'error',
        text: 'El archivo debe tener formato jpg, PNG, png ó JPEG, jpeg'
      })
    }
  }


  upload(newTask : TaskModel) {
    this.firebaseStorageService
    .uploadFile('images/' + 'tareas/' + this.newTask.id, 'image.' + this.selectedExtension, this.currentFileUpload).then(
        (responseFirebase) => {
        this.firebaseStorageService
        .loadFile('images/' + 'tareas/' + this.newTask.id, 'image.' + this.selectedExtension).then(
          (dowloadURL) => {
                this.downloadUrl = dowloadURL
                this.passEntry.emit('tarea_creada');
                this.addPhoto(this.downloadUrl)
              }
            ).catch(error => {
              console.error(error);
              this.messageService.getInfoMessageBadInternet();
            })
        }, (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      )
  }

  addPhoto( downloadURL:any) {
    this.newTask.firebaseFile = downloadURL;
    this.crudServices.createModel('api/tarea/guardar', this.newTask).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.activeModal.close()
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
        this.errorMessage = error.error.answer;
      }
    );
  }


      
}
