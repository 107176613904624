export class FirebaseFile {

    id: String;
    fileName: String;
    downloadUrl: String;
    pathFirebaseFile: String;
    relationalId: String;
    relationalType: String;
    fileType: String;
    idCreator: String;
    createdDate: String;
    lastUpdateDate: String;

    public constructor() {
        this.id = '';
        this.fileName = '';
        this.downloadUrl = '';
        this.pathFirebaseFile = '';
        this.relationalId = '';
        this.relationalType = '';
        this.idCreator = '';
        this.createdDate = '';
        this.lastUpdateDate = '';
    }

}
