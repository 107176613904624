import { HousingUnitModel } from 'models/entities/housing-unit-model';
import { ProfileModel } from 'models/entities/profile-model';
import { UserModel } from 'models/entities/user-model';
export class UserProfileHousingUnitModel {
    id: string;
    idUser: UserModel;
    idProfile: ProfileModel;
    idHousingUnit: HousingUnitModel;

    public constructor() {
        this.id = '';
        this.idUser = new UserModel();
        this.idProfile = new ProfileModel();
        this.idHousingUnit = new HousingUnitModel();

    }
}
