import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileRoutingModule } from './profile-routing.module';
import { CreateProfileComponent } from './create-profile/create-profile.component';
import { ListProfileComponent } from './list-profile/list-profile.component';
import { RoleAuthGuard } from 'app/shared/auth/roleAuth-guard.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DetailProfileComponent } from './detail-profile/detail-profile.component';
import { UpdateProfileComponent } from './update-profile/update-profile.component';
import { ToPairUserComponent } from './to-pair-user/to-pair-user.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  imports: [
    CommonModule,
    ProfileRoutingModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule
  ],
  declarations: [CreateProfileComponent, ListProfileComponent, DetailProfileComponent, UpdateProfileComponent, ToPairUserComponent],
  providers: [RoleAuthGuard],
})
export class ProfileModule { }
