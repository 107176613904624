import { QuestionResponseModel } from "./../../../models/entities/questio-mode-response";
import { element } from "protractor";
import { NgForm } from "@angular/forms";
import { take } from "rxjs/operators";
import { GenericResponseModel } from "./../../../models/utilities/generic.response.model";
import { QuestionOptionModel } from "./../../../models/entities/question-option-model";
import { TokenStorageService } from "./../../shared/storage-services/token-storage.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { InfoMessagesService } from "./../../shared/messages/info-messages.service";
import { CrudServiceService } from "./../../shared/backend/cruds/crud-service.service";
import { QuestionModel } from "./../../../models/entities/question-model";
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "app-create-question",
  templateUrl: "./create-question.component.html",
  styleUrls: ["./create-question.component.scss"],
})
export class CreateQuestionComponent implements OnInit {
  questionOptionList: Array<QuestionOptionModel> = new Array();
  newQuestion: QuestionResponseModel = new QuestionResponseModel();
  questionOptionInfo: QuestionOptionModel = new QuestionOptionModel();
  @Output() passEntry: EventEmitter<QuestionResponseModel> = new EventEmitter();
  @ViewChild("q") questionOptionForm: NgForm;

  constructor(
    public crudServices: CrudServiceService,
    public messageService: InfoMessagesService,
    public activeModal: NgbActiveModal,
    public tokenStorage: TokenStorageService
  ) {}

  ngOnInit() {
    //alert(this.newQuestion.question.multipleRespuesta);
  }

  onSubmit() {
    if (
      this.newQuestion.listQuestionOption.length < 2 &&
      this.newQuestion.question.multipleRespuesta == false
    ) {
      this.messageService.getInfoMessagePersonalized(
        "warning",
        "La cantidad de respuestas de una pregunta de única respuesta debe ser mayor que 1",
        "Atención!"
      );
      return;
    }
    if (
      this.newQuestion.question.maxRespuestaMultiple <= 1 &&
      this.newQuestion.question.multipleRespuesta == true
    ) {
      this.messageService.getInfoMessagePersonalized(
        "warning",
        "La cantidad máxima de opciones a seleccionar debe ser mayor que 1",
        "Atención!"
      );
      return;
    }
    if (
      this.newQuestion.listQuestionOption.length < 2 &&
      this.newQuestion.question.multipleRespuesta == true
    ) {
      this.messageService.getInfoMessagePersonalized(
        "warning",
        "La cantidad de respuestas de una pregunta múltiple debe ser mayor que 1",
        "Atención!"
      );
      return;
    }

    this.passEntry.emit(this.newQuestion);
    this.activeModal.close();
  }

  saveQuestionList() {
    this.newQuestion.listQuestionOption.push(this.questionOptionInfo);
    this.questionOptionInfo = new QuestionOptionModel();
    this.questionOptionForm.form.markAsPristine();
    this.questionOptionForm.form.markAsUntouched();
    this.questionOptionForm.form.updateValueAndValidity();
  }

  deleteQuestionOptionList(index) {
    this.newQuestion.listQuestionOption.splice(index, 1);
  }

  // saveOptions(id) {
  //   this.questionOptionList.forEach(options => {
  //     options.question.id = id;
  //   });
  //   this.crudServices.createModel('api/preguntas/guardar-opciones', this.questionOptionList).subscribe(
  //     (genericResponse: GenericResponseModel) => {
  //       if (genericResponse.code === 200) {
  //         this.messageService.getInfoMessageCreate().then()
  //         this.passEntry.emit('creado')
  //         this.activeModal.close()
  //       }
  //       if (genericResponse.code === 400) {
  //         this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Problema creando opción de pregunta')
  //       }
  //     },
  //     error => {
  //       console.error(error);
  //       this.messageService.getInfoMessageError();
  //     }
  //   );
  // }
}
