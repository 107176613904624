import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'environments/environment.prod';
import { PetModel } from '../../../models/entities/pet-model';

@Component({
  selector: 'app-detail-pet',
  templateUrl: './detail-pet.component.html',
  styleUrls: ['./detail-pet.component.scss']
})
export class DetailPetComponent implements OnInit {

  @Input() petsInfo: PetModel;

  ownerPet: any;
  coownershipPet: any;
  finalOwner: any;
  
  urlPeticionImg = environment.serverUrl + 'api/archivos/ver-img?ruta=';


  constructor(
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.ownerPet = this.petsInfo.coOwnerShip.ownerId.completeName;
    this.coownershipPet = this.petsInfo.coOwnerShip.number;
    this.finalOwner = this.ownerPet + ' - ' + this.coownershipPet;
  }

  closeModal() {
    this.modalService.dismissAll();
  }

}
