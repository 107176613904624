import {MessagingServicesService} from './shared/messaging/messaging-services.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  subscription: Subscription;

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private router: Router,
    private messagingService: MessagingServicesService
  ) {
    const userId = new Date().getTime();
    this.messagingService.requestPermission(userId);
    this.messagingService.receiveMessage();
    //console.log(this.messagingService.currentMessage);
  }

  ngOnInit() {
    this.titleService.setTitle('Quartier, Administra conjuntos residenciales');
    this.metaTagService.addTags([
      {name: 'author', content: 'Quartier app'},
      {
        name: 'keywords',
        content:
          'Administrar unidades residenciales, Conexión, portería, propietarios, arrendatarios, Reuniones, Comunidad, comunicación , apartamentos, conjuntos',
      },
      {
        name: 'description',
        content:
          'Plataforma dinámica donde estarás enterado de toda la información de tu propiedad, diseñada para administradores y arrendatarios; podrás comunicarte con la portería, la administración, autorizar el ingreso de visitantes entre otras actividades para prestar un mejor servicio. Descubre la evolución de las copropiedades.',
      },
      {charset: 'UTF-8'},
    ]);

    this.subscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => window.scrollTo(0, 0));
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
