import { JoinModel } from "models/types/join-model";
import { DocumentTypeModel } from "./document-type-model";
import { PlansModel } from "./plans-model";
export class UserModel {
  id: string;
  completeName: string;
  name: string;
  lastName: string;
  username: string;
  email: string;
  documentNumber: string;
  birthday: string;
  address: string;
  phone: string;
  cellphone: string;
  pictureProfile: any;
  role: string;
  gender: string;
  documentTypeId: DocumentTypeModel;
  longitude: string;
  latitude: string;
  activatedPlanId: PlansModel;
  creatorId: UserModel;
  createdDate: string;
  lastUpdateDate: string;
  tokenWeb: string;
  tokenMovil: string;
  isLegalRepresent: boolean;
  reminderBirthday: string;
  firstLogin: boolean;
  nationality: string;
  countryBirth: string;
  eps: string;

  housingId: string;
  signatureUrl: string;

  constructor() {
    this.id = null;
    this.completeName = "";
    this.name = "";
    this.lastName = "";
    this.username = "";
    this.email = "";
    this.documentNumber = "";
    this.birthday = "";
    this.address = "";
    this.phone = "";
    this.cellphone = "";
    this.pictureProfile = "";
    this.role = "";
    this.gender = "";
    this.documentTypeId = new DocumentTypeModel();
    this.longitude = "";
    this.latitude = "";
    this.activatedPlanId = null;
    this.creatorId = null;
    this.createdDate = new Date().toUTCString();
    this.lastUpdateDate = new Date().toUTCString();
    this.tokenWeb = "";
    this.tokenMovil = "";
    this.isLegalRepresent = true;
    this.reminderBirthday = "true";
    this.firstLogin = false;
    this.housingId = "";
    this.nationality = "";
    this.countryBirth = "";
    this.eps = "";
  }
}
