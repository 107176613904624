import { CoownershipModel } from './coowner-ship-model';
import { TowerModel } from './tower-model';

export class StorageModel {
  id: string;
  number: string;
  area: string;
  percentage: string;
  coownerShipId: CoownershipModel;
  towerBlockId: TowerModel;

  public constructor() {
    this.id = '';
    this.number = '';
    this.area = '';
    this.percentage = '';
    this.coownerShipId = new CoownershipModel();
    this.towerBlockId = new TowerModel();
  }
}
