import { FirebaseStorageService } from './../../shared/firebase/firebase-storage.service';
import { TokenStorageService } from './../../shared/storage-services/token-storage.service';
import { UserModel } from './../../../models/entities/user-model';
import { GenericResponseModel } from './../../../models/utilities/generic.response.model';
import { TaskTypeModel } from './../../../models/entities/task-type';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoMessagesService } from './../../shared/messages/info-messages.service';
import { CrudServiceService } from './../../shared/backend/cruds/crud-service.service';
import { TaskModel } from './../../../models/entities/task';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import swal from 'sweetalert2';

@Component({
  selector: 'app-update-task',
  templateUrl: './update-task.component.html',
  styleUrls: ['./update-task.component.scss']
})
export class UpdateTaskComponent implements OnInit {

  listTaskType: Array<TaskTypeModel> = new Array();
  @Input() public taskInfo: TaskModel;
  listEmployees: Array<UserModel> = new Array();
  errorMessage: String;
  currentFileUpload: File;
  url: any;
  downloadUrl = ' ';
  selectedExtension = '';
  typesRestrictions = ['jpg', 'PNG', 'png', 'JPGE','doc', 'docx', 'pdf'];

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  constructor(
    public crudServices: CrudServiceService,
    public messageService: InfoMessagesService,
    public modalService: NgbModal,
    public tokenStorage: TokenStorageService,
    private firebaseStorageService: FirebaseStorageService,
  ) { }


  ngOnInit() {
    this.getTaskTypes()
    this.getEmployees()
  }
  getTaskTypes() {
    const pathListDOcumentTypesUrl = 'api/tarea/obtener-tipos';
    this.listTaskType = new Array();
    this.crudServices.getModel(pathListDOcumentTypesUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.listTaskType = genericResponse.answerList;
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los tipos de tareas del sistema',
            'Problema consultando los tipos de tareas');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar los tipos de tareas' + JSON.stringify(error))
      }
    );
  }

  getEmployees() {
    this.crudServices.getModel('api/usuarios/obtener-colaborador-empleado?idHousingUnit=' + this.tokenStorage.getCompanyId()).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.listEmployees = genericResponse.answerList;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se encontraron empleados')
        }
      }, error => {
        this.errorMessage = `${error.status}: ${error.error.message}`;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  closeWindow() {
    this.modalService.dismissAll();
  }

  udpateTask() {
    this.crudServices.createModel('api/tarea/guardar', this.taskInfo).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageCreate();
          this.passEntry.emit('creado');
          this.closeWindow();
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Problema actualizando la tarea')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageError();
        this.errorMessage = error.error.answer;
      }
    );
  }

  selectFile(event) {
    if (this.typesRestrictions.includes(event.target.files.item(0).name.split('.').pop())) {
      this.currentFileUpload = event.target.files.item(0);
      this.selectedExtension = event.target.files.item(0).name.split('.').pop()

    } else {
      this.currentFileUpload = undefined;
      swal({
        title: 'El tipo no es correcto', type: 'error',
        text: 'El archivo debe tener formato jpg, PNG, png ó JPEG, jpeg'
      })
    }
  }


  upload(taskImage : TaskModel) {
    // tslint:disable-next-line: max-line-length
    this.firebaseStorageService.uploadFile('images/' + 'task/' + taskImage.id, 'image.' + this.selectedExtension, this.currentFileUpload).then(
      (responseFirebase) => {
        this.firebaseStorageService.loadFile('images/' + 'task/' + taskImage.id, 'image.' + this.selectedExtension).then(
          (dowloadURL) => {
          }
        ).catch(error => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        })
      }, (error) => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    )
  }

  readUrl(inputValue: any): void {
    const img = new Image();
    img.src = window.URL.createObjectURL(inputValue.target.files.item(0));
    img.onload = () => {
      window.URL.revokeObjectURL(img.src);
      if (img.naturalWidth <= 2000 && img.naturalHeight <= 2000) {
        this.currentFileUpload = inputValue.target.files[0];
        var myReader: FileReader = new FileReader();
        myReader.onloadend = (e) => {
          this.url = myReader.result;

        }
        myReader.readAsDataURL(this.currentFileUpload);
      } else{
        // tslint:disable-next-line: max-line-length
        this.messageService.getInfoMessagePersonalized('warning', 'El tamaño de la imagen debe ser menor o igual a 200 x 200 px','Imagen fuera de proporciones');

      }
    }
      }
}
