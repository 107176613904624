import { CoownershipModel } from './coowner-ship-model';
import { HousingUnitModel } from './housing-unit-model';
import { UserModel } from './user-model';
import { DocumentTypeModel } from './document-type-model';
export class DoormanVisitorModel {
  id: string;
  arrivalDate: string;
  incomeTime: string;
  receivedBy: string;
  dispatchedBy: string;
  completeName: string;
  documentTypeId: DocumentTypeModel;
  document: string;
  vehicle: string;
  vehiclePlaque: string;
  accompanists: string;
  status: string;
  departureDate: string;
  coOwnerShipId: CoownershipModel;
  housingUnitId: HousingUnitModel;
  outcomeTime: string;

  public constructor() {
    this.id = null;
    this.arrivalDate = '';
    this.incomeTime = '';
    this.receivedBy = '';
    this.dispatchedBy = '';
    this.completeName = '';
    this.documentTypeId = new DocumentTypeModel;
    this.document = '';
    this.vehicle = '';
    this.vehiclePlaque = '';
    this.accompanists = '';
    this.status = '';
    this.departureDate = '';
    this.coOwnerShipId = new CoownershipModel();
    this.housingUnitId = new HousingUnitModel();
    this.outcomeTime = '';
  }
}
