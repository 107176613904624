export class PairModulePlanModel {
    id: string;
    planId: any;
    moduleId: any;
    createdDate: string;

    public constructor() {
        this.id = '';
        this.planId = '';
        this.moduleId = '-1';
        this.createdDate = '';
    }
}
