import { CrudServiceService } from './../../shared/backend/cruds/crud-service.service';
import { PlansModel } from './../../../models/entities/plans-model';
import { DetailPlanComponent } from './../detail-plan/detail-plan.component';
import { CreatePlanComponent } from './../create-plan/create-plan.component';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/shared/auth/auth.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { UpdatePlanComponent } from '../update-plan/update-plan.component';
import swal from 'sweetalert2';
import { ToPairModuleComponent } from '../to-pair-module/to-pair-module.component';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';

@Component({
  selector: 'app-list-plan',
  templateUrl: './list-plan.component.html',
  styleUrls: ['./list-plan.component.scss']
})
export class ListPlanComponent implements OnInit {

  rows = [];
  temp: string[];
  board: string;
  errorMessage: string;
  dato: any;
  button = [];
  datosRecibidos: any;
  eliminadoHijo: boolean;
  

  // Table Column Titles
  columns = [
    { prop: 'name' },
    { name: 'createdDate' }
  ];

  constructor(
    private modalService: NgbModal,
    private crudServices: CrudServiceService,
    private messageService: InfoMessagesService,
    ) { }

  ngOnInit() {
    this.configPage();
  }

  recibirDatos(datos: any) {
    this.datosRecibidos = datos;
    
    if (this.datosRecibidos === 'Plan Creado') {
      this.configPage();
    } else {
      return false;
    }
  }

  openPlans() {
    const modalRef = this.modalService.open(CreatePlanComponent, {
      windowClass: 'modal', size: 'lg'
    });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      if (receivedEntry === 'Plan Creado') {
        this.configPage();
      } else {
        return false;
      }
    })
  }

  configPage() {
    this.crudServices.getModel('api/plan/listar-todas').subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.dato = genericResponse.answerList;
          this.temp = {...this.dato};
          this.rows = this.dato;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin planes')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
        this.errorMessage = `${error.status}: ${error.error.message}`;
      }
    );
  }

  listPlanDetail(id) {
    this.crudServices.getModel('api/plan/ver-plan?planId=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          const modalRef = this.modalService.open(DetailPlanComponent, {
            windowClass: '', size: 'lg',  backdrop: 'static'
          });
          modalRef.componentInstance.planInfo = genericResponse.genericObject;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se encontro el plan')
        }
      },
      error => {
        this.errorMessage = `${error.status}: ${error.error.message}`;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  onUpdatePlans(id) {
    this.crudServices.getModel('api/plan/ver-plan?planId=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          const modalRef = this.modalService.open(UpdatePlanComponent, {
            windowClass: '', size: 'lg',  backdrop: 'static'
          });
          modalRef.componentInstance.planInfoUpdate = genericResponse.genericObject;
          modalRef.componentInstance.passEntry.subscribe((planUpdated) => {
            this.crudServices.createModel('api/plan/actualizar', planUpdated).subscribe(
              genericUpdateResponse => {
                if (genericUpdateResponse.code === 200) {
                  this.messageService.getInfoMessageCreate().then(
                    (result) => {
                      this.configPage();
                      this.modalService.dismissAll();
                    }
                  )
                }
                if (genericUpdateResponse.code === 400) {
                  this.messageService.getInfoMessagePersonalized('warning', genericUpdateResponse.answer, 'No se pudo actualizar el plan')
                }
              },
              error => {
                this.errorMessage = `${error.status}: ${error.error.message}`;
                console.error(error);
                this.messageService.getInfoMessageBadInternet();
              }
            );
          })
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se encontro el plan')
        }
      },
      error => {
        this.errorMessage = `${error.status}: ${error.error.message}`;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  onDelete(id) {
    this.crudServices.deleteModel('api/plan/eliminar?planId=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.configPage();
          this.messageService.getInfoMessageDelete();
          this.eliminadoHijo = true;
        }
        else if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se elimino el plan')
          this.eliminadoHijo = false;
        } 
      },
      error => {
        this.errorMessage = `${error.status}: ${error.error.message}`;
        console.error(error);
        this.messageService.getInfoMessagePersonalized('error', 'El Plan se encuentra asociado en el sistema ',
        'Ups, tenemos un problema.2');
        this.eliminadoHijo = false;
      }
    );
    this.eliminadoHijo = false;
  }

  // Eliminar un módulo
  deleteWarning(id) {
    event.preventDefault(); // prevent form submit
    swal({
      title: '¿Está seguro que desea eliminar el plan?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.onDelete(id);
      }
    })
  }

  toPairModule(id) {
    this.crudServices.getModel('api/plan/ver-plan?planId=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          const modalRef = this.modalService.open(ToPairModuleComponent, {
            windowClass: '', size: 'lg',  backdrop: 'static'
          });
          modalRef.componentInstance.planInfoUpdate = genericResponse.genericObject;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se encontro el plan')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

}
