import { GenericResponseModel } from "./../../../models/utilities/generic.response.model";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { CoownershipModel } from "./../../../models/entities/coowner-ship-model";
import { CrudServiceService } from "./../../shared/backend/cruds/crud-service.service";
import { ChangePasswordByComponent } from "./../change-password-by/change-password-by.component";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { NgForm, FormGroup, FormControl, FormBuilder } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ChangePasswordComponent } from "../change-password/change-password.component";
import { InfoMessagesService } from "app/shared/messages/info-messages.service";
import { DocumentTypeModel } from "models/entities/document-type-model";
import { TokenStorageService } from "app/shared/storage-services/token-storage.service";
import { changePasswordModel } from "models/entities/change-password.model";
import { environment } from "environments/environment.prod";
import { UserModel } from "models/entities/user-model";
import { HandleServicesService } from "app/shared/handle-service/handle-services.service";

@Component({
  selector: "app-update-user",
  templateUrl: "./update-user.component.html",
  styleUrls: ["./update-user.component.scss"],
})
export class UpdateUserComponent implements OnInit {
  form: any = {};
  public planInfo: [];
  user: any;
  role: any;
  errorMessage = "";
  dato: any;
  coownerShip: Array<CoownershipModel> = new Array();
  rows: any[];
  temp: any[];
  public selected: any;
  @Input() public userInfoUpdate;
  userInfoAux: UserModel = new UserModel();
  @Input() public isView = false;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Output() fiPhoto: EventEmitter<any> = new EventEmitter();
  @ViewChild("f") updateUserForm: NgForm;
  @ViewChild("ft") registerFormTwo: NgForm;
  listDocumentTypes: Array<DocumentTypeModel> = new Array();

  //FILE AWS
  filePhoto: any = null;
  filePhotoRender: any = "";

  urlPeticionImg = environment.serverUrl + "api/archivos/ver-img?ruta=";

  imageStatic: any = "https://cdn-icons-png.flaticon.com/512/2704/2704398.png";

  // change password
  passwordModel = new changePasswordModel();
  passwordValidationError = "";
  isErrorPassword = false;
  oldPassword: any;
  newPassword: any;
  passwordConfirmation: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    private modalService: NgbModal,
    private tokenStorage: TokenStorageService,
    private userForm: FormBuilder,
    private handleService: HandleServicesService
  ) {}

  ngOnInit() {
    console.log(this.userInfoUpdate);
    this.role = this.tokenStorage.getAuthorities();
    this.getDocumentsTypes();
    if (this.tokenStorage.getAuthorities() === "ROLE_SUPER") {
      this.configSelectPlan();
    }
    this.user = this.userForm.group({
      id: this.userInfoUpdate.userAux.id,
      completeName: this.userInfoUpdate.userAux.completeName,
      email: this.userInfoUpdate.userAux.email,
      username: this.userInfoUpdate.userAux.username,
      address: this.userInfoUpdate.userAux.address,
      newRole: this.userInfoUpdate.userAux.role,
      phone: this.userInfoUpdate.userAux.phone,
      cellphone: this.userInfoUpdate.userAux.cellphone,
      documentTypeId: this.userForm.group({
        id: this.userInfoUpdate.userAux.documentTypeId.id,
      }),
      documentNumber: this.userInfoUpdate.userAux.documentNumber,
      nameCompany: this.userInfoUpdate.nameCompany,
      activatedPlanId: this.userInfoUpdate.idActivatedPlan,
    });
    this.listCoownerShipUser(this.userInfoUpdate.userAux.id);
  }

  // public loadPhoto(event, files): void {
  //   this.filePhoto = event.target.files[0];
  //   const reader = new FileReader();
  //   reader.readAsDataURL(event.target.files[0]);

  //   reader.onload = (_event) => {
  //     this.filePhotoRender = reader.result;
  //   };
  // }

  // public uploadPhoto(modelo: UserModel): void {
  //   console.log("TENGO QUE SUBIR LA FOTO" + modelo.id);
  //   const folderPath = "admin " + modelo.id;
  //   var data = new FormData();
  //   data.append("archivo", this.filePhoto);
  //   let extension = this.filePhoto.name.split(".").pop();

  //   let path =
  //     "api/archivos/cargar-firma-admin?folderPath=" +
  //     folderPath +
  //     "&relationalId=" +
  //     modelo.id +
  //     "&extension=" +
  //     extension;
  //   this.crudService
  //     .createFile(path, data)
  //     .toPromise()
  //     .then((genericResponse) => {
  //       console.log(genericResponse);
  //       console.log("----");
  //       if (genericResponse.code === 200) {
  //         this.messageService.getInfoMessageCreate().then((result) => {
  //           this.modalService.dismissAll();
  //         });
  //       } else {
  //         this.messageService.getInfoMessagePersonalized(
  //           "warning",
  //           "Se creó el usuario correctamente pero verifique la foto por favor",
  //           "Atención!"
  //         );
  //       }
  //     })
  //     .catch((error) => {
  //       this.handleService.handleError(error);
  //     });
  // }

  getDocumentsTypes() {
    const pathListDOcumentTypesUrl =
      "api/tipos-de-documento/ver-por-objetivo?objective=Personal";
    this.listDocumentTypes = new Array();
    this.crudService.getModel(pathListDOcumentTypesUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          const listDocumentTypesAux = genericResponse.answerList;
          listDocumentTypesAux.forEach((documentType) => {
            this.listDocumentTypes.push(documentType);
          });
          this.user.documentTypeId = this.listDocumentTypes[0];
        } else {
          this.messageService.getInfoMessagePersonalized(
            "warning",
            "No se pudieron listar los tipos de documentos del sistema",
            "Problema consultando los tipos de documentos del sistema"
          );
        }
      },
      (error) => {
        this.messageService.getInfoMessageError();
        console.error(
          "Error al cargar los tipos de documentos del sistema " +
            JSON.stringify(error)
        );
      }
    );
  }

  passBack() {
    const formValue = this.user.value;
    // console.log("TENGO " + this.userInfoUpdate.userAux.id);
    //this.uploadPhoto(this.userInfoUpdate.userAux);
    this.passEntry.emit(formValue);
  }

  configSelectPlan() {
    this.crudService.getModel("api/plan/listar-todas").subscribe(
      (genericResponse) => {
        if (genericResponse.code === 200) {
          this.planInfo = genericResponse.answerList;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized(
            "warning",
            genericResponse.answer,
            "No se encontraron planes"
          );
        }
      },
      (error) => {
        this.errorMessage = error.error.message;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  passwordValidation() {
    if (this.newPassword !== this.passwordConfirmation) {
      this.passwordValidationError = "¡Las contraseñas no coinciden!";
      this.isErrorPassword = true;
      return;
    } else if (this.newPassword === this.passwordConfirmation) {
      this.passwordValidationError = "¡Las contraseñas no coinciden!";
      this.isErrorPassword = false;
      return;
    }
  }

  changePassword() {
    this.passwordModel.id = this.userInfoUpdate.userAux.userId.id;
    this.passwordModel.newPassword = this.newPassword;
    console.log(this.passwordModel);
    this.passwordValidation();
    this.crudService
      .createModel(
        "api/usuarios/actualizar-contrasena-user",
        this.passwordModel
      )
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            console.log(genericResponse);
            console.log("contraseña actualizada");
            this.messageService.getInfoMessageUpdate().then((response) => {
              console.log(response);
            });
          } else if (genericResponse.code === 201) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Actualizacion fallida"
            );
          } else if (genericResponse.code === 202) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Actualizacion fallida"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
          this.errorMessage = JSON.stringify(error.error.mensaje);
        }
      );
  }

  // changePassword() {
  //   const modalRef = this.modalService.open(
  //     ChangePasswordByComponent, {
  //     windowClass: '', size: 'lg', backdrop: 'static'
  //   }
  //   );
  //   modalRef.componentInstance.userInfoUpdate = this.userInfoUpdate;
  //   modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
  //     if (receivedEntry === 'creado') {
  //     } else {
  //       return false;
  //     }
  //   })
  // }

  closeWindow() {
    this.modalService.dismissAll();
  }

  listCoownerShipUser(id) {
    this.crudService
      .getModel("api/coownership/listar-mis-copropiedades?id=" + id)
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.coownerShip = genericResponse.answerList;
            this.temp = { ...this.dato };
            console.log(this.coownerShip);
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No hay menús emparejados"
            );
          }
        },
        (error) => {
          this.errorMessage = error.error.message;
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  onDetail(id) {
    this.router.navigate(["/copropiedad/detalle-copropiedad", { id }], {
      relativeTo: this.route.parent,
    });
    this.modalService.dismissAll();
  }
}
