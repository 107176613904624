import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LogCargaMasivaErroresPropietarios } from '../../../models/utilities/errores-carga-masiva';

import * as xls from 'xlsx';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-log-errores',
  templateUrl: './log-errores.component.html',
  styleUrls: ['./log-errores.component.scss']
})
export class LogErroresComponent implements OnInit {
  
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() public log: Array<LogCargaMasivaErroresPropietarios> = new Array();

  @Input() public propietario;
  
  EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  constructor(
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
  }

  exportarExcel() : void {
    let element = document.getElementById('logErroresId'); 

    const worksheet: xls.WorkSheet = xls.utils.table_to_sheet(element);
    const workbook: xls.WorkBook = xls.utils.book_new();
    xls.utils.book_append_sheet(workbook, worksheet, 'Hoja1');
    const excelBuffer: any = xls.write(workbook, { bookType: 'xlsx', type: 'array' });

    this.generarArchivo(excelBuffer, 'LogErroresCargaMasiva');
  }

  private generarArchivo(buffer: any, fileName: string) : void {
      const data: Blob = new Blob([buffer], { type: this.EXCEL_TYPE });
      FileSaver.saveAs(data, fileName + '.xlsx');
  }

  cerrarModal() {
    this.passEntry.emit(0);
  }

}