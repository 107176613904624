export class EquipoAuxModel{
    nombreEquipo: string;
	ubicacionCopropiedad: string;
	ubicacionTorreBloque: string;
	programacionUltimoMantenimiento: string;
	programacionFechaInstalacion: string;
	tiempoMantenimiento: string;
	cantidadTiempoMantenimiento: string;
	tipo_mantenimiento: string;
	porcentaje: string;
  
    public constructor(){
      this.nombreEquipo = '';
      this.ubicacionCopropiedad = '';
      this.ubicacionTorreBloque = '';
      this.programacionUltimoMantenimiento = '';
      this.programacionFechaInstalacion = '';
      this.tiempoMantenimiento = '';
      this.cantidadTiempoMantenimiento = '';
      this.tipo_mantenimiento = '';
      this.porcentaje = '';
    }
  }
  