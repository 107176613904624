import { CalendarsModule } from 'app/calendar/calendar.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InvoiceRoutingModule } from './invoice-routing.module';
import { ListInvoiceComponent } from './list-invoice/list-invoice.component';
import { CreateInvoiceComponent } from './create-invoice/create-invoice.component';
import { CreateMassiveInvoiceComponent } from './create-massive-invoice/create-massive-invoice.component';
import { DetailInvoiceComponent } from './detail-invoice/detail-invoice.component';
import { UpdateInvoiceComponent } from './update-invoice/update-invoice.component';
import { ListInvoiceOwnerComponent } from './list-invoice-owner/list-invoice-owner.component';

@NgModule({
  declarations: [ListInvoiceComponent, CreateInvoiceComponent,
     CreateMassiveInvoiceComponent, DetailInvoiceComponent, UpdateInvoiceComponent,
    ListInvoiceOwnerComponent],
  imports: [
    CommonModule,
    InvoiceRoutingModule,
    FormsModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    CalendarsModule
  ],
  exports: [
    DetailInvoiceComponent
  ]
})
export class InvoiceModule { }
