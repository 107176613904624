import { Component, OnInit } from '@angular/core';
import { DoormanVisitorModel } from '../../../models/entities/doorman-visitor-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { CreateDoormanVisitorComponent } from '../create-doorman-visitor/create-doorman-visitor.component';
import { UpdateDoormanVisitorComponent } from '../update-doorman-visitor/update-doorman-visitor.component';
import { DetailDoormanVisitorComponent } from '../detail-doorman-visitor/detail-doorman-visitor.component';
import { FilterDoormanVisitorModel } from '../../../models/entities/filter-doorman-visitor-model';
import { GenericResponseModel } from '../../../models/utilities/generic.response.model';

@Component({
  selector: 'app-list-doorman-visitor',
  templateUrl: './list-doorman-visitor.component.html',
  styleUrls: ['./list-doorman-visitor.component.scss']
})
export class ListDoormanVisitorComponent implements OnInit {

  visitorList: Array<DoormanVisitorModel> = new Array();
  rows = [];
  temp: string[];
  dato: any;
  infovisitor = new DoormanVisitorModel();

  visitorInfo: DoormanVisitorModel = new DoormanVisitorModel();
  paginator: any;

  filter: FilterDoormanVisitorModel = new FilterDoormanVisitorModel();
  isFilter: Boolean = false;

  rowsCoownerShip = [];
  tempCoownerShip: string[];
  datoCoownerShip: any;

  constructor(
    private modalService: NgbModal, private crudServices: CrudServiceService,
    private messageService: InfoMessagesService, private tokenStorage: TokenStorageService,
  ) { }

  ngOnInit() {
    this.configPage();
    //this.configPaginator()
    this.listOwnersAndCoowners();
    //this.listCoownerShip();
  }

  configPage() {
    this.crudServices.getModel('api/porteria/ver-mis-visitas?id=' + this.tokenStorage.getCompanyId()).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.dato = genericResponse.answerList;
          this.temp = { ...this.dato };
          this.rows = this.dato;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin visitas')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  configPaginationPage(page: number) {
    this.crudServices.getModel('api/porteria/ver-mis-visitas/page/'+page+'?id=' + this.tokenStorage.getCompanyId())
    .subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.dato = genericResponse.genericObject.content;
          this.temp = { ...this.dato };
          this.rows = this.dato;
          this.paginator =  genericResponse.genericObject;
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  configPaginator(){
    let page: number;

    if ( !page ) {
      page = 0;
    }
    this.configPage();
    //this.configPaginationPage(page);
}

  changePage(newPage){
    if ( !this.isFilter ) {
      //this.configPaginationPage(newPage);
      this.configPage();
    } else {
      this.onFilter(newPage);
    }
  }

  openCreateVisitor() {
    const modalRef = this.modalService.open(CreateDoormanVisitorComponent, {
      windowClass: '', size: 'lg', backdrop: 'static',
    });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
      if (receivedEntry === 'creado') {
        //this.configPaginator();
        this.configPage();
      } else {
        return false;
      }
    })
  }

  detailVisitor(id) {
    this.crudServices.getModel('api/porteria/buscar-visita?id=' + id).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.infovisitor = genericResponse.genericObject
          const modalRef = this.modalService.open(
            DetailDoormanVisitorComponent, {
              windowClass: '', size: 'lg', backdrop: 'static',
            }
          );
          modalRef.componentInstance.visitorInfo = this.infovisitor;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error buscando el modulo')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  updateVisitor(id) {
    this.crudServices.getModel('api/porteria/buscar-visita?id=' + id).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.infovisitor = genericResponse.genericObject
          const modalRef = this.modalService.open(
            UpdateDoormanVisitorComponent, {
              windowClass: '', size: 'lg', backdrop: 'static'
            }
          );
          modalRef.componentInstance.visitorInfo = this.infovisitor
          modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
            if (receivedEntry === 'creado') {
              //this.configPaginator();
              this.configPage();
            } else {
              return false;
            }
          })
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error buscando el área social')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  listCoownerShip() {
    {
      this.crudServices.getModel('api/coownership/listar-todo-admin?id=' + this.tokenStorage.getCompanyId()).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.datoCoownerShip = genericResponse.answerList;
            this.tempCoownerShip = { ...this.datoCoownerShip };
            this.rowsCoownerShip = this.datoCoownerShip;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin copropiedades')
          }
        },
        error => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
    }
  }

  listOwnersAndCoowners() {
    this.crudServices
      .getModel(
        "api/coownership/listar-todo-propietarios-copropietarios-por-unidad-residencial?housingId=" +
          this.tokenStorage.getCompanyId()
      )
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.datoCoownerShip = genericResponse.answerList;
            this.tempCoownerShip = { ...this.datoCoownerShip };
            this.rowsCoownerShip = this.datoCoownerShip;
            console.log(this.rowsCoownerShip);
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No hay propietarios ni copropietarios registrados"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  // Search Filters

  onFilter(page?: number) {
    if ( !page ) {
        page = 0;
      }
    if (this.filter.coOwnerShipId === null && this.filter.arrivalDate === '' && this.filter.departureDate === '') {
      this.messageService.getInfoMessagePersonalized('warning', 'Todos los campos no pueden estar vacíos.', ' Error en la búsqueda')
    } else {
      this.isFilter = true;
      // se transforma el id obtenido del token storage para evitar error de datos,ya que del token entra con string y se necesita entero
      let myHousingId = this.tokenStorage.getCompanyId();
      let housingid = Number(myHousingId);
      this.filter.housingId =  housingid;
      console.log(this.filter);
      this.crudServices.getModelModel('api/porteria/visitas-co-propiedad/page/'+page, this.filter).subscribe(
        (genericResponse: GenericResponseModel) => {
          console.log(genericResponse);
          if (genericResponse.code === 200) {
            console.log(genericResponse.genericObject.content);
            console.log(genericResponse.genericObject);
            this.dato = genericResponse.genericObject.content;
            this.temp = { ...this.dato };
            this.rows = this.dato;
            this.paginator =  genericResponse.genericObject;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin correspondencia')
          }
        },
        error => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );

    }
  }

  clean() {
    this.filter = new FilterDoormanVisitorModel();
    this.isFilter = false;
    this.configPage();
    //this.configPaginator();
  }

}
