import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateNewsComponent } from './create-news/create-news.component';
import { ListNewsComponent } from './list-news/list-news.component';
import { DetailNewsComponent } from './detail-news/detail-news.component';
import { AdminNewsComponent } from './admin-news/admin-news.component';
import { CreateAdminNewsComponent } from './create-admin-news/create-admin-news.component';

const routes: Routes = [
  {
    path: 'listar-noticia',
    component: ListNewsComponent,
    data: {
      title: 'list-new',
      expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN']
    }
  },
  {
    path: 'listar-noticia-admin',
    component: AdminNewsComponent,
    data: {
      title: 'list-new-admin',
      expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN']
    }
  },
  {
    path: 'crear-noticia-admin',
    component: CreateAdminNewsComponent,
    data: {
      title: 'create-new-admin',
      expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN']
    }
  },
  {
    path: 'crear-noticia',
    component: CreateNewsComponent,
    data: {
      title: 'create-new',
      expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN']
    }
  },
  {
    path: 'detalle-noticia',
    component: DetailNewsComponent,
    data: {
      title: 'detail-new',
      expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN']
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NewsRoutingModule { }
