import { TowerModel } from "./../../../models/entities/tower-model";
import { CoownershipModel } from "./../../../models/entities/coowner-ship-model";

import { UpdateCoownershipComponent } from "./../update-coownership/update-coownership.component";
import { TokenStorageService } from "app/shared/storage-services/token-storage.service";
import { NgForm } from "@angular/forms";
import * as xlsx from "ts-xlsx";
import * as XLSX from "ts-xlsx";
import * as XLSX2 from "xlsx";
import { filterCoownerShipModel } from "./../../../models/entities/filter-coownership-model";
import { Component, OnInit, ViewChild } from "@angular/core";
import { CrudServiceService } from "app/shared/backend/cruds/crud-service.service";
import { InfoMessagesService } from "app/shared/messages/info-messages.service";
import swal from "sweetalert2";
import { NgbModal, NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { CreateCoownershipComponent } from "../create-coownership/create-coownership.component";
import { Observable, Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  merge,
} from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { CreateTowerBlockComponent } from "app/tower-block/create-tower-block/create-tower-block.component";
import { GenericResponseModel } from "../../../models/utilities/generic.response.model";
import { CreateUserComponent } from "app/usercomponents/create-user/create-user.component";
import { NgxSpinnerService } from "ngx-spinner";
import { LogErroresComponent } from "app/usercomponents/log-errores-carga-masiva/log-errores.component";

enum Field {
  TOWER = "BLOQUE/TORRE",
  BLOCK = "MANZANA",
  NAME = "NOMBRE",
  LAST_NAME = "APELLIDO",
  DOC_TYPE = "TIPO_DOCUMENTO",
  DOC_NUMBER = "NUMERO_DOCUMENTO",
  BIRTHDAY = "FECHA_NACIMIENTO",
  EMAIL = "EMAIL",
  CELLPHONE = "CELULAR",
  CO_OWNERSHIP = "APTO/CASA",
  ROLE = "PERFIL",
  PERSON_NUMBER = "#_PERSONAS_COPROPIEDAD",
  CHILDREN = "#_NIÑOS",
  PETS = "#_MASCOTAS",
  COEFFICIENT = "COEFICIENTE",
  EPS = "EPS",
  EXPEDITION_COUNTRY = "PAIS_EXPEDICION",
  COUNTRY_BIRTH = "PAIS_NACIMIENTO",
  NATIONALITY = "NACIONALIDAD",
}

enum Role {
  OWNER = "Propietario",
  GUEST = "Inquilino",
  ROLE_OWNER = "ROLE_OWNER",
  ROLE_CO_OWNER = "ROLE_COOWNER",
}

interface FieldValue {
  value: string;
  required: boolean;
  field: Field;
}

interface StructureTemplate {
  housingUnitId: string;
  towers: TowerStructure[];
}

interface TowerStructure {
  name: string;
  coOwnerships: CoOwnershipStructure[];
}

interface CoOwnershipStructure {
  name: string;
  coefficient: number;
  personNumber?: number;
  children?: number;
  pets?: number;
  coOwners?: UserStructure[];
  guests?: UserStructure[];
}

interface UserStructure {
  name: string;
  docType: string;
  lastName: string;
  docNumber: string;
  birthday: Date;
  expeditionCountry: string;
  email: string;
  nationality: string;
  countryBirth: string;
  cellphone: string;
  eps: string;
  role?: string;
}

@Component({
  selector: "app-list-coownership",
  templateUrl: "./list-coownership.component.html",
  styleUrls: ["./list-coownership.component.scss"],
})
export class ListCoownershipComponent implements OnInit {
  filter: filterCoownerShipModel = new filterCoownerShipModel();
  fields: {
    tower?: FieldValue;
    block?: FieldValue;
    name?: FieldValue;
    lastName?: FieldValue;
    docType?: FieldValue;
    docNumber?: FieldValue;
    birthday?: FieldValue;
    email?: FieldValue;
    cellphone?: FieldValue;
    coOwnership?: FieldValue;
    role?: FieldValue;
    personNumber?: FieldValue;
    children?: FieldValue;
    pets?: FieldValue;
    coefficient?: FieldValue;
    eps?: FieldValue;
    expeditionCountry?: FieldValue;
    countryBirth?: FieldValue;
    nationality?: FieldValue;
  };
  rows = [];
  data = [];
  temp: string[];
  dato: any;
  listUsers: any = {};
  towerInfo: any = {};
  rowsTower = [];
  tempTower: string[];
  datoTower: any;
  coownerShipInfo: CoownershipModel = new CoownershipModel();
  errorMessage: string;
  focusUser$ = new Subject<string>();
  clickUser$ = new Subject<string>();
  focusUserIn$ = new Subject<string>();
  @ViewChild("p") createParkingForm: NgForm;
  @ViewChild("instanceUser") instanceUser: NgbTypeahead;
  coownerShipMassive: any;
  maxCantidadCopropiedades: number;
  arrayBuffer;
  excel;
  file: File;
  selectedTower: TowerModel = new TowerModel();
  createType = "OWNER";
  idHousingUnit: any = this.tokenStorage.getCompanyId();

  public canShowLoading = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private crudServices: CrudServiceService,
    private messageService: InfoMessagesService,
    private tokenStorage: TokenStorageService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.configPage();
    this.configSelectUsers();
    this.configSelectTowers();
  }

  configPage() {
    // Obtendremos los usuarios a partir del servicio SUPER o ADMIN
    if (this.tokenStorage.getAuthorities() === "ROLE_SUPER") {
      this.crudServices.getModel("api/coownership/listar-todo-super").subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.dato = genericResponse.answerList;
            this.temp = { ...this.dato };
            this.rows = this.dato;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Sin copropiedades"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
    } else {
      this.crudServices
        .getModel(
          "api/coownership/listar-todo-admin?id=" +
            this.tokenStorage.getCompanyId()
        )
        .subscribe(
          (genericResponse) => {
            if (genericResponse.code === 200) {
              this.dato = genericResponse.answerList;
              this.temp = { ...this.dato };
              this.rows = this.dato;
              this.maxCantidadCopropiedades = this.dato.length;
            }
            if (genericResponse.code === 400) {
              this.messageService.getInfoMessagePersonalized(
                "warning",
                genericResponse.answer,
                "Sin copropiedades"
              );
            }
          },
          (error) => {
            console.error(error);
            this.messageService.getInfoMessageBadInternet();
          }
        );
    }
  }

  configPageFilter() {
    // id unidad residencial
    this.filter.unidadResidencialId.id = this.tokenStorage.getCompanyId();

    // Obtendremos los usuarios a partir del servicio SUPER o ADMIN
    if (
      this.filter.idTower === null &&
      this.filter.name === "" &&
      this.filter.number === ""
    ) {
      this.messageService.getInfoMessagePersonalized(
        "warning",
        "Todos los campos no pueden estar vacíos.",
        " Error en la búsqueda"
      );
    } else {
      this.crudServices
        .getModelModel("api/coownership/filtrar-lista", this.filter)
        .subscribe(
          (genericResponse: GenericResponseModel) => {
            if (genericResponse.code === 200) {
              this.dato = genericResponse.answerList;
              this.temp = { ...this.dato };
              this.rows = this.dato;
            }
            if (genericResponse.code === 400) {
              this.messageService.getInfoMessagePersonalized(
                "warning",
                genericResponse.answer,
                "Sin copropiedades"
              );
            }
          },
          (error) => {
            console.error(error);
            this.messageService.getInfoMessageBadInternet();
          }
        );
    }
  }

  deleteWarning(id) {
    event.preventDefault(); // prevent form submit
    swal({
      title: "¿Está seguro que desea eliminar la copropiedad?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "¡Sí, eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        this.onDelete(id);
      }
    });
  }

  onDelete(id) {
    this.crudServices
      .deleteModel("api/coownership/borrar?coOwnershipId=" + id)
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.messageService.getInfoMessageDelete().then(() => {
              this.configPage();
            });
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se eliminó la coporpiedad"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessagePersonalized(
            "error",
            "Se tienen asociados items a la copropiedad porfavor eliminelos primero ",
            "No se eliminó la copropiedad"
          );
          this.errorMessage = `${error.status}: ${error.error.message}`;
        }
      );
  }

  openCreateCoownerShip() {
    var plan: any;
    this.crudServices
      .getModel(
        "api/plan/buscar-por-nombre?name=" + this.tokenStorage.getPlanId()
      )
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            plan = genericResponse.genericObject;
            this.createCoownerShip(plan);
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se puede crear copropiedad"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  createCoownerShip(plan) {
    if (this.maxCantidadCopropiedades >= plan.maxNumCoownerShip) {
      if (plan.planSiguiente == null) {
        this.messageService.getInfoMessagePersonalized(
          "warning",
          "Contacta con tu administrador llamando al numero +57 300 7393720",
          "Has excedido la capacidad máxima de unidades permitidas en tu plan actual"
        );
      } else {
        this.crudServices
          .getModel("api/plan/buscar-por-nombre?name=" + plan.planSiguiente)
          .subscribe(
            (genericResponse) => {
              if (genericResponse.code === 200) {
                const nextPlan = genericResponse.genericObject;
                this.messageService.getInfoUpgradePlan(
                  nextPlan.name,
                  nextPlan.maxNumHousUnit,
                  nextPlan.maxNumCoownerShip,
                  nextPlan.value
                );
              }
              if (genericResponse.code === 400) {
                this.messageService.getInfoMessagePersonalized(
                  "warning",
                  "Contacta con tu administrador llamando al numero +57 300 7393720",
                  "Has excedido la capacidad máxima de unidades permitidas en tu plan actual"
                );
              }
            },
            (error) => {
              console.error(error);
              this.messageService.getInfoMessagePersonalized(
                "warning",
                "Contacta con tu administrador llamando al numero +57 300 7393720",
                "Has excedido la capacidad máxima de unidades permitidas en tu plan actual"
              );
            }
          );
      }
    } else {
      this.coownerShipInfo.housingUnitId.id = this.tokenStorage.getCompanyId();
      const modalRef = this.modalService.open(CreateCoownershipComponent, {
        windowClass: "",
        size: "lg",
        backdrop: "static",
      });
      modalRef.componentInstance.passEntry.subscribe(
        (receivedEntry: string) => {
          if (receivedEntry === "copropiedad_creada") {
            this.configPage();
          } else {
            return false;
          }
        }
      );
    }
  }

  configSelectTowers() {
    this.crudServices
      .getModel(
        "api/torre-bloque/obtener-torres?id=" + this.tokenStorage.getCompanyId()
      )
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.datoTower = genericResponse.answerList;
            this.tempTower = { ...this.datoTower };
            this.rowsTower = this.datoTower;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Sin copropiedades"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  openCreateTower() {
    const modalRef = this.modalService.open(CreateTowerBlockComponent, {
      windowClass: "",
      size: "lg",
      backdrop: "static",
    });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
      if (receivedEntry === "creado") {
        this.configSelectTowers();
      } else {
        return false;
      }
    });
  }

  openRegister() {
    const modalRef = this.modalService.open(CreateUserComponent, {
      windowClass: "modal",
      size: "lg",
      backdrop: "static",
    });
    modalRef.componentInstance.createType = this.createType;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      if (receivedEntry === "Usuario Registrado") {
        this.configPage();
      } else {
        return false;
      }
    });
  }

  formatterUser = (object: { completeName: string }) => object.completeName;

  searchByNameUser = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusUserIn$),
      merge(
        this.clickUser$.pipe(filter(() => !this.instanceUser.isPopupOpen()))
      ),
      map((search) =>
        (search === ""
          ? this.listUsers
          : this.listUsers.filter(
              (user) =>
                user.completeName.toLowerCase().indexOf(search.toLowerCase()) >
                -1
            )
        ).slice(0, 10)
      )
    );

  configSelectUsers() {
    // Obtendremos los usuarios a partir del servicio SUPER o ADMIN
    this.crudServices.getModel("api/usuarios/obtener-lista-usuarios").subscribe(
      (genericResponse) => {
        if (genericResponse.code === 200) {
          this.listUsers = genericResponse.answerList;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized(
            "warning",
            genericResponse.answer,
            "Sin usuarios"
          );
        }
      },
      (error) => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  onDetail(id) {
    this.router.navigate(["/copropiedad/detalle-copropiedad", { id }], {
      relativeTo: this.route.parent,
    });
  }

  openAccountStatus(id) {
    this.tokenStorage.saveIdCoownerShip(id);
    this.router.navigate(
      [
        "/facturas/facturas-propietario",
        { "": this.tokenStorage.getCoownerShipId() },
      ],
      { relativeTo: this.route.parent }
    );
  }

  clean() {
    this.filter = new filterCoownerShipModel();
    this.configPage();
  }

  onFileChange(evt) {
    const file = evt.target.files[0];
    let arrayBuffer: any;
    this.coownerShipMassive = [];
    const fileReader = new FileReader();
    fileReader.onload = () => {
      arrayBuffer = fileReader.result;
      const data = new Uint8Array(arrayBuffer);
      const arr = [];
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join("");
      const wb = xlsx.read(bstr, { type: "binary" });
      const wsName = wb.SheetNames[0];
      const ws = wb.Sheets[wsName];
      const rows = xlsx.utils.sheet_to_json(ws, { header: 1, raw: true });
      for (let i = 1; i < rows.length; i++) {
        const channel = rows[i][0];
        const beginDate = rows[i][1];
        const beginTime = rows[i][2];
        const endDate = rows[i][3];
        const endTime = rows[i][4];
        const url = rows[i][5];
        const grill = { channel, beginDate, beginTime, endDate, endTime, url };

        this.coownerShipMassive.push(grill);
      }
    };
    if (Boolean(file)) {
      fileReader.readAsArrayBuffer(file);
    }
  }

  onUpdateCoownerShip(id) {
    this.crudServices
      .getModel("api/coownership/detalle?coOwnershipId=" + id)
      .subscribe(
        (geenricResponse) => {
          if (geenricResponse.code === 200) {
            this.coownerShipInfo = geenricResponse.genericObject;
            const modalRef = this.modalService.open(
              UpdateCoownershipComponent,
              {
                windowClass: "",
                size: "lg",
                backdrop: "static",
              }
            );
            modalRef.componentInstance.coownershipInfoUpdate =
              this.coownerShipInfo;
            modalRef.componentInstance.passEntry.subscribe(
              (coownerShipToUpdate) => {
                this.crudServices
                  .createModel("api/menu/update", coownerShipToUpdate)
                  .subscribe(
                    (geenricResponseUpdate) => {
                      if (geenricResponseUpdate.code === 200) {
                        swal({
                          title: "Actualización Completa",
                          text:
                            coownerShipToUpdate.name +
                            " se ha actualizado correctamente",
                          type: "success",
                        }).then((result) => {
                          this.configPage();
                          this.modalService.dismissAll();
                        });
                      }
                      if (geenricResponseUpdate.code === 400) {
                        this.messageService.getInfoMessagePersonalized(
                          "warning",
                          geenricResponseUpdate.answer,
                          "Error actualizando el modulo"
                        );
                      }
                    },
                    (error) => {
                      console.error(error);
                      this.messageService.getInfoMessageBadInternet();
                    }
                  );
              }
            );
          }
          if (geenricResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              geenricResponse.answer,
              "Error consultanto el modulo"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  updateCoownerShip(id) {
    this.crudServices
      .getModel("api/coownership/detalle?coOwnershipId=" + id)
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.coownerShipInfo = genericResponse.genericObject;
            const modalRef = this.modalService.open(
              UpdateCoownershipComponent,
              {
                windowClass: "",
                size: "lg",
                backdrop: "static",
              }
            );
            modalRef.componentInstance.coownerShipInfo = this.coownerShipInfo;
            modalRef.componentInstance.passEntry.subscribe(
              (receivedEntry: string) => {
                if (receivedEntry === "creado") {
                  this.configPage();
                } else {
                  return false;
                }
              }
            );
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Error buscando la copropiedad"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  async incomingfile(event) {
    let plan: any;
    this.crudServices
      .getModel(
        "api/plan/buscar-por-nombre?name=" + this.tokenStorage.getPlanId()
      )
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            plan = genericResponse.genericObject;
            this.incomingFile(event, plan);
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se puede crear copropiedad"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  async incomingFile(event, plan) {
    this.file = event.target.files[0];
    const value = await swal({
      title: "Confirmar carga?",
      text: "Este archivo creará nuevos registros!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si , confirmar!",
    }).then((result) => {
      if (result.value) {
        this.canShowLoading = true;
        this.spinner.show(); // SE MUESTRA LA VENTANA DE CARGANDO
        this.createMassive();
      } else {
        $("#uploadFile").val("");
      }
    });
  }

  async validates(value, rowNumber) {
    this.fields = {};
    this.fields = {
      tower: { value: value[Field.TOWER], required: false, field: Field.TOWER },
      block: { value: value[Field.BLOCK], required: false, field: Field.BLOCK },
      name: { value: value[Field.NAME], required: true, field: Field.NAME },
      lastName: {
        value: value[Field.LAST_NAME],
        required: true,
        field: Field.LAST_NAME,
      },
      docType: {
        value: value[Field.DOC_TYPE],
        required: true,
        field: Field.DOC_TYPE,
      },
      docNumber: {
        value: value[Field.DOC_NUMBER],
        required: true,
        field: Field.DOC_NUMBER,
      },
      birthday: {
        value: value[Field.BIRTHDAY],
        required: false,
        field: Field.BIRTHDAY,
      },
      email: { value: value[Field.EMAIL], required: true, field: Field.EMAIL },
      cellphone: {
        value: value[Field.CELLPHONE],
        required: true,
        field: Field.CELLPHONE,
      },
      coOwnership: {
        value: value[Field.CO_OWNERSHIP],
        required: true,
        field: Field.CO_OWNERSHIP,
      },
      role: { value: value[Field.ROLE], required: true, field: Field.ROLE },
      personNumber: {
        value: value[Field.PERSON_NUMBER],
        required: false,
        field: Field.PERSON_NUMBER,
      },
      children: {
        value: value[Field.CHILDREN],
        required: false,
        field: Field.CHILDREN,
      },
      pets: { value: value[Field.PETS], required: false, field: Field.PETS },
      coefficient: {
        value: value[Field.COEFFICIENT],
        required: true,
        field: Field.COEFFICIENT,
      },
      eps: { value: value[Field.EPS], required: false, field: Field.EPS },
      expeditionCountry: {
        value: value[Field.EXPEDITION_COUNTRY],
        required: false,
        field: Field.EXPEDITION_COUNTRY,
      },
      countryBirth: {
        value: value[Field.COUNTRY_BIRTH],
        required: false,
        field: Field.COUNTRY_BIRTH,
      },
      nationality: {
        value: value[Field.NATIONALITY],
        required: false,
        field: Field.NATIONALITY,
      },
    };
    if (!this.fields.tower || !this.fields.block) {
      await this.spinner.hide();
      this.canShowLoading = false;
      $("#uploadFile").val("");
      return this.messageService.getInfoMessagePersonalized(
        "warning",
        `El campo ${Field.TOWER} o ${Field.BLOCK} en la fila ${rowNumber} no puede estar vacio`,
        "Atención!"
      );
    }

    for (const key in this.fields) {
      if (this.fields[key].required && !this.fields[key].value) {
        await this.spinner.hide();
        this.canShowLoading = false;
        $("#uploadFile").val("");
        return this.messageService.getInfoMessagePersonalized(
          "warning",
          `El campo ${this.fields[key].field} en la fila ${rowNumber} no puede estar vacio`,
          "Atención!"
        );
      }
    }
  }

  async createMassive() {
    const fileReader = new FileReader();
    fileReader.onload = async (e) => {
      this.arrayBuffer = fileReader.result;
      const data = new Uint8Array(this.arrayBuffer);
      const arr = [];

      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }

      let numeroFila = 1;

      const bstr = arr.join("");
      const workbook = XLSX.read(bstr, { type: "binary" });
      const first_sheet_name = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[first_sheet_name];
      const excel = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      this.excel = [];
      const registers: StructureTemplate = {
        housingUnitId: this.tokenStorage.getCompanyId(),
        towers: [],
      };

      for (const valor of excel) {
        const clave = excel.indexOf(valor);
        numeroFila++;
        const resultValidate = await this.validates(valor, numeroFila);
        if (resultValidate) {
          return resultValidate;
        }
        const user: UserStructure = {
          name: this.fields.name.value,
          docType: this.fields.docType.value,
          lastName: this.fields.lastName.value,
          docNumber: this.fields.docNumber.value,
          birthday: new Date(this.fields.birthday.value),
          expeditionCountry: this.fields.expeditionCountry.value,
          email: this.fields.email.value,
          nationality: this.fields.nationality.value,
          countryBirth: this.fields.countryBirth.value,
          cellphone: this.fields.cellphone.value,
          eps: this.fields.eps.value,
          role: Role.ROLE_OWNER,
        };

        const coOwnerOrGuest = {
          [Role.OWNER]: (
            coOwnershipAlter: CoOwnershipStructure
          ): CoOwnershipStructure => {
            user.role = Role.ROLE_OWNER;
            coOwnershipAlter.coOwners
              ? coOwnershipAlter.coOwners.push(user)
              : (coOwnershipAlter.coOwners = [{ ...user }]);
            return coOwnershipAlter;
          },
          [Role.GUEST]: (
            coOwnershipAlter: CoOwnershipStructure
          ): CoOwnershipStructure => {
            user.role = Role.ROLE_CO_OWNER;
            coOwnershipAlter.guests
              ? coOwnershipAlter.guests.push(user)
              : (coOwnershipAlter.guests = [{ ...user }]);
            return coOwnershipAlter;
          },
        };
        const newCoOwnership: CoOwnershipStructure = {
          name: this.fields.coOwnership.value,
          coefficient: Number(this.fields.coefficient.value),
          personNumber: Number(this.fields.personNumber.value),
          children: Number(this.fields.children.value),
          pets: Number(this.fields.pets.value),
        };
        const nameTowerOrBlock =
          this.fields.tower.value || this.fields.block.value;
        const tower = registers.towers.find((t) => t.name === nameTowerOrBlock);

        if (!tower) {
          registers.towers.push({
            name: nameTowerOrBlock,
            coOwnerships: [
              coOwnerOrGuest[this.fields.role.value](newCoOwnership),
            ],
          });
        } else {
          const coOwnership = tower.coOwnerships.find(
            (co) => co.name === this.fields.coOwnership.value
          );
          if (!coOwnership) {
            tower.coOwnerships.push(
              coOwnerOrGuest[this.fields.role.value](newCoOwnership)
            );
          } else {
            coOwnerOrGuest[this.fields.role.value](coOwnership);
          }
        }
      }
      return this.loadMassive(registers);
    };

    fileReader.readAsArrayBuffer(this.file);
  }

  loadMassive(list) {
    const path = "api/unidad-residencial/create-structure";
    this.crudServices
      .createModel(path, list)
      .toPromise()
      .then((result) => {
        if (result.code === 200) {
          if (result.answer === "Exito") {
            this.messageService.getInfoMessageCreate();
            this.configPage();
          } else if (result.answer === "ErrorLE") {
            this.messageService
              .getInfoMessagePersonalizedLoadMassive(
                "error",
                "No se pudo realizar la carga masiva, a continuación podra ver los problemas presentados",
                "Error!"
              )
              .then(() => {
                $("#uploadFile").val("");
                this.canShowLoading = false;
                this.spinner.hide();
                this.lanzarModalErroresCargaMasiva(result.answerList);
              });
          } else {
            $("#uploadFile").val("");
            this.canShowLoading = false;
            this.spinner.hide();
            this.messageService.getInfoMessagePersonalized(
              "error",
              result.answer,
              "Error!"
            );
          }
        }
        if (result.code === 400) {
          console.log(result.answerList, "result.answerList");
          this.messageService.getInfoMessagePersonalizedHtml(
            "error",
            result.answerList,
            "Error!"
          );
        } else {
          $("#uploadFile").val("");
          this.canShowLoading = false;
          this.spinner.hide();
          this.messageService.getInfoMessageError();
        }
      })
      .catch((error) => {
        $("#uploadFile").val("");
        this.canShowLoading = false;
        this.spinner.hide();
        this.messageService.getInfoMessagePersonalized(
          "error",
          error.error.answer,
          "Error!"
        );
      });
  }

  lanzarModalErroresCargaMasiva(logErrores) {
    const modalRef = this.modalService.open(LogErroresComponent, {
      windowClass: "my-class",
      backdrop: "static",
    });
    modalRef.componentInstance.propietario = false;
    modalRef.componentInstance.log = logErrores;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      modalRef.dismiss();
    });
  }

  getAllValuetoExcel(): void {
    this.data = [];
    let sendData = {
      housingUnitId: this.idHousingUnit,
    };
    this.crudServices.createModel("api/reports/residents", sendData).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.data = genericResponse.answerList2;
          console.log(this.data, "data 2")
          this.downloadExcel()
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized(
            "warning",
            genericResponse.answer,
            "No se encontraron empleados"
          );
        }
      },
      (error) => {
        this.errorMessage = `${error.status}: ${error.error.message}`;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  downloadExcel() {
    var todayDate = new Date().toISOString().slice(0, 10);
    // Crear la hoja de cálculo
    const worksheet: XLSX2.WorkSheet = XLSX2.utils.json_to_sheet(this.data);
    //console.log(this.data);
    // Personalizar los nombres de las columnas
    worksheet.A1.v = "Torre";
    worksheet.B1.v = "Numero";
    worksheet.C1.v = "Perfil";
    worksheet.D1.v = "Nombre";
    worksheet.E1.v = "Email";
    worksheet.F1.v = "Telefono";
    worksheet.G1.v = "Tipo documento";
    worksheet.H1.v = "Documento";
    worksheet.I1.v = "Vehiculo";
    worksheet.J1.v = "Placa";
    worksheet.K1.v = "Parqueadero";
    worksheet.L1.v = "Cuarto util";

    // Dibujar la tabla
    const range: XLSX2.Range = XLSX2.utils.decode_range(worksheet["!ref"]);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cell: XLSX2.CellObject =
        worksheet[XLSX2.utils.encode_cell({ r: 0, c: C })];
      cell.s = {
        border: {
          top: { style: "thin", color: { rgb: "FF0000FF" } },
          bottom: { style: "thin", color: { rgb: "FF0000FF" } },
          left: { style: "thin", color: { rgb: "FF0000FF" } },
          right: { style: "thin", color: { rgb: "FF0000FF" } },
        },
      };
    }
    for (let R = range.s.r; R <= range.e.r; ++R) {
      const cell: XLSX2.CellObject =
        worksheet[XLSX2.utils.encode_cell({ r: R, c: 0 })];
      cell.s = {
        border: {
          top: { style: "thin", color: { rgb: "FF0000FF" } },
          bottom: { style: "thin", color: { rgb: "FF0000FF" } },
          left: { style: "thin", color: { rgb: "FF0000FF" } },
          right: { style: "thin", color: { rgb: "FF0000FF" } },
        },
      };
    }

    // Crear el libro y agregar la hoja de cálculo
    const workbook: XLSX2.WorkBook = XLSX2.utils.book_new();
    XLSX2.utils.book_append_sheet(workbook, worksheet, "Datos");
    const date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    // Descargar el archivo
    XLSX2.writeFile(workbook, `reporte-${todayDate}.xlsx`);
  }
}
