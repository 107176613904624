import { Component, OnInit, Input } from "@angular/core";
import { CrudServiceService } from "app/shared/backend/cruds/crud-service.service";
import { InfoMessagesService } from "app/shared/messages/info-messages.service";
import { CoownershipModel } from "models/entities/coowner-ship-model";
import md5 from "crypto-js/md5";
import { TokenStorageService } from "app/shared/storage-services/token-storage.service";
import { UserModel } from "models/entities/user-model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PlansModel } from "../../../models/entities/plans-model";
import { GenericResponseModel } from "models/utilities/generic.response.model";

@Component({
  selector: "app-pay-member",
  templateUrl: "./pay-member.component.html",
  styleUrls: ["./pay-member.component.scss"],
})
export class PayMemberComponent implements OnInit {
  @Input() plan: PlansModel = new PlansModel();
  referenceCode: string = "";
  // Datos prueba
  action: string =
    "https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu/";
  apiKey: string = "4Vj8eK4rloUd272L48hsrarnUA";
  merchantId: string = "508029";
  accountId: string = "512321";
  test: number = 1;
  /// ----------------------------
  // Datos reales
  // action: string = "https://checkout.payulatam.com/ppp-web-gateway-payu/";
  // apiKey: string = "P8Ah3qgmyAya4gidkU1K2l9m0K";
  // merchantId: string = "983142";
  // accountId: string = "991207";
  // test: number = 0;
  amount: number = 0;
  txtValue: string = "";
  currency: string = "COP";
  dataToHash: string = "";
  signature: string = "";
  buyerEmail: string = "";
  date: string = "";
  dato: any;
  UserInfo: UserModel = new UserModel();
  errorMessage: any;
  constructor(
    private messageService: InfoMessagesService,
    private crudServices: CrudServiceService,
    private tokenStorage: TokenStorageService,
    public modalService: NgbModal
  ) {}

  ngOnInit() {
    this.getDetailClient();

    //let latest_date = this.datepipe.transform(this.date, "yyyy-MM-dd");
  }

  getDetailClient(): void {
    this.crudServices
      .getModel("api/usuarios/obtener-por-id?id=" + this.tokenStorage.getId())
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.dato = genericResponse.genericObject;
            this.UserInfo = this.dato;
            this.amount = parseInt(this.UserInfo.activatedPlanId.value);
            this.buyerEmail = this.UserInfo.email;
            this.generateSignature(this.UserInfo);
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              " Error en la búsqueda"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  linkPay(): void {
    this.crudServices
      .createModel("api/pagar/link-pago", {
        amount: this.amount,
        idUserResponsable: this.UserInfo.id,
        typeDocPayer: "C",
        emailPayer: this.buyerEmail,
        docPayer: this.UserInfo.documentNumber,
        namePayer: this.UserInfo.completeName,
        typePersonPayer: "N",
        typeProduct: "membresia",
        idProduct: this.UserInfo.activatedPlanId.id,
        celPayer:
          this.UserInfo.cellphone == ""
            ? this.UserInfo.phone
            : this.UserInfo.cellphone,
      })
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.messageService.getInfoMessagePersonalized(
              "success",
              genericResponse.answer,
              "Link de pago generado"
            );
            window.open(genericResponse.genericObject, "_blank");
            this.closeWindow();
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se generó link de pago"
            );
            this.errorMessage = genericResponse.answer;
          }
        },
        (error) => {
          this.errorMessage = error.error.message;
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  generateSignature(userInfo: UserModel): void {
    this.date = new Date().toISOString().slice(0, 10);
    this.referenceCode =
      "PAGO_PHP" +
      "_" +
      "PROPIETARIO_" +
      userInfo.documentNumber +
      "_" +
      new Date(new Date().toString().split("GMT")[0] + " UTC").toISOString();
    this.dataToHash =
      this.apiKey +
      "~" +
      this.merchantId +
      "~" +
      this.referenceCode +
      "~" +
      this.amount +
      "~" +
      this.currency;
    this.signature = md5(this.dataToHash).toString();
    //alert(this.signature);
  }
  closeWindow() {
    this.modalService.dismissAll();
  }
}
