import { UserModel } from 'models/entities/user-model';
import { Component, OnInit, Input } from '@angular/core';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import swal from 'sweetalert2';
import { FirebaseStorageService } from 'app/shared/firebase/firebase-storage.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { FileModel } from 'models/entities/file-model';
import { RelationalFileModel } from 'models/utilities/relational-type-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewFileComponent } from '../new-file/new-file.component';
import { CrudEquipoComponent } from '../crud-equipo/crud-equipo.component';
import { ComposeModel } from 'models/utilities/compose-model';
import { EquipoModel } from 'models/entities/equipo-model';

@Component({
  providers:[CrudEquipoComponent],
  selector: 'app-list-files',
  templateUrl: './list-files.component.html',
  styleUrls: ['./list-files.component.scss']
})
export class ListFilesComponent implements OnInit {

  public canShowLoading = false;
  public listFiles: Array<FileModel> = new Array();
  public listFilesTemp: Array<FileModel> = new Array();
  public creator=new UserModel();
  messages:any;

  imagen =  new FileModel();

  @Input() relationalFileModel: RelationalFileModel = new RelationalFileModel();
  @Input() equipoModel: EquipoModel = new EquipoModel();
  @Input() llamadoModulo = '';
  @Input() isView = false;

  columnsFile = [
    { prop: 'id' },
    { prop: 'fileName' },
    { prop: 'downloadUrl' }
  ]

  constructor(
    private crudService: CrudServiceService,
    private firebaseServices: FirebaseStorageService,
    private messageService: InfoMessagesService,
    private modalService: NgbModal,
    private crudEquipo: CrudEquipoComponent,
  ) { }

  ngOnInit() {
    this.messages = {
        'emptyMessage': 'No se encontraron registros',
        'totalMessage': 'registros totales'
      };

      //se valida el tipo de archivo imagen - adjunto
      if(this.llamadoModulo == 'mantenimiento_imagen')
      {
        
        this.relationalFileModel.relationalType = 'MANTENIMIENTO_IMAGEN';
        this.relationalFileModel.relationalFileType = 'IMAGEN';
        this.relationalFileModel.relationalObjetive = 'imagen mantenimiento';
      }
      else if(this.llamadoModulo == 'mantenimiento_adjunto')
      {
        this.relationalFileModel.relationalType = 'MANTENIMIENTO_ARCHIVO';
        this.relationalFileModel.relationalFileType = 'ARCHIVO';
        this.relationalFileModel.relationalObjetive = 'adjunto mantenimiento';
      }
      this.configFiles();
  }

  configFiles() {
    if (this.relationalFileModel.relationalId !== 0) {
      const url = 'api/archivo-firebase/obtener-por-id-relacionado-y-tipo?relationalId=' + this.relationalFileModel.relationalId +
        '&relationalType=' + this.relationalFileModel.relationalType;
      this.crudService.getModel(url).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.listFiles = genericResponse.answerList;
            this.listFiles.forEach(element => {
              //this.getUser(element);
            });
            this.listFilesTemp = this.listFiles;
            setTimeout(() => {
              this.seleccionCheckImagen();
            }, 1000);
            
          } else {
            this.messageService.getInfoMessagePersonalized('warning',
              'Ocurrio un error cargando los archivos', 'Problema cargando archivos');
          }
        },
        error => {
          this.messageService.getInfoMessageBadInternet();
          console.error(error);
        }
      )
    }
  }

  downloadFile(urlToDowload) {
    window.open(urlToDowload, '_blank')
  }

  detalleArchivo(fileMode){
    // se valida si es una carga de imagen o adjunto
    if(this.relationalFileModel.relationalFileType == 'IMAGEN'){
       this.imagen = fileMode;
       console.log(fileMode);
    }else if(this.relationalFileModel.relationalFileType == 'ARCHIVO'){
      window.open(fileMode.urlToDowload, '_blank')
    }
  }

  getUser(element){
    
    let path='api/usuarios/buscar-por-id?userId='+element.idCreator;
    this.crudService.getModel(path).subscribe(
      result=>{
        if(result.code===200)
        {
      console.log(result);
         element.idCreator=result.genericObject.userAux.completeName;
         this.listFiles = this.listFiles;
        }
      },
      error=>{
       this.messageService.getInfoMessageError();
      }
    )
  }


  deleteFile(fileToDelete: FileModel) {
    this.canShowLoading = true;
    const url = 'api/archivo-firebase/borrar-por-id?idFile=' + fileToDelete.id;
    this.crudService.deleteModel(
      url
    ).subscribe((genericResponse: GenericResponseModel) => {
      if (genericResponse.code === 200) {
        this.firebaseServices.deleteFile(fileToDelete.pathFirebaseFile).then((data) => {
          this.messageService.getInfoMessageDelete();
          this.configFiles();
        })
      } else {
        this.messageService.getInfoMessagePersonalized('warning', 'No se pudo eliminar', 'Algo salio mal');
        this.canShowLoading = false;
      }
    }, error => {
      this.messageService.getInfoMessageBadInternet();
      console.error(error);
    })
  }

  deleteWarningFile(fileToDelete: FileModel) {
    swal({
      title: '¿Está seguro que desea eliminar el archivo?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.deleteFile(fileToDelete);
      }
    })
  }

  newFile() {
    
    const modal = this.modalService.open(NewFileComponent, {
      windowClass: 'modal', size: 'sm', backdrop: 'static'
    });
    const composeModelToSend: ComposeModel = new ComposeModel();
    composeModelToSend.fileObjetive = this.relationalFileModel.relationalObjetive;
    composeModelToSend.fileType = this.relationalFileModel.relationalFileType

    const fileModelToSend: FileModel = new FileModel();
    fileModelToSend.relationalId = this.relationalFileModel.relationalId;
    fileModelToSend.relationalType = this.relationalFileModel.relationalType;
    fileModelToSend.fileType = this.relationalFileModel.relationalFileType; // 'IMG' si las restricciones son imagenes por ejemplo
    modal.componentInstance.composeModel = composeModelToSend;
    modal.componentInstance.fileModel = fileModelToSend;

    //modal.componentInstance.restrictions = this.relationalFileModel.relationalRestrictions; // new Array('JPEG', 'PNG', 'png', 'jpeg', 'jpg');
    if(this.relationalFileModel.relationalFileType == 'IMAGEN'){
      modal.componentInstance.restrictions = new Array('JPEG', 'PNG', 'png', 'jpeg', 'jpg');
    }else if(this.relationalFileModel.relationalFileType == 'ARCHIVO'){
      modal.componentInstance.restrictions = new Array('docx', 'doc', 'xls', 'xl', 'xlsx', 'pdf', 'ppt');
    }

    modal.componentInstance.emit.subscribe((response) => {
      if (response !== 0) {
        this.configFiles();
        modal.close();
      } else {
        modal.close();
      }
    });
  }

  imagenPrincipal(firebaseModel){

    let texto = '';

    if($("#check-imagen-" + firebaseModel.id).prop('checked')){
      texto = 'elegir';
    }else{
      texto = 'desmarcar';
    }
    
    swal({
      title: '¿Está seguro que desea '+ texto +' la imágen como principal?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.limpiarCheckImagen(firebaseModel.id);
        
        if($("#check-imagen-" + firebaseModel.id).prop('checked')){
          this.equipoModel.imagenPrincipalFirebaseId = firebaseModel;
        }else{
          this.equipoModel.imagenPrincipalFirebaseId = null;
        }

        this.crudEquipo.imagenPrincipal(this.equipoModel);
      }else{
        $("#check-imagen-" + firebaseModel.id).prop('checked','');
      }
    })
    
  }

  limpiarCheckImagen(id){
    this.listFiles.forEach(element => {
      if(id != element.id){
        $("#check-imagen-" + element.id).prop('checked','');
        //$("#check-imagen-" + element.id).prop('disabled','');
      }
    });
    //$("#check-imagen-" + id).prop('disabled','disabled');
  }

  seleccionCheckImagen(){
    let id = this.equipoModel.imagenPrincipalFirebaseId.id;
    this.listFiles.forEach(element => {
      if(id == element.id){
        $("#check-imagen-" + id).prop('checked','checked');
        //$("#check-imagen-" + id).prop('disabled','disabled');
      }
    });
    
  }

}
