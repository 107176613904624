import { AssignedHousingUnitComponent } from './assigned-housing-unit/assigned-housing-unit.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListHousingUnitComponent } from './list-housing-unit/list-housing-unit.component';
import { CreateHousingUnitComponent } from './create-housing-unit/create-housing-unit.component';
import { UpdateHousingUnitComponent } from './update-housing-unit/update-housing-unit.component';
import { SelectHousingUnitComponent } from './select-housing-unit/select-housing-unit.component';
import { DetailHousingUnitComponent } from './detail-housing-unit/detail-housing-unit.component';

const routes: Routes = [
  {
    path: 'listar-unidades-residenciales',
    component: ListHousingUnitComponent,
    data: {
      title: 'list-housing-unit',
    }
  },
  {
    path: 'crear-unidades-residenciales',
    component: CreateHousingUnitComponent,
    data: {
      title: 'create-housing-unit',
    }
  },
  {
    path: 'actualizar-unidades-residenciales',
    component: UpdateHousingUnitComponent,
    data: {
      title: 'update-housing-unit',
    }
  },
  {
    path: 'seleccionar-unidades-residenciales',
    component: SelectHousingUnitComponent,
    data: {
      title: 'select-housing-unit',
    }
  }, {
    path: 'detalle-unidad-residencial',
    component: DetailHousingUnitComponent,
    data: {
      title: 'detail-housing-unit',
    }
  },
   {
    path: 'unidad-residencial-asignadas',
    component: AssignedHousingUnitComponent,
    data: {
      title: 'unidad-residencial-asignadas',
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HousingUnitRoutingModule { }
