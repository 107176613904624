import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ListPetsHousingUnitComponent } from "./list-pets-housing-unit/list-pets-housing-unit.component";
import { RoleAuthGuard } from "app/shared/auth/roleAuth-guard.service";

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "consultar-mascotas",
        component: ListPetsHousingUnitComponent,
        data: {
          title: "list-pets-by-housing",
          expectedRole: ["ROLE_OWNER", "ROLE_COOWNER"],
        },
        canActivate: [RoleAuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PetsRoutingModule {}
