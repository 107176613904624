import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { stringify } from 'querystring';
import { QuestionOptionModel } from 'models/entities/question-option-model';
import { QuestionResponseModel } from 'models/entities/questio-mode-response';
import { forEach } from 'underscore';

@Component({
  selector: 'app-list-question-owner',
  templateUrl: './list-question-owner.component.html',
  styleUrls: ['./list-question-owner.component.scss']
})
export class ListQuestionOwnerComponent implements OnInit {


  @Input() surveyId: number;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  rows = [];
  temp: string[];
  dato: any;
  dato2 : object;
  datoRespuestas : any;
  answerUser : any;
  variableComparacion:any;
  
  rand_NumeroAleatorio = Math.floor((999-4)*Math.random()) + 1;
  answerId: Array<number> =  new Array();
  
  listaPreguntasUnicas: any = [];
  listaPreguntasMultiples: any = [];

  botonRespuestasUnicas: boolean = false;
  botonRespuestasMultiples: boolean = false;

  respuestaUnicaEnviada: boolean = false;
  respuestaMultipleEnviada: boolean = false;
  contador: number = 0;

  constructor(
    private modalService: NgbModal, private crudServices: CrudServiceService,
    private tokenStorage: TokenStorageService, private messageService: InfoMessagesService
    
  ) { }

  ngOnInit() {
    this.configPage();
    setTimeout(() => {
      // alert('este' + JSON.stringify(this.listaPreguntasUnicas[0].questionOptionId[0].id));
      // this.listaPreguntasUnicas[0].questionOptionId[0] = '0';
      $("#answerUser").append("<option value='' selected disabled=''>Seleccione una opción</option>");
    }, 100);
  }

  configPage() {
    this.crudServices.getModel('api/preguntas/listar-preguntas-respuestas?id=' + this.surveyId).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.dato = genericResponse.answerList;
          this.temp = { ...this.dato };
          this.rows = this.dato;
          
          this.rows.forEach(element => {
            if(element.questionId.multipleRespuesta) {
              this.listaPreguntasMultiples.push(element);
            }
            else {
              this.listaPreguntasUnicas.push(element);
            }
          });

          if(this.listaPreguntasUnicas.length <= 0) {
            this.respuestaUnicaEnviada = true;
          }
          if(this.listaPreguntasMultiples.length <= 0) {
            this.respuestaMultipleEnviada = true;
          }
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  
  onSubmit() {
    //this.conseguirMisRespuestasPropietario();
    //if(this.variableComparacion === true){
      //guardar la encuesta
      this.crudServices.createModel(
        `api/preguntas/responder-encuesta-web?coowernshipId=${this.tokenStorage.getCoownerShipId()}`, this.listaPreguntasUnicas
        ).subscribe(
        (genericResponse: GenericResponseModel) => {
          console.log(genericResponse)
          if (genericResponse.code === 200) {
            this.passEntry.emit('creado');
            this.messageService.getInfoMessagePersonalized('success', 'Su respuestas han sido guardadas', 'Correcto').then(() => {
              this.botonRespuestasUnicas = false;
              this.respuestaUnicaEnviada = true;

              if(this.respuestaUnicaEnviada && this.respuestaMultipleEnviada) {
                this.modalService.dismissAll();
              }
            })
           }//else{
          //   this.messageService.getInfoMessagePersonalized('warning', "Ya la respondiste anterioridad", 'No se pudo guardar el resultado de la encuesta');
          // }
        },
        error => {
          console.error(error);
        }
      );
      //}
  }



  guardarRespuestasOpcionMultiple() {
    //this.conseguirMisRespuestasPropietario();
    //if(this.variableComparacion === true){
      //guardar la encuesta
      this.crudServices.createModel(
        `api/preguntas/responder-encuesta-web-multiple-respuesta?coowernshipId=${this.tokenStorage.getCoownerShipId()}`, this.listaPreguntasMultiples
        ).subscribe(
        (genericResponse: GenericResponseModel) => {
          console.log(genericResponse)
          if (genericResponse.code === 200) {
            this.passEntry.emit('creado');
            this.messageService.getInfoMessagePersonalized('success', 'Su respuestas han sido guardadas', 'Correcto').then(() => {
            this.botonRespuestasMultiples = false;
            this.respuestaMultipleEnviada = true;

          if(this.respuestaUnicaEnviada && this.respuestaMultipleEnviada) {
             this.modalService.dismissAll();
          }

                });
           }//else{
          //   this.messageService.getInfoMessagePersonalized('warning', "Ya la respondiste anterioridad", 'No se pudo guardar el resultado de la encuesta');
          // }
        },
        error => {
          console.error(error);
        }
      );
      //}
  }

   

    async validateAnswersUnique() {
      let validation = true;
      
      this.listaPreguntasUnicas.forEach(item => {
        if (item.answerId === null) {
          validation = false;
        }
      });

      if (validation) {
        this.botonRespuestasUnicas = true;
      }
    }

    /* Funcion que se encarga de validar la cantidad maxima de opciones de respuesta asi como el minimo para poder guardar
      @param i controla la posicion de la lista (listaPreguntasMultiples) de la opcion marcada en ese momento
      @param j controla la posicion de la lista (questionOptionId) de la opcion marcada en ese momento
      @param idCheck me determina el checkbox para operarlo por jQuery
    */
    validateAnswersMultiple(pregunta, i, j, idCheck) {

      let limit = pregunta.questionId.maxRespuestaMultiple;
      let contador = 0;
      let validation = false;

      pregunta.questionOptionId.forEach(element => {
        if (element.respuestaSeleccionada) {
          validation = true;
          contador++;
        }
      });

      if(contador <= limit) {
        if (validation) {
          this.botonRespuestasMultiples = true;
        }else{
          this.botonRespuestasMultiples = false;
        }
      }
      else {
        $("#checkbox"+idCheck).prop('checked', false);
        this.listaPreguntasMultiples[i].questionOptionId[j].respuestaSeleccionada = false;
        this.messageService.getInfoMessagePersonalized('warning', 'Sólo puede seleccionar un maximo de ' + limit + " respuestas", 'Atención!')
      }

    }

    closeModal() {
      this.modalService.dismissAll();
    }

  conseguirMisRespuestasPropietario() {
    this.crudServices.getModel(
      `api/preguntas/detalle-encuesta-respondida?coownerId=${this.tokenStorage.getCoownerShipId()}&surveyId=${this.surveyId}`
    ).subscribe(
      //Metodo para hallar si un ususario respondio una encuesta
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 201) {
          this.variableComparacion = true;
        }
        else if (genericResponse.code === 200) {
          this.variableComparacion = false;
          this.dato2 = genericResponse.answerList;
        }
      },
      error => {
        console.error(error);
      }
    );
  }
 


}
