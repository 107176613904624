import { GenericResponseModel } from './../../../models/utilities/generic.response.model';
import { TokenStorageService } from './../../shared/storage-services/token-storage.service';
import { InfoMessagesService } from './../../shared/messages/info-messages.service';
import { CrudServiceService } from './../../shared/backend/cruds/crud-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserProfileHousingUnitModel } from './../../../models/entities/user-profile-housingunit';
import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';

@Component({
  selector: 'app-assigned-housing-unit',
  templateUrl: './assigned-housing-unit.component.html',
  styleUrls: ['./assigned-housing-unit.component.scss']
})
export class AssignedHousingUnitComponent implements OnInit {

  userProfileHousinUList: Array<UserProfileHousingUnitModel> = new Array();
  userProfileHousingUSelected: UserProfileHousingUnitModel;
  rows = [];
  errorMessage: string;

  public housingUnitStorage: string;

  constructor(private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private crudServices: CrudServiceService,
    private messageService: InfoMessagesService, private tokenStorage: TokenStorageService

    ) { }


  ngOnInit() {
    this.configPage();
    this.housingUnitStorage = this.tokenStorage.getCompanyId();
  }


  /*   openCreateTower() {
    const modalRef = this.modalService.open(CreateTowerBlockComponent, {
      windowClass: '', size: 'lg', backdrop: 'static',
    });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
      if (receivedEntry === 'creado') {
        this.configPage();
      } else {
        return false;
      }
    })
  } */

  configPage() {
    this.crudServices.getModel('api/perfil-uresidencial/obtener-asignados').subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.userProfileHousinUList = genericResponse.answerList;
          this.rows = this.userProfileHousinUList;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin torres')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }


  deleteWarning(id) {
    event.preventDefault(); // prevent form submit
    swal({
      title: '¿Está seguro que desea eliminar la relación?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.onDelete(id);
      }
    })
  }
  onDelete(id) {
    this.crudServices.deleteModel('api/perfil-uresidencial/borrar?id=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageDelete().then(
            () => {
              this.configPage();
            }
          )
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se eliminó la relación.')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessagePersonalized('error', 'Se tienen asociados items a la copropiedad porfavor eliminelos primero '
          , 'No se eliminó la relación')
        this.errorMessage = `${error.status}: ${error.error.message}`;
      }
    );
  }

  openDetailHousing(id: any) {
    this.tokenStorage.saveIdCompany(id);
    this.router.navigate(['/unidades-residenciales/detalle-unidad-residencial' , {id} ], { relativeTo: this.route.parent } );
  }

 /*  openModalUpdate(tower: TowerModel) {
    this.userProfileHousingUSelected = tower;
    this.modalServices.openModal();
  } */

/* 
  detailTowerBlock(id) {
    this.crudServices.getModel('api/torre-bloque/buscar?id=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          console.log(genericResponse)
          this.infoMenu = genericResponse.genericObject
          const modalRef = this.modalService.open(
            DetailTowerBlockComponent, {
              windowClass: '', size: 'lg',
            }
          );
          modalRef.componentInstance.towerBlockInfo = this.infoMenu;
          console.log(this.infoMenu)
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error buscando el modulo')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }
 */
}
