import { TaskTypeModel } from './task-type';
import { UserModel } from './user-model';
import { ActivityTypeModel } from 'models/entities/activity-type.model';
import { FirebaseFile } from './firebaseFile';
import { HousingUnitModel } from 'models/entities/housing-unit-model';
export class TaskModel {
    id: string;
    openDate: Date;
    closeDate: string;
    responsable: UserModel;
    status: string;
    days: string;
    activityDescription: string;
    generalDescription: string;
    housingUnitId: HousingUnitModel;
    firebaseFile: string;
    activityType: TaskTypeModel;

    public constructor() {
        this.id = '';
        this.openDate = new Date();
        this.closeDate = '';
        this.responsable = new UserModel;
        this.status = '';
        this.activityDescription = '';
        this.generalDescription = '';
        this.housingUnitId = new HousingUnitModel();
        this.firebaseFile  = '';
        this.activityType = new TaskTypeModel();
    }
}

