import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { CreateTowerBlockComponent } from 'app/tower-block/create-tower-block/create-tower-block.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateCoownershipComponent } from 'app/coownership/create-coownership/create-coownership.component';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { TokenStorageService } from '../../shared/storage-services/token-storage.service';
import { HousingUnitModel } from 'models/entities/housing-unit-model';
import { ModalInfo } from 'models/entities/modal-info';
import { ReporteMantenimientoComponent } from 'app/mantenimiento/reporte-mantenimientos/reporte-mantenimientos.component';
import { formatDate } from '@angular/common';
import { EquipoAuxModel } from 'models/utilities/equipo-aux-model';
import { environment } from 'environments/environment.prod';

@Component({
  selector: 'app-detail-housing-unit',
  templateUrl: './detail-housing-unit.component.html',
  styleUrls: ['./detail-housing-unit.component.scss']
})
export class DetailHousingUnitComponent implements OnInit {

  id: any;
  housingU: HousingUnitModel = new HousingUnitModel();
  modalInfo: ModalInfo;
  listaEquipoAux:Array<EquipoAuxModel> = new Array();
  equipoAux = new EquipoAuxModel();

  urlPeticionImg = environment.serverUrl + 'api/archivos/ver-img?ruta=';

  constructor(
    private tokenStorage: TokenStorageService, private messageService: InfoMessagesService,
     private crudServices: CrudServiceService,private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.configPage();
    this.reporte();
  }

  // onDetail(id) {
  //   console.log('EL ID ESTA ACA: ' + id)
  //   this.router.navigate(['/copropiedad/detalle-copropiedad', { id }], { relativeTo: this.route.parent });
  // }

  configPage() {
    this.crudServices.getModel('api/unidad-residencial/buscar?id=' + this.tokenStorage.getCompanyId()).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.housingU = genericResponse.genericObject;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, ' Error al buscar la Unidad ')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  abrirReporteEquipos() {
    this.modalInfo = new ModalInfo();
    this.modalInfo.isCreate = true;

    const modalRef = this.modalService.open(ReporteMantenimientoComponent, {
      windowClass: 'my-class', backdrop: 'static'
    });
  }

  reporte() {
    this.crudServices.getModel('api/equipo/reporte?unidadResidencialId='  + this.tokenStorage.getCompanyId()).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          
          this.equipoAux = genericResponse.answerList;
          this.calcularPorcentajes(this.equipoAux);
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin equipos')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  calcularPorcentajes(equipoAux){
    let total = 0;
    let contador = 0;

    for(var i in equipoAux){
      //se valida el tipo de mantenimeinto =  cantidades
      if(equipoAux[i].tipo_mantenimiento == 2 || equipoAux[i].tipo_mantenimiento == 3){
        
        total = (equipoAux[i].cantidadTiempoMantenimiento * 100) / equipoAux[i].tiempoMantenimiento;
        
      }else{// dias
        let fecha = '';

        if(equipoAux[i].programacionUltimoMantenimiento != null){
          fecha = equipoAux[i].programacionUltimoMantenimiento;
        }else{
          fecha = equipoAux[i].programacionFechaInstalacion;
        }

        const parts = fecha.split('-');
        const dateValidUntul = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2])).getTime();

        const fechaActual = formatDate(new Date(), 'yyyy-MM-dd', 'en');
        const parts2 = fechaActual.split('-');
        const currentDate = new Date(parseInt(parts2[0]), parseInt(parts2[1]) - 1, parseInt(parts2[2])).getTime();

        // se multiplica para obtener la diferencia en días
        const diff = Math.trunc(((dateValidUntul - currentDate) / (1000 * 60 * 60 * 24)));

        total = ((equipoAux[i].tiempoMantenimiento - diff) * 100) / equipoAux[i].tiempoMantenimiento;
      }

      equipoAux[i].porcentaje =  total.toFixed();

      total = Number(total.toFixed());
      

      if(Number(total) >= 85){
        this.listaEquipoAux.push(this.equipoAux[i]);
        contador++;
      }

    }

    if(contador > 0){
      this.abrirReporteEquipos();
    }
  }

}
