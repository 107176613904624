import { HousingUnitModel } from "./housing-unit-model";
import { DepartmentModel } from "./department-model";
import { CityModel } from "./city-model";
export class providerModel {
  id: string;
  name: string;
  contact: string;
  address: string;
  phone: string;
  email: string;
  service: string;
  departmentId: DepartmentModel;
  cityId: CityModel;
  housingUnitId: HousingUnitModel;
  startDate: string;
  finishDate: string;

  public constructor() {
    this.id = "";
    this.name = "";
    this.contact = "";
    this.address = "";
    this.phone = "";
    this.email = "";
    this.service = "";
    this.departmentId = new DepartmentModel();
    this.cityId = new CityModel();
    this.housingUnitId = new HousingUnitModel();
    this.startDate = "";
    this.finishDate = "";
  }
}
