import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateNewsComponent } from '../create-news/create-news.component';
import { NewsModel } from '../../../models/entities/news-model';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { DetailIncidentComponent } from 'app/incident/detail-incident/detail-incident.component';
import { DetailNewsComponent } from '../detail-news/detail-news.component';
import swal from 'sweetalert2';
import { environment } from 'environments/environment.prod';

@Component({
  selector: 'app-list-news',
  templateUrl: './list-news.component.html',
  styleUrls: ['./list-news.component.scss']
})
export class ListNewsComponent implements OnInit {

  newsList: Array<NewsModel> = new Array();
  newsTitle: any;
  newsModel = new NewsModel;

  rolAdmin = this.tokenStorage.getAuthorities();
  urlPeticionImg = environment.serverUrl + 'api/archivos/ver-img?ruta=';

  constructor(
    private crudServices: CrudServiceService, private modalService: NgbModal,
    private messageService: InfoMessagesService, private tokenStorage: TokenStorageService
  ) { }

  ngOnInit() {
    this.newsTitle =  this.tokenStorage.getCompanyName();
    this.configPage();

  }

  configPage() {

    this.crudServices.getModel('api/noticias/ver-mis-noticias?id=' + this.tokenStorage.getCompanyId()).subscribe(
      (genericResponse: GenericResponseModel) => {

        if (genericResponse.code === 200) {
          this.newsList = genericResponse.answerList;

        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin noticias')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  detailNews(id) {
    this.crudServices.getModel('api/noticias/buscar-noticia?id=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.newsModel = genericResponse.genericObject
          const modalRef = this.modalService.open(
            DetailNewsComponent, {
              windowClass: '', size: 'lg', backdrop: 'static',
            }
          );

          modalRef.componentInstance.newsInfo = this.newsModel;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error buscando el incidente')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  onDelete(id) {
    this.crudServices.deleteModel('api/noticias/eliminar-noticia?id=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageDelete()
          this.configPage()
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );

  }
  deleteWarning(id) {
    event.preventDefault();
    swal({
      title: '¿Está seguro que desea eliminar la noticia?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.onDelete(id);
      }
    })
  }

}
