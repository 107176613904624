import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { CreatemenuComponent } from '../createmenu/createmenu.component';
import swal from 'sweetalert2';
import { PairModulePlanModel } from 'models/utilities/pair-module-plan-model';
import { DetailmenuComponent } from '../detailmenu/detailmenu.component';
import { MenuModel } from 'models/entities/menu-model';
import { UpdatemenuComponent } from '../updatemenu/updatemenu.component';
@Component({
  selector: 'app-listmenu',
  templateUrl: './listmenu.component.html',
  styleUrls: ['./listmenu.component.scss']
})
export class ListmenuComponent implements OnInit {

  rows = [];
  temp: string[];
  board: string;
  errorMessage: string;
  dato: any;
  info: any = new PairModulePlanModel();
  datosDetalle: any;
  button = [];
  infoMenu = new MenuModel();


  // Table Column Titles
  columns = [
    { prop: 'name' }
  ];

  constructor(
    private modalService: NgbModal,
    private crudServices: CrudServiceService,
    private messageService: InfoMessagesService
  ) { }

  ngOnInit() {
    this.configPage();
  }

  openCreateMenu() {
    const modalRef = this.modalService.open(CreatemenuComponent, {
      windowClass: '', size: 'lg', backdrop: 'static',
    });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      if (receivedEntry === 'Menú Creado') {
        this.configPage();
      } else {
        return false;
      }
    })
  }

  configPage() {
    this.crudServices.getModel('api/menu/ver-todos').subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.dato = genericResponse.answerList;
          this.temp = {...this.dato};
          this.rows = this.dato;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin menús')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  onDelete(id) {
    this.crudServices.deleteModel('api/menu/eliminar?id=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageDelete().then(
            () => {
              this.configPage();
            }
          )
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se eliminó el menú')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
        this.errorMessage = `${error.status}: ${error.error.message}`;
      }
    );
  }

  deleteWarning(id) {
    event.preventDefault(); // prevent form submit
    swal({
      title: '¿Está seguro que desea eliminar el menu?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.onDelete(id);
      }
    })
  }

  onUpdateMenu(id) {
    this.crudServices.getModel('api/menu/detalle-de-menu?idMenu=' + id).subscribe(
      geenricResponse => {
        if (geenricResponse.code === 200) {
          this.info = geenricResponse.genericObject;
          const modalRef = this.modalService.open(UpdatemenuComponent, {
            windowClass: '', size: 'lg', backdrop: 'static'
          });
          modalRef.componentInstance.menuInfoUpdate = this.info;
          modalRef.componentInstance.passEntry.subscribe((menuToUpdate) => {
           this.crudServices.createModel('api/menu/update', menuToUpdate).subscribe(
              geenricResponseUpdate => {
                if (geenricResponseUpdate.code === 200) {
                  swal({
                    title: 'Actualización Completa',
                    text: menuToUpdate.name + ' se ha actualizado correctamente',
                    type: 'success'
                  }).then((result) => {
                    this.configPage();
                    this.modalService.dismissAll();
                  })
                }
                if (geenricResponseUpdate.code === 400) {
                  this.messageService.getInfoMessagePersonalized('warning', geenricResponseUpdate.answer, 'Error actualizando el modulo')
                }
              },
              error => {
                console.error(error);
                this.messageService.getInfoMessageBadInternet();
              }
            );
          })
        }
        if (geenricResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', geenricResponse.answer, 'Error consultanto el modulo')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  listMenuDetail(id) {
    this.crudServices.getModel('api/menu/detalle-de-menu?idMenu=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.infoMenu = genericResponse.genericObject
          const modalRef = this.modalService.open(
            DetailmenuComponent, {
              windowClass: '', size: 'lg', backdrop: 'static'
            }
          );
          modalRef.componentInstance.menuInfo = this.infoMenu;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error buscando el modulo')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

}
