import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { PlansModel } from './../../../models/entities/plans-model';
import { Component, OnInit, ViewChild, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';

@Component({
  selector: 'app-create-plan',
  templateUrl: './create-plan.component.html',
  styleUrls: ['./create-plan.component.scss']
})
export class CreatePlanComponent implements OnInit, OnChanges {

  public planInfo = new PlansModel()
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() valorPadre: boolean;
  public isCreatedPlan = true;
  errorMessage: any;
  form: any = {};
  rowsPlanes = [];
  tempPlanes: string[];
  datoPlanes: any;
  @ViewChild('f') createModuleForm: NgForm;

  constructor(
    private messageServices: InfoMessagesService,
    private crudServices: CrudServiceService,
    private modalService: NgbModal) {
     }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.valorPadre) {
      console.log('valorPadre', this.valorPadre);
      this.listarPlanes();
    }
  }

  ngOnInit() {
    this.listarPlanes();
  }


  onSubmit() {
    this.planInfo.name = this.form.name;
    this.planInfo.description = this.form.description;
    this.planInfo.value = this.form.value;
    this.planInfo.lastUpdateDate = new Date().toUTCString();
    this.planInfo.maxNumHousUnit = this.form.maxNumHousUnit;
    this.planInfo.maxNumCoownerShip = this.form.maxNumCoownerShip;
    
    if(this.form.isFauvorite == undefined){
      this.form.isFauvorite = false;
    }
    this.planInfo.isFauvorite = this.form.isFauvorite;
    console.log(this.form.isFauvorite);
    this.planInfo.planSiguiente = this.form.planSiguiente;
    console.log(this.planInfo);

    this.crudServices.createModel('api/plan/crear', this.planInfo).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.isCreatedPlan = true;
          this.modalService.dismissAll();
          
          this.messageServices.getInfoMessageCreate().then(
            (result) => {
              this.passEntry.emit('Plan Creado');
              this.isCreatedPlan = true;
              this.form = {};
              this.listarPlanes();
            }
          )
        }
        if (genericResponse.code === 400) {
          this.messageServices.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se creó el plan')
        }
      },
      error => {
        this.isCreatedPlan = false;
        this.errorMessage = error.error.info;
        console.error(error);
        this.messageServices.getInfoMessageBadInternet();
      }
    );
  }

  listarPlanes() {
    this.crudServices.getModel('api/plan/listar-todas-menos-admin').subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.datoPlanes = genericResponse.answerList;
          this.tempPlanes = { ...this.datoPlanes };
          this.rowsPlanes = this.datoPlanes;

          console.log(this.rowsPlanes);
        }
        if (genericResponse.code === 400) {
          this.messageServices.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se creó el plan')
        }
      },
      error => {
        this.isCreatedPlan = false;
        this.errorMessage = error.error.info;
        console.error(error);
        this.messageServices.getInfoMessageBadInternet();
      }
    );
  }

  closeWindow() {
    this.modalService.dismissAll();
  }


}
