import { CoownerShipVehicleModel } from './../../../models/entities/coownership-vehicle';
import { GenericResponseModel } from './../../../models/utilities/generic.response.model';
import { VehicleModel } from './../../../models/entities/vehicle';
import { TokenStorageService } from './../../shared/storage-services/token-storage.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoMessagesService } from './../../shared/messages/info-messages.service';
import { CrudServiceService } from './../../shared/backend/cruds/crud-service.service';
import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-update-vehicle',
  templateUrl: './update-vehicle.component.html',
  styleUrls: ['./update-vehicle.component.scss']
})
export class UpdateVehicleComponent implements OnInit {

  @Input() public infoVehicle: VehicleModel;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  errorMessage: any;
  constructor(
    public crudServices: CrudServiceService,
    public messageService: InfoMessagesService,
    public activeModal: NgbActiveModal,
    public tokenStorage: TokenStorageService,
  ) { }

  ngOnInit() {
  }

  onSubmit() {
    this.saveVehicle()

  }

  saveVehicle() {
    this.crudServices.createModel('api/vehiculo/actualizar-vehiculo', this.infoVehicle).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageCreate();
          this.passEntry.emit('vehiculo_creado');
          this.activeModal.dismiss();
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se creó el Vehiculo')
          this.errorMessage = genericResponse.answer;
        }
      },
      error => {
        this.errorMessage = error.error.message;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

}
