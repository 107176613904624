import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { ClientInfoRegisterModel } from 'models/entities/client-info-register.model';
import { GenericResponseModel } from 'models/utilities/generic.response.model';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent implements OnInit {

  @ViewChild('f') clientForm: NgForm;
  clientRegisterModel: ClientInfoRegisterModel = new ClientInfoRegisterModel();

  constructor(
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService
  ) { }

  ngOnInit() {
  }

  onSubmit() {
    console.log(this.clientRegisterModel)
    this.crudService.createModelWA('api-wa/info-cliente/guardar', this.clientRegisterModel).subscribe(
      (genericResponse: GenericResponseModel) => {
        console.log(genericResponse)
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessagePersonalized('success', 'Hemos recibido tu información',
            'Te contáctaremos en un parpadeo');
          //limpiar form
          this.clientForm.reset();
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudo guardar su información',
            'Comuniquese por favor al 300-739-3720');
        }
      },
      error => {
        this.messageService.getInfoMessagePersonalized('warning', 'No se pudo guardar su información',
            'Comuniquese por favor al 300-739-3720');
        console.error('Error al guardar la información del cliente' + JSON.stringify(error))
      }
    );
  }

}
