import { DepartmentModel } from './department-model';

export class CityModel {
    id: string;
    name: string;
    departmentId: DepartmentModel;

    public constructor() {
        this.id = '';
        this.name = '';
        this.departmentId = new DepartmentModel();
    }
}
