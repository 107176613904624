import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserOAuthModel } from '../../../models/entities/user-o-auth-model';
import { GenericResponseModel } from '../../../models/utilities/generic.response.model';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { encode } from 'punycode';
import { changePasswordModel } from '../../../models/entities/change-password.model';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  errorMessage: any;
  passwordModel = new changePasswordModel();
  passwordValidationError = '';
  isErrorPassword = false;

  oldPassword: any;
  newPassword: any;
  passwordConfirmation: any;


  @ViewChild('f') planForm: NgForm;
  constructor(
    private crudServices: CrudServiceService,
    private messageService: InfoMessagesService,
    private modalService: NgbModal, private tokenStorage: TokenStorageService,
  ) { }

  ngOnInit() {
    this.configPage();
  }

  // Se obtiene el id del usuario para actualizar
  // PD: no sirve el que esta en el token storage, ya que provienen de tablas diferentes
  configPage() {
    this.crudServices.getModel('api/usuarios/buscar-usuario?username=' + this.tokenStorage.getUsername()).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.passwordModel.id = genericResponse.genericObject.id;
        }
      }, error => {
        this.messageService.getInfoMessagePersonalized('error', 'Error la carga de la info', 'Error');
      }
    );
  }

  passwordValidation() {
    if (this.newPassword !== this.passwordConfirmation) {
      this.passwordValidationError = '¡Las contraseñas no coinciden!';
      this.isErrorPassword = true;
      return;
    } else if (this.newPassword === this.passwordConfirmation) {
      this.passwordValidationError = '¡Las contraseñas no coinciden!';
      this.isErrorPassword = false;
      return;
    }
  }

  preSubmit() {
    this.passwordValidation();
    this.passwordModel.oldPassword = this.oldPassword;
    this.passwordModel.newPassword = this.newPassword;
    console.log(this.passwordModel);
    this.crudServices.createModel('api/usuarios/actualizar-contrasena', this.passwordModel).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          console.log(genericResponse)
          this.messageService.getInfoMessageUpdate().then(
            (response) => {
              this.modalService.dismissAll();
            }
          )
        } else if (genericResponse.code === 201) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Actualizacion fallida')
        } else if (genericResponse.code === 202) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Actualizacion fallida')
        }

      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
        this.errorMessage = JSON.stringify(error.error.mensaje);
      }
    );
  }

  changePassword() {
      this.crudServices.createModel('api/usuarios/actualizar-contrasena', this.passwordModel).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            console.log(genericResponse)
            this.messageService.getInfoMessageUpdate().then(
              (response) => {
                this.modalService.dismissAll();
              }
            )
          } else if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Actualizacion fallida')
          }

        },
        error => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
          this.errorMessage = JSON.stringify(error.error.mensaje);
        }
      );
  }

  closeWindow() {
    this.modalService.dismissAll();
  }
}
