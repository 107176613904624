export class IncidentTypetModel {
    id: string;
    typeIncident: string;
    estimatedTime: string;

    public constructor() {
        this.id = '';
        this.typeIncident = '';
        this.estimatedTime = '';
    }
}
