import { EquipoModel } from 'models/entities/equipo-model';

export class RutinaModel {

    id: string;
    descripcion: string;
    equipoId: EquipoModel;

    public constructor(){
        this.id = '';
        this.descripcion = '';
        this.equipoId = new EquipoModel();
    }

}