export class TipoEquipoModel {

    id: string;
    nombre: string;
    descripcion: string;

    public constructor(){
        this.id = '';
        this.nombre = '';
        this.descripcion = '';
    }

}