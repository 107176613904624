import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { CrudServiceService } from "app/shared/backend/cruds/crud-service.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TokenStorageService } from "app/shared/storage-services/token-storage.service";
import { InfoMessagesService } from "app/shared/messages/info-messages.service";
import { DoormanVisitorModel } from "../../../models/entities/doorman-visitor-model";
import { GenericResponseModel } from "models/utilities/generic.response.model";
import { DocumentTypeModel } from "models/entities/document-type-model";
import { Console } from "console";

@Component({
  selector: "app-create-doorman-visitor",
  templateUrl: "./create-doorman-visitor.component.html",
  styleUrls: ["./create-doorman-visitor.component.scss"],
})
export class CreateDoormanVisitorComponent implements OnInit {
  public visitorModel = new DoormanVisitorModel();

  public isCreatedVisitor = true;

  rowsOwners = [];
  tempOwners: string[];
  datoOwners: any;

  rowsCoownerShip = [];
  tempCoownerShip: string[];
  datoCoownerShip: any;

  typeDocument = [];
  typeDocumentList: Array<DocumentTypeModel> = new Array();

  @Output()
  passEntry: EventEmitter<any> = new EventEmitter();

  errorMessage: any;

  constructor(
    private crudServices: CrudServiceService,
    private modalService: NgbModal,
    public tokenStorage: TokenStorageService,
    private messageService: InfoMessagesService
  ) {}

  ngOnInit() {
    this.listOwnersAndCoowners();
    //this.listCoownerShip();
    this.listTypeDocument();
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  onSubmit() {
    this.visitorModel.housingUnitId.id = this.tokenStorage.getCompanyId();
    this.visitorModel.receivedBy = this.tokenStorage.getCompleteName();
    this.visitorModel.status = "Pendiente";
    this.visitorModel.departureDate = "";
    console.log(
      "Identificador visitante " + this.visitorModel.coOwnerShipId.id
    );
    this.crudServices
      .createModel("api/porteria/registrar-visitas", this.visitorModel)
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.isCreatedVisitor = true;
            this.messageService.getInfoMessageCreate().then((result) => {
              this.modalService.dismissAll();
              this.passEntry.emit("creado");
            });
          }
          if (genericResponse.code === 400) {
            this.isCreatedVisitor = false;
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Problema registrando la torre"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
          this.isCreatedVisitor = false;
          this.errorMessage = error.error.answer;
        }
      );
  }

  listOwners() {
    {
      this.crudServices
        .getModel(
          "api/usuarios/obtener-propietarios?id=" +
            this.tokenStorage.getCompanyId()
        )
        .subscribe(
          (genericResponse) => {
            if (genericResponse.code === 200) {
              this.datoOwners = genericResponse.answerList;
              this.tempOwners = { ...this.datoOwners };
              this.rowsOwners = this.datoOwners;
              console.log(this.rowsOwners);
            }
            if (genericResponse.code === 400) {
              this.messageService.getInfoMessagePersonalized(
                "warning",
                genericResponse.answer,
                "Sin copropiedades"
              );
            }
          },
          (error) => {
            console.error(error);
            this.messageService.getInfoMessageBadInternet();
          }
        );
    }
  }

  listOwnersAndCoowners() {
    this.crudServices
      .getModel(
        "api/coownership/listar-todo-propietarios-copropietarios-por-unidad-residencial?housingId=" +
          this.tokenStorage.getCompanyId()
      )
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.datoOwners = genericResponse.answerList;
            this.tempOwners = { ...this.datoOwners };
            this.rowsOwners = this.datoOwners;
            console.log(this.rowsOwners);
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No hay propietarios ni copropietarios registrados"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  listCoownerShip() {
    this.crudServices
      .getModel(
        "api/coownership/listar-todo-admin?id=" +
          this.tokenStorage.getCompanyId()
      )
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.datoCoownerShip = genericResponse.answerList;
            this.tempCoownerShip = { ...this.datoCoownerShip };
            this.rowsCoownerShip = this.datoCoownerShip;
            console.log(this.rowsCoownerShip);
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Sin copropiedades"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  listTypeDocument() {
    this.crudServices
      .getModel("api/tipos-de-documento/listar-documentos")
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.typeDocumentList = genericResponse.answerList;
            this.typeDocument = this.typeDocumentList;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Sin documentos"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }
}
