import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { CrudServiceService } from "app/shared/backend/cruds/crud-service.service";
import { TokenStorageService } from "app/shared/storage-services/token-storage.service";
import { InfoMessagesService } from "app/shared/messages/info-messages.service";
import { NewsModel } from "../../../models/entities/news-model";
import swal from "sweetalert2";
import { FirebaseStorageService } from "app/shared/firebase/firebase-storage.service";
import { GenericResponseModel } from "models/utilities/generic.response.model";
import { DatePipe } from "@angular/common";
import { Console } from "@angular/core/src/console";
import { environment } from "environments/environment.prod";
import { HandleServicesService } from "app/shared/handle-service/handle-services.service";

@Component({
  selector: 'app-create-admin-news',
  templateUrl: './create-admin-news.component.html',
  styleUrls: ['./create-admin-news.component.scss']
})
export class CreateAdminNewsComponent implements OnInit {

  themeClass = "ql-snow";

  newsModel = new NewsModel();
  isCreatedNews = true;
  errorMessage: any;

  newM: any;

  minDate = new Date();
  startDate = new DatePipe("es-CO");
  minDate2 = this.startDate.transform(this.minDate, "yyyy-MM-dd");

  //FILE AWS 
  filePhoto: any = null;
  filePhotoRender: any = '';

  urlPeticionImg = environment.serverUrl + 'api/archivos/ver-img?ruta=';
  
  imageStatic: any = 'https://cdn-icons-png.flaticon.com/512/1040/1040241.png';


  // file
  @ViewChild("uploadFile") uploadFile: ElementRef;
  currentFileUpload: File;
  url: any;
  selectedExtension = "";
  typesRestrictions = ["jpg", "PNG", "png", "JPGE"];
  atached = false;
  downloadURL: any;

  constructor(
    private crudServices: CrudServiceService, private handleService: HandleServicesService,
    private firebaseStorageService: FirebaseStorageService,
    public tokenStorage: TokenStorageService,
    private messageService: InfoMessagesService
  ) {}

  ngOnInit() {}

  onSubmit() {
    let datePipe = new DatePipe("es-CO");
    let currentDate: any = new Date();
    currentDate = datePipe.transform(currentDate, "yyyy-MM-dd");

    if (this.newsModel.content == "" || this.newsModel.name == "") {
      this.messageService.getInfoMessagePersonalized(
        "info",
        "Los campos no pueden estar vacíos.",
        "Alerta!"
      );
      return;
    } else if (
      this.newsModel.publicationDate == "" ||
      this.newsModel.dueDate == ""
    ) {
      this.messageService.getInfoMessagePersonalized(
        "info",
        "Los campos no pueden estar vacíos.",
        "Alerta!"
      );
      return;
    } else if (
      this.newsModel.content == null ||
      this.newsModel.content.length == 0
    ) {
      this.messageService.getInfoMessagePersonalized(
        "info",
        "El contenido de la noticia no puede estar vacío",
        "La noticia no tiene contenido"
      );
      return;
    } else if (this.newsModel.content.length <= 10) {
      this.messageService.getInfoMessagePersonalized(
        "info",
        "Se requieren como mínimo 10 caracteres para el contenido de la noticia",
        "Alerta!"
      );
      return;
    } else if (this.newsModel.content.length >= 3000) {
      this.messageService.getInfoMessagePersonalized(
        "info",
        "El contenido de la notica es muy largo",
        "No puede execeder 3.000 caracteres"
      );
      return;
    } else if (this.newsModel.publicationDate < currentDate) {
      this.messageService.getInfoMessagePersonalized(
        "info",
        "La fecha de publicación debe ser igual o mayor a fecha la actual",
        ""
      );
      return;
    } else if (this.newsModel.publicationDate > this.newsModel.dueDate) {
      this.messageService.getInfoMessagePersonalized(
        "info",
        "La fecha de expiración debe ser mayor a la fecha de publicación",
        ""
      );
      return;
    } else {
      this.newsModel.housingUnitId.id = "0";
      this.newsModel.publishedBy.id = "1";
      console.log(this.newsModel);
      this.crudServices
        .createModel("api/noticias/crear-noticia", this.newsModel)
        .subscribe(
          (genericResponse: GenericResponseModel) => {
            if (genericResponse.code === 200) {
              this.newsModel = genericResponse.genericObject;
              if (this.atached) {
                swal({
                  title: "Guardando la noticia!",
                  text: "Sólo tardara unos segundos.",
                  timer: 2000,
                  showConfirmButton: false,
                  imageUrl:
                    "https://ea1hlh.es/wp-content/uploads/2019/04/loading.gif",
                });
                setTimeout(() => {}, 2000);
                this.upload(this.newsModel);
              }

              if (!this.atached) {
                this.newsModel = new NewsModel();
                this.isCreatedNews = true;
                this.messageService.getInfoMessageCreate();
              }
            }
            if (genericResponse.code === 400) {
              this.isCreatedNews = false;
              this.messageService.getInfoMessagePersonalized(
                "warning",
                genericResponse.answer,
                "Problema registrando la correspondencia"
              );
            }
          },
          (error) => {
            console.error(error);
            this.messageService.getInfoMessageBadInternet();
            this.isCreatedNews = false;
            this.errorMessage = error.error.answer;
          }
        );
    }
  }

  cleanContent() {
    this.newsModel.content = "";
  }

  // Upload Image in Firebase

  readUrl(inputValue: any): void {
    if (inputValue.type === "change") {
      const img = new Image();
      this.atached = true;
      img.src = window.URL.createObjectURL(inputValue.target.files.item(0));
      img.onload = () => {
        window.URL.revokeObjectURL(img.src);
        if (img.naturalWidth <= 2000 && img.naturalHeight <= 2000) {
          this.currentFileUpload = inputValue.target.files[0];
          var myReader: FileReader = new FileReader();
          myReader.onloadend = (e) => {
            this.url = myReader.result;
          };
          myReader.readAsDataURL(this.currentFileUpload);
        } else {
          this.messageService.getInfoMessagePersonalized(
            "warning",
            "El tamaño de la imagen debe ser menor o igual a 200 x 200 px",
            "Imagen fuera de proporciones"
          );
        }
      };
    } else {
      this.atached = false;
    }
  }

  selectFile(event) {
    if (
      this.typesRestrictions.includes(
        event.target.files.item(0).name.split(".").pop()
      )
    ) {
      this.currentFileUpload = event.target.files.item(0);
      this.selectedExtension = event.target.files.item(0).name.split(".").pop();
    } else {
      this.currentFileUpload = undefined;
      swal({
        title: "El tipo no es correcto",
        type: "error",
        text: "El archivo debe tener formato jpg, PNG, png ó JPEG, jpeg",
      });
    }
  }

  upload(news: NewsModel) {
    this.firebaseStorageService
      .uploadFile(
        "images/" + "noticias/" + news.id,
        "news." + this.selectedExtension,
        this.currentFileUpload
      )
      .then(
        (responseFirebase) => {
          this.firebaseStorageService
            .loadFile(
              "images/" + "noticias/" + news.id,
              "news." + this.selectedExtension
            )
            .then((dowloadURL) => {
              this.downloadURL = dowloadURL;
              this.addPhoto(this.downloadURL);
            })
            .catch((error) => {
              console.error(error);
              this.messageService.getInfoMessageBadInternet();
            });
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  addPhoto(downloadURL: any) {
    this.newsModel.logoUrl = downloadURL;
    this.crudServices
      .createModel("api/noticias/crear-noticia", this.newsModel)
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.uploadFile.nativeElement.value = "";
            this.newsModel = new NewsModel();
            this.readUrl(event);
            this.messageService.getInfoMessageCreate();
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
          this.errorMessage = error.error.answer;
        }
      );
  }

  //AWS BUCKET S3

  loadPhoto(event, files) {
    this.filePhoto = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (_event) => { this.filePhotoRender = reader.result }
  }

  updateInfo(){
    swal({
      title: '¿Está seguro que desea crear la noticia?',
      text: "",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, crear!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.updateProfile();
      }
    })
  }

  updateProfile() {
    let datePipe = new DatePipe("es-CO");
    let currentDate: any = new Date();
    currentDate = datePipe.transform(currentDate, "yyyy-MM-dd");
  
    let path = 'api/noticias/crear-noticia';

    if (this.newsModel.content == "" || this.newsModel.name == "") {
      this.messageService.getInfoMessagePersonalized(
        "info",
        "Los campos no pueden estar vacíos.",
        ""
      );
      return;
    } else if (
      this.newsModel.publicationDate == "" ||
      this.newsModel.dueDate == ""
    ) {
      this.messageService.getInfoMessagePersonalized(
        "info",
        "Los campos no pueden estar vacíos.",
        ""
      );
      return;
    } else if (
      this.newsModel.content == null ||
      this.newsModel.content.length == 0
    ) {
      this.messageService.getInfoMessagePersonalized(
        "info",
        "El contenido de la noticia no puede estar vacío",
        "La noticia no tiene contenido"
      );
      return;
    } else if (this.newsModel.content.length <= 10) {
      this.messageService.getInfoMessagePersonalized(
        "info",
        "Se requieren como mínimo 10 caracteres para el contenido de la noticia",
        ""
      );
      return;
    } else if (this.newsModel.content.length >= 3000) {
      this.messageService.getInfoMessagePersonalized(
        "info",
        "El contenido de la notica es muy largo",
        "No puede execeder 3.000 caracteres"
      );
      return;
    } else if (this.newsModel.publicationDate < currentDate) {
      this.messageService.getInfoMessagePersonalized(
        "info",
        "La fecha de publicación debe ser igual o mayor a fecha la actual",
        ""
      );
      return;
    } else if (this.newsModel.publicationDate > this.newsModel.dueDate) {
      this.messageService.getInfoMessagePersonalized(
        "info",
        "La fecha de expiración debe ser mayor a la fecha de publicación",
        ""
      );
      return;
    } else {
  
      this.newsModel.housingUnitId.id = "0";
      this.newsModel.publishedBy.id = "1";

      this.newsModel.logoUrl = "";
      console.log(this.newsModel);

      this.crudServices.createModel(path, this.newsModel).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.newsModel = genericResponse.genericObject;
            this.newsModel.logoUrl = this.filePhotoRender;

          if(this.filePhoto !== null) {
            this.uploadPhoto(this.newsModel);
          }
          else {
            this.messageService.getInfoMessageUpdate();
          }

          this.filePhoto = null;
        }
        else {
          this.messageService.getInfoMessagePersonalized("error", genericResponse.answer, "Atención!");
        }
      }, error => {
        this.messageService.getInfoMessagePersonalized('error', 'Error en la actualización.', 'Error');
      }
      );
    }
  }

  uploadPhoto(newsModel) {
    const folderPath = "unidad residencial " + this.tokenStorage.getCompanyId();

    var data = new FormData();
    data.append('archivo', this.filePhoto);
      
    let extension = this.filePhoto.name.split('.').pop();

    let path = 'api/archivos/cargar-foto-noticia?folderPath=' + folderPath + '&relationalId=' + newsModel.id + 
          '&extension=' + extension;
    this.crudServices.createFile(path, data).toPromise().then((genericResponse) => {
      if (genericResponse.code === 200) {
        this.messageService.getInfoMessageUpdate().then(() => {window.location.reload()});
      }
      else {
        this.messageService.getInfoMessagePersonalized(
          'warning', 
          "Se actualizó la información correctamente pero verifique la foto por favor", 
          'Atención!'
        );
      }
    }).catch((error) => {
      this.handleService.handleError(error);
    })
  }
}

