import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MeetingSurveyOwnerComponent } from './meeting-survey-owner/meeting-survey-owner.component';
import { ListQuestionOwnerComponent } from './list-question-owner/list-question-owner.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'reuniones-propietario',
        component: MeetingSurveyOwnerComponent,
      },
      {
        path: 'listado-preguntas',
        component: ListQuestionOwnerComponent,
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MeetingSurveyOwnerRoutingModule { }
