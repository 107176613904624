import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ModalInfo } from '../../../models/entities/modal-info';
import { CrudMantenimientoComponent } from '../crud-mantenimiento/crud-mantenimiento.component';
import { CrudServiceService } from '../../shared/backend/cruds/crud-service.service';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';

import swal from 'sweetalert2';
import { EquipoModel } from 'models/entities/equipo-model';
import { FileModel } from 'models/entities/file-model';
import { FirebaseStorageService } from 'app/shared/firebase/firebase-storage.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { formatDate } from '@angular/common';
import { EquipoAuxModel } from 'models/utilities/equipo-aux-model';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reporte-mantenimientos',
  templateUrl: './reporte-mantenimientos.component.html',
  styleUrls: ['./reporte-mantenimientos.component.scss']
})
export class ReporteMantenimientoComponent implements OnInit {

  rows = [];
  temp: string[];
  board: string;
  errorMessage: string;
  dato: any;
  datosDetalle: any;
  button = [];
  equipoAux = new EquipoAuxModel();
  listaEquipoAux:Array<EquipoAuxModel> = new Array();

  @ViewChild(DatatableComponent) table: DatatableComponent;
  
  modalInfo: ModalInfo;

  constructor(public modalService: NgbModal,
    private crudServices: CrudServiceService,
    private messageService: InfoMessagesService,
    private tokenStorage: TokenStorageService,
    private firebaseServices: FirebaseStorageService,
    public activeModal: NgbActiveModal,
    public route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.reporte()
  }

  getRowClass(row) {

    let total = 0;

    //se valida el tipo de mantenimeinto
    if(row.tipo_mantenimiento == 2 || row.tipo_mantenimiento == 3){
      
      total = (row.cantidadTiempoMantenimiento * 100) / row.tiempoMantenimiento;
      
    }else{
      let fecha = '';

      if(row.programacionUltimoMantenimiento != null){
        fecha = row.programacionUltimoMantenimiento;
      }else{
        fecha = row.programacionFechaInstalacion;
      }

      const parts = fecha.split('-');
      const dateValidUntul = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2])).getTime();

      const fechaActual = formatDate(new Date(), 'yyyy-MM-dd', 'en');
      const parts2 = fechaActual.split('-');
      const currentDate = new Date(parseInt(parts2[0]), parseInt(parts2[1]) - 1, parseInt(parts2[2])).getTime();

      // se multiplica para obtener la diferencia en días
      const diff = Math.trunc(((dateValidUntul - currentDate) / (1000 * 60 * 60 * 24)));

      total = ((row.tiempoMantenimiento - diff) * 100) / row.tiempoMantenimiento;
    }

    total = Number(total.toFixed());

    if(Number(total) >= 85 && Number(total) <= 92){
      return 'row-color-green';
    }else if(Number(total) > 92 && Number(total) <= 100){
      return 'row-color-yellow';
    }else if(Number(total) >= 101){
      return 'row-color-red';
    }

    // // segun la diferencia en días se le agrega una clase por css
    // if (diff <= 45 && diff >= 30) {
    //   return 'row-color-green';
    // } else if (diff <= 29 && diff >= 15) {
    //   return 'row-color-yellow';
    // } else if (diff <= 14 && diff >= 1) {
    //   return 'row-color-red'
    // } else if (diff < 1) {
    //   return 'row-color-blue'
    // }
  }

  reporte() {
    this.crudServices.getModel('api/equipo/reporte?unidadResidencialId='  + this.tokenStorage.getCompanyId()).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          /*this.dato = genericResponse.answerList;
          this.temp = [...this.dato];
          this.rows = this.dato;*/
          
          this.equipoAux = genericResponse.answerList;
          this.calcularPorcentajes(this.equipoAux);
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin equipos')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  calcularPorcentajes(equipoAux){
    let total = 0;

    for(var i in equipoAux){
      //se valida el tipo de mantenimeinto =  cantidades
      if(equipoAux[i].tipo_mantenimiento == 2 || equipoAux[i].tipo_mantenimiento == 3){
        
        total = (equipoAux[i].cantidadTiempoMantenimiento * 100) / equipoAux[i].tiempoMantenimiento;
        
      }else{// dias
        let fecha = '';

        if(equipoAux[i].programacionUltimoMantenimiento != null){
          fecha = equipoAux[i].programacionUltimoMantenimiento;
        }else{
          fecha = equipoAux[i].programacionFechaInstalacion;
        }

        const parts = fecha.split('-');
        const dateValidUntul = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2])).getTime();

        const fechaActual = formatDate(new Date(), 'yyyy-MM-dd', 'en');
        const parts2 = fechaActual.split('-');
        const currentDate = new Date(parseInt(parts2[0]), parseInt(parts2[1]) - 1, parseInt(parts2[2])).getTime();

        // se multiplica para obtener la diferencia en días
        const diff = Math.trunc(((dateValidUntul - currentDate) / (1000 * 60 * 60 * 24)));

        total = ((equipoAux[i].tiempoMantenimiento - diff) * 100) / equipoAux[i].tiempoMantenimiento;
      }

      equipoAux[i].porcentaje =  total.toFixed();

      total = Number(total.toFixed());


      if(Number(total) >= 85){
        this.listaEquipoAux.push(this.equipoAux[i]);
      }


    }

    this.ordenarDesc(this.listaEquipoAux, 'porcentaje');
    let contador = 0;

    // se agrega % a la cantidad porcentaje
    for(var i in this.listaEquipoAux){
      this.listaEquipoAux[i].porcentaje = this.listaEquipoAux[i].porcentaje + ' %';
      contador++;
    }

    if(contador == 0){
      this.activeModal.close();
    }

    this.rows = this.listaEquipoAux;
  }

  ordenarAsc(p_array_json, p_key) {
    p_array_json.sort(function (a, b) {
       return a[p_key] > b[p_key];
    });
  }

  ordenarDesc(p_array_json, p_key) {
    this.ordenarAsc(p_array_json, p_key); p_array_json.reverse(); 
  }

  enviarPaginaMantenimiento(id){
    this.activeModal.close();
    this.router.navigate(['/mantenimiento/listar-equipos', { id }], { relativeTo: this.route.parent });
  }

}