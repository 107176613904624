import {TokenStorageService} from './../../shared/storage-services/token-storage.service';
import {UserCreationModel} from './../../../models/entities/user-creation-model';
import {InfoMessagesService} from 'app/shared/messages/info-messages.service';
import {GenericResponseModel} from 'models/utilities/generic.response.model';
import {CrudServiceService} from 'app/shared/backend/cruds/crud-service.service';
import {DocumentTypeModel} from 'models/entities/document-type-model';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {employeeModel} from 'models/entities/employee';

@Component({
  selector: 'app-create-employee',
  templateUrl: './create-employee.component.html',
  styleUrls: ['./create-employee.component.scss'],
})
export class CreateEmployeeComponent implements OnInit {
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  newEmployee: employeeModel = new employeeModel();
  listDocumentTypes: Array<DocumentTypeModel> = [];
  errorMessage: String;
  userCreation: UserCreationModel = new UserCreationModel();
  passwordConfirmation = '';
  passwordValidationError = '';
  isErrorPassword = false;
  termsAndConditions = false;

  constructor(
    public modalService: NgbModal,
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    public activeModal: NgbActiveModal,
    public tokenStorage: TokenStorageService
  ) {
  }

  ngOnInit() {
    this.getDocumentsTypes();
  }

  onSubmit() {
    this.saveUser();
  }

  saveUser() {
    this.userCreation.userInfo.role = 'ROLE_EMPLOYEE';
    this.userCreation.userInfo.phone = this.userCreation.userInfo.cellphone;
    this.userCreation.user.username = this.userCreation.userInfo.cellphone;
    this.userCreation.userInfo.completeName = `${this.userCreation.userInfo.name} ${this.userCreation.userInfo.lastName}`;
    this.userCreation.userInfo.housingId = this.tokenStorage.getCompanyId();
    this.crudService
      .createModel('api/usuarios/crear', this.userCreation)
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 201) {
            this.userCreation = new UserCreationModel();
            this.activeModal.dismiss();
            this.passEntry.emit('empleado_creado');
            this.newEmployee.idUser = genericResponse.genericObject;
            this.saveEmployee();
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              'warning',
              genericResponse.answer,
              'No se creó el usuario'
            );
            this.errorMessage = genericResponse.answer;
          }
        },
        (error) => {
          this.errorMessage = error.error.message;
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  saveEmployee() {
    this.newEmployee.housingUnitId.id = this.tokenStorage.getCompanyId();
    this.crudService
      .createModel('api/empleado/guardar', this.newEmployee)
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 201) {
            this.userCreation = new UserCreationModel();
            this.messageService.getInfoMessageCreate();
            this.activeModal.dismiss();
            this.passEntry.emit('empleado_creado');
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              'warning',
              genericResponse.answer,
              'No se creó el empleado'
            );
            this.errorMessage = genericResponse.answer;
          }
        },
        (error) => {
          this.errorMessage = error.error.message;
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  getDocumentsTypes() {
    const pathListDOcumentTypesUrl =
      'api/tipos-de-documento/ver-por-objetivo?objective=PERSONAL';
    this.listDocumentTypes = [];
    this.crudService.getModel(pathListDOcumentTypesUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.listDocumentTypes = genericResponse.answerList;
        } else {
          this.messageService.getInfoMessagePersonalized(
            'warning',
            'No se pudieron listar los tipos de documentos del sistema',
            'Problema consultando los tipos de documentos del sistema'
          );
        }
      },
      (error) => {
        this.messageService.getInfoMessageError();
        console.error(
          'Error al cargar los tipos de documentos del sistema ' +
          JSON.stringify(error)
        );
      }
    );
  }

  passwordValidation() {
    if (this.userCreation.user.password !== this.passwordConfirmation) {
      this.passwordValidationError = '¡Las contraseñas no coinciden!';
      this.isErrorPassword = true;
      setTimeout(() => {
        this.isErrorPassword = false;
      }, 3000);
    }
  }
}
