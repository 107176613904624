import { CoownershipModel } from './coowner-ship-model';
import { HousingUnitModel } from './housing-unit-model';
import { TypePetModel } from './type-pets.model';

export class PetModel {

	id: String;
	name: String;
	race: String;
	typePet: TypePetModel;
	dateBirth: String;
	petPicture: String;
	coOwnerShip: CoownershipModel;
	housingUnitId: HousingUnitModel;

	public constructor() {
		this.id = '';
		this.name = '';
		this.race = '';
		this.typePet = new TypePetModel();
		this.dateBirth = '';
		this.petPicture = '';
		this.coOwnerShip = new CoownershipModel();
		this.housingUnitId = new HousingUnitModel();
	}
}