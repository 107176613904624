import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { CreateIncidentComponent } from '../create-incident/create-incident.component';
import { IncidentModel } from 'models/entities/incident-model';
import { DetailIncidentComponent } from '../detail-incident/detail-incident.component';
import { UpdateIncidentComponent } from '../update-incident/update-incident.component';
import swal from 'sweetalert2';
import { GenericResponseModel } from 'models/utilities/generic.response.model';

@Component({
  selector: 'app-list-incident-owner',
  templateUrl: './list-incident-owner.component.html',
  styleUrls: ['./list-incident-owner.component.scss']
})
export class ListIncidentOwnerComponent implements OnInit {

  public incidentList: Array<IncidentModel> = new Array();
  rows = [];
  temp: string[];
  dato: any;
  infoIncident = new IncidentModel();
  errorMessage: string;

  columns = [
    { prop: 'id' },
    { prop: 'title' },
    { prop: 'description' },
  ];

  constructor(
    private modalService: NgbModal, private crudServices: CrudServiceService,
    private messageService: InfoMessagesService, private tokenStorage: TokenStorageService,
  ) { }

  ngOnInit() {
    this.configPage();
  }

  configPage() {
    this.crudServices.getModel('api/incidentes/ver-mis-incidentes-propietario?id=' + this.tokenStorage.getCoownerShipId()).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.dato = genericResponse.answerList;
          this.temp = { ...this.dato };
          this.rows = this.dato;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin incidentes')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  openCreateIncident() {
    const modalRef = this.modalService.open(CreateIncidentComponent, {
      windowClass: '', size: 'lg', backdrop: 'static',
    });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
      if (receivedEntry === 'creado') {
        this.configPage();
      } else {
        return false;
      }
    })
  }

  detailIncident(id) {
    this.crudServices.getModel('api/incidentes/buscar-incidente?id=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.infoIncident = genericResponse.genericObject
          const modalRef = this.modalService.open(
            DetailIncidentComponent, {
              windowClass: '', size: 'lg', backdrop: 'static',
            }
          );
          modalRef.componentInstance.incidentInfo = this.infoIncident;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error buscando el incidente')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  updateIncident(id) {
    this.crudServices.getModel('api/incidentes/buscar-incidente?id=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.infoIncident = genericResponse.genericObject
          const modalRef = this.modalService.open(
            UpdateIncidentComponent, {
              windowClass: '', size: 'lg', backdrop: 'static'
            }
          );
          modalRef.componentInstance.incidentInfo = this.infoIncident;
          modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
            if (receivedEntry === 'creado') {
              this.configPage();
            } else {
              return false;
            }
          })
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error actualizando el incidente')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  onIncident(id: any) {
    this.crudServices.deleteModel('api/incidentes/eliminar-incidente?id=' + id).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageDelete().then(
            () => {
              this.configPage();
            }
          )
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se eliminó la Unidad')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
        this.errorMessage = `${error.status}: ${error.error.message}`;
      }
    );
  }

  deleteIncident(id: any) {
    event.preventDefault(); // prevent form submit
    swal({
      title: '¿Está seguro que desea eliminar el incidente?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.onIncident(id);
      }
    })
  }

}
