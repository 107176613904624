import { Component, OnInit, ViewChild } from '@angular/core';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { NgForm } from '@angular/forms';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountRequest } from 'models/request/AccountRequest';
import { DocumentTypeModel } from 'models/entities/document-type-model';
import { DepartmentModel } from 'models/entities/department-model';
import { CityModel } from 'models/entities/city-model';
import { HousingUnitModel } from 'models/entities/housing-unit-model';
import { HousingUnitTypeModel } from 'models/entities/housing-unit-type-model';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  lstDocumentsPersonTypes = [];
  lstCompanyDocumentTypes = [];
  listOfNotWorking = [];
  lstGenders = [];

  acceptTerms = false;
  sameInfo = false;
  password2 = '';
  loading = false;

  public housingModel = new HousingUnitModel();
  typeDocumentList: Array<DocumentTypeModel> = new Array();
  //listDepartamentos: Array<DepartmentModel> = new Array();
  //listCiudades: Array<CityModel> = new Array();
  Citys = [];
  citysList: Array<CityModel> = new Array();
  getDeparmentId  = '';
  Department = [];
  departmentList: Array<DepartmentModel> = new Array();
  accountRequest: AccountRequest = new AccountRequest();

  typeHousing = [];
  typeHousingUnitList: Array<HousingUnitTypeModel> = new Array();

  @ViewChild('f') registerForm: NgForm;

  constructor(
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    private router: Router,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.listTypeDocument();
    //this.getDepartamentos();
    this.listDepartments();
    this.listTypeHousing();
  }

  
  onSubmit() {

    
    if (this.loading) {
      return;
    }
    this.loading = true;

    if (!this.validatePasswords()) {
      return;
    }

   this.accountRequest.user.username = this.accountRequest.userInfo.email;
  

    const url = 'api/usuarios/crear-cuenta-gratuita';

    console.log(JSON.stringify(this.accountRequest));

    this.crudService.createModelSA(url, this.accountRequest).toPromise().then((response: GenericResponseModel) => {
      if(response.code === 200) {

        this.messageService.getInfoMessagePersonalized(
          'success',
          'Hemos enviado un correo electrónico para que puedas activar tu cuenta',
          'Cuenta creada!'
        );

        this.activeModal.dismiss();
        this.router.navigateByUrl('pages/auth');

        this.accountRequest = new AccountRequest();
        this.loading = false;
      }
      else {
        this.loading = false;
        this.messageService.getInfoMessagePersonalized(
          'error',
          response.answer,
          'Atención!'
        );
      }
      
    })
    .catch(error => {
      this.loading = false;
      console.log(error);
      swal({ type: 'error', text: error.answer });
    })
  }

  listTypeHousing() {
    this.crudService.getModelSA('api/tipos-de-unidad-residencial/ver-todos').subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.typeHousingUnitList = genericResponse.answerList;
          this.typeHousing = this.typeHousingUnitList;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No hay tipos de Unidad')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  listTypeDocument() {
    this.crudService.getModelSA('api/tipos-de-documento/listar-documentos').subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.typeDocumentList = genericResponse.answerList;
        }
        else if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin documentos')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }
 
  validatePasswords() {
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9a-zA-Z]).{6,40}$/;

    if (!regex.test(this.accountRequest.user.password)) {
      swal({ type: 'error', text: 'La contraseña debe contener un dígito, una letra minúscula, una letra mayúscula y debe contener entre 6 y 40 caracteres' });
      return false;
    }
    if (this.accountRequest.user.password !== this.password2) {
      swal({ type: 'error', text: 'Las contraseñas no coinciden' });
      return false;
    }
    

    return true;
  }


  
  
  listDepartments() {
    this.crudService.getModelSA('api/departamentos/listar-departamentos').subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.departmentList = genericResponse.answerList;
          this.Department = this.departmentList;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin departamentos')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  listCitys() {
    this.getDeparmentId = this.accountRequest.housingUnit.departmentId.id;
    this.crudService.getModelSA('api/ciudades/listar-ciudades?id=' + this.getDeparmentId).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.citysList = genericResponse.answerList;
          this.Citys = this.citysList;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin ciudades')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  goBack() {
    this.activeModal.dismiss();
    this.router.navigateByUrl('welcome');
  }
}
