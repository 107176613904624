import { UpdateEmployeeComponent } from './update-employee/update-employee.component';
import { DetailEmployeeComponent } from './detail-employee/detail-employee.component';
import { CreateEmployeeComponent } from './create-employee/create-employee.component';
import { RoleAuthGuard } from 'app/shared/auth/roleAuth-guard.service';
import { ListEmployeeComponent } from './list-employee/list-employee.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [

  {
    path: '',
    children: [
      {
        path: 'listar-empleados',
        component: ListEmployeeComponent,
        data: {
          expectedRole: ['ROLE_ADMIN']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'crear-empleados',
        component: CreateEmployeeComponent,
        data: {
          expectedRole: ['ROLE_ADMIN']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'detail-empleados',
        component: DetailEmployeeComponent,
        data: {
          expectedRole: ['ROLE_ADMIN']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'update-empleados',
        component: UpdateEmployeeComponent,
        data: {
          expectedRole: ['ROLE_ADMIN']
        },
        canActivate: [RoleAuthGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmployeeRoutingModule { }
