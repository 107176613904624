import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileModel } from 'models/entities/profile-model';

@Component({
  selector: 'app-detail-profile',
  templateUrl: './detail-profile.component.html',
  styleUrls: ['./detail-profile.component.scss']
})
export class DetailProfileComponent implements OnInit {

  @Input() public profileInfo = new ProfileModel()
  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }
  closeWindow() {
    this.modalService.dismissAll();
  }
}
