import { QuestionOptionModel } from './question-option-model';
import { QuestionModel } from './question-model';
export class QuestionResponseModel {
    question: QuestionModel;
    listQuestionOption: Array<QuestionOptionModel>;

    constructor() {
        this.question = new QuestionModel();
        this.listQuestionOption = new Array();
    }
}
