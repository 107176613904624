import { VehicleModel } from './vehicle';
import { CoownershipModel } from './coowner-ship-model';
export class CoownerShipVehicleModel {
    id: string;
    coOwnershipId: CoownershipModel;
    vehicleId: VehicleModel;
    constructor() {
        this.id = '';
        this.coOwnershipId = new CoownershipModel();
        this.vehicleId = new VehicleModel();
    }
}