export class FilterDoormanCorrespondenceModel {
    coOwnerShipId: string;
    housingId: number;
    receiptDate: string;
    deliverDate: string;

    public constructor() {
        this.coOwnerShipId = null;
        this.housingId = null;
        this.receiptDate = '';
        this.deliverDate = '';
    }
}
