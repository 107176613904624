import { ModalInformationModel } from 'models/utilities/modal-information-model';
import { EquipoModel } from '../../../models/entities/equipo-model';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { GenericResponseModel } from '../../../models/utilities/generic.response.model';

import { FormGroup, Validators, FormBuilder, ValidatorFn, AbstractControl } from '@angular/forms';
import swal from 'sweetalert2';

@Component({
  selector: 'app-registro-cantidades',
  templateUrl: './registro-cantidades.component.html',
  styleUrls: ['./registro-cantidades.component.scss']
})
export class RegistroCantidadesComponent implements OnInit {

  idCopropiedad = this.tokenStorage.getCompanyId()

  @Input() public equipoInformacion;
  @Input() public modalInfo = new ModalInformationModel();
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  errorMessage: any;

  form: any = {};
  formGroup: FormGroup;
  equipoModel = new EquipoModel();
  cantidadTiempoMantenimiento = '';
  cantidadActual = 0;

  constructor(
    private crudServices: CrudServiceService,
    private messageService: InfoMessagesService,
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private tokenStorage: TokenStorageService ) {

      this.formGroup = this.fb.group({
        'cantidadTiempoMantenimiento': ['', [Validators.required]]
      });

  }

  ngOnInit() {
    this.equipoModel = this.equipoInformacion;
    this.calcularCantidadActual();
  }

  calcularCantidadActual(){
    this.cantidadActual = Number(this.equipoModel.tiempoMantenimiento) - Number(this.equipoModel.cantidadTiempoMantenimiento);
  }

  registrarCantidad(){
    
    swal({
      title: 'Esta seguro que desea registrar la cantidad?',
      text: '',
      type: 'success',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, registrar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {

        //nuevo valor de la cantidad
        let nuevaValor =  Number(this.equipoModel.cantidadTiempoMantenimiento) + Number(this.cantidadTiempoMantenimiento);

        this.equipoModel.cantidadTiempoMantenimiento = nuevaValor.toString();

        this.crudServices.createModel('api/equipo/crear', this.equipoModel).subscribe(
          (genericResponse: GenericResponseModel) => {
            if (genericResponse.code === 200) {
    
                this.messageService.getInfoMessageCreate();
                this.passEntry.emit('registro_cantidad_creado');
                this.activeModal.close();
            }
            if (genericResponse.code === 400) {
              this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se creó la cantidad')
              this.errorMessage = genericResponse.answer;
            }
          },
          error => {
            this.errorMessage = error.error.message;
            console.error(error);
            this.messageService.getInfoMessageBadInternet();
          }
        );
      }
    })
    
  }

}
