import { RelationalFileModel } from '../../../models/utilities/relational-type-model';
import { ModalInformationModel } from 'models/utilities/modal-information-model';
import { EquipoModel } from '../../../models/entities/equipo-model';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { GenericResponseModel } from '../../../models/utilities/generic.response.model';

import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import swal from 'sweetalert2';
import { FileModel } from 'models/entities/file-model';
import { FirebaseStorageService } from 'app/shared/firebase/firebase-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MantenimientoModel } from 'models/entities/mantenimiento-model';
import { DetalleMantenimientoModel } from 'models/entities/detalle-mantenimiento-model';

@Component({
  selector: 'app-crud-mantenimiento',
  templateUrl: './crud-mantenimiento.component.html',
  styleUrls: ['./crud-mantenimiento.component.scss']
})
export class CrudMantenimientoComponent implements OnInit {

  currentJustify = 'start';
  idCopropiedad = this.tokenStorage.getCompanyId();

  @Input() public mantenimientoInfo;
  @Input() public modalInfo = new ModalInformationModel();
  @Input() public equipoInfo;
  @Input() public cantidadMantenimientos;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  errorMessage: any;
  usuarioSesion: String;

  form: any = {};
  formGroup: FormGroup;
  formGroupGes: FormGroup;
  mantenimientoModel = new MantenimientoModel();
  detalleMantenimientoModel = new DetalleMantenimientoModel();
  equipoModel = new EquipoModel();
  selectedFiles: any = undefined;
  selectedExtension = '';
  idUsuarioSesion = '';
  restrictions = new Array('JPEG', 'PNG', 'png', 'jpeg', 'jpg');
  fileModel: FileModel = new FileModel();
  fileModelTemp: FileModel = new FileModel();

  listaTipos = [{id:'Preventivo',nombre:'Preventivo'},{id:'Correctivo',nombre:'Correctivo'}];
  listaEstados = [{id:'En Servicio',nombre:'En Servicio'},{id:'Fuera de Servicio',nombre:'Fuera de Servicio'}];
  listaEstadoMantenimiento = [{id:'Asignada',nombre:'Asignada'},
                              {id:'En Tramite',nombre:'En Tramite'}
                             ];
  listaEstadodetalleMantenimiento = [{id:'Asignada',nombre:'Asignada'},
                              {id:'En Tramite',nombre:'En Tramite'},
                              {id:'Finalizado',nombre:'Finalizado'}
                            ];
                             

  listaColaboradores = [];
  listaDetalleMantenimiento = [];

  nombreFactura = '';
  
  public relationalModel: RelationalFileModel = new RelationalFileModel();

  constructor(
    private crudServices: CrudServiceService,
    private messageService: InfoMessagesService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private tokenStorage: TokenStorageService,
    private firebaseStorageService: FirebaseStorageService,
    private storageInfoCtrl: TokenStorageService,
    private spinner: NgxSpinnerService,
    private firebaseServices: FirebaseStorageService ) {

      this.formGroup = this.fb.group({
        'programacion': [''],
        'ejecucion': [''],
        'tipo': ['', [Validators.required]],
        'estado': [''],
        'estadoMantenimiento': ['', [Validators.required]],
        'usuarioAsignar': [''],
        'descripcion': ['']
      });

      this.formGroupGes = this.fb.group({
        'costoManoObra': [''],
        'costoRepuesto': [''],
        'observacionManoObra': [''],
        'observacionRepuesto': [''],
        'estadoMantenimientoDetalle': ['', [Validators.required]],
        'usuarioAsignarDetalle': [''],
      });

  }

  ngOnInit() {
    
    if (!this.modalInfo.isCreate){
      this.mantenimientoModel = this.mantenimientoInfo;
      //this.fileModelTemp = this.mantenimientoModel.firebaseId;
      
    } else {
      this.mantenimientoModel = new MantenimientoModel();
      this.fileModelTemp = null;
    }

    this.usuarioSesion = this.tokenStorage.getUsername();
    this.equipoModel = this.equipoInfo;

    if (this.modalInfo.isCreate){
      // se valida si el equipo tiene mantenimientos registrados para obtener la fecha de programacion
      if(this.cantidadMantenimientos == 0){
        this.obtenerFechaProgramacionxFechaInstalacion();
      }else{
        this.obtenerFechaProgramacionUltimoMantenimiento();
      }
      // se obtiene la fecha actual
      this.mantenimientoModel.fechaEjecucion = this.obtenerFechaActual();
    }

    this.obtenerColaboradoresUnidadResidencial();

    if(this.mantenimientoModel.id != ''){
      this.obtenerdetalleMantenimientos();
    }

    //usuario en sesion '
    this.idUsuarioSesion = this.tokenStorage.getIdSesion();

    // se inicializa el modelo relationalModel
    this.relationalModel.relationalId = this.mantenimientoModel.id;
    this.relationalModel.relationalRestrictions = new Array();

  }

  crearImagen(){
    this.uploadFile().then((urlResult) => {
      this.fileModel.downloadUrl = urlResult;
      this.crudServices.createModelWA('api/archivo-firebase/crear', this.fileModel).subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.messageService.getInfoMessageCreate().then(
              (response) => {
                this.selectFile = undefined;
                this.crearMatenimientoImagen(genericResponse.genericObject);
              }
            )
          } else if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se pudo crear el archivo')
            this.spinner.hide();
          }
        }
      )
    }).catch((error) => {
      console.error(error);
      this.messageService.getInfoMessageBadInternet();
    })
  }

  crearMantenimiento(){

    
    this.mantenimientoModel.equipoId = this.equipoModel;

    swal({
      title: 'Esta seguro que desea registrar el mantenimiento?',
      text: '',
      type: 'success',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, registrar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        
        this.spinner.show();
        this.crudServices.createModel('api/mantenimiento/crear', this.mantenimientoModel).subscribe(
          (genericResponse: GenericResponseModel) => {
            if (genericResponse.code === 200) {

              this.mantenimientoModel = new MantenimientoModel();
              this.mantenimientoModel = genericResponse.genericObject;
              this.crearDetalleMantenimiento();

              /*if(this.selectedFiles == undefined){
                this.messageService.getInfoMessageCreate();
                this.passEntry.emit('mantenimiento_creado');
                this.activeModal.close();
              }else{
                //configuracion archivo - imagen
                this.fileModel.relationalId = genericResponse.genericObject.id;
                this.mantenimientoModel.id = genericResponse.genericObject.id;
                this.fileModel.relationalType = 'FACTURA_MANTENIMIENTO';
                this.fileModel.fileName = this.nombreFactura;
                this.fileModel.idCreator = this.storageInfoCtrl.getId();
                this.crearImagen();
              }*/
            }
            if (genericResponse.code === 400) {
              this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se creó el mantenimiento')
              this.errorMessage = genericResponse.answer;
            }
          },
          error => {
            this.errorMessage = error.error.message;
            console.error(error);
            this.messageService.getInfoMessageBadInternet();
          }
        );
      }
    })
    
  }

  crearDetalleMantenimiento(){

    this.detalleMantenimientoModel.estadoMantenimiento = this.mantenimientoModel.estadoMantenimiento;
    this.detalleMantenimientoModel.responsableId.id = this.mantenimientoModel.responsableId.id;
    this.detalleMantenimientoModel.mantenimientoId = this.mantenimientoModel;

    this.crudServices.createModel('api/detalle-mantenimiento/crear', this.detalleMantenimientoModel).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageCreate();
          this.passEntry.emit('mantenimiento_creado');
          this.obtenerdetalleMantenimientos();

          
          this.detalleMantenimientoModel = new DetalleMantenimientoModel();

          this.spinner.hide();
          //this.activeModal.close();
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se creó el detalle smantenimiento')
          this.errorMessage = genericResponse.answer;
        }
      },
      error => {
        this.errorMessage = error.error.message;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  crearDetalle(){

    this.detalleMantenimientoModel.mantenimientoId = this.mantenimientoModel;

    if(this.detalleMantenimientoModel.responsableId.id == null){
      this.detalleMantenimientoModel.responsableId = null;
    }else{
      this.mantenimientoModel.responsableId.id = this.detalleMantenimientoModel.responsableId.id;
    }

    swal({
      title: 'Esta seguro que desea registrar el detalle?',
      text: '',
      type: 'success',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, registrar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.crudServices.createModel('api/detalle-mantenimiento/crear', this.detalleMantenimientoModel).subscribe(
          (genericResponse: GenericResponseModel) => {
            if (genericResponse.code === 200) {
              //this.messageService.getInfoMessageCreate();
              this.obtenerdetalleMantenimientos();

              //se actualiza el estado del mantenimiento

              this.mantenimientoModel.estadoMantenimiento = this.detalleMantenimientoModel.estadoMantenimiento;
              
              this.actualizarMantenimiento();
              this.detalleMantenimientoModel = new DetalleMantenimientoModel();
            }
            if (genericResponse.code === 400) {
              this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se creó el detalle mantenimiento')
              this.errorMessage = genericResponse.answer;
            }
          },
          error => {
            this.errorMessage = error.error.message;
            console.error(error);
            this.messageService.getInfoMessageBadInternet();
          }
        );
      }
    })

  }

  actualizarMantenimiento(){

    this.mantenimientoModel.equipoId = this.equipoModel;

    if(this.selectedFiles == undefined){
      //this.mantenimientoModel.firebaseId = null;
    }

    this.crudServices.createModel('api/mantenimiento/crear', this.mantenimientoModel).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {

          if(this.selectedFiles == undefined){
            this.messageService.getInfoMessageCreate();
            this.spinner.hide();
            //this.passEntry.emit('mantenimiento_creado');
            //this.activeModal.close();
          }else{
            //configuracion archivo - imagen
            this.fileModel.relationalId = genericResponse.genericObject.id;
            this.mantenimientoModel.id = genericResponse.genericObject.id;
            this.fileModel.relationalType = 'REPUESTOS';
            this.fileModel.fileName = this.nombreFactura;
            this.fileModel.idCreator = this.storageInfoCtrl.getId();
            
            
              this.crearImagen();
           
          }
          
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se actualizo el mantenimiento')
          this.errorMessage = genericResponse.answer;
        }
      },
      error => {
        this.errorMessage = error.error.message;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  crearMatenimientoImagen(firebaseModel){

    this.mantenimientoModel.equipoId.id = this.equipoModel.id;
    //this.mantenimientoModel.firebaseId = firebaseModel;
    this.crudServices.createModel('api/mantenimiento/crear', this.mantenimientoModel).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {

         
          if(this.fileModelTemp != null){
            this.deleteFile(this.fileModelTemp);
          }
          
          //this.messageService.getInfoMessageCreate();
          this.passEntry.emit('mantenimiento_creado');
          this.spinner.hide();
          this.activeModal.close();

        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se creó el mantenimiento')
          this.errorMessage = genericResponse.answer;
        }
      },
      error => {
        this.errorMessage = error.error.message;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  selectFile(event) {

    console.log(event.target.files.item(0));
    console.log((Number(event.target.files.item(0).size) / 1000 / 1000));

    this.nombreFactura = event.target.files.item(0).name;
    
    if ((Number(event.target.files.item(0).size) / 1000 / 1000) <= 500) {
      if (this.restrictions.length === 0) {
        this.selectedFiles = event.target.files.item(0);
        
        this.selectedExtension = this.selectedFiles.name.split('.').pop()
      } else {
        this.selectedFiles = event.target.files.item(0);
        if (this.restrictions.includes(this.selectedFiles.name.split('.').pop())) {
          this.selectedExtension = this.selectedFiles.name.split('.').pop()
        } else {
          this.selectedFiles = undefined;
          swal({
            title: 'El tipo de archivo seleccionado no es correcto', type: 'error',
            text: 'El archivo debe tener formato ' + this.restrictions.toString()
          })
        }
      }
    } else {
      this.selectedFiles = undefined;
      swal({
        title: 'El archivo es demasiado pesado', type: 'error',
        text: 'El archivo no puede pesar mas de ' + 500 + 'mb'
      })
    }
  }

  uploadFile(): Promise<any> {
    return new Promise((resolve, reject) => {
      const realTimeUpload = new Date().getTime()
      if (this.fileModel.relationalType === '') {
        this.fileModel.relationalType = 'FILE'
      }
      if (this.fileModel.fileType === '') {
        this.fileModel.fileType = 'FILE'
      }
      const folderPath = this.storageInfoCtrl.getCompanyId() + '/' + this.fileModel.idCreator + '/'
        + this.fileModel.relationalType + '/' + this.fileModel.relationalId + '/';
      //const fileName = this.fileModel.fileName.trim() + '-' + realTimeUpload + '.' + this.selectedExtension;
      const fileName = this.fileModel.fileName.trim() + '.' + this.selectedExtension;
      this.fileModel.pathFirebaseFile = folderPath + fileName;
      this.fileModel.fileName = fileName
      this.firebaseStorageService.uploadFile(folderPath, fileName, this.selectedFiles).then(
        (responseFirebase) => {
          this.firebaseStorageService.loadFile(folderPath, fileName).then(
            (dowloadURL) => {
              resolve(dowloadURL);
            }
          ).catch(error => {
            console.error(error);
            this.messageService.getInfoMessageBadInternet();
            reject(error)
          })
        }, (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
          reject(error)
        })
    })
  }

  

  deleteFile(fileModelTemp) {

    let fileToDelete: FileModel;
    fileToDelete = fileModelTemp;

    const url = 'api/archivo-firebase/borrar-por-id?idFile=' + fileToDelete.id;
    this.crudServices.deleteModel(
      url
    ).subscribe((genericResponse: GenericResponseModel) => {
      if (genericResponse.code === 200) {
        this.firebaseServices.deleteFile(fileToDelete.pathFirebaseFile).then((data) => {
          console.log("se elimina la imagen " + fileModelTemp.fileName);
        })
      } else {
        this.messageService.getInfoMessagePersonalized('warning', 'No se pudo eliminar', 'Algo salio mal');
      }
    }, error => {
      this.messageService.getInfoMessageBadInternet();
      console.error(error);
    })
  }

  obtenerFechaProgramacionxFechaInstalacion() {
    this.crudServices.getModel('api/mantenimiento/obtner-fecha-instalacion?equipoId=' + this.equipoModel.id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.mantenimientoModel.fechaProgramacion = genericResponse.genericObject;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin fecha de programacion')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  obtenerFechaProgramacionUltimoMantenimiento() {
    this.crudServices.getModel('api/mantenimiento/obtener-fecha-ultimo-mantenimiento?equipoId=' + this.equipoModel.id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.mantenimientoModel.fechaProgramacion = genericResponse.genericObject;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin fecha de programacion')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  obtenerFechaActual(){
    let hoy = new Date();
    let dd = hoy.getDate();
    let mm = hoy.getMonth()+1;
    let yyyy = hoy.getFullYear();
        
    dd = this.addZero(dd);
    mm = this.addZero(mm);

    return yyyy + "-" + mm + "-" + dd;

  }

  addZero(i) {
    if (i < 10) {
        i = '0' + i;
    }
    return i;
}

obtenerColaboradoresUnidadResidencial() {
  this.crudServices.getModel('api/detalle-mantenimiento/listar-colaboradores-unidad-residencial?id=' + this.idCopropiedad).subscribe(
    (genericResponse: GenericResponseModel) => {
      if (genericResponse.code === 200) {
        for(let i in genericResponse.answerList){

          this.listaColaboradores.push({id:genericResponse.answerList[i][0],nombre:genericResponse.answerList[i][1]});

        }
      }
      if (genericResponse.code === 400) {
        this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se encontraron colaboradores')
      }
    }, error => {
      this.messageService.getInfoMessageBadInternet();
    }
  );
}

obtenerdetalleMantenimientos() {
  
  this.crudServices.getModel('api/detalle-mantenimiento/listar-todos-mantenimiento?id=' + this.mantenimientoModel.id).subscribe(
    (genericResponse: GenericResponseModel) => {
      if (genericResponse.code === 200) {
        this.listaDetalleMantenimiento = genericResponse.answerList;
      }
      if (genericResponse.code === 400) {
        this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se encontraron detalles del mantenimiento')
      }
    }, error => {
      this.messageService.getInfoMessageBadInternet();
    }
  );
}

}
