import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { MantenientoRoutingModule } from './mantenimiento-routing.module';
import { NgxSpinnerModule } from "ngx-spinner";
import { ListaEquipoComponent } from './lista-equipo/lista-equipo.component';
import { CrudEquipoComponent } from './crud-equipo/crud-equipo.component';
import { RoleAuthGuard } from 'app/shared/auth/roleAuth-guard.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgSelectModule } from '@ng-select/ng-select';
import { ListFilesComponent } from './list-files/list-files.component';
import { ListFilesMantenimientoComponent } from './list-files-mantenimiento/list-files-mantenimiento.component';
import { ListaRepuestosComponent } from './lista-repuestos/lista-repuestos.component';
import { CrudRepuestoComponent } from './crud-repuesto/crud-repuesto.component';
import { CrudRutinaComponent } from './crud-rutina/crud-rutina.component';
import { ListaMantenimientoComponent } from './lista-mantenimiento/lista-matenimiento.component';
import { CrudMantenimientoComponent } from './crud-mantenimiento/crud-mantenimiento.component';
import { RegistroCantidadesComponent } from './registro-cantidades/registro-cantidades.component';
import { LogEquipoComponent } from './log-equipo/log-equipo.component';
import { ReporteMantenimientoComponent } from './reporte-mantenimientos/reporte-mantenimientos.component';
import { NewFileComponent } from './new-file/new-file.component';
import { NewFileMantenimientoComponent } from './new-file-mantenimiento/new-file-mantenimiento.component';

@NgModule({
  imports: [
    CommonModule,
    MantenientoRoutingModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    NgbModule,
    NgSelectModule,
    NgxSpinnerModule
  ],
  declarations: [ListaEquipoComponent, CrudEquipoComponent, ListFilesComponent, NewFileComponent, ListaRepuestosComponent,
                 CrudRepuestoComponent, CrudRutinaComponent, ListaMantenimientoComponent, CrudMantenimientoComponent,
                 RegistroCantidadesComponent, LogEquipoComponent, ReporteMantenimientoComponent, ListFilesMantenimientoComponent,
                 NewFileMantenimientoComponent],
  providers: [RoleAuthGuard],
  entryComponents: [CrudEquipoComponent, ListFilesComponent, NewFileComponent, ListaRepuestosComponent, CrudRepuestoComponent,
                    CrudRutinaComponent, ListaMantenimientoComponent, CrudMantenimientoComponent, RegistroCantidadesComponent,
                    LogEquipoComponent, ReporteMantenimientoComponent, ListFilesMantenimientoComponent,
                    NewFileMantenimientoComponent]
})
export class MantenimientoModule { }
