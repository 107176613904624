import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MeetingSurveyOwnerRoutingModule } from './meeting-survey-owner-routing.module';
import { MeetingSurveyOwnerComponent } from './meeting-survey-owner/meeting-survey-owner.component';
import { ListQuestionOwnerComponent } from './list-question-owner/list-question-owner.component';
import { ListMeetingOwnerComponent } from './list-meeting-owner/list-meeting-owner.component';
import { ListSurveyOwnerComponent } from './list-survey-owner/list-survey-owner.component';
import { DetailQuestionOwnerComponent } from './detail-question-owner/detail-question-owner.component';
import { SurveyResultOwnerComponent } from './survey-result-owner/survey-result-owner.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule } from '@angular/forms';
import { QuestionModule } from 'app/question/question.module';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [MeetingSurveyOwnerComponent, ListQuestionOwnerComponent, ListMeetingOwnerComponent, ListSurveyOwnerComponent,
  DetailQuestionOwnerComponent, SurveyResultOwnerComponent],
  imports: [
    CommonModule,
    MeetingSurveyOwnerRoutingModule,
    NgxDatatableModule,
    FormsModule,
    NgSelectModule,
    QuestionModule
  ],
  entryComponents: [
    ListQuestionOwnerComponent, SurveyResultOwnerComponent, DetailQuestionOwnerComponent
  ]
})
export class MeetingSurveyOwnerModule { }
