import { DatatableComponent } from '@swimlane/ngx-datatable';
import { UpdateEmployeeComponent } from './../update-employee/update-employee.component';
import { DetailEmployeeComponent } from './../detail-employee/detail-employee.component';
import { employeeModel } from 'models/entities/employee';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateEmployeeComponent } from './../create-employee/create-employee.component';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { MessagingServicesService } from './../../shared/messaging/messaging-services.service';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { EmployeeModule } from '../employee.module';
import swal from 'sweetalert2';

@Component({
  selector: 'app-list-employee',
  templateUrl: './list-employee.component.html',
  styleUrls: ['./list-employee.component.scss']
})
export class ListEmployeeComponent implements OnInit {
  rows: any;
  temp = [];
  dato: any;
  errorMessage: string;
  roleTitle = this.tokenStorage.getAuthorities();
  employeeInfo: employeeModel = new employeeModel();
  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(private crudServices: CrudServiceService,
    private messageService: InfoMessagesService,
    private tokenStorage: TokenStorageService,
    private modalService: NgbModal) { }

  ngOnInit() {
    this.configPage()
  }

  updateFilterByName(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function (d: any) {
      if (d.idUser.completeName) {
        return d.idUser.completeName.toLowerCase().indexOf(val) !== -1 || !val;
      } else {
        return false;
      }
    });
    this.rows = temp;
    this.table.offset = 0;
  }

  configPage() {
    this.crudServices.getModel('api/empleado/obtener-empleados?housingUnitId=' + this.tokenStorage.getCompanyId()).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.dato = genericResponse.answerList;
          this.temp = [...this.dato];
          this.rows = this.dato;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se encontraron empleados')
        }
      }, error => {
        this.errorMessage = `${error.status}: ${error.error.message}`;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );

  }

  openCreateEmployee() {
    const modalRef = this.modalService.open(CreateEmployeeComponent, {
      windowClass: '', size: 'lg', 'backdrop': 'static'
    });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
      if (receivedEntry === 'empleado_creado') {
        this.configPage();
      } else {
        return false;
      }
    })
  }

  detailEmployee(id) {
    this.crudServices.getModel('api/empleado/detalle?id=' + id).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.employeeInfo = genericResponse.genericObject
          const modalRef = this.modalService.open(
            DetailEmployeeComponent, {
              windowClass: '', size: 'lg',  backdrop: 'static'
            }
          );
          modalRef.componentInstance.employeeInfo = this.employeeInfo;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error buscando el modulo')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  updateEmployee(id) {
    this.crudServices.getModel('api/empleado/detalle?id=' + id).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.employeeInfo = genericResponse.genericObject
          const modalRef = this.modalService.open(
            UpdateEmployeeComponent, {
              windowClass: '', size: 'lg',  backdrop: 'static'
            }
          );
          modalRef.componentInstance.employeeInfo = this.employeeInfo;
          modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
            if (receivedEntry === 'creado') {
              this.configPage();
            } else {
              return false;
            }
          })
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error buscando el modulo')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }


  onDelete(id) {
    this.crudServices.deleteModel('api/empleado/borrar?id=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageDelete()
          this.configPage()
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se eliminó el parqueadero')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
        this.errorMessage = `${error.status}: ${error.error.message}`;
      }
    );

  }

  deleteWarning(id) {
    event.preventDefault();
    swal({
      title: '¿Está seguro que desea eliminar el empleado?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.onDelete(id);
      }
    })
  }
}
