import { changePasswordModel } from './../../../models/entities/change-password.model';
import { DocumentTypeModel } from './../../../models/entities/document-type-model';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { employeeModel } from 'models/entities/employee';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-update-employee',
  templateUrl: './update-employee.component.html',
  styleUrls: ['./update-employee.component.scss']
})
export class UpdateEmployeeComponent implements OnInit {
  errorMessage: any;
  listDocumentTypes: any;
  @Input() public employeeInfo: employeeModel;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  passwordModel = new changePasswordModel();
  passwordValidationError = '';
  isErrorPassword = false;
  oldPassword: any;
  newPassword: any;
  passwordConfirmation: any;


  constructor(
    public crudServices: CrudServiceService,
    public messageService: InfoMessagesService,
    public modalService: NgbModal
  ) { }

  ngOnInit() {
    this.getDocumentsTypes()
  }

  closeWindow() {
    this.modalService.dismissAll();
  }

  updateEmployee() {
    this.crudServices.createModel('api/empleado/guardar', this.employeeInfo).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 201) {
          this.updateInfoUser(this.employeeInfo.idUser)
          this.messageService.getInfoMessageCreate();

        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Problema actualizando el empleado')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageError();
        this.errorMessage = error.error.answer;
      }
    );
  }

  passwordValidation() {
    if (this.newPassword !== this.passwordConfirmation) {
      this.passwordValidationError = '¡Las contraseñas no coinciden!';
      this.isErrorPassword = true;
      return;
    } else if (this.newPassword === this.passwordConfirmation) {
      this.passwordValidationError = '¡Las contraseñas no coinciden!';
      this.isErrorPassword = false;
      return;
    }
  }

  changePassword() {
    this.passwordModel.newPassword = this.newPassword;
    console.log(this.passwordModel)
    this.passwordValidation();
    this.crudServices.createModel('api/usuarios/actualizar-contrasena-user', this.passwordModel).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          console.log(genericResponse)
          console.log('contraseña actualizada')
          this.messageService.getInfoMessageUpdate().then(
            (response) => {
              console.log(response)
            }
          )
        } else if (genericResponse.code === 201) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Actualizacion fallida')
        } else if (genericResponse.code === 202) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Actualizacion fallida')
        }

      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
        this.errorMessage = JSON.stringify(error.error.mensaje);
      }
    );
  }

  getDocumentsTypes() {
    const pathListDOcumentTypesUrl = 'api/tipos-de-documento/ver-por-objetivo?objective=PERSONAL';
    this.listDocumentTypes = new Array();
    this.crudServices.getModel(pathListDOcumentTypesUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.listDocumentTypes = genericResponse.answerList;
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los tipos de documentos del sistema',
            'Problema consultando los tipos de documentos del sistema');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar los tipos de documentos del sistema ' + JSON.stringify(error))
      }
    );
  }

  updateInfoUser(userUpdated) {
    this.crudServices.createModel('api/usuarios/actualizar', userUpdated).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.passEntry.emit('creado');
          this.closeWindow();
        } if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se realizó la actualización')
        }
      }, error => {
        this.errorMessage = `${error.status}: ${error.error.message}`;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      })
  }

  getInfoUserAuthenticated() {
    this.crudServices.getModel('api/usuarios/buscar-usuario?username=' + this.employeeInfo.idUser.email).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
         this.passwordModel.id = genericResponse.genericObject.id;
         this.changePassword()
        }

      }, error => {
        this.errorMessage = 'Credenciales incorrectas';
      }
    );

  }
}
