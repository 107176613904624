import { HousingUnitModel } from './housing-unit-model';

// tslint:disable-next-line: class-name
export class filterCoownerShipModel {
    unidadResidencialId: HousingUnitModel;
    idTower: string;
    name: string;
    number: string;

    public constructor() {
        this.unidadResidencialId = new HousingUnitModel();
        this.idTower = null;
        this.name = '';
        this.number = '';
    }
}
