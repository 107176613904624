import { FirebaseStorageService } from "./../../shared/firebase/firebase-storage.service";
import { CrudServiceService } from "./../../shared/backend/cruds/crud-service.service";
import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgForm } from "@angular/forms";
import { TokenStorageService } from "app/shared/storage-services/token-storage.service";
import { UserModel } from "models/entities/user-model";
import { InfoMessagesService } from "app/shared/messages/info-messages.service";
import swal from "sweetalert2";
import { DocumentTypeModel } from "models/entities/document-type-model";
import { GenericResponseModel } from "models/utilities/generic.response.model";
import { UserCreationModel } from "models/entities/user-creation-model";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-create-user-coowner",
  templateUrl: "./create-user-coowner.component.html",
  styleUrls: ["./create-user-coowner.component.scss"],
})
export class CreateUserCoownerComponent implements OnInit {
  currentFileUpload: File;
  selectedExtension = "";
  role: any;
  userCreation: UserCreationModel = new UserCreationModel();
  isSignedUp = false;
  isSignUpFailed = false;
  errorMessage = "";
  roleTitle = this.tokenStorage.getAuthorities();
  passwordValidationError = "";
  email: string = "";
  isErrorPassword = false;
  ok: boolean = false;
  typesRestrictions = ["jpg", "PNG", "png", "JPGE"];
  @Input() public createType;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  passwordConfirmation = "";
  termsAndConditions = false;
  listDocumentTypes: Array<DocumentTypeModel> = new Array();

  @Input() public modalInformacion;

  @ViewChild("f") registerForm: NgForm;
  @ViewChild("ft") registerFormTwo: NgForm;


  constructor(
    private modalService: NgbModal,
    private firebaseStorageService: FirebaseStorageService,
    private messageService: InfoMessagesService,
    private crudService: CrudServiceService,
    private tokenStorage: TokenStorageService,
    public activeModal: NgbActiveModal,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getDocumentsTypes();
    if (this.createType == "ROLE_COLLABORATOR") {
      this.userCreation.userInfo.role = this.createType;
    } else {
      this.userCreation.userInfo.role = "ROLE_OWNER";
    }
  }

  getDocumentsTypes() {
    const pathListDOcumentTypesUrl =
      "api/tipos-de-documento/ver-por-objetivo?objective=PERSONAL";
    this.listDocumentTypes = new Array();
    this.crudService.getModel(pathListDOcumentTypesUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.listDocumentTypes = genericResponse.answerList;
          this.userCreation.userInfo.documentTypeId.id =
            this.listDocumentTypes[0].id;
        } else {
          this.messageService.getInfoMessagePersonalized(
            "warning",
            "No se pudieron listar los tipos de documentos del sistema",
            "Problema consultando los tipos de documentos del sistema"
          );
        }
      },
      (error) => {
        this.messageService.getInfoMessageError();
        console.error(
          "Error al cargar los tipos de documentos del sistema " +
            JSON.stringify(error)
        );
      }
    );
  }

  selectFile(event) {
    if (
      this.typesRestrictions.includes(
        event.target.files.item(0).name.split(".").pop()
      )
    ) {
      this.currentFileUpload = event.target.files.item(0);
      this.selectedExtension = event.target.files.item(0).name.split(".").pop();
    } else {
      this.currentFileUpload = undefined;
      swal({
        title: "El tipo de archivo no es correcto",
        type: "error",
        text: "El archivo debe tener formato JPG, PNG o JPEG",
      });
    }
  }

  passwordValidation() {
    if (this.userCreation.user.password !== this.passwordConfirmation) {
      this.passwordValidationError = "¡Las contraseñas no coinciden!";
      this.isErrorPassword = true;
      setTimeout(() => {
        this.isErrorPassword = false;
      }, 3000);
    }
  }

  onSubmit() {
    if (this.tokenStorage.getAuthorities() === "ROLE_SUPER") {
      this.userCreation.userInfo.role = "ROLE_ADMIN";
    } else if (this.tokenStorage.getAuthorities() === "ROLE_ADMIN") {
      if (this.userCreation.userInfo.role === "ROLE_OWNER") {
        this.userCreation.userInfo.role = "ROLE_OWNER";
      } else if (this.userCreation.userInfo.role === "ROLE_COOWNER") {
        this.userCreation.userInfo.role = "ROLE_COOWNER";
      } else {
        this.userCreation.userInfo.role = "ROLE_COLLABORATOR";
      }
    }
    this.userCreation.userInfo.housingId= this.tokenStorage.getCompanyId();
    this.email = this.userCreation.user.username;
    this.crudService
      .createModel("api/usuarios/crear", this.userCreation)
      .subscribe(
        (genericResponse) => {
          // console.log(this.userCreation.userInfo);

          if (genericResponse.code === 201) {
            this.isSignedUp = true;
            this.isSignUpFailed = false;
            this.userCreation = new UserCreationModel();
            this.messageService.getInfoMessageCreate();
            this.asociateProperty();
            this.passEntry.emit("Usuario Registrado");

            // if () {
            //
            // } else {
            //   this.messageService.getInfoMessagePersonalized(
            //     "warning",
            //     genericResponse.answer,
            //     "No se creó el usuario"
            //   );
            // }
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se creó el usuario"
            );
            this.errorMessage = genericResponse.answer;
            this.isSignedUp = false;
            this.isSignUpFailed = true;
          }
        },
        (error) => {
          this.errorMessage = error.error.message;
          this.isSignUpFailed = true;
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  asociateProperty(): void {
    this.crudService
      .createModel(
        `api/usuarios/asociar-copropiedad?email=${
          this.email
        }&id=${this.tokenStorage.getCoownerShipId()}`,
        {}
      )
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.activeModal.dismiss();
            this.passEntry.emit("Usuario Registrado");
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se creó el usuario"
            );
            this.errorMessage = genericResponse.answer;
            this.isSignedUp = false;
            this.isSignUpFailed = true;
          }
        },
        (error) => {
          this.errorMessage = error.error.message;
          this.isSignUpFailed = true;
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  openTermsAndConditions() {
    // this.router.navigate(['/terminos-condiciones'], { relativeTo: this.route.parent });
    // window.location.href = 'https://www.google.com';
    window.open(" /terminos-condiciones ");
  }
}
