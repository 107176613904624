import { CoownershipModel } from './../../../models/entities/coowner-ship-model';
import { GenericResponseModel } from './../../../models/utilities/generic.response.model';
import { TokenStorageService } from './../../shared/storage-services/token-storage.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoMessagesService } from './../../shared/messages/info-messages.service';
import { CrudServiceService } from './../../shared/backend/cruds/crud-service.service';
import { CoownerShipVehicleModel } from './../../../models/entities/coownership-vehicle';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-create-vehicle',
  templateUrl: './create-vehicle.component.html',
  styleUrls: ['./create-vehicle.component.scss']
})
export class CreateVehicleComponent implements OnInit {
  errorMessage: any;
  newCoownerShipVehicle: CoownerShipVehicleModel = new CoownerShipVehicleModel();
  @Input() public coownerShipInfo: CoownershipModel;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  constructor(
    public crudServices: CrudServiceService,
    public messageService: InfoMessagesService,
    public activeModal: NgbActiveModal,
    public tokenStorage: TokenStorageService,
  ) { }

  ngOnInit() {
  }
  onSubmit() {
    this.saveVehicle()

  }

  saveVehicle() {
    this.newCoownerShipVehicle.coOwnershipId = this.coownerShipInfo
    this.crudServices.createModel('api/vehiculo/guardar-vehiculo', this.newCoownerShipVehicle).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageCreate();
          this.passEntry.emit('Vehiculo_Registrado');
          this.activeModal.dismiss();
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se creó el Vehiculo')
          this.errorMessage = genericResponse.answer;
        }
      },
      error => {
        this.errorMessage = error.error.message;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

}
