import { MeetingModel } from './meeting-module';
import { CoownershipModel } from './coowner-ship-model';
export class MeetingAssistantModel {

    id: string;
    status: string;
    coownerShipId: CoownershipModel;
    meetingId: MeetingModel;

    public constructor(){
        this.id = '';
        this.status = '';
        this.coownerShipId = new CoownershipModel();
        this.meetingId = new MeetingModel();
    }

}