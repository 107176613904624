import { GenericResponseModel } from './../../../models/utilities/generic.response.model';
import { TokenStorageService } from './../../shared/storage-services/token-storage.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoMessagesService } from './../../shared/messages/info-messages.service';
import { CrudServiceService } from './../../shared/backend/cruds/crud-service.service';
import { StorageModel } from './../../../models/entities/storage-model';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-update-storage-room',
  templateUrl: './update-storage-room.component.html',
  styleUrls: ['./update-storage-room.component.scss']
})
export class UpdateStorageRoomComponent implements OnInit {

  errorMessage: any
  @Input() public infoStorage: StorageModel;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  rowsTower = [];
  tempTower: string[];
  datoTower: any;
   constructor(
     public crudServices: CrudServiceService,
     public messageService: InfoMessagesService,
     public activeModal: NgbActiveModal,
     public tokenStorage: TokenStorageService,
     public token: TokenStorageService
   ) { }

  ngOnInit() {
    this.configSelectTowers()
  }

  onSubmit() {
    this.saveStorage()
  }

  saveStorage() {
    this.crudServices.createModel('api/cuarto-util/guardar', this.infoStorage).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageCreate();
          this.passEntry.emit('cuarto_creado');
          this.activeModal.dismiss();
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se actualizo el cuarto útil')
          this.errorMessage = genericResponse.answer;
        }
      },
      error => {
        this.errorMessage = error.error.message;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  configSelectTowers() {
    this.crudServices.getModel('api/torre-bloque/obtener-torres?id=' + this.token.getCompanyId()).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.datoTower = genericResponse.answerList;
          this.tempTower = { ...this.datoTower };
          this.rowsTower = this.datoTower;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin torres')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

}
