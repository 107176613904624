import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PetModel } from '../../../models/entities/pet-model';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CoownershipModel } from 'models/entities/coowner-ship-model';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import swal from 'sweetalert2';
import { FirebaseStorageService } from 'app/shared/firebase/firebase-storage.service';
import { environment } from 'environments/environment.prod';
import { HandleServicesService } from 'app/shared/handle-service/handle-services.service';

@Component({
  selector: 'app-update-pet',
  templateUrl: './update-pet.component.html',
  styleUrls: ['./update-pet.component.scss']
})
export class UpdatePetComponent implements OnInit {

  @Input() petsInfo;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  errorMessage: any;

  rowsCoownerShip: Array<CoownershipModel> = [];
  tempCoownerShip: string[];
  datoCoownerShip: any;

  // type pets
  rowsTypePets = [];
  tempTypePets: string[];
  datoTypePets: any;

  // file
  currentFileUpload: File;
  url: any;
  selectedExtension = '';
  typesRestrictions = ['jpg', 'PNG', 'png', 'JPGE'];
  atached = false;
  downloadURL: any;

  filePhoto: any = null;
  filePhotoRender: any = '';

  urlPeticionImg = environment.serverUrl + 'api/archivos/ver-img?ruta=';
  
  imageStatic: any = 'https://cdn-icons-png.flaticon.com/512/2358/2358540.png';

  sendPicture = {
    estado:'actualizado',
    petsInfo: this.petsInfo
  }

  constructor(
    private crudServices: CrudServiceService, private modalService: NgbModal, private handleService: HandleServicesService,
    public tokenStorage: TokenStorageService, private messageService: InfoMessagesService,
    private firebaseStorageService: FirebaseStorageService,
  ) { }

  ngOnInit() {
    this.listCoownerShip();
    this.typePets();
  }

  typePets() { 
    this.crudServices.getModel('api/mascotas/especies-mascotas').subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.datoTypePets = genericResponse.answerList;
          this.tempTypePets = { ...this.datoTypePets };
          this.rowsTypePets = this.datoTypePets;
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }
  closeModal() {
    this.modalService.dismissAll();
  }

  onSubmit() {
    console.log("entro actualizo");
    console.log(this.petsInfo);
    console.log(this.petsInfo.id);
    this.crudServices.putModel('api/mascotas/actualizar-mascota?id=' + this.petsInfo.id, this.petsInfo).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          if (this.atached) {
            swal({
              title: 'Estamos actualizando la foto!',
              text: 'Sólo tardara unos segundos.',
              timer: 2000,
              showConfirmButton: false,
              imageUrl: 'https://ea1hlh.es/wp-content/uploads/2019/04/loading.gif'
            });
            setTimeout(() => {
            }, 2000);
            this.upload(this.petsInfo)
          }

          if ( !this.atached) {
            this.sendPicture.petsInfo = this.petsInfo;
            this.messageService.getInfoMessageCreate().then(
              (result) => {
                this.passEntry.emit(this.sendPicture);
              }
            )
          }

        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  listCoownerShip() {
    {
      this.crudServices.getModel('api/coownership/listar-mis-copropiedades?id=' + this.tokenStorage.getId()).subscribe(
        genericResponse => {

          if (genericResponse.code === 200) {
            this.datoCoownerShip = genericResponse.answerList;
            this.tempCoownerShip = { ...this.datoCoownerShip };
            this.rowsCoownerShip = this.datoCoownerShip;
            console.log(this.rowsCoownerShip);
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Sin copropiedades')
          }
        },
        error => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
    }
  }

  // Upload Image in Firebase

  readUrl(inputValue: any): void {
    const img = new Image();
    this.atached = true;
    img.src = window.URL.createObjectURL(inputValue.target.files.item(0));
    img.onload = () => {
      window.URL.revokeObjectURL(img.src);
      if (img.naturalWidth <= 2000 && img.naturalHeight <= 2000) {
        this.currentFileUpload = inputValue.target.files[0];
        var myReader: FileReader = new FileReader();
        myReader.onloadend = (e) => {
          this.url = myReader.result;
        }
        myReader.readAsDataURL(this.currentFileUpload);
      } else {
        this.messageService.getInfoMessagePersonalized
          ('warning', 'El tamaño de la imagen debe ser menor o igual a 200 x 200 px', 'Imagen fuera de proporciones');

      }
    }
  }

  selectFile(event) {
    if (this.typesRestrictions.includes(event.target.files.item(0).name.split('.').pop())) {
      this.currentFileUpload = event.target.files.item(0);
      this.selectedExtension = event.target.files.item(0).name.split('.').pop()
    } else {
      this.currentFileUpload = undefined;
      swal({
        title: 'El tipo no es correcto', type: 'error',
        text: 'El archivo debe tener formato jpg, PNG, png ó JPEG, jpeg'
      })
    }
  }


  upload(pets: PetModel) {
    this.firebaseStorageService
      .uploadFile('images/' + 'mascota/' + pets.id, 'pets.' + this.selectedExtension, this.currentFileUpload).then(
        (responseFirebase) => {
          this.firebaseStorageService
            .loadFile('images/' + 'mascota/' + pets.id, 'pets.' + this.selectedExtension).then(
              (dowloadURL) => {
                this.downloadURL = dowloadURL
                this.sendPicture.petsInfo = pets;
                this.sendPicture.petsInfo.petPicture = dowloadURL;
                this.passEntry.emit(this.sendPicture);
                this.addPhoto(this.downloadURL)
              }
            ).catch(error => {
              console.error(error);
              this.messageService.getInfoMessageBadInternet();
            })
        }, (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      )
  }

  addPhoto(downloadURL: any) {
    this.petsInfo.petPicture = downloadURL;
    this.crudServices.createModel('api/mascotas/registrar-mascota', this.petsInfo).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.petsInfo = new PetModel()
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
        this.errorMessage = error.error.answer;
      }
    );
  }

  updateProfile() {
    this.petsInfo.housingUnitId.id = this.tokenStorage.getCompanyId();
    this.petsInfo.coOwnerShip.id = this.tokenStorage.getCoownerShipId();

  let copiaPet = this.petsInfo
    let path = 'api/mascotas/actualizar-mascota?id=' + this.petsInfo.id;

    this.petsInfo.petPicture = this.filePhotoRender;
    let petModelAux = this.petsInfo;
    petModelAux.petPicture = null;

    this.crudServices.putModel(path, petModelAux).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.petsInfo = genericResponse.genericObject;
        if(this.filePhoto !== null) {
          this.uploadPhoto(copiaPet);
        }
        else {
          this.messageService.getInfoMessageUpdate();
        }

        this.filePhoto = null;
      }
      else {
        this.messageService.getInfoMessagePersonalized("error", genericResponse.answer, "Atención!");
      }
    }, error => {
      this.messageService.getInfoMessagePersonalized('error', 'Error en la actualización.', 'Error');
   }
  );
  }


  loadPhoto(event, files) {
    this.filePhoto = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (_event) => { this.filePhotoRender = reader.result }
  }

  updateInfo(){
    swal({
      title: '¿Está seguro que desea registrar la mascota?',
      text: "",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, actualizar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.updateProfile();
      }
    })
  }

  uploadPhoto(petModel) {
    console.log('entra a actualizar la foto');
    const folderPath = "unidad residencial " + this.tokenStorage.getCompanyId();

    var data = new FormData();
    data.append('archivo', this.filePhoto);
      
    let extension = this.filePhoto.name.split('.').pop();
    console.log('extension'+extension);
    console.log('id'+petModel.id );

    let path = 'api/archivos/cargar-foto-mascota?folderPath=' + folderPath + '&relationalId=' +  petModel.id + 
          '&extension=' + extension;
 
    this.crudServices.createFile(path, data).toPromise().then((genericResponse) => {
      if (genericResponse.code === 200) {
        this.messageService.getInfoMessageUpdate().then(() => {window.location.reload()});
      }
      else {
        this.messageService.getInfoMessagePersonalized(
          'warning', 
          "Se actualizo la información correctamente pero verifique la foto por favor", 
          'Atención!'
        );
      }
    }).catch((error) => {
      this.handleService.handleError(error);
    })
  }

}
