export class PlansModel {
    id: string;
    name: string;
    value: string;
    description: string;
    createdDate: string;
    lastUpdateDate: string;
    maxNumHousUnit: string;
    maxNumCoownerShip: string;
    planSiguiente: string;
    planAnterior: string;
    isFauvorite: boolean;

    public constructor() {
        this.id = '';
        this.name = '';
        this.value = '';
        this.description = '';
        this.createdDate = '';
        this.lastUpdateDate = '';
        this.maxNumHousUnit = '';
        this.maxNumCoownerShip = '';
        this.planSiguiente = '';
        this.planAnterior = '';
        this.isFauvorite = false;
    }
}
