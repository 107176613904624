import { HousingUnitModel } from './housing-unit-model';
export class TowerModel {
    id: string;
    name: string;
    quantityCellar: string;
    quantityParking: string;
    quantityApartaments: string
    area: string;
    location: string;
    housingUnitId: HousingUnitModel;

    public constructor() {
        this.id = '';
        this.name = '';
        this.quantityCellar = '';
        this.quantityParking = '';
        this.quantityApartaments = '';
        this.area = '';
        this.location = '';
        this.housingUnitId = new HousingUnitModel;
    }
}
