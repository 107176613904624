import { UpdateInvoiceComponent } from './update-invoice/update-invoice.component';
import { DetailInvoiceComponent } from './detail-invoice/detail-invoice.component';
import { CreateMassiveInvoiceComponent } from './create-massive-invoice/create-massive-invoice.component';
import { CreateInvoiceComponent } from './create-invoice/create-invoice.component';
import { ListInvoiceComponent } from './list-invoice/list-invoice.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListInvoiceOwnerComponent } from './list-invoice-owner/list-invoice-owner.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'listar-facturas-propietario',
        component: ListInvoiceComponent,
        data: {
          title: 'listar-facturas-propietario',
          expectedRole: ['ROLE_OWNER', 'ROLE_ADMIN', 'ROLE_COLLABORATOR']
        }
      },
      {
        path: 'crear-factura-propietario',
        component: CreateInvoiceComponent,
        data: {
          title: 'crear-factura-propietario',
          expectedRole: ['ROLE_ADMIN', 'ROLE_COLLABORATOR']
        }
      },
      {
        path: 'crear-factura-masiva-propietario',
        component: CreateMassiveInvoiceComponent,
        data: {
          title: 'crear-factura-masiva-propietario',
          expectedRole: ['ROLE_ADMIN', 'ROLE_COLLABORATOR']
        }
      },
      {
        path: 'detalle-factura',
        component: DetailInvoiceComponent,
        data: {
          title: 'detalle-factura',
          expectedRole: ['ROLE_OWNER', 'ROLE_ADMIN', 'ROLE_COLLABORATOR']
        }
      },
      {
        path: 'actualizar-factura',
        component: UpdateInvoiceComponent,
        data: {
          title: 'actualizar-factura',
          expectedRole: ['ROLE_OWNER', 'ROLE_ADMIN', 'ROLE_COLLABORATOR']
        }
      },
      {
        path: 'facturas-propietario',
        component: ListInvoiceOwnerComponent,
        data: {
          title: 'facturas-propietario',
          expectedRole: ['ROLE_OWNER', 'ROLE_ADMIN', 'ROLE_COLLABORATOR']
        }
      },
    ]
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InvoiceRoutingModule { }
