import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment.prod';
import { TokenStorageService } from '../storage-services/token-storage.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { GenericResponseModel } from '../../../models/utilities/generic.response.model';
import { InfoMessagesService } from '../messages/info-messages.service';
import { FirstLoginComponent } from 'app/usercomponents/first-login/first-login.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

// Encabezados de las peticiones http con la información de la aplicación.

// Verificar esto.
@Injectable({ providedIn: 'root' })

export class AuthService {
  errorMessage: string;

  headersAuto = {
    'Authorization':
      'Basic c3ByaW5nLXNlY3VyaXR5LW9hdXRoMi1yZWFkLXdyaXRlLWNsaWVudDpzcHJpbmctc2VjdXJpdHktb2F1dGgyLXJlYWQ' +
      'td3JpdGUtY2xpZW50LXBhc3N3b3JkMTIzNA=='
  }

  headers = {
  }
  token: TokenStorageService;
  private serverURL = environment.serverUrl;

  constructor(private http: HttpClient,
    private tokenStorage: TokenStorageService,
    private crudServices: CrudServiceService,
    private messageService: InfoMessagesService,
    private modalService: NgbModal,
    private router: Router
  ) {
    //this.isAuthenticated()
  }

  async buildHeaderAuthenticated() {
    this.headers = {
      'Authorization': this.tokenStorage.getToken() + ''
    };
  }


  logout() {
    this.token = null;
  }

  getToken() {
    return this.token;
  }

  attemptAuth(path, credentials): Observable<any> {
    return this.http.post<any>(`${this.serverURL}${path}`, credentials, { headers: this.headersAuto });
  }

  isAuthenticated() {
    if (this.tokenStorage.getToken() != null) {
      this.buildHeaderAuthenticated();
      return true;
    } else {
      return false;
    }
  }

  async getInfoUserAuthenticated() {
    
    this.crudServices.getModel('api/usuarios/obtener-info-usuario-autenticado').subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.tokenStorage.saveAuthorities(genericResponse.genericObject.role);

          this.tokenStorage.saveCompleteName(genericResponse.genericObject.completeName);
          this.tokenStorage.saveID(genericResponse.genericObject.id);
          this.tokenStorage.saveIdSesion(genericResponse.genericObject.userId.id);
          this.tokenStorage.saveUsername(genericResponse.genericObject.email);
          this.tokenStorage.saveIdPlan(genericResponse.genericObject.activatedPlanId.name);
          this.tokenStorage.saveUserCreatedDate(genericResponse.genericObject.createdDate);

          if(genericResponse.genericObject.firstLogin) {
            //this.openRegister(genericResponse.genericObject.id);
          }
        }      
      }, error => {
        this.errorMessage = 'Credenciales incorrectas';
      }
      
    );
    this.recordatorioSuscripcion(this.tokenStorage.getUserCreatedDate()); 
   
  }

  recordatorioSuscripcion(fechaCreacion){
        let fechaCreacionUsuario = fechaCreacion;
        // The number of milliseconds in one day
    var oneDay = (1000 * 60 * 60 * 24);
    var currentDate = new Date();
    // FALTA VERIFICAR SI SE PASA DE 1 MES

    // Convert both dates to milliseconds
    let ano = parseInt(fechaCreacionUsuario.split("-")[0], 10);
    let mes = parseInt(fechaCreacionUsuario.split("-")[1], 10);
    let dia = parseInt(fechaCreacionUsuario.split("-")[2], 10);

    let anoActual = currentDate.getFullYear();
    let mesActual = (currentDate.getMonth() + 1);
    let diaActual = currentDate.getDate();

    let date1_ms = new Date(ano, mes, dia).getTime()
    let date2_ms = new Date(anoActual, mesActual, diaActual).getTime();

    // Calculate the difference in milliseconds
    let difference_ms = Math.abs(date2_ms - date1_ms);

    // Convert back to days and return
    let diferenciaDias = Math.round(difference_ms / oneDay);
      

    if ( this.tokenStorage.getPlanId() === 'Gratuito'   &&  diferenciaDias >= 30){
    this.messageService.getInfoMessagePersonalized('warning', "Contacta con tu administrador", 
    'Has terminado tu periodo de prueba gratuito, si te interesa cambiar de plan')
    }
  }


  openRegister(idUsuario) {
   
    const modalRef = this.modalService.open(FirstLoginComponent, {
      windowClass: 'modal', size: 'lg', backdrop: 'static', keyboard: false
    });
    modalRef.componentInstance.idUsuario = idUsuario;
  }
}
