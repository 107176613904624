import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ModalInfo } from '../../../models/entities/modal-info';
//import { CrudMantenimientoComponent } from '../crud-mantenimiento/crud-mantenimiento.component';
import { CrudServiceService } from '../../shared/backend/cruds/crud-service.service';
import { Component, OnInit, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';

import swal from 'sweetalert2';
import { EquipoModel } from 'models/entities/equipo-model';
import { FileModel } from 'models/entities/file-model';
import { FirebaseStorageService } from 'app/shared/firebase/firebase-storage.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { formatDate } from '@angular/common';
import { EquipoAuxModel } from 'models/utilities/equipo-aux-model';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalInformationModel } from 'models/utilities/modal-information-model';
import { MeetingAssistantModel } from 'models/entities/meeting-assistant-model';
import { HandleServicesService } from 'app/shared/handle-service/handle-services.service';

@Component({
  selector: 'app-modal-reuniones',
  templateUrl: './modal-reuniones.component.html',
  styleUrls: ['./modal-reuniones.component.scss']
})
export class ModalReunionesComponent implements OnInit {

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  rows = [];
  temp: string[];
  board: string;
  errorMessage: string;
  dato: any;
  datosDetalle: any;
  button = [];
  reunion = new MeetingAssistantModel();
  
  nombreReunion: string;
  idReunion: string;

  @ViewChild(DatatableComponent) table: DatatableComponent;
  
  @Input() public modalInfo = new ModalInformationModel();

  constructor(public modalService: NgbModal,
    private crudServices: CrudServiceService,
    private messageService: InfoMessagesService,
    private tokenStorage: TokenStorageService,
    private firebaseServices: FirebaseStorageService,
    public activeModal: NgbActiveModal,
    public route: ActivatedRoute,
    private handle: HandleServicesService,
    private router: Router) { }

  ngOnInit() {
    this.cargarInformacion()
  }

  cargarInformacion(){

    let cadena = this.modalInfo.param;
    
    let newReunion = Object.assign(new MeetingAssistantModel(), cadena); 
    this.reunion = newReunion;

    this.nombreReunion = newReunion.meetingId.name;

  }

  confirmarAsistencia() {
    this.crudServices.putModel('api/reuniones/confirmar-asistencia', this.reunion).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
         
          this.messageService.getInfoMessagePersonalized('success', 'Se ha confirmado la asistencia para la reunion', 'Correcto');
          this.passEntry.emit('creado');
          this.activeModal.close();
          this.router.navigate(['/reuniones-encuestas/reuniones-propietario'], { relativeTo: this.route.parent });
        }
      },
      error => {
        this.errorMessage = `${error.status}: ${error.error.message}`;
        console.error(error);
        this.handle.handleError(error);
      }
    );
  }


}