export class VehicleModel {
    id: string;
    number: string;
    color: string;
    type: string;
    marca: string;

    public constructor() {
        this.id = '';
        this.number = '';
        this.color = '';
        this.type = '';
        this.marca = '';

    }
}
