export class InvoiceTypeModel {
    id: string;
    name: string;


    public constructor() {
        this.id = '';
        this.name = '';

    }
}
