import { UserModel } from './../../../models/entities/user-model';
import { GenericResponseModel } from './../../../models/utilities/generic.response.model';
import { changePasswordModel } from './../../../models/entities/change-password.model';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from 'app/shared/auth/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import swal from 'sweetalert2';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';

@Component({
  selector: 'app-change-password-by',
  templateUrl: './change-password-by.component.html',
  styleUrls: ['./change-password-by.component.scss']
})
export class ChangePasswordByComponent implements OnInit {

  errorMessage: any;
  form: any = {};
  passwordModel = new changePasswordModel();
  passwordValidationError = '';
  isErrorPassword = false;
  @Input() userInfoUpdate: any;
  @ViewChild('f') planForm: NgForm;
  newPassword: any;
  passwordConfirmation: any;

  constructor(
    private crudServices: CrudServiceService,
    private messageService: InfoMessagesService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    console.log('desde el camnbio de pass')
    console.log('aloh ' + this.userInfoUpdate)
    // this.configPage();
  }

  changePassword() {

  }

  passwordValidation() {
    if (this.newPassword !== this.passwordConfirmation) {
      this.passwordValidationError = '¡Las contraseñas no coinciden!';
      this.isErrorPassword = true;
      return;
    } else if (this.newPassword === this.passwordConfirmation) {
      this.passwordValidationError = '¡Las contraseñas no coinciden!';
      this.isErrorPassword = false;
      return;
    }
  }

  preSubmit() {
    this.passwordValidation();
    this.passwordModel.newPassword = this.newPassword;
    this.crudServices.createModel('api/usuarios/actualizar-contrasena', this.passwordModel).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          console.log(genericResponse)
          this.messageService.getInfoMessageUpdate().then(
            (response) => {
              this.modalService.dismissAll();
            }
          )
        } else if (genericResponse.code === 201) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Actualizacion fallida')
        } else if (genericResponse.code === 202) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Actualizacion fallida')
        }

      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
        this.errorMessage = JSON.stringify(error.error.mensaje);
      }
    );
  }


  closeWindow() {
    this.modalService.dismissAll();
  }

}
