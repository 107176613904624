import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { CrudServiceService } from "app/shared/backend/cruds/crud-service.service";
import { HandleServicesService } from "app/shared/handle-service/handle-services.service";
import { InfoMessagesService } from "app/shared/messages/info-messages.service";
import { TokenStorageService } from "app/shared/storage-services/token-storage.service";
import { InvoiceModel } from "models/entities/invoice-model";
import { PlansModel } from "models/entities/plans-model";
import { UserModel } from "models/entities/user-model";
import { GenericResponseModel } from "models/utilities/generic.response.model";
import { NgxSpinnerService } from "ngx-spinner";
import swal from "sweetalert2";
import * as XLSX from "ts-xlsx";
import { CreateUserComponent } from "../create-user/create-user.component";
import { LogErroresComponent } from "../log-errores-carga-masiva/log-errores.component";
import { ToPairProfileComponent } from "../to-pair-profile/to-pair-profile.component";
import { UpdateUserComponent } from "../update-user/update-user.component";
import { filterCoownerShipModel } from "./../../../models/entities/filter-coownership-model";
import { UserCreationModel } from "./../../../models/entities/user-creation-model";
import { isThisQuarter } from "date-fns";

@Component({
  selector: "app-list-user",
  templateUrl: "./list-user.component.html",
  styleUrls: ["./list-user.component.scss"],
})
export class ListUserComponent implements OnInit {
  rows: any;
  temp = [];
  board: string;
  errorMessage: string;
  dato: any;
  titlePage: any;
  buttonCreate: any;
  typeUser: any;
  button = [];
  email: any;
  isSignedUp = false;
  isSignUpFailed = false;
  roleTitle = this.tokenStorage.getAuthorities();
  nombreEmpresa: any;
  adminStatus: any;
  isAdmin: boolean;
  createType = "OWNER";
  showPair = false;
  file: File;
  newInvoice: InvoiceModel = new InvoiceModel();
  usuarioObjetivo: UserModel;
  public canShowLoading = false;
  updPhoto: boolean = false;
  photo: any;
  userAux: UserModel = new UserModel();
  filter: filterCoownerShipModel = new filterCoownerShipModel();
  rowsTower: [];
  datoTower: any;
  tempTower: string[];
  towerName = "";

  columns = [
    { prop: "completeName" },
    { name: "id" },
    { prop: "email" },
    { prop: "role" },
    { prop: "documentNumber" },
  ];
  role: any;

  @ViewChild(DatatableComponent) table: DatatableComponent;
  arrayBuffer;
  excel;

  constructor(
    private crudServices: CrudServiceService,
    private modalService: NgbModal,
    private tokenStorage: TokenStorageService,
    private messageService: InfoMessagesService,
    private spinner: NgxSpinnerService,
    private handleService: HandleServicesService
  ) {}

  ngOnInit() {
    this.configPage();
    this.getInfoUserAuthenticated();
    this.configSelectTowers();
    console.log(JSON.stringify(this.usuarioObjetivo));
    this.role = this.tokenStorage.getAuthorities();
  }

  configSelectTowers() {
    this.crudServices
      .getModel(
        "api/torre-bloque/obtener-torres?id=" + this.tokenStorage.getCompanyId()
      )
      .subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.datoTower = genericResponse.answerList;
            this.tempTower = { ...this.datoTower };
            this.rowsTower = this.datoTower;
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "Sin copropiedades"
            );
          }
        },
        (error) => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  getInfoUserAuthenticated() {
    this.crudServices
      .getModel("api/usuarios/obtener-info-usuario-autenticado")
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
          }
        },
        (error) => {
          this.errorMessage = "Credenciales incorrectas";
          // this.messagesServicesCtrl.getInfoMessagePersonalized('error', 'Inténtalo de nuevo', 'Credenciales incorrectas');
        }
      );
  }

  configPage() {
    if (this.tokenStorage.getAuthorities() === "ROLE_SUPER") {
      this.showPair = true;
      this.isAdmin = false;
      this.titlePage = "Gestión de administradores.";
      this.buttonCreate = "Agregar administrador";
      this.crudServices.getModel("api/usuarios/super/listar-todos").subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.dato = genericResponse.answerList;
            this.rows = this.dato;
            console.log(this.dato);

            // this.dato.concat(["adminStaus"])
            this.dato.forEach((element) => {
              if (element.role === "ROLE_ADMIN") {
                element.role = "Administrador";
              } else if (element.role === "ROLE_COLLABORATOR") {
                element.role = "Colaborador";
              } else if (element.role === "ROLE_USER") {
                element.role = "Usuario";
              } else if (element.role === "ROLE_OWNER") {
                element.role = "Propietario";
              } else if (element.role === "ROLE_COOWNER") {
                element.role = "Copropietario";
              } else {
                element.role = "Superadministrador";
              }
            });
          }
          if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se encontraron usuarios"
            );
          }
        },
        (error) => {
          this.errorMessage = `${error.status}: ${error.error.message}`;
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
    } else {
      this.showPair = false;
      this.isAdmin = true;
      console.log(this.tokenStorage.getCoownerShipId());
      this.titlePage = "Gestión de propietarios";
      this.buttonCreate = "Agregar propietarios";
      const extraTowerNameParams = this.towerName
        ? "&towerName=" + this.towerName
        : "";
      const extraCoOwnershipParams = this.filter.number
        ? "&coOwnershipName=" + this.filter.number
        : "";
      const extraNameParams = this.filter.name
        ? "&name=" + this.filter.name
        : "";

      this.crudServices
        .getModel(
          "api/usuarios/listar-propietarios-unidad-residencial?id=" +
            this.tokenStorage.getCoownerShipId() +
            extraTowerNameParams +
            extraCoOwnershipParams +
            extraNameParams
        )
        .subscribe(
          (genericResponse) => {
            if (genericResponse.code === 200) {
              this.dato = genericResponse.answerList;
              this.rows = this.dato;
              console.log(this.dato);
              this.dato.forEach((element) => {
                if (element.role === "ROLE_ADMIN") {
                  element.role = "Administrador";
                } else if (element.role === "ROLE_COLLABORATOR") {
                  element.role = "Colaborador";
                } else if (element.role === "ROLE_USER") {
                  element.role = "Usuario";
                } else if (element.role === "ROLE_OWNER") {
                  element.role = "Propietario";
                } else if (element.role === "ROLE_COOWNER") {
                  element.role = "Copropietario";
                } else {
                  element.role = "Superadministrador";
                }
              });
            }
            if (genericResponse.code === 400) {
              this.messageService.getInfoMessagePersonalized(
                "warning",
                genericResponse.answer,
                "No se encontraron usuarios"
              );
            }
          },
          (error) => {
            this.errorMessage = `${error.status}: ${error.error.message}`;
            console.error(error);
            this.messageService.getInfoMessageBadInternet();
          }
        );
    }
  }

  onList(id) {
    this.crudServices
      .getModel("api/usuarios/buscar-por-id?userId=" + id)
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            const modalRef = this.modalService.open(UpdateUserComponent, {
              windowClass: "",
              size: "lg",
              backdrop: "static",
            });

            modalRef.componentInstance.userInfoUpdate =
              genericResponse.genericObject;
            modalRef.componentInstance.isView = true;
          } else if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se encontro el usuario"
            );
          }
        },
        (error) => {
          this.errorMessage = `${error.status}: ${error.error.message}`;
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  updateFilterByName(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function (d: any) {
      if (d.completeName) {
        return d.completeName.toLowerCase().indexOf(val) !== -1 || !val;
      } else {
        return false;
      }
    });
    this.rows = temp;
    this.table.offset = 0;
  }

  onUpdateUser(id) {
    this.crudServices
      .getModel("api/usuarios/buscar-por-id?userId=" + id)
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            const modalRef = this.modalService.open(UpdateUserComponent, {
              windowClass: "",
              size: "lg",
              backdrop: "static",
            });
            modalRef.componentInstance.userInfoUpdate =
              genericResponse.genericObject;
            modalRef.componentInstance.passEntry.subscribe(
              (userUpdated: UserModel) => {
                const selectedPlan: any = userUpdated.activatedPlanId;
                userUpdated.activatedPlanId = new PlansModel();
                userUpdated.activatedPlanId.id = selectedPlan;
                userUpdated.cellphone = userUpdated.cellphone;
                userUpdated.phone = userUpdated.cellphone;
                swal({
                  title: "¿Está seguro que desea realizar la actualización?",
                  showCancelButton: true,
                  confirmButtonText: "Aceptar",
                  type: "warning",
                }).then((result) => {
                  if (result.value) {
                    this.crudServices
                      .createModel("api/usuarios/actualizar", userUpdated)
                      .subscribe(
                        (genericUpdatedResponse) => {
                          if (genericUpdatedResponse.code === 200) {
                            this.messageService
                              .getInfoMessageUpdate()
                              .then((response) => {
                                this.configPage();
                                this.modalService.dismissAll();
                              });
                          }
                          if (genericUpdatedResponse.code === 400) {
                            this.messageService.getInfoMessagePersonalized(
                              "warning",
                              genericResponse.answer,
                              "No se realizó la actualización"
                            );
                          }
                        },
                        (error) => {
                          this.errorMessage = `${error.status}: ${error.error.message}`;
                          console.error(error);
                          this.messageService.getInfoMessageBadInternet();
                        }
                      );
                  }
                });
              }
            );
          } else if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se encontró el usuario"
            );
          }
        },
        (error) => {
          this.errorMessage = `${error.status}: ${error.error.message}`;
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  public uploadPhoto(filePhoto: any, modelo: UserModel): void {
    console.log("TENGO QUE SUBIR LA FOTO" + modelo.id);
    const folderPath = "admin " + modelo.id;
    var data = new FormData();
    data.append("archivo", filePhoto);
    let extension = filePhoto.name.split(".").pop();

    let path =
      "api/archivos/cargar-firma-admin?folderPath=" +
      folderPath +
      "&relationalId=" +
      modelo.id +
      "&extension=" +
      extension;
    this.crudServices
      .createFile(path, data)
      .toPromise()
      .then((genericResponse) => {
        console.log(genericResponse);
        console.log("----");
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageCreate().then((result) => {
            this.modalService.dismissAll();
          });
        } else {
          this.messageService.getInfoMessagePersonalized(
            "warning",
            "Se creó el usuario correctamente pero verifique la foto por favor",
            "Atención!"
          );
        }
      })
      .catch((error) => {
        this.handleService.handleError(error);
      });
  }

  onDelete(email) {
    this.crudServices
      .deleteModel("api/usuarios/borrar?username=" + email)
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.messageService.getInfoMessageDelete();
            this.configPage();
          } else if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se elimino el usuario"
            );
          }
        },
        (error) => {
          this.errorMessage = `${error.status}: ${error.error.message}`;
          if (error.error.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              error.error.answer,
              "Error eliminando el usuario"
            );
          } else {
            this.messageService.getInfoMessageBadInternet();
          }
        }
      );
  }

  openRegister() {
    const modalRef = this.modalService.open(CreateUserComponent, {
      windowClass: "modal",
      size: "lg",
      backdrop: "static",
    });
    modalRef.componentInstance.createType = this.createType;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      if (receivedEntry === "Usuario Registrado") {
        this.configPage();
      } else {
        return false;
      }
    });
  }

  toPairProfile(id) {
    this.crudServices
      .getModel("api/usuarios/buscar-por-id?userId=" + id)
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            const modalRef = this.modalService.open(ToPairProfileComponent, {
              windowClass: "",
              size: "lg",
              backdrop: "static",
            });
            // Enviamos la información al modal hijo.
            modalRef.componentInstance.userInfoUpdate =
              genericResponse.genericObject;
          } else if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se encontró el usuario"
            );
          }
        },
        (error) => {
          this.errorMessage = error.error.message;
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }

  deleteWarning(email) {
    event.preventDefault();
    swal({
      title: "¿Está seguro que desea eliminar el registro?",
      text: "No podrá recuperar el registro luego",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "¡Sí, eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        this.onDelete(email);
      }
    });
  }

  changeAdminStatus(id) {
    this.crudServices
      .getModel("api/usuarios/super/cambiar-estado-activacion?userId=" + id)
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.adminStatus = genericResponse.genericObject;
            this.configPage();
          } else if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se cambió el estado del administrador"
            );
          }
        },
        (error) => {
          this.errorMessage = error.error.message;
          console.error(error);
          // this.messageService.getInfoMessageBadInternet();
          this.messageService.getInfoMessageError();
        }
      );
  }

  getAdminStatus(id) {
    this.crudServices
      .getModel("api/usuarios/super/obtener-estado-admin?userId=" + id)
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            return genericResponse.genericObject;
          } else if (genericResponse.code === 400) {
            return this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se pudo obtener el estado del administrador"
            );
          }
        },
        (error) => {
          this.errorMessage = error.error.message;
          console.error(error);
          this.messageService.getInfoMessageUpdate();
        }
      );
  }

  public incomingfile(event) {
    this.file = event.target.files[0];
    swal({
      title: "Confirmar carga?",
      text: "Este archivo creará nuevos registros!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si , confirmar!",
    }).then((result) => {
      if (result.value) {
        this.canShowLoading = true;
        this.spinner.show(); // SE MUESTRA LA VENTANA DE CARGANDO
        this.createMassive();
      } else {
        $("#uploadFile").val("");
      }
    });
  }
  createMassive() {
    let banderaLoad = true;

    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      const data = new Uint8Array(this.arrayBuffer);
      const arr = new Array();

      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }

      let numeroFila = 1;

      const bstr = arr.join("");
      const workbook = XLSX.read(bstr, { type: "binary" });
      const first_sheet_name = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[first_sheet_name];
      const excel = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      this.excel = new Array();

      excel.forEach((valor, clave) => {
        numeroFila++;
        const modelAux = new UserCreationModel();

        if (
          valor["NOMBRE_COMPLETO"] !== undefined &&
          valor["NOMBRE_COMPLETO"] !== ""
        ) {
          modelAux.userInfo.completeName = valor["NOMBRE_COMPLETO"];
        } else {
          banderaLoad = false;
          this.spinner.hide();
          this.canShowLoading = false;
          $("#uploadFile").val("");
          return this.messageService.getInfoMessagePersonalized(
            "warning",
            "El campo nombre completo en la fila " +
              numeroFila +
              " no puede estar vacio",
            "Atención!"
          );
        }

        if (valor["EMAIL"] !== undefined && valor["EMAIL"] !== "") {
          modelAux.userInfo.email = valor["EMAIL"];
          //modelAux.user.username = modelAux.userInfo.email;
          modelAux.user.username = valor["NUMERO_DOCUMENTO"];
        } else {
          banderaLoad = false;
          this.spinner.hide();
          this.canShowLoading = false;
          $("#uploadFile").val("");
          return this.messageService.getInfoMessagePersonalized(
            "warning",
            "El campo email en la fila " + numeroFila + " no puede estar vacio",
            "Atención!"
          );
        }

        if (
          valor["NUMERO_DOCUMENTO"] !== undefined &&
          valor["NUMERO_DOCUMENTO"] !== ""
        ) {
          modelAux.userInfo.documentNumber = valor["NUMERO_DOCUMENTO"];
          modelAux.userInfo.role = "ROLE_OWNER";
          modelAux.user.password = modelAux.userInfo.documentNumber;
        } else {
          banderaLoad = false;
          this.spinner.hide();
          this.canShowLoading = false;
          $("#uploadFile").val("");
          return this.messageService.getInfoMessagePersonalized(
            "warning",
            "El campo numero de documento en la fila " +
              numeroFila +
              " no puede estar vacio",
            "Atención!"
          );
        }

        if (
          valor["TIPO_DOCUMENTO"] !== undefined &&
          valor["TIPO_DOCUMENTO"] !== ""
        ) {
          modelAux.userInfo.documentTypeId.name = valor["TIPO_DOCUMENTO"];
        } else {
          banderaLoad = false;
          this.spinner.hide();
          this.canShowLoading = false;
          $("#uploadFile").val("");
          return this.messageService.getInfoMessagePersonalized(
            "warning",
            "El campo tipo de documento en la fila " +
              numeroFila +
              " no puede estar vacio",
            "Atención!"
          );
        }

        modelAux.userInfo.address = valor["DIRECCION"];
        modelAux.userInfo.phone = valor["TELEFONO"];
        modelAux.userInfo.cellphone = modelAux.userInfo.phone;

        this.excel.push(modelAux);
      });

      if (banderaLoad) {
        return this.loadMassive(this.excel);
      }
    };

    fileReader.readAsArrayBuffer(this.file);
  }

  loadMassive(list) {
    const path = "api/usuarios/crear-masivo";
    this.crudServices
      .createModel(path, list)
      .toPromise()
      .then((result: GenericResponseModel) => {
        if (result.code === 200) {
          if (result.answerList.length > 0) {
            this.messageService
              .getInfoMessagePersonalizedLoadMassive(
                "error",
                "No se pudo realizar la carga masiva, a continuación podra ver los problemas presentados",
                "Error!"
              )
              .then(() => {
                $("#uploadFile").val("");
                this.canShowLoading = false;
                this.spinner.hide();
                this.lanzarModalErroresCargaMasiva(result.answerList);
              });
          } else {
            this.messageService
              .getInfoMessagePersonalized(
                "success",
                "Se cargaron todos los propietarios correctamente",
                "Éxito!"
              )
              .then(() => {
                $("#uploadFile").val("");
                this.canShowLoading = false;
                this.spinner.hide();
                this.configPage();
              });
          }
        } else if (result.code === 400) {
          if (result.answerList.length > 0) {
            this.messageService
              .getInfoMessagePersonalizedLoadMassive(
                "error",
                "No se pudo realizar la carga masiva, a continuación podra ver los problemas presentados",
                "Error!"
              )
              .then(() => {
                $("#uploadFile").val("");
                this.canShowLoading = false;
                this.spinner.hide();
                this.lanzarModalErroresCargaMasiva(result.answerList);
              });
          } else {
            $("#uploadFile").val("");
            this.canShowLoading = false;
            this.spinner.hide();
            this.messageService.getInfoMessagePersonalized(
              "warning",
              result.answer,
              "Error!"
            );
          }
        }
      })
      .catch((error) => {
        $("#uploadFile").val("");
        this.canShowLoading = false;
        this.spinner.hide();
        this.messageService.getInfoMessageError();
      });
  }

  lanzarModalErroresCargaMasiva(logErrores) {
    const modalRef = this.modalService.open(LogErroresComponent, {
      windowClass: "my-class",
      backdrop: "static",
    });
    modalRef.componentInstance.propietario = true;
    modalRef.componentInstance.log = logErrores;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      modalRef.dismiss();
    });
  }

  clean() {
    this.filter = new filterCoownerShipModel();
    this.towerName = "";
    this.configPage();
  }

  saveInvoicePlan(id) {
    this.crudServices
      .getModel("api/usuarios/buscar-id-enviar-factura?usuarioActual=" + id)
      .subscribe(
        (genericResponse) => {
          if (genericResponse.code === 200) {
            this.usuarioObjetivo = genericResponse.genericObject;
            this.messageService.getInfoMessagePersonalized(
              "success",
              " ",
              "¡Correo enviado correctamente!"
            );
          } else if (genericResponse.code === 400) {
            this.messageService.getInfoMessagePersonalized(
              "warning",
              genericResponse.answer,
              "No se encontro el usuario"
            );
          }
        },
        (error) => {
          this.errorMessage = `${error.status}: ${error.error.message}`;
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        }
      );
  }
}
